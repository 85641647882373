//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : Otp.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240701     Glynnis      create Otp.jsx
//* 20240705     Jecyline     add handleChange(), handleVerifyOtp(), handleResendOtp() function
//* 20240712     Jecyline     add refresh_token to local storage
//* 20240715     Jecyline     change navigate dashboard to first-time-login
//* 20240718     Jecyline     language selection module
//* 20240805     Jecyline     set feedbackModalShown to localStorage
//* 20240806     Jecyline     amend way to set refresh_token to local storage
//
//***************************************************************************/
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Applogo } from "../../../Routes/ImagePath";
import { CoverPhoto } from "../../../Routes/ImagePath";
import LanguageSelector from '../../layout/LanguageSelector';
import { useTranslation } from 'react-i18next';
import { handleApiError } from '../../../utils/errorHandler';
import { guestApi } from '../../../services/api';
import { useDispatch } from 'react-redux';

const Otp = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [otp, setOtp] = useState(new Array(4).fill(''));
  const [message, setMessage] = useState('');
  const [timer, setTimer] = useState(0); // Timer state
  const [resendDisabled, setResendDisabled] = useState(false); // Disable resend link

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const { email , redirect } = location.state || {};

  const handleChange = (element, index) => {
    const value = element.value.replace(/[^0-9]/g, ''); // Only allow numbers
    if (value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
    }
    // Move to the next input field automatically
    if (value && element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleVerifyOtp = async (e) => {
    setErrorMessage('')

    e.preventDefault();
    const otpCode = otp.join('');

    if(!email){

      setErrorMessage(t(`errors.INVALID_REQUEST`)); // Translate the custom errCode to a user-friendly message
      setTimeout(() => {
        setErrorMessage('');
        navigate('/');
      }, 3000);
            
    } else {
      try {
        // const response = await axios.post(api_url + '/verify-otp', {
        //   email, otp: otpCode , redirect
        // });
        const response = await guestApi.post('/verify-otp', { 
          email, otp: otpCode , redirect
        });

        const responseData = response.data;

        if(redirect === 'first-time-login'){ //first time login
          const { access_token, token_type, expires_in, user } = responseData;
          dispatch({ type: 'RESET_ALL' });
          localStorage.clear();  // Clears all local storage
          sessionStorage.clear(); 
          
          // Save tokens and user data to local storage
          localStorage.setItem('access_token', access_token);
          localStorage.setItem('token_type', token_type);
          localStorage.setItem('expires_in', expires_in);
          localStorage.setItem('refresh_token', user.refresh_token);
          localStorage.setItem('feedbackModalShown', 'false');
          localStorage.setItem('user_id', user._id);
        } else { //reset-password
          //do nothing
        }

        // Navigate to the dashboard or another route
        console.log(email);
        navigate('/'+redirect, { state: { email: email } });
          
        // Clear form 
        setOtp(new Array(4).fill(''));
        
      } catch (error) {
        console.error('API Error:', error);
        setErrorMessage('')  

        handleApiError(error, setErrorMessage, null, t); // Call the error handler function

        setTimeout(() => setErrorMessage(''), 10000);
      }
    }
  }

  useEffect(() => {
    let interval = null;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setResendDisabled(false); // Re-enable resend link
    }

    return () => clearInterval(interval);
  }, [timer]);

  const handleResendOtp = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if(!email){

      setMessage('INVALID_REQUEST');
      setTimeout(() => {
        setMessage('');
        navigate('/');
      }, 3000);

    } else {

      if (timer === 0) {
        // Call your OTP resend logic here
        console.log('OTP Resent');
  
        // Start the timer
        setTimer(60);
        setResendDisabled(true);
      }
  
      try {
        // const response = await axios.post(api_url + '/resend-otp', {
        //   email , redirect
        // });
        const response = await guestApi.post('/resend-otp', { email, redirect });

        const responseData = response.data;
        console.log(responseData);

        setSuccessMessage(responseData.message);
        setTimeout(() => setSuccessMessage(''), 5000);

      } catch (error) {
        console.error('API Error:', error);
        setErrorMessage('')  

        handleApiError(error, setErrorMessage, null, t); // Call the error handler function

        setTimeout(() => setErrorMessage(''), 10000);
      } finally {
        setIsSubmitting(false);
      }
    }
  }

  return (
    <>
      <div className="account-page">
        {/* /reset Section */}
        <div className="otp-wrapper">
          {/* Main Wrapper */}
          <div className="main-wrapper">
            <div className="account-content">
              <div className="container">
                {/* Account Logo */}
                <div className="account-logo">
                  <Link to="/admin-dashboard">
                    <img src={Applogo} alt="Omninexus" />
                  </Link>
                </div>
                {/* /Account Logo */}
                <div className="account-box">
                  <div className="account-wrapper">
                    {/* <LanguageSelector /> */}
                    <h3 className="account-box-title">{t('OTP')}</h3>
                    {/* <p className="account-subtitle">Verify your account</p> */}
                    <p className="account-box-subtitle">{t('Please check your email for OTP')}.</p>
                    {/* Message Display */}
                    {successMessage && (
                    <div className="alert alert-success" role="alert">
                        {successMessage}
                      </div>
                    )}
                    {errorMessage && (
                      <div className="alert alert-danger" role="alert">
                        {errorMessage}
                      </div>
                    )}
                    {/* /Message Display */}
                    {/* Message Display */}
                    {message && (
                      <div className={`alert ${message.includes('success') ? 'alert-success' : 'alert-danger'}`}>
                        {t(`errors.${message}`)}
                      </div>
                    )}
                    {/* /Message Display */}

                    {/* Account Form */}
                    <form action="admin-dashboard">
                      <div className="otp-wrap">
                        {otp.map((data, index) => (
                          <input
                          key={index}
                            type="text"
                            placeholder={0}
                            maxLength={1}
                            className="otp-input"
                            value={otp[index]}
                            onChange={(e) => handleChange(e.target, index)}
                            onFocus={(e) => e.target.select()}
                          />
                        ))}
                      </div>
                      <div className="input-block mb-4 text-center">
                        <Link
                          to="#"
                          className="btn btn-primary account-btn"
                          onClick={handleVerifyOtp}
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? t('Submitting...') : t('Enter')}
                        </Link>
                      </div>
                      <div className="account-footer">
                        <p>
                          {t('Not yet received?')} 
                          <Link 
                            to="#"
                            onClick={handleResendOtp}
                            style={{ pointerEvents: resendDisabled ? 'none' : 'auto' }}
                          >
                            {/* {resendDisabled ? ` Resend OTP (${timer}s)` : ' Resend OTP'} */}
                            {resendDisabled ? (
                              `  ${t('Resend OTP')} (${timer}${t('s')})`
                            ) : (
                              `  ${t('Resend OTP')}`
                            )}
                          </Link>
                        </p>
                      </div>
                    </form>
                    {/* /Account Form */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Main Wrapper */}
        </div>
        
      </div>
    </>
  );
};

export default Otp;
