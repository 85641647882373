//****************************************************************************
//* Filename  : campaignSummarySagas.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 Oct 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20241001     Jecyline     Create campaignSummarySagas.jsx
//
//***************************************************************************/
import { call, put, takeLatest, delay, all, select } from 'redux-saga/effects';
// import i18n from 'i18next';
// import { backend_url } from '../../base_urls';
import { getFullImageUrl } from '../../utils/urlUtils';
import { 
  getCampaignListAction,
  campaignClickAction,
} from '../actions/campaignSummaryActions';

import { 
  setCampaignList, 
  setSelectedCampaignId,
  setCurrentCampaignData,
  setIsLoading,
} from '../slices/campaignSummarySlice';

import { 
  selectCurrentCampaignData
} from '../selectors/campaignSummarySelectors';

import { 
  getCampaignSummaryListApi,
  getCampaignSummaryDetailByIdApi,
} from '../../api/campaignSummaryApi';

function* handleGetCampaignList(action) {
  console.log('campaignSummarySagas: handleGetCampaignList', action);
  const { name } = action.payload;

  try {
    const response = yield call(getCampaignSummaryListApi, name);
    const { chats } = response.data;

    yield put(setCampaignList(chats));

  } catch (error) {
    console.error('handleGetCampaignList Saga Error:', error);

  }
}

function* handleCampaignClick(action) {
  console.log('campaignSummarySagas: handleCampaignClick', action);

  const { chatId } = action.payload;

  try {
    const currentCampaignData = yield select(selectCurrentCampaignData);

    if(!currentCampaignData || (currentCampaignData && chatId !== currentCampaignData._id)){

      yield put(setCurrentCampaignData(null));
      yield put(setSelectedCampaignId(chatId));
      yield put(setIsLoading(true));

      yield call(handleFetchCampaignSummaryDetails, { payload: { chatId } });
    }
  } catch (error) {
    console.error('handleCampaignClick Saga Error:', error);

  }
}

function* handleFetchCampaignSummaryDetails(action) {
  console.log('campaignSummarySagas: handleFetchCampaignSummaryDetails', action);

  const { chatId } = action.payload;
  try {
    
    const response = yield call(getCampaignSummaryDetailByIdApi, chatId);
    const { chat, selected_copy_id, selected_image_id, selected_copy, selected_image_url, selected_image_desc } = response.data;

    yield put(setIsLoading(false));
    yield put(setCurrentCampaignData({
      chat: chat,
      copy_id: selected_copy_id ?? '',
      image_id: selected_image_id ?? '',
      selected_copy: selected_copy ?? '',
      selected_image_url: selected_image_url ? getFullImageUrl(selected_image_url) : '',
      selected_image_desc: selected_image_desc ?? '',
    }));
    console.log('handleFetchCampaignSummaryDetails: chat', chat);

  } catch (error) {
    console.error('handleFetchCampaignSummaryDetails Saga Error:', error);
  }
}


// Root Saga for Campaign Summary
export function* campaignSummarySagas() {
  yield takeLatest(getCampaignListAction.type, handleGetCampaignList);
  yield takeLatest(campaignClickAction.type, handleCampaignClick);

}

export default campaignSummarySagas;


