//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : sidebardata.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240701     Glynnis      Function features short description
//* 20240718     Jecyline     change MAIN to main
//* 20240724     Jecyline     add Chats to sidebar
//* 20240924     Glynnis      amended chat to campaign to sidebar
//
//***************************************************************************/
export const SidebarData = [


{
    tittle: 'main',
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: 'Dashboard',
        hasSubRoute: false,
        showSubRoute: false,
        route: "/dashboard",
        icon: "la la-magic",
      },
      {
        menuValue: 'Campaigns',
        hasSubRoute: false,
        showSubRoute: false,
        route: "/chat",
        icon: "fa fa-star",
      },
      {
        menuValue: 'Campaign Summary',
        hasSubRoute: false,
        showSubRoute: false,
        route: "/campaign-summary",
        icon: "fa fa-clipboard",
      },
      {
        menuValue: 'File Manager',
        hasSubRoute: false,
        showSubRoute: false,
        route: "/file-manager",
        icon: "fa fa-folder",
      },
      // {
      //   menuValue: 'Chats',
      //   hasSubRoute: true,
      //   showSubRoute: false,
      //   route: "#",
      //   icon: "fa fa-comment",
      //   subMenus: [
      //     {
      //       menuValue: 'Chats List',
      //       route: "/chat-list",
      //     },
      //     {
      //       menuValue: 'File Manager',
      //       route: "/file-manager",
      //     },
      //   ],
      // },
    ],
  },
];
