//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : chatRightsidebar.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign   Description
//* ------------ ----   ------------------------------------------------------
//* 20240701     Glynnis Function features short description
//
//***************************************************************************/
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useAxiosInterceptors from '../../../../hooks/useAxiosInterceptors'; // Import the custom hook
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { getFullImageUrl } from '../../../../utils/urlUtils';
import MarkdownRenderer from '../../../../utils/MarkdownRenderer';
import { 
  selectChatStageForCurrentChat,
} from '../../../../store/selectors/chatSelectors';
import { BiLike } from "react-icons/bi";
import { GoComment } from "react-icons/go";
import { PiShareFat } from "react-icons/pi";
import CreativeSelectionModal from "./modal/CreativeSelectionModal";
import { 
  updateContentFlagAction
} from '../../../../store/actions/chatActions';
import ImageModal from "./modal/imageModal";

const ChatRightsidebar = () => {
  useAxiosInterceptors(); 
  const { t } = useTranslation();

  const currentChatData = useSelector((state) => state.chat.currentChatData);
  const favouriteList = useSelector((state) => state.chat.favouriteList);
  const chatStage = useSelector((state) => selectChatStageForCurrentChat(state));

  const dispatch = useDispatch();

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isCreativeSelectionModalOpen, setIsCreativeSelectionModalOpen] = useState(false);
  const [modalType, setModalType] = useState(''); // 'images' or 'copies'
  const [chatId, setChatId] = useState(''); // 'images' or 'copies'
  const [chatDetailId, setChatDetailId] = useState(''); // 'images' or 'copies'

  const [showImageModal, setShowImageModal] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  const selectImage = (chat_id) => {
    console.log('selectImage chat_id', chat_id);
    setChatId(chat_id);
    setModalType('isImage');

    setIsCreativeSelectionModalOpen(true);
  };

  const selectCopy = (chat_id) => {
    console.log('selectCopy chat_id', chat_id);
    setChatId(chat_id);
    setModalType('isCopy');

    setIsCreativeSelectionModalOpen(true);
  };

  const toggleSidebar = () => {
    setIsCollapsed((prev) => !prev);
  };

  const handleCreativeSelectionModalSubmit = (chat_detail_id, flag, value) => {
    // console.log('chatRightSidebar: handleCreativeSelectionModalSubmit');

    console.log('chat_detail_id:', chat_detail_id);
    console.log('flag:', flag);
    console.log('value:', value);

    if(chat_detail_id && flag) {      /////////////////////////////////////
      // chatAction: updateContentFlagAction
      dispatch(updateContentFlagAction({
        chat_detail_id,
        flag,
        value
      }));
    }
  };

  //manage image
  const handleOpenImageModal = (url, chat_detail_id) => {
    setShowImageModal(true);
    setChatDetailId(chat_detail_id)
    setImageUrl(url);
  };

  const handleCloseImageModal = () => {
    setShowImageModal(false);
    setImageUrl('');
    setChatDetailId('');
  };

  return (
    <>
      <div className="chat-sidebar">
        
        <div
          className="chat-sidebar-collapse"
        >
            <ul
              className="nav nav-tabs nav-tabs-solid nav-justified mb-0"
              style={{ marginLeft: "10px", flex: 1 }}
            >
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  to="#collection"
                  data-bs-toggle="tab"
                >
                  {t('Collection')}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="#creative-preview"
                  data-bs-toggle="tab"
                >
                  {t('Creative Preview')}
                </Link>
              </li>
            </ul>
        </div>

        {/* Sidebar Content */}
        <div className="chat-window video-window">
          
            <div className="tab-content chat-contents">
              <div className="content-full tab-pane show active" id="profile_tab">
                <div className="display-table">
                  <div className="table-row">
                    <div className="table-body">
                      <div className="table-content">
                        <div className="transfer-files">
                          <div className="tab-content">
                            <div className="chat-sidebar-collection-tab tab-pane show active" id="collection">
                              { favouriteList && favouriteList.length > 0 && (
                                <>
                                  { favouriteList.map((item) => (
                                    <div className="chat-sidebar-collections" key={item._id}>
                                      {item.chat_detail_id.ui_type === 'brandboost' && item.chat_detail_id.reply ? (
                                        <>
                                          {(() => {
                                            const cleanedJsonString = item.chat_detail_id.reply.replace(/\\(?!n)/g, '');
                                            const decodedData = JSON.parse(cleanedJsonString); // Decoding the JSON string

                                            // Return the UI based on `decodedData`
                                            if (decodedData) {
                                              return (
                                                <div className="brandboost-container">
                                                  <div className="brandboost-box">
                                                    <p className="text-bold">{t('Your')}{decodedData.keyword_approach === 'image' ? (
                                                        `${t('Image')}`
                                                      ) : decodedData.keyword_approach === 'prompt' ? (
                                                        `${t('Keyword')}`
                                                      ) : null} :
                                                    </p>

                                                    {decodedData.keyword_approach === 'prompt' 
                                                      ? <p>{decodedData.keyword}</p> || `${t('No product keyword')}`
                                                      : decodedData.keyword_url 
                                                        ? 
                                                          <Link
                                                            className="link attach-icon"
                                                            to="#"
                                                            // onClick={() => handleOpenImageModal(getFullImageUrl(decodedData.keyword_url))}
                                                          >
                                                            <img src={decodedData.keyword_url} alt={decodedData.keyword || 'Product Image'} className="keyword-img" /> 
                                                          </Link>
                                                        : `${t('No image available')}`}
                                                  </div>
                                                  <div className="brandboost-box">
                                                    <p className="underline text-bold mb-3">{t('BrandBoost Insights')}</p>
                                                    <div className="mb-3">
                                                      {decodedData.summary 
                                                        ? decodedData.summary.split('\n').map((line, index) => (
                                                            <React.Fragment key={index}>
                                                              <p className="mb-2 summary">{line}</p>
                                                              {/* <br /> */}
                                                            </React.Fragment>
                                                          ))
                                                        : `${t('No summary available')}`}
                                                    </div>
                                                    
                                                    <p>{t('Keywords that matches your product')}:</p>
                                                    <ul className="mb-3">
                                                      {decodedData.flexible_spec_interest && decodedData.flexible_spec_interest.length > 0 ? (
                                                        decodedData.flexible_spec_interest.map((item, index) => (
                                                          <li key={index}>
                                                            <span>{index + 1}. {item.name}</span>
                                                          </li>
                                                        ))
                                                      ) : (
                                                        <li>{t('No keywords available')}</li>
                                                      )}
                                                    </ul>
                                                    <p>{t('Target Audience')}:</p>
                                                    <ul>
                                                      {decodedData.target_audience ? (
                                                        <>
                                                          <li>
                                                            <span>{`${t('Gender')}: ${decodedData.target_audience[0].gender || t('N/A')}`}</span>
                                                          </li>
                                                          <li>
                                                            <span>{`${t('Marital Status')}: ${decodedData.target_audience[0].married_status || t('N/A')}`}</span>
                                                          </li>
                                                          <li>
                                                            <span>{`${t('Language')}: ${decodedData.target_audience[0].language || t('N/A')}`}</span>
                                                          </li>
                                                          <li>
                                                            <span>
                                                              {`${t('Age range')}: ${decodedData.target_audience[0].age_min && decodedData.target_audience[0].age_max 
                                                              ? `${decodedData.target_audience[0].age_min} - ${decodedData.target_audience[0].age_max}`
                                                              : t('N/A')}`}
                                                            </span>
                                                          </li>
                                                        </>
                                                      ) : (
                                                        t('No target audience data')
                                                      )}
                                                    </ul>
                                                  </div>
                                                </div>
                                              );
                                            }
                                            return <p>Invalid data format</p>;
                                          })()}
                                        </>
                                      ) : (
                                        <>
                                        {item.type === 'image' ? (
                                          <>
                                            <p className="chat-sidebar-collection-title">{t('Images')}</p>
                                            <div className="chat-sidebar-collection-image"
                                              onClick={() => handleOpenImageModal(getFullImageUrl(item.chat_detail_id.reply_url), item.chat_detail_id._id)}
                                            >
                                              <img 
                                                src={getFullImageUrl(item.chat_detail_id.reply_url)} 
                                                alt={`Collection ${item._id}`} 
                                              />
                                            </div>
                                          </>
                                        ) : null}
                                        {item.type === 'copy' ? (
                                          <>
                                            <p className="chat-sidebar-collection-title">{t('Copywriting')}</p>
                                            <div className="chat-sidebar-collection-copy">
                                              <span>
                                                <MarkdownRenderer content={item.chat_detail_id.reply} />
                                              </span>
                                            </div>
                                          </>
                                        ) : null}
                                        {item.type === 'both' ? (
                                          <>
                                            <p className="chat-sidebar-collection-title">{t('Creatives')}</p>
                                            <div className="chat-sidebar-collection-image"
                                              onClick={() => handleOpenImageModal(getFullImageUrl(item.chat_detail_id.reply_url), item.chat_detail_id._id)}
                                            >
                                              <img 
                                                src={getFullImageUrl(item.chat_detail_id.reply_url)} 
                                                alt={`Collection ${item._id}`} 
                                              />
                                            </div>
                                            <div className="chat-sidebar-collection-copy">
                                              <span>
                                                <MarkdownRenderer content={item.chat_detail_id.reply} />
                                              </span>
                                            </div>
                                          </>
                                        ) : null}
                                        </>
                                      )}
                                      <hr />
                                    </div>
                                  ))}
                                </>
                              )}
                                {/* <p>
                                    {showFullText ? copywritingText : truncatedText}
                                    <span
                                      style={{
                                        color: "purple",
                                        cursor: "pointer",
                                        marginLeft: "5px",
                                      }}
                                      onClick={toggleText}
                                    >
                                      {showFullText ? "Show Less" : "Show More"}
                                    </span>
                                  </p> */}
                            </div>
                            <div className="chat-sidebar-preview-tab tab-pane" id="creative-preview">
                              { currentChatData && chatStage && chatStage.campaign_type !== 'BrandBoost' && (
                                <>
                                  {/* <p className="chat-sidebar-collection-title">Creatives</p> */}

                                  <div className="ad-post-container">
                                    <div className="ad-header">
                                      <div className="ad-profile">
                                        {/* <div>{currentChatData._id}</div> */}
                                        <img src={getFullImageUrl(currentChatData.chat_logo_url)} alt={`${currentChatData.chat_brand_name}'s profile`} className="profile-picture" />
                                        <div className="ad-page-name">{currentChatData.chat_brand_name}</div>
                                      </div>
                                    </div>

                                    {/* Copy section */}
                                    {chatStage.selected_copy ? (
                                      <div className={`ad-body line2`}
                                        onClick={() => chatStage.campaign_type === 'Streamline' && selectCopy(currentChatData._id)}
                                      >
                                        <MarkdownRenderer content={chatStage.selected_copy} />
                                      </div>
                                    ) : (
                                      <>
                                        {chatStage.campaign_type === 'Streamline' ? (
                                          <div className="placeholder-title">
                                            <div className=""
                                              onClick={() => selectCopy(currentChatData._id)} 
                                            >
                                              {t('Select Copy')}
                                            </div>
                                          </div>
                                        ) : chatStage.campaign_type !== 'VisualAds' ? (
                                          <div className="placeholder-title">
                                            <p>{t('No Description Available')}</p>
                                          </div>
                                        ) : null}
                                      </>
                                    )}

                                    {/* Image section */}
                                    {chatStage.selected_image_url ? (
                                      <div className="ad-image" 
                                        onClick={() => chatStage.campaign_type === 'Streamline' && selectImage(currentChatData._id)}
                                      >
                                        <img 
                                          src={getFullImageUrl(chatStage.selected_image_url)} 
                                          alt="Streamline Img"
                                        />
                                      </div>
                                    ) : (
                                      <>
                                        {chatStage.campaign_type === 'Streamline' ? (
                                          <div className="placeholder-image">
                                            <div className=""
                                              onClick={() => selectImage(currentChatData._id)} 
                                            >
                                              {t('Select Image')}
                                            </div>
                                          </div>
                                        ) : chatStage.campaign_type !== 'CopyCraft' ? (
                                          <div className="placeholder-image">
                                            <p>{t('No Image Available')}</p>
                                          </div>
                                        ) : null}
                                      </>
                                    )}

                                    {/* <div className="ad-actions">
                                      <span className="like-count"> 
                                        <BiLike />
                                        Like
                                      </span>
                                      <span className="comment-count"> 
                                        <GoComment />
                                        Comment
                                      </span>
                                      <span className="share-count"> 
                                        <PiShareFat />
                                        Share
                                      </span>
                                    </div> */}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
        </div>
      </div>
      <CreativeSelectionModal
        isOpen={isCreativeSelectionModalOpen}
        onClose={() => setIsCreativeSelectionModalOpen(false)}
        chatId={chatId}
        modalType={modalType}
        onSubmit={handleCreativeSelectionModalSubmit}
      />
      <ImageModal 
        showImageModal={showImageModal} 
        handleCloseImageModal={handleCloseImageModal} 
        imageUrl={imageUrl}
        chatDetailId = {chatDetailId}
      />
    </>
  );
};

export default ChatRightsidebar;