//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : MarkdownRenderer.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (02 Aug 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240802     Jecyline     create MarkdownRenderer.jsx
//* 20240805     Jecyline     refine chat markdown styles
//
//***************************************************************************/
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'; // For GitHub Flavored Markdown
import rehypeRaw from 'rehype-raw';
import rehypeHighlight from 'rehype-highlight'; // For syntax highlighting
import '../assets/scss/utils/_markdown.scss';

const CustomHeading = ({ level, children }) => {
  const Tag = `h${level}`;
  return <Tag className={`heading-${level}`}>{children}</Tag>;
};

// const CustomCode = ({ node, inline, className, children, ...props }) => {
//   const language = className?.replace(/language-/, '') || '';
//   return !inline ? (
//     <pre className={`code-block language-${language}`} {...props}>
//       <code className={`language-${language}`}>{children}</code>
//     </pre>
//   ) : (
//     <code className={`code-inline language-${language}`} {...props}>
//       {children}
//     </code>
//   );
// };

const CustomPre = ({ children, ...props }) => {
  return (
    <pre className="code-block" {...props}>
      {children}
    </pre>
  );
};

const CustomCode = ({ children, className, ...props }) => {
  const language = className?.replace(/language-/, '') || '';
  return (
    <code className={`code-inline language-${language}`} {...props}>
      {children}
    </code>
  );
};

const MarkdownRenderer = ({ content }) => (
  <div className="chat-text">
    <ReactMarkdown
      children={content}
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw, rehypeHighlight]}
      components={{
        pre: CustomPre,
        code: CustomCode,
        h1: CustomHeading,
        h2: CustomHeading,
        h3: CustomHeading,
      }}
    />
  </div>
);

export default MarkdownRenderer;
