//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : campaignSummaryApi.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 Oct 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20241001     Jecyline     Create campaignSummaryApi.jsx
//
//***************************************************************************/
import { api } from '../services/api'; //apiClient
import API_ENDPOINTS from './endpoints';

export const getCampaignSummaryListApi = (name) => {
  return api.post(API_ENDPOINTS.CAMPAIGN_SUMMARY_LIST(), {name: name});

};

export const getCampaignSummaryDetailByIdApi = (chat_id) => {

  return api.get(API_ENDPOINTS.CAMPAIGN_SUMMARY_DETAIL_BY_ID(chat_id));
};