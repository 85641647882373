//****************************************************************************
//* Filename  : fileManagerActions.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 Oct 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20241001     Jecyline     Create fileManagerActions.jsx
//
//***************************************************************************/

import { createAction } from '@reduxjs/toolkit';

export const getFileChatListAction = createAction('file-manager/handleGetFileChatList');
export const fileChatClickAction = createAction('file-manager/handleFileChatClick');
export const fetchMoreFileChatDetailsAction = createAction('file-manager/handleFetchMoreFileChatDetails');
export const externalFileChatClickAction = createAction('file-manager/handleExternalFileChatClick');

// export const openChatRoomAction = createAction('file-manager/handleOpenChatRoom');


