//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : ProfileTab.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240701     Glynnis      Function features short description
//* 20240716     Jecyline     fetch and update user profile
//* 20240718     Jecyline     language selection module
//
//***************************************************************************/
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PersonalInformationModal from "./modal/PersonalInformationModal";
import { ListItem } from "./ProfileContent";
import { api } from '../../../../services/api';
import countryList from 'react-select-country-list';
import useAxiosInterceptors from '../../../../hooks/useAxiosInterceptors';
import { useTranslation } from 'react-i18next';

const ProfileTab = () => {
  useAxiosInterceptors();
  const { t } = useTranslation();

  const [personalInfoData, setPersonalInfoData] = useState([]);
  const [modalData, setModalData] = useState(null); // Add state for modal data
  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const passwordData = [
    { id: 'password', title: "Password", text: "**********" },
  ];
  
  // Initialize the country options
  const countryOptions = countryList().getData().map(country => ({
    value: country.value,
    label: country.label,
  }));

  useEffect(() => {
    fetchUserInfo();
  }, []);

  // Fetch user profile data
  const fetchUserInfo = async () => {
    try {
      const response = await api.get(`/profile`);
      const data = response.data;

      // Map data to personalInfoData state
      setPersonalInfoData([
        { id: 'name', title: 'Name', text: data.name || '' },
        { id: 'email', title: 'Email', text: data.email || '' },
        { id: 'phone_no', title: 'Phone No', text: data.phone_no || '' },
        { id: 'company_country_code', title: 'Country', text: countryOptions.find((opt) => opt.value === data?.company_country_code)?.label || '', value: countryOptions.find((opt) => opt.value === data?.company_country_code)?.value || '' },
        { id: 'birth_year', title: 'Birth Year', text: data.birth_year || '' },
      ]);
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  const showSuccessMessage = (message) => {
    setSuccessMessage(message);
    setTimeout(() => setSuccessMessage(''), 5000); // Clear message after 5 seconds
  };

  // Functions to handle modal show and hide
  const handleShow = () => {
    const name = personalInfoData.find((item) => item.id === 'name')?.text || '';
    const email = personalInfoData.find((item) => item.id === 'email')?.text || '';
    const phone_no = personalInfoData.find((item) => item.id === 'phone_no')?.text || '';
    const company_country_code = personalInfoData.find((item) => item.id === 'company_country_code')?.value || '';
    const birth_year = personalInfoData.find((item) => item.id === 'birth_year')?.text || '';
    
    setModalData({ name, email, phone_no, company_country_code, birth_year });
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  // Update personal info data
  const updatePersonalInfoData = (updatedInfo) => {
    setPersonalInfoData((prevData) =>
      prevData.map((item) =>
        item.id === updatedInfo.id ? { ...item, text: updatedInfo.text } : item
      )
    );
  };

  return (
    <>
      <div className="tab-content">
        <div
          id="emp_profile"
          className="pro-overview tab-pane fade show active"
        >
          {successMessage && (
            <div className="alert alert-success" role="alert">
              {successMessage}
            </div>
          )}
          <div className="row">
            <div className="col-md-12 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    {t('Personal Information')}{" "}
                    <Link
                      to="#"
                      className="edit-icon"
                      onClick={handleShow}
                    >
                      <i className="fa fa-pencil" />
                    </Link>
                  </h3>
                  <ul className="personal-info">
                    {personalInfoData.map((item, index) => (
                      <ListItem
                        id={item.id}
                        key={index}
                        title={t(item.title)}
                        text={item.text}
                      />
                    ))}
                  </ul>
                  <hr />
                  <h3 className="card-title">
                    {t('Password Updates')} {" "}
                  </h3>
                  <ul className="personal-info">
                    {passwordData.map((item, index) => (
                      <ListItem
                        id={item.id}
                        key={index}
                        title={t(item.title)}
                        text={item.text}
                      />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        {/* <ProjectDetails /> */}
        
      </div>
      {/* Model Popup*/}
      <PersonalInformationModal
        show={showModal}
        handleClose={handleClose}
        onUpdate={updatePersonalInfoData} // Pass update handler to modal
        initialData={modalData} // Pass modal data to modal
        showSuccessMessage={showSuccessMessage} // Pass success message handler to modal
      />
    </>
  );
};

export default ProfileTab;
