//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : imageModal.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (12 October 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20241012     Jecyline     create imageModal.jsx for manage image
//
//***************************************************************************/
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import { backend_url, api_url, frontend_url } from "../../../../../base_urls";
import { useTranslation } from 'react-i18next';
import { api } from '../../../../../services/api';
import { downloadImage } from '../../../../../utils/downloadUtils';

import useAxiosInterceptors from '../../../../../hooks/useAxiosInterceptors';


const ImageModal = ({ showImageModal, handleCloseImageModal, imageUrl, chatDetailId }) => {
  useAxiosInterceptors(); 
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleEditImage = () => {
    navigate(`/visuallex`, { state: { page: 'Chat', chatDetailId } });
  };

  const handleDownload = async () => {
    downloadImage(imageUrl, 'downloaded-image.jpg');
  };

  if (!showImageModal) {
    return null;
  }

  return (
    <div>
      {/* Backdrop */}
      <div className={`modal-backdrop ${showImageModal ? 'show' : ''}`} onClick={handleCloseImageModal}></div>

      {/* Modal */}
      <div className={`modal custom-modal camera-modal ${showImageModal ? 'show d-block' : 'd-none'} fade`} tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {t('Manage Image')}
              </h5>
              <button 
                type="button"
                className="btn-close" 
                onClick={() => {
                  handleCloseImageModal();
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              {/* <a href={imageUrl} download target="_blank" rel="noreferrer">
                
              </a> */}
              <img src={imageUrl} alt={`Prompt`} className="chat-img"/>

            </div>
            <div className="submit-section">
              <button type="button" className="btn btn-primary mx-1 mb-2" onClick={handleEditImage}>
                 <i className="fas fa fa-paint-brush"></i> {t('Edit')} 
              </button>
              <button type="button" className="btn btn-primary mx-1 mb-2" onClick={handleDownload}>
                 <i className="fas fa-download"></i> {t('Download')} 
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageModal;
