//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : rootReducer.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (29 Aug 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240829     Jecyline      create rootReducer.jsx for chat reducer
//* 20241001     Jecyline      import campaignSummaryReducer
//* 20241003     Jecyline      add RESET_ALL
//
//***************************************************************************/
import { combineReducers } from 'redux';

import userReducer from "./slices/userSlice"; // Make sure this import is correct
import chatReducer from './slices/chatSlice'; // Import the chat reducer
import fileManagerReducer from './slices/fileManagerSlice'; // Import the chat reducer
import campaignSummaryReducer from './slices/campaignSummarySlice'; // Import the chat reducer

const appReducer = combineReducers({
  user: userReducer,
  chat: chatReducer,
  file_manager: fileManagerReducer,
  campaign_summary: campaignSummaryReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_ALL') {
    state = undefined; // This will clear all state
  }
  return appReducer(state, action);
};

export default rootReducer;
