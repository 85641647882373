//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : ResetPassword.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign   Description
//* ------------ ----   ------------------------------------------------------
//* 20240701     Glynnis Function features short description
//* 20241002     Asheraf Reset Password
//
//***************************************************************************/
import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Applogo } from "../../../Routes/ImagePath";
import { CoverPhoto } from "../../../Routes/ImagePath";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import { passwordrgx } from "../../../utils/RegEx";
import LanguageSelector from '../../layout/LanguageSelector';
import { handleApiError } from '../../../utils/errorHandler';
import { guestApi } from '../../../services/api';


const ResetPassword = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { email } = location.state;

  
  const validationSchema = Yup.object().shape({
    password: Yup
      .string()
      .matches(passwordrgx, {
        message: `${t('Password')} ${t(' must be 8-20 characters long, include at least one uppercase letter, one digit, and one special character')}`,
        excludeEmptyString: true
      })
      .required(`${t('Password')}${t(' is required')}`)
      .trim(),
    confirmPassword: Yup
      .string()
      .oneOf([Yup.ref('password'), null], `${t('Password must match')}`)
      .required(`${t('Please confirm your password')}`),
  });

  const {
      control,
      handleSubmit,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(validationSchema),
  });

  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const [passwordEye, setPasswordEye] = useState(true);
  const [confirmPasswordEye, setConfirmPasswordEye] = useState(true);
  const [serverErrors, setServerErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      // const response = await fetch(api_url + '/reset-password', {
      //   method: 'POST',
      //   headers: {
      //       'Authorization': `Bearer ${localStorage.getItem('refresh_token')}`,
      //       'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({ email ,password: data.password })
      // });
      const response = await guestApi.post('/reset-password', { email, password: data.password });
      navigate('/');

    } catch (error) {
      console.error('API Error:', error);
      setErrorMessage('')  

      handleApiError(error, setErrorMessage, null, t); // Call the error handler function

      setTimeout(() => setErrorMessage(''), 10000);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="account-page">
      {/* /reset Section */}
      <div className="reset-wrapper">
        <div className="main-wrapper">
          <div className="account-content">
            <div className="container">
              <div className="account-logo">
                <Link to="/app/main/dashboard">
                  <img src={Applogo} alt="Dreamguy's Technologies" />
                </Link>
              </div>
              <div className="account-box">
                <div className="account-wrapper">
                  {/* <LanguageSelector /> */}
                  <p className="account-box-subtitle">
                    <label className="col-form-label">{t('Enter your new password')}</label>
                  </p>
                  
                  {errorMessage && (
                      <div className="alert alert-danger" role="alert">
                        {errorMessage}
                      </div>
                    )}
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="input-block">
                      <label className="col-form-label">{t('Password')}</label>
                      <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                          <div
                            className="pass-group"
                            style={{ position: "relative" }}
                          >
                            <input
                              className={`form-control`}
                              type={passwordEye ? "password" : "text"}
                              {...field}
                              placeholder="password"
                              autoComplete="false"
                            />
                            <span
                              style={{
                                position: "absolute",
                                right: "5%",
                                top: "30%",
                              }}
                              onClick={() => setPasswordEye(!passwordEye)}
                              className={`eye-icon fa toggle-password ${
                                passwordEye ? "fa-eye-slash" : "fa-eye"
                              }`}
                            />
                            <span
                              style={{
                                position: "absolute",
                                left: "5%",
                                top: "25%",
                                color: "#ccc",
                              }}
                              className="eye-icon fa fa-unlock-alt"
                            />
                          </div>
                        )}
                        defaultValue=""
                      />
                      <span className="text-danger d-block">
                        {errors.password && <span>{errors.password.message}</span>}
                        {serverErrors.password && <p>{serverErrors.password[0]}</p>}
                      </span>
                    </div>
                    <div className="input-block">
                      <label className="col-form-label">{t('Confirm Password')}</label>
                      <Controller
                        name="confirmPassword"
                        control={control}
                        render={({ field }) => (
                          <div
                            className="pass-group"
                            style={{ position: "relative" }}
                          >
                            <input
                              className={`form-control`}
                              type={confirmPasswordEye ? "password" : "text"}
                              {...field}
                              placeholder="confirm password"
                              autoComplete="false"
                            />
                            <span
                              style={{
                                position: "absolute",
                                right: "5%",
                                top: "30%",
                              }}
                              onClick={() =>
                                setConfirmPasswordEye(!confirmPasswordEye)
                              }
                              className={`fa toggle-password ${
                                confirmPasswordEye ? "fa-eye-slash" : "fa-eye"
                              }`}
                            />
                            <span
                              style={{
                                position: "absolute",
                                left: "5%",
                                top: "25%",
                                color: "#ccc",
                              }}
                              className="eye-icon fa fa-unlock-alt"
                            />
                          </div>
                        )}
                        defaultValue=""
                      />
                      <span className="text-danger d-block">
                        {errors.confirmPassword && <span>{errors.confirmPassword.message}</span>}
                      </span>
                    </div>
                    <div className="input-block text-center">
                      <button
                        className="btn btn-primary account-btn"
                        type="submit" disabled={isSubmitting}>
                        {isSubmitting ? t('Resetting password...') : t('Reset Password')}
                      </button>
                    </div>
                    {/* <div className="account-footer">
                      <p>
                        {t('Remember your password?')} <Link to="/">{t('Login')}</Link>
                      </p>
                    </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};
export default ResetPassword;
