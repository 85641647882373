//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : ForgotPassword.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign   Description
//* ------------ ----   ------------------------------------------------------
//* 20240701     Glynnis Function features short description
//* 20241002     Asheraf Reset Password
//
//***************************************************************************/
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Applogo } from "../../../Routes/ImagePath";
import { CoverPhoto } from "../../../Routes/ImagePath";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import * as Yup from "yup";
import LanguageSelector from '../../layout/LanguageSelector';
import { useTranslation } from 'react-i18next';
// import axios from 'axios';
// import { api_url } from "../../../base_urls";
import { guestApi } from '../../../services/api';
import { handleApiError } from '../../../utils/errorHandler'; // Adjust the import path as needed

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Email is required"),
});

const ForgotPassword = () => {
  const { t } = useTranslation();

  const {
      control,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(validationSchema),
  });

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      
      // const response = await axios.post(api_url + '/verify-email', { email });
      const response = await guestApi.post('/verify-email', { email });
      // const { access_token, token_type, expires_in, user } = response.data;
      const redirect = 'reset-password';

      // const response = await fetch(api_url + '/resend-otp', {
      //   method: 'POST',
      //   headers: {
      //       'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({ email , redirect})
      // });
      const response2 = await guestApi.post('/resend-otp', { email, redirect });
      const responseData = response2.data;
      console.log('responseData',responseData)

      // if (responseData.success) {
        navigate('/otp', { state: { email: email , redirect: redirect } });

        setSuccessMessage(responseData.message);
        setTimeout(() => setSuccessMessage(''), 10000);
      // }
    } catch (error) {
      console.error('API Error:', error);
      setErrorMessage('')
      
      handleApiError(error, setErrorMessage, null, t); // Call the error handler function
      
      setTimeout(() => setErrorMessage(''), 10000);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="account-page">
      {/* /forget Section */}
      <div className="forget-wrapper">
        <div className="main-wrapper">
          <div className="account-content">
            <div className="container">
              <div className="account-logo">
                <Link to="/app/main/dashboard">
                  <img src={Applogo} alt="Dreamguy's Technologies" />
                </Link>
              </div>
              <div className="account-box">
                <div className="account-wrapper">
                  {/* <LanguageSelector /> */}

                  <p className="account-box-subtitle">
                    <label className="col-form-label">{t('Enter your email to get a password reset link')}</label>
                  </p>
                  
                  {message && (
                    <div className={`alert ${message.includes('success') ? 'alert-success' : 'alert-danger'}`}>
                      {t(`errors.${message}`)}
                    </div>
                  )}
                  {/* Message Display */}
                    {successMessage && (
                      <div className="alert alert-success" role="alert">
                        {successMessage}
                      </div>
                    )}
                    {errorMessage && (
                      <div className="alert alert-danger" role="alert">
                        {errorMessage}
                      </div>
                    )}
                  {/* /Message Display */}
                  <form onSubmit={handleSubmit}>
                    <div className="input-block">
                      <div style={{ position: "relative"}}>
                        <span
                          style={{
                            position: "absolute",
                            left: "5%",
                            top: "50%",
                            transform: "translateY(-50%)",
                            color: "#ccc",
                          }}
                          className="fa fa-envelope" // Add your desired icon class here
                        ></span>
                        <Controller
                          type="text"
                          name="email"
                          control={control}
                          render={({ field }) => (
                            <input
                              className={`form-control ${
                                error?.email ? "error-input" : ""
                              }`}
                              type="text"
                              value={email} 
                              placeholder="email"
                              onChange={(e) => setEmail(e.target.value)}
                              autoComplete="true"
                              required
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="input-block text-center">
                      <button
                        className="btn btn-primary account-btn"
                        type="submit" disabled={isSubmitting}>
                        {isSubmitting ? t('Sending OTP...') : t('Reset Password')}
                      </button>
                    </div>
                    <div className="account-footer">
                      <p>
                        {t('Remember your password?')} <Link to="/">{t('Login')}</Link>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
