//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : campaignSummarySelectors.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 Oct 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20241001     Jecyline      create campaignSummarySelectors.jsx for campaign summary reducer
//
//***************************************************************************/
import { createSelector } from 'reselect';

// Selector to get currentChatData
export const selectCurrentCampaignData = (state) => state.campaign_summary.currentCampaignData;
