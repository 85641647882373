//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : index.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240701     Glynnis      Function features short description
//* 20240724     Jecyline     add scroll to bottom function, remove chatModals
//* 20240725     Jecyline     remove ref={bottomRef} from chat-main-row
//* 20240729     Jecyline     hide ChatRightsidebar
//* 20240801     Jecyline     lazyload for ChatView
//
//***************************************************************************/
/* eslint-disable react/no-unescaped-entities */

import React, { useState, useEffect, Suspense } from "react";
// import ChatView from "./chatView";
import ChatRightsidebar from "./chatRightsidebar";
import { useTranslation } from 'react-i18next';
const ChatView = React.lazy(() => import('./chatView'));
// 
const Chat = ({ bottomRef}) => {
  const { t } = useTranslation();

  const [windowDimension, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });
  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimension]);

  useEffect(() => {
    let firstload = localStorage.getItem("minheight");
    if (firstload === "false") {
      setTimeout(function () {
        window.location.reload(1);
        localStorage.removeItem("minheight");
      }, 1000);
    }
  });

  return (
    <>
      <div
        className="page-wrapper"
        style={{ minHeight: windowDimension.winHeight }}
      >
        {/* Chat Main Row */}
        <div className="chat-main-row">
          {/* Chat Main Wrapper */}
          <div className="chat-main-wrapper">
            <Suspense fallback={<div>{t('Loading...')}</div>}>
              <ChatView bottomRef={bottomRef} />
            </Suspense>
            {/* Collapse/Expand Button */}
          {/* <button
            onClick={toggleCollapse}
            style={{
              backgroundColor: "#007bff",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              padding: "5px 10px",
              cursor: "pointer",
            }}
          >
            {isCollapsed ? ">" : "<"}
          </button> */}
            <ChatRightsidebar />
            
          </div>
        </div>
      </div>
    </>
  );
};

export default Chat;
