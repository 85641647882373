//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : DotLoader.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (25 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240725     Jecyline      create DotLoader.jsx
//
//***************************************************************************/
import Lottie from 'lottie-react';
import loadingAnimation from '../../assets/animations/dot-loader.json';

const DotLoader = () => {
  return (
    <>
    <div className="dot-loader">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>
    {/* <div className="lottie-dot-loader">
      <Lottie animationData={loadingAnimation} loop={true} />
    </div> */}
    </>
  );
};

export default DotLoader;
