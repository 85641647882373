//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : Header.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240701     Glynnis      create Header.jsx
//* 20240705     Jecyline     add handleLogout() function
//* 20240712     Jecyline     add Company Info to dropdown, change handleLogout() api call from using base_urls to services/api 
//* 20240715     Jecyline     add Affiliate to dropdown
//* 20240716     Jecyine      remove mobile-user-menu
//* 20240717     Jecyine      fix handleLogout() response
//* 20240718     Jecyine      fix user menu dropdown for mobile size, call api to get profile name, language selection module 
//* 20240805     Jecyline     remove feedbackModalShown from localStorage
//* 20240827     Jecyline     amend screen size checking
//* 20240905     Glynnis      Revamp UI
//* 20241001     Jecyline     add Campaign Summary, add 'active to className for current pathname
//* 20241003     Jecyline     clear all reducer, localstorage and sessionstorage when logout
//
//***************************************************************************/
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import notifications from "../../assets/json/notifications";
// import message from "../../assets/json/message";
import { api } from '../../services/api';
import useAxiosInterceptors from '../../hooks/useAxiosInterceptors';
import LanguageSelector from './LanguageSelector';

import {
  Applogo,
  Avatar_02,
  headerlogo,
  headerlogocollapse,
  lnEnglish,
  lnFrench,
  lnGerman,
  lnSpanish,
  lnChina
} from "../../Routes/ImagePath";

import { FaRegBell, FaRegComment } from "react-icons/fa";
import { useLocation } from "react-router-dom/dist";
import { useTranslation } from "react-i18next";
import { useLanguage } from '../../context/LanguageContext';
import { useDispatch } from 'react-redux';

const Header = (props) => {
  useAxiosInterceptors();
  const dispatch = useDispatch();

  // const data = notifications.notifications;
  // const datas = message.message;
  const [notification, setNotifications] = useState(false);
  const [flag, setflag] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [profile, setProfile] = useState(false);
  const navigate = useNavigate();

  const [isCustom575, setIsCustom575] = useState(window.innerWidth <= 575);
  const [userName, setUserName] = useState('');

  const { t } = useTranslation();
  const { language, changeLanguage } = useLanguage();
  const [languageMenu, setLanguageMenu] = useState(false);

  useEffect(() => {
    document.body.classList.remove("slide-nav");
    fetchUserInfo();
  }, []);

  // Fetch user profile data
  const fetchUserInfo = async () => {
    try {
      const response = await api.get(`/profile`);
      const name = response.data.name;

      // Capitalize the first letter and set to userName
      const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);
      setUserName(capitalizedName);

    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  
  useEffect(() => {
    const handleResize = () => {
      setIsCustom575(window.innerWidth <= 575);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handlesidebar = () => {
    document.body.classList.toggle("mini-sidebar");
  };
  const onMenuClik = () => {
    document.body.classList.toggle("slide-nav");
  };

  const themes = localStorage.getItem("theme");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setNotifications(false);
    setProfile(false);
    setflag(false);
  };

  const handleNotification = () => {
    setNotifications(!notification);
    setflag(false);
    setIsOpen(false);
    setProfile(false);
  };
  const handleProfile = () => {
    document.body.classList.remove("slide-nav");
    setProfile(!profile);
    setNotifications(false);
    setflag(false);
    setIsOpen(false);
  };

  const handleLogout = async () => {
    try {
      const response = await api.post(`/logout`);

      if (response.data.success) {
        // Handle successful logout
        console.log('Logout successful');

      } else {
        // Handle errors
        console.error('Logout failed:', response.data.error || 'An error occurred during logout');
      }

      // localStorage.removeItem('access_token');
      // localStorage.removeItem('token_type');
      // localStorage.removeItem('expires_in');
      // localStorage.removeItem('refresh_token');
      // localStorage.removeItem('feedbackModalShown');

      dispatch({ type: 'RESET_ALL' });
      localStorage.clear();  // Clears all local storage
      sessionStorage.clear();  // Clears session storage
      navigate('/');

    } catch (error) {
      console.error('Logout error:', error);

      // localStorage.removeItem('access_token');
      // localStorage.removeItem('token_type');
      // localStorage.removeItem('expires_in');
      // localStorage.removeItem('refresh_token');
      // localStorage.removeItem('feedbackModalShown');

      dispatch({ type: 'RESET_ALL' });
      localStorage.clear();  // Clears all local storage
      sessionStorage.clear();  // Clears session storage
      navigate('/');

    }
  }

  const location = useLocation();
  let pathname = location.pathname;
  
  const handleLanguageMenu = () => {
    setLanguageMenu(!languageMenu);
  };
  

  const handleLanguageChange = (lng) => {
    changeLanguage(lng);
    setLanguageMenu(false);
  };

  const getLanguageClass = (lng) => {
    return language === lng ? 'active' : '';
  };

  return (
    <div className="header" style={{ right: "0px" }}>
      {/* Logo */}
      <div className="header-left">
        <Link to="/dashboard" className="logo">
          <img src={headerlogo}  alt="img" />
        </Link>
        <Link to="/dashboard" className="logo collapse-logo">
          <img src={headerlogocollapse}  alt="img" />
        </Link>
      </div>
      {/* /Logo */}
      <div className="header-right">
        <Link
          id="toggle_btn"
          to="#"
          style={{
            display: pathname.includes("tasks")
              ? "none"
              : pathname.includes("compose")
                ? "none"
                : "",
          }}
          onClick={handlesidebar}
        >
          <span className="bar-icon">
            <span />
            <span />
            <span />
          </span>
        </Link>
        
        <Link
          id="mobile_btn"
          className="mobile_btn"
          to="#"
          onClick={() => onMenuClik()}
        >
          <i className="fa fa-bars" />
        </Link>
        {/* Header Menu */}
        <ul className="nav user-menu">
          {/* /Message Notifications */}
          <li><LanguageSelector/></li>
          <li className={`nav-item dropdown ${isCustom575 ? '' : 'has-arrow main-drop'}`}>
            {!isCustom575 && (
              <Link
                to="#"
                className="dropdown-toggle nav-link no-underline-hover"
                data-bs-toggle="dropdown"
                onClick={handleProfile}
              >
                {" "}
                
                <span>{userName ? `${userName}` : "Admin"}</span>
              </Link>
            )}
            
            {isCustom575 && (
              <Link
                to="#"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fa fa-ellipsis-v" />
              </Link>
            )}
            
            <div
              className={`dropdown-menu dropdown-menu-end ${profile ? "show" : "" }`}
              onClick={(e) => e.stopPropagation()}
            >
              <Link 
                className={`dropdown-item ${pathname.includes('profile') ? 'active' : ''}`}
                to="/profile"
                onClick={() => {
                  setProfile(false); // Close the dropdown
                  document.body.classList.remove("slide-nav"); // Ensure no leftover styles
                }}
              >
                {t('My Profile')}
              </Link>
              <Link 
                className={`dropdown-item ${pathname.includes('company-info') ? 'active' : ''}`} 
                to="/company-info"
                onClick={() => {
                  setProfile(false); // Close the dropdown
                  document.body.classList.remove("slide-nav"); // Ensure no leftover styles
                }}
                >
                {t('Company Info')}
              </Link>
              {/*<Link 
                className={`dropdown-item ${pathname.includes('file-manager') ? 'active' : ''}`}
                to="/file-manager"
              >
                {t('File Manager')}
              </Link>
              <Link 
                className={`dropdown-item ${pathname.includes('campaign-summary') ? 'active' : ''}`}
                to="/campaign-summary"
              >
                {t('Campaign Summary')}
              </Link> */}
              <Link 
                className={`dropdown-item ${pathname.includes('affiliate') ? 'active' : ''}`}
                to="/affiliate"
                onClick={() => {
                  setProfile(false); // Close the dropdown
                  document.body.classList.remove("slide-nav"); // Ensure no leftover styles
                }}
              >
                {t('Referral')}
              </Link>
              <Link className="dropdown-item" to="#" onClick={handleLogout}>
                {t('Logout')}
              </Link>
            </div>
          </li>
        </ul>
        {/* /Header Menu */}
      </div>
      
      
    </div>
  );
};

export default Header;
