// src/data.js

export const budget = [
  51227,
  102453,
  153680,
  204906,
  256049,
  307276,
  358502,
  368496,
  421318,
  492867,
  567691,
  644111,
  720531,
  799555,
  882357,
  965159,
  1052749,
  1142773,
  1233973,
  1332059,
  1430146,
  1536042,
  1643282,
  1761187,
  1882367,
  2003548,
  2124728,
  2245824,
  2367005,
  2504225,
  2644384,
  2784543,
  2924703,
  3064862,
  3219382,
  3378268,
  3537238,
  3696209,
  3859798,
  4034220,
  4208559,
  4382897,
  4559419,
  4742323,
  4925227,
  5108131,
  5295738,
  5490567,
  5685312,
  5880141,
  6074970,
  6269799,
  6464628,
  6681292,
  6898711,
  7116046,
  7333465,
  7550800,
  7773258,
  8013267,
  8253192,
  8493202,
  8733211,
  8973808,
  9234644,
  9495395,
  9756147,
  10016899,
  10282018,
  10559481,
  10837028,
  11114492,
  11391955,
  11687222,
  11987695,
  12288169,
  12588642,
  12889115,
  13189589,
  13490062,
  13790451,
  14090925,
  14391398,
  14691872,
  14996796,
  15332120,
  15667360,
  16002685,
  16338009,
  16673249,
  17008573,
  17343814,
  17679138,
  18014462,
  18349702,
  18709044,
  19079220,
  19449311,
  19819486,
  20189577,
  20559752,
  20929843,
  21300018,
  21670110,
  22047675,
  22450014,
  22852352,
  23254607,
  23656946,
  24059284,
  24461623,
  24863878,
  25266217,
  25682076,
  26110616,
  26539072,
  26967527,
  27395983,
  27824439,
  28252895,
  28681351,
  29121984,
  29587642,
  30053300,
  30518958,
  30984616,
  31450274,
  31915933,
  32381591,
  32847249,
  33329955,
  33850870,
  34371702,
  34892618,
  35413449,
  35934365,
  36455197,
  36976113,
  37509793,
  38082439,
  38655085,
  39227731,
  39800378,
  40373024,
  40945670,
  41518232,
  42129340,
  42744143,
  43358862,
  43973665,
  44588385,
  45203104,
  45819838,
  46460591,
  47101343,
  47742095,
  48382848,
  49023600,
  49664352,
  50321900,
  50995908,
  51669916,
  52344007,
  53018015,
  53692023,
  54366030,
  55090677,
  55819690,
  56548703,
  57277716,
  58006729,
  58735827,
  59509600,
  60292443,
  61075287,
  61858214,
  62641057,
  63431878,
  64264856,
  65097834,
  65930896,
  66763874,
  67596936,
  68465689,
  69342083,
  70218562,
  71095041,
  71971435,
  72890239,
  73823319,
  74756399,
  75689478,
  76622558,
  77628279,
  78637023,
  79645683,
  80654427,
  81700961,
  82780667,
  83860372,
  84940078,
  86044053,
  87185314,
  88326660,
  89467921,
  90631773,
  91820230,
  93008687,
  94197144,
  95430361,
  96681550,
  97932738,
  99199463,
  100537820,
  101876178,
  103214536,
  104620328,
  106035862,
  107451311,
  108912781,
  110389619,
  111866456,
  113373526,
  114890673,
  116407820,
  117963765,
  119527603,
  121091442,
  122655281,
  124219036,
  125797739,
  127431363,
  129065072,
  130698780,
  132332405,
  133966113,
  135657263,
  137360505,
  139063747,
  140766990,
  142470316,
  144218151,
  145989835,
  147761520,
  149533204,
  151304888,
  153161810,
  155060470,
  156959045,
  158857620,
  160756195,
  162781829,
  164827450,
  166873071,
  168918607,
  170968175,
  173113562,
  175258864,
  177404167,
  179549470,
  181737769,
  183986197,
  186234708,
  188483136,
  190731648,
  193084712,
  195439624,
  197794452,
  200149363,
  202584474,
  205048809,
  207513144,
  209977480,
  212524281,
  215114499,
  217704718,
  220294936,
  222950321,
  225682630,
  228414939,
  231147164,
  233946320,
  236821307,
  239696295,
  242571283,
  245540326,
  248556733,
  251573139,
  254593577,
  257747708,
  260901922,
  264056137,
  267297773,
  270612889,
  273928006,
  277243206,
  281030195,
  284826926,
  288623656,
  292945502,
  297613000,
  302280415,
  307048855,
  311972907,
  316897042,
  321903560,
  327077026,
  332250493,
  337528176,
  342965837,
  348403498,
  353990304,
  359733225,
  365476147,
  371463528,
  377543201,
  383663855,
  390118406,
  396572958,
  403298422,
  410174794,
  417242385,
  424702741,
  432163013,
  439623369,
  447083724,
  454544080,
  462682391,
  470871088,
  479059869,
  487248567,
  495437264,
  504328941,
  513255553,
  522182250,
  531108862,
  540276911,
  549929261,
  559581527,
  569233878,
  578893954,
  589231985,
  599570100,
  609908215,
  620246329,
  631362753,
  642539220,
  653715688,
  665122255,
  677350295,
  689578335,
  701806376,
  714875204,
  728129120,
  741383120,
  755270650,
  769466883,
  783663032,
  798429057,
  813417623,
  828406273,
  844299114,
  860290629,
  876282229,
  892273745,
  908331939,
  925729666,
  943127394,
  960525037,
  977922765,
  996100564,
  1014967404,
  1033834243,
  1052701166,
  1072056505,
  1092442339,
  1112828172,
  1133214006,
  1154201710,
  1176136519,
  1198071327,
  1220006135,
  1243364707,
  1267329852,
  1291294997,
  1317185675,
  1343642700,
  1370378784,
  1399089275,
  1427799683,
  1457706693,
  1488175180,
  1519022324,
  1550517023,
  1582145751,
  1614808080,
  1647791878,
  1682348751,
  1717516901,
  1753936407,
  1791493312
    // ... (rest of your budget data)
  ];
  
  export const impression = [
    40058,
          80117,
          120175,
          160234,
          200292,
          240351,
          280409,
          288242,
          321638,
          364866,
          410969,
          458475,
          505981,
          554493,
          604438,
          654382,
          706256,
          759100,
          812460,
          868760,
          925059,
          984880,
          1045306,
          1110622,
          1177441,
          1244259,
          1311077,
          1377896,
          1444714,
          1518752,
          1594129,
          1669505,
          1744881,
          1820258,
          1901879,
          1985447,
          2069014,
          2152582,
          2238084,
          2327993,
          2417903,
          2507813,
          2598446,
          2691196,
          2783946,
          2876696,
          2971218,
          3068451,
          3165684,
          3262916,
          3360149,
          3457381,
          3554614,
          3660800,
          3767276,
          3873753,
          3980229,
          4086705,
          4195132,
          4310280,
          4425429,
          4540577,
          4655725,
          4771108,
          4893439,
          5015770,
          5138102,
          5260433,
          5383969,
          5510943,
          5637917,
          5764891,
          5891865,
          6024231,
          6158171,
          6292111,
          6426051,
          6559991,
          6693930,
          6827870,
          6961810,
          7095750,
          7229690,
          7363630,
          7499058,
          7644644,
          7790229,
          7935815,
          8081401,
          8226987,
          8372572,
          8518158,
          8663744,
          8809329,
          8954915,
          9108017,
          9264500,
          9420983,
          9577466,
          9733949,
          9890432,
          10046914,
          10203397,
          10359880,
          10518443,
          10683960,
          10849477,
          11014994,
          11180511,
          11346029,
          11511546,
          11677063,
          11842580,
          12011162,
          12182580,
          12353997,
          12525414,
          12696832,
          12868249,
          13039666,
          13211083,
          13385542,
          13566234,
          13746927,
          13927619,
          14108311,
          14289004,
          14469696,
          14650389,
          14831081,
          15016503,
          15212477,
          15408450,
          15604424,
          15800397,
          15996371,
          16192344,
          16388318,
          16587634,
          16797085,
          17006536,
          17215987,
          17425438,
          17634889,
          17844340,
          18053791,
          18272139,
          18491330,
          18710520,
          18929710,
          19148901,
          19368091,
          19587595,
          19810916,
          20034237,
          20257557,
          20480878,
          20704198,
          20927519,
          21153898,
          21383266,
          21612634,
          21842002,
          22071370,
          22300737,
          22530105,
          22770752,
          23012365,
          23253978,
          23495591,
          23737204,
          23978817,
          24229817,
          24482724,
          24735632,
          24988539,
          25241447,
          25495873,
          25758405,
          26020937,
          26283469,
          26546001,
          26808533,
          27076970,
          27346678,
          27616386,
          27886093,
          28155801,
          28433288,
          28713388,
          28993488,
          29273588,
          29553688,
          29848108,
          30143123,
          30438138,
          30733153,
          31034937,
          31342687,
          31650437,
          31958187,
          32269636,
          32586782,
          32903929,
          33221075,
          33540586,
          33862683,
          34184781,
          34506878,
          34835588,
          35166940,
          35498293,
          35832269,
          36178277,
          36524285,
          36870294,
          37224768,
          37580458,
          37936149,
          38294324,
          38653331,
          39012338,
          39368291,
          39723236,
          40078182,
          40430394,
          40782053,
          41133712,
          41485372,
          41837031,
          42189077,
          42542564,
          42896050,
          43249536,
          43603022,
          43956508,
          44310287,
          44664127,
          45017967,
          45371807,
          45725647,
          46078632,
          46431160,
          46783687,
          47136215,
          47488743,
          47839146,
          48188511,
          48537875,
          48887240,
          49236605,
          49581760,
          49926255,
          50270750,
          50615245,
          50959579,
          51300037,
          51640496,
          51980955,
          52321414,
          52661048,
          52999525,
          53338002,
          53676479,
          54014956,
          54353790,
          54692629,
          55031469,
          55370309,
          55711361,
          56053220,
          56395080,
          56736939,
          57080624,
          57425269,
          57769913,
          58114558,
          58459731,
          58805527,
          59151322,
          59497117,
          59843894,
          60191785,
          60539676,
          60887567,
          61237555,
          61588600,
          61939645,
          62290817,
          62646177,
          63001537,
          63356897,
          63714690,
          64074533,
          64434375,
          64794218,
          65158323,
          65522516,
          65886710,
          66254019,
          66623380,
          66992740,
          67364466,
          67739844,
          68115221,
          68492867,
          68875117,
          69257366,
          69642756,
          70032971,
          70423187,
          70817610,
          71216436,
          71615261,
          72020462,
          72428071,
          72836666,
          73253297,
          73669929,
          74092561,
          74518540,
          74949041,
          75388833,
          75828625,
          76268417,
          76708209,
          77148002,
          77603422,
          78060004,
          78516586,
          78973167,
          79429749,
          79899515,
          80369937,
          80840358,
          81310780,
          81784390,
          82264392,
          82744395,
          83224398,
          83704445,
          84188414,
          84672382,
          85156351,
          85640319,
          86131347,
          86622920,
          87114493,
          87609218,
          88115196,
          88621175,
          89127153,
          89642222,
          90159292,
          90676362,
          91197651,
          91720995,
          92244340,
          92767737,
          93291155,
          93814573,
          94338154,
          94861753,
          95385352,
          95908951,
          96432808,
          96961859,
          97490910,
          98019960,
          98549011,
          99081287,
          99616411,
          100151535,
          100686659,
          101223937,
          101765760,
          102307582,
          102849405,
          103394063,
          103943183,
          104492303,
          105041423,
          105598963,
          106160090,
          106721217,
          107293837,
          107869837,
          108447159,
          109033827,
          109620496,
          110210105,
          110801093,
          111390765,
          111978184,
          112565120,
          113148324,
          113731735,
          114316152,
          114901262,
          115487788,
          116078651
    // ... (rest of your impression data)
  ];
  
  export const reach = [
    27795,
          55590,
          83385,
          111180,
          138975,
          166770,
          194565,
          200000,
          222360,
          250155,
          277950,
          305745,
          333540,
          361335,
          389130,
          416925,
          444720,
          472515,
          500310,
          528105,
          555900,
          583695,
          611490,
          639285,
          667080,
          694875,
          722670,
          750465,
          778260,
          806055,
          833850,
          861645,
          889440,
          917235,
          945030,
          972825,
          1000620,
          1028415,
          1056210,
          1084005,
          1111800,
          1139595,
          1167390,
          1195185,
          1222980,
          1250775,
          1278570,
          1306365,
          1334160,
          1361955,
          1389750,
          1417545,
          1445340,
          1473135,
          1500930,
          1528725,
          1556520,
          1584315,
          1612110,
          1639905,
          1667700,
          1695495,
          1723290,
          1751085,
          1778880,
          1806675,
          1834470,
          1862265,
          1890060,
          1917855,
          1945650,
          1973445,
          2001240,
          2029035,
          2056830,
          2084625,
          2112420,
          2140215,
          2168010,
          2195805,
          2223600,
          2251395,
          2279190,
          2306985,
          2334780,
          2362575,
          2390370,
          2418165,
          2445960,
          2473755,
          2501550,
          2529345,
          2557140,
          2584935,
          2612730,
          2640525,
          2668320,
          2696115,
          2723910,
          2751705,
          2779500,
          2807295,
          2835090,
          2862885,
          2890680,
          2918475,
          2946270,
          2974065,
          3001860,
          3029655,
          3057450,
          3085245,
          3113040,
          3140835,
          3168630,
          3196425,
          3224220,
          3252015,
          3279810,
          3307605,
          3335400,
          3363195,
          3390990,
          3418785,
          3446580,
          3474375,
          3502170,
          3529965,
          3557760,
          3585555,
          3613350,
          3641145,
          3668940,
          3696735,
          3724530,
          3752325,
          3780120,
          3807915,
          3835710,
          3863505,
          3891300,
          3919095,
          3946890,
          3974685,
          4002480,
          4030275,
          4058070,
          4085865,
          4113660,
          4141455,
          4169250,
          4197045,
          4224840,
          4252635,
          4280430,
          4308225,
          4336020,
          4363815,
          4391610,
          4419405,
          4447200,
          4474995,
          4502790,
          4530585,
          4558380,
          4586175,
          4613970,
          4641765,
          4669560,
          4697355,
          4725150,
          4752945,
          4780740,
          4808535,
          4836330,
          4864125,
          4891920,
          4919715,
          4947510,
          4975305,
          5003100,
          5030895,
          5058690,
          5086485,
          5114280,
          5142075,
          5169870,
          5197665,
          5225460,
          5253255,
          5281050,
          5308845,
          5336640,
          5364435,
          5392230,
          5420025,
          5447820,
          5475615,
          5503410,
          5531205,
          5559000,
          5586795,
          5614590,
          5642385,
          5670180,
          5697975,
          5725770,
          5753565,
          5781360,
          5809155,
          5836950,
          5864745,
          5892540,
          5920335,
          5948130,
          5975925,
          6003720,
          6031515,
          6059310,
          6087105,
          6114900,
          6142695,
          6170490,
          6198285,
          6226080,
          6253875,
          6281670,
          6309465,
          6337260,
          6365055,
          6392850,
          6420645,
          6448440,
          6476235,
          6504030,
          6531825,
          6559620,
          6587415,
          6615210,
          6643005,
          6670800,
          6698595,
          6726390,
          6754185,
          6781980,
          6809775,
          6837570,
          6865365,
          6893160,
          6920955,
          6948750,
          6976545,
          7004340,
          7032135,
          7059930,
          7087725,
          7115520,
          7143315,
          7171110,
          7198905,
          7226700,
          7254495,
          7282290,
          7310085,
          7337880,
          7365675,
          7393470,
          7421265,
          7449060,
          7476855,
          7504650,
          7532445,
          7560240,
          7588035,
          7615830,
          7643625,
          7671420,
          7699215,
          7727010,
          7754805,
          7782600,
          7810395,
          7838190,
          7865985,
          7893780,
          7921575,
          7949370,
          7977165,
          8004960,
          8032755,
          8060550,
          8088345,
          8116140,
          8143935,
          8171730,
          8199525,
          8227320,
          8255115,
          8282910,
          8310705,
          8338500,
          8366295,
          8394090,
          8421885,
          8449680,
          8477475,
          8505270,
          8533065,
          8560860,
          8588655,
          8616450,
          8644245,
          8672040,
          8699835,
          8727630,
          8755425,
          8783220,
          8811015,
          8838810,
          8866605,
          8894400,
          8922195,
          8949990,
          8977785,
          9005580,
          9033375,
          9061170,
          9088965,
          9116760,
          9144555,
          9172350,
          9200145,
          9227940,
          9255735,
          9283530,
          9311325,
          9339120,
          9366915,
          9394710,
          9422505,
          9450300,
          9478095,
          9505890,
          9533685,
          9561480,
          9589275,
          9617070,
          9644865,
          9672660,
          9700455,
          9728250,
          9756045,
          9783840,
          9811635,
          9839430,
          9867225,
          9895020,
          9922815,
          9950610,
          9978405,
          10006200,
          10033995,
          10061790,
          10089585,
          10117380,
          10145175,
          10172970,
          10200765,
          10228560,
          10256355,
          10284150,
          10311945,
          10339740,
          10367535,
          10395330,
          10423125,
          10450920,
          10478715,
          10506510,
          10534305,
          10562100,
          10589895,
          10617690,
          10645485,
          10673280,
          10701075,
          10728870,
          10756665,
          10784460,
          10812255,
          10840050,
          10867845,
          10895640,
          10923435,
          10951230,
          10979025,
          11006820,
          11034615,
          11062410,
          11090205,
          11118120
    // ... (rest of your reach data)
  ];

  // Add the new data arrays
export const reach_lower = [
  27795,
          55590,
          83385,
          111180,
          138975,
          166770,
          194565,
          200000,
          222360,
          250155,
          277950,
          305745,
          333540,
          361335,
          389130,
          416925,
          444720,
          472515,
          500310,
          528105,
          555900,
          583695,
          611490,
          639285,
          667080,
          694875,
          722670,
          750465,
          778260,
          806055,
          833850,
          861645,
          889440,
          917235,
          945030,
          972825,
          1000620,
          1028415,
          1056210,
          1084005,
          1111800,
          1139595,
          1167390,
          1195185,
          1222980,
          1250775,
          1278570,
          1306365,
          1334160,
          1361955,
          1389750,
          1417545,
          1445340,
          1473135,
          1500930,
          1528725,
          1556520,
          1584315,
          1612110,
          1639905,
          1667700,
          1695495,
          1723290,
          1751085,
          1778880,
          1806675,
          1834470,
          1862265,
          1890060,
          1917855,
          1945650,
          1973445,
          2001240,
          2029035,
          2056830,
          2084625,
          2112420,
          2140215,
          2168010,
          2195805,
          2223600,
          2251395,
          2279190,
          2306985,
          2334780,
          2362575,
          2390370,
          2418165,
          2445960,
          2473755,
          2501550,
          2529345,
          2557140,
          2584935,
          2612730,
          2640525,
          2668320,
          2696115,
          2723910,
          2751705,
          2779500,
          2807295,
          2835090,
          2862885,
          2890680,
          2918475,
          2946270,
          2974065,
          3001860,
          3029655,
          3057450,
          3085245,
          3113040,
          3140835,
          3168630,
          3196425,
          3224220,
          3252015,
          3279810,
          3307605,
          3335400,
          3363195,
          3390990,
          3418785,
          3446580,
          3474375,
          3502170,
          3529965,
          3557760,
          3585555,
          3613350,
          3641145,
          3668940,
          3696735,
          3724530,
          3752325,
          3780120,
          3807915,
          3835710,
          3863505,
          3891300,
          3919095,
          3946890,
          3974685,
          4002480,
          4030275,
          4058070,
          4085865,
          4113660,
          4141455,
          4169250,
          4197045,
          4224840,
          4252635,
          4280430,
          4308225,
          4336020,
          4363815,
          4391610,
          4419405,
          4447200,
          4474995,
          4502790,
          4530585,
          4558380,
          4586175,
          4613970,
          4641765,
          4669560,
          4697355,
          4725150,
          4752945,
          4780740,
          4808535,
          4836330,
          4864125,
          4891920,
          4919715,
          4947510,
          4975305,
          5003100,
          5030895,
          5058690,
          5086485,
          5114280,
          5142075,
          5169870,
          5197665,
          5225460,
          5253255,
          5281050,
          5308845,
          5336640,
          5364435,
          5392230,
          5420025,
          5447820,
          5475615,
          5503410,
          5531205,
          5559000,
          5586795,
          5614590,
          5642385,
          5670180,
          5697975,
          5725770,
          5753565,
          5781360,
          5809155,
          5836950,
          5864745,
          5892540,
          5920335,
          5948130,
          5975925,
          6003720,
          6031515,
          6059310,
          6087105,
          6114900,
          6142695,
          6170490,
          6198285,
          6226080,
          6253875,
          6281670,
          6309465,
          6337260,
          6365055,
          6392850,
          6420645,
          6448440,
          6476235,
          6504030,
          6531825,
          6559620,
          6587415,
          6615210,
          6643005,
          6670800,
          6698595,
          6726390,
          6754185,
          6781980,
          6809775,
          6837570,
          6865365,
          6893160,
          6920955,
          6948750,
          6976545,
          7004340,
          7032135,
          7059930,
          7087725,
          7115520,
          7143315,
          7171110,
          7198905,
          7226700,
          7254495,
          7282290,
          7310085,
          7337880,
          7365675,
          7393470,
          7421265,
          7449060,
          7476855,
          7504650,
          7532445,
          7560240,
          7588035,
          7615830,
          7643625,
          7671420,
          7699215,
          7727010,
          7754805,
          7782600,
          7810395,
          7838190,
          7865985,
          7893780,
          7921575,
          7949370,
          7977165,
          8004960,
          8032755,
          8060550,
          8088345,
          8116140,
          8143935,
          8171730,
          8199525,
          8227320,
          8255115,
          8282910,
          8310705,
          8338500,
          8366295,
          8394090,
          8421885,
          8449680,
          8477475,
          8505270,
          8533065,
          8560860,
          8588655,
          8616450,
          8644245,
          8672040,
          8699835,
          8727630,
          8755425,
          8783220,
          8811015,
          8838810,
          8866605,
          8894400,
          8922195,
          8949990,
          8977785,
          9005580,
          9033375,
          9061170,
          9088965,
          9116760,
          9144555,
          9172350,
          9200145,
          9227940,
          9255735,
          9283530,
          9311325,
          9339120,
          9366915,
          9394710,
          9422505,
          9450300,
          9478095,
          9505890,
          9533685,
          9561480,
          9589275,
          9617070,
          9644865,
          9672660,
          9700455,
          9728250,
          9756045,
          9783840,
          9811635,
          9839430,
          9867225,
          9895020,
          9922815,
          9950610,
          9978405,
          10006200,
          10033995,
          10061790,
          10089585,
          10117380,
          10145175,
          10172970,
          10200765,
          10228560,
          10256355,
          10284150,
          10311945,
          10339740,
          10367535,
          10395330,
          10423125,
          10450920,
          10478715,
          10506510,
          10534305,
          10562100,
          10589895,
          10617690,
          10645485,
          10673280,
          10701075,
          10728870,
          10756665,
          10784460,
          10812255,
          10840050,
          10867845,
          10895640,
          10923435,
          10951230,
          10979025,
          11006820,
          11034615,
          11062410,
          11090205,
          11118120
  // ... (rest of your reach_lower data)
];

export const impression_lower = [
  40058,
          80117,
          120175,
          160234,
          200292,
          240351,
          280409,
          288242,
          321638,
          364866,
          410969,
          458475,
          505981,
          554493,
          604438,
          654382,
          706256,
          759100,
          812460,
          868760,
          925059,
          984880,
          1045306,
          1110622,
          1177441,
          1244259,
          1311077,
          1377896,
          1444714,
          1518752,
          1594129,
          1669505,
          1744881,
          1820258,
          1901879,
          1985447,
          2069014,
          2152582,
          2238084,
          2327993,
          2417903,
          2507813,
          2598446,
          2691196,
          2783946,
          2876696,
          2971218,
          3068451,
          3165684,
          3262916,
          3360149,
          3457381,
          3554614,
          3660800,
          3767276,
          3873753,
          3980229,
          4086705,
          4195132,
          4310280,
          4425429,
          4540577,
          4655725,
          4771108,
          4893439,
          5015770,
          5138102,
          5260433,
          5383969,
          5510943,
          5637917,
          5764891,
          5891865,
          6024231,
          6158171,
          6292111,
          6426051,
          6559991,
          6693930,
          6827870,
          6961810,
          7095750,
          7229690,
          7363630,
          7499058,
          7644644,
          7790229,
          7935815,
          8081401,
          8226987,
          8372572,
          8518158,
          8663744,
          8809329,
          8954915,
          9108017,
          9264500,
          9420983,
          9577466,
          9733949,
          9890432,
          10046914,
          10203397,
          10359880,
          10518443,
          10683960,
          10849477,
          11014994,
          11180511,
          11346029,
          11511546,
          11677063,
          11842580,
          12011162,
          12182580,
          12353997,
          12525414,
          12696832,
          12868249,
          13039666,
          13211083,
          13385542,
          13566234,
          13746927,
          13927619,
          14108311,
          14289004,
          14469696,
          14650389,
          14831081,
          15016503,
          15212477,
          15408450,
          15604424,
          15800397,
          15996371,
          16192344,
          16388318,
          16587634,
          16797085,
          17006536,
          17215987,
          17425438,
          17634889,
          17844340,
          18053791,
          18272139,
          18491330,
          18710520,
          18929710,
          19148901,
          19368091,
          19587595,
          19810916,
          20034237,
          20257557,
          20480878,
          20704198,
          20927519,
          21153898,
          21383266,
          21612634,
          21842002,
          22071370,
          22300737,
          22530105,
          22770752,
          23012365,
          23253978,
          23495591,
          23737204,
          23978817,
          24229817,
          24482724,
          24735632,
          24988539,
          25241447,
          25495873,
          25758405,
          26020937,
          26283469,
          26546001,
          26808533,
          27076970,
          27346678,
          27616386,
          27886093,
          28155801,
          28433288,
          28713388,
          28993488,
          29273588,
          29553688,
          29848108,
          30143123,
          30438138,
          30733153,
          31034937,
          31342687,
          31650437,
          31958187,
          32269636,
          32586782,
          32903929,
          33221075,
          33540586,
          33862683,
          34184781,
          34506878,
          34835588,
          35166940,
          35498293,
          35832269,
          36178277,
          36524285,
          36870294,
          37224768,
          37580458,
          37936149,
          38294324,
          38653331,
          39012338,
          39368291,
          39723236,
          40078182,
          40430394,
          40782053,
          41133712,
          41485372,
          41837031,
          42189077,
          42542564,
          42896050,
          43249536,
          43603022,
          43956508,
          44310287,
          44664127,
          45017967,
          45371807,
          45725647,
          46078632,
          46431160,
          46783687,
          47136215,
          47488743,
          47839146,
          48188511,
          48537875,
          48887240,
          49236605,
          49581760,
          49926255,
          50270750,
          50615245,
          50959579,
          51300037,
          51640496,
          51980955,
          52321414,
          52661048,
          52999525,
          53338002,
          53676479,
          54014956,
          54353790,
          54692629,
          55031469,
          55370309,
          55711361,
          56053220,
          56395080,
          56736939,
          57080624,
          57425269,
          57769913,
          58114558,
          58459731,
          58805527,
          59151322,
          59497117,
          59843894,
          60191785,
          60539676,
          60887567,
          61237555,
          61588600,
          61939645,
          62290817,
          62646177,
          63001537,
          63356897,
          63714690,
          64074533,
          64434375,
          64794218,
          65158323,
          65522516,
          65886710,
          66254019,
          66623380,
          66992740,
          67364466,
          67739844,
          68115221,
          68492867,
          68875117,
          69257366,
          69642756,
          70032971,
          70423187,
          70817610,
          71216436,
          71615261,
          72020462,
          72428071,
          72836666,
          73253297,
          73669929,
          74092561,
          74518540,
          74949041,
          75388833,
          75828625,
          76268417,
          76708209,
          77148002,
          77603422,
          78060004,
          78516586,
          78973167,
          79429749,
          79899515,
          80369937,
          80840358,
          81310780,
          81784390,
          82264392,
          82744395,
          83224398,
          83704445,
          84188414,
          84672382,
          85156351,
          85640319,
          86131347,
          86622920,
          87114493,
          87609218,
          88115196,
          88621175,
          89127153,
          89642222,
          90159292,
          90676362,
          91197651,
          91720995,
          92244340,
          92767737,
          93291155,
          93814573,
          94338154,
          94861753,
          95385352,
          95908951,
          96432808,
          96961859,
          97490910,
          98019960,
          98549011,
          99081287,
          99616411,
          100151535,
          100686659,
          101223937,
          101765760,
          102307582,
          102849405,
          103394063,
          103943183,
          104492303,
          105041423,
          105598963,
          106160090,
          106721217,
          107293837,
          107869837,
          108447159,
          109033827,
          109620496,
          110210105,
          110801093,
          111390765,
          111978184,
          112565120,
          113148324,
          113731735,
          114316152,
          114901262,
          115487788,
          116078651
  // ... (rest of your impression_lower data)
];

// Upper bounds
export const reach_upper = [
  27795,
          55590,
          83385,
          111180,
          138975,
          166770,
          194565,
          200000,
          222360,
          250155,
          277950,
          305745,
          333540,
          361335,
          389130,
          416925,
          444720,
          472515,
          500310,
          528105,
          555900,
          583695,
          611490,
          639285,
          667080,
          694875,
          722670,
          750465,
          778260,
          806055,
          833850,
          861645,
          889440,
          917235,
          945030,
          972825,
          1000620,
          1028415,
          1056210,
          1084005,
          1111800,
          1139595,
          1167390,
          1195185,
          1222980,
          1250775,
          1278570,
          1306365,
          1334160,
          1361955,
          1389750,
          1417545,
          1445340,
          1473135,
          1500930,
          1528725,
          1556520,
          1584315,
          1612110,
          1639905,
          1667700,
          1695495,
          1723290,
          1751085,
          1778880,
          1806675,
          1834470,
          1862265,
          1890060,
          1917855,
          1945650,
          1973445,
          2001240,
          2029035,
          2056830,
          2084625,
          2112420,
          2140215,
          2168010,
          2195805,
          2223600,
          2251395,
          2279190,
          2306985,
          2334780,
          2362575,
          2390370,
          2418165,
          2445960,
          2473755,
          2501550,
          2529345,
          2557140,
          2584935,
          2612730,
          2640525,
          2668320,
          2696115,
          2723910,
          2751705,
          2779500,
          2807295,
          2835090,
          2862885,
          2890680,
          2918475,
          2946270,
          2974065,
          3001860,
          3029655,
          3057450,
          3085245,
          3113040,
          3140835,
          3168630,
          3196425,
          3224220,
          3252015,
          3279810,
          3307605,
          3335400,
          3363195,
          3390990,
          3418785,
          3446580,
          3474375,
          3502170,
          3529965,
          3557760,
          3585555,
          3613350,
          3641145,
          3668940,
          3696735,
          3724530,
          3752325,
          3780120,
          3807915,
          3835710,
          3863505,
          3891300,
          3919095,
          3946890,
          3974685,
          4002480,
          4030275,
          4058070,
          4085865,
          4113660,
          4141455,
          4169250,
          4197045,
          4224840,
          4252635,
          4280430,
          4308225,
          4336020,
          4363815,
          4391610,
          4419405,
          4447200,
          4474995,
          4502790,
          4530585,
          4558380,
          4586175,
          4613970,
          4641765,
          4669560,
          4697355,
          4725150,
          4752945,
          4780740,
          4808535,
          4836330,
          4864125,
          4891920,
          4919715,
          4947510,
          4975305,
          5003100,
          5030895,
          5058690,
          5086485,
          5114280,
          5142075,
          5169870,
          5197665,
          5225460,
          5253255,
          5281050,
          5308845,
          5336640,
          5364435,
          5392230,
          5420025,
          5447820,
          5475615,
          5503410,
          5531205,
          5559000,
          5586795,
          5614590,
          5642385,
          5670180,
          5697975,
          5725770,
          5753565,
          5781360,
          5809155,
          5836950,
          5864745,
          5892540,
          5920335,
          5948130,
          5975925,
          6003720,
          6031515,
          6059310,
          6087105,
          6114900,
          6142695,
          6170490,
          6198285,
          6226080,
          6253875,
          6281670,
          6309465,
          6337260,
          6365055,
          6392850,
          6420645,
          6448440,
          6476235,
          6504030,
          6531825,
          6559620,
          6587415,
          6615210,
          6643005,
          6670800,
          6698595,
          6726390,
          6754185,
          6781980,
          6809775,
          6837570,
          6865365,
          6893160,
          6920955,
          6948750,
          6976545,
          7004340,
          7032135,
          7059930,
          7087725,
          7115520,
          7143315,
          7171110,
          7198905,
          7226700,
          7254495,
          7282290,
          7310085,
          7337880,
          7365675,
          7393470,
          7421265,
          7449060,
          7476855,
          7504650,
          7532445,
          7560240,
          7588035,
          7615830,
          7643625,
          7671420,
          7699215,
          7727010,
          7754805,
          7782600,
          7810395,
          7838190,
          7865985,
          7893780,
          7921575,
          7949370,
          7977165,
          8004960,
          8032755,
          8060550,
          8088345,
          8116140,
          8143935,
          8171730,
          8199525,
          8227320,
          8255115,
          8282910,
          8310705,
          8338500,
          8366295,
          8394090,
          8421885,
          8449680,
          8477475,
          8505270,
          8533065,
          8560860,
          8588655,
          8616450,
          8644245,
          8672040,
          8699835,
          8727630,
          8755425,
          8783220,
          8811015,
          8838810,
          8866605,
          8894400,
          8922195,
          8949990,
          8977785,
          9005580,
          9033375,
          9061170,
          9088965,
          9116760,
          9144555,
          9172350,
          9200145,
          9227940,
          9255735,
          9283530,
          9311325,
          9339120,
          9366915,
          9394710,
          9422505,
          9450300,
          9478095,
          9505890,
          9533685,
          9561480,
          9589275,
          9617070,
          9644865,
          9672660,
          9700455,
          9728250,
          9756045,
          9783840,
          9811635,
          9839430,
          9867225,
          9895020,
          9922815,
          9950610,
          9978405,
          10006200,
          10033995,
          10061790,
          10089585,
          10117380,
          10145175,
          10172970,
          10200765,
          10228560,
          10256355,
          10284150,
          10311945,
          10339740,
          10367535,
          10395330,
          10423125,
          10450920,
          10478715,
          10506510,
          10534305,
          10562100,
          10589895,
          10617690,
          10645485,
          10673280,
          10701075,
          10728870,
          10756665,
          10784460,
          10812255,
          10840050,
          10867845,
          10895640,
          10923435,
          10951230,
          10979025,
          11006820,
          11034615,
          11062410,
          11090205,
          11118120
  // ... (rest of your reach_upper data)
];

export const impression_upper = [
  40058,
          80117,
          120175,
          160234,
          200292,
          240351,
          280409,
          288242,
          321638,
          364866,
          410969,
          458475,
          505981,
          554493,
          604438,
          654382,
          706256,
          759100,
          812460,
          868760,
          925059,
          984880,
          1045306,
          1110622,
          1177441,
          1244259,
          1311077,
          1377896,
          1444714,
          1518752,
          1594129,
          1669505,
          1744881,
          1820258,
          1901879,
          1985447,
          2069014,
          2152582,
          2238084,
          2327993,
          2417903,
          2507813,
          2598446,
          2691196,
          2783946,
          2876696,
          2971218,
          3068451,
          3165684,
          3262916,
          3360149,
          3457381,
          3554614,
          3660800,
          3767276,
          3873753,
          3980229,
          4086705,
          4195132,
          4310280,
          4425429,
          4540577,
          4655725,
          4771108,
          4893439,
          5015770,
          5138102,
          5260433,
          5383969,
          5510943,
          5637917,
          5764891,
          5891865,
          6024231,
          6158171,
          6292111,
          6426051,
          6559991,
          6693930,
          6827870,
          6961810,
          7095750,
          7229690,
          7363630,
          7499058,
          7644644,
          7790229,
          7935815,
          8081401,
          8226987,
          8372572,
          8518158,
          8663744,
          8809329,
          8954915,
          9108017,
          9264500,
          9420983,
          9577466,
          9733949,
          9890432,
          10046914,
          10203397,
          10359880,
          10518443,
          10683960,
          10849477,
          11014994,
          11180511,
          11346029,
          11511546,
          11677063,
          11842580,
          12011162,
          12182580,
          12353997,
          12525414,
          12696832,
          12868249,
          13039666,
          13211083,
          13385542,
          13566234,
          13746927,
          13927619,
          14108311,
          14289004,
          14469696,
          14650389,
          14831081,
          15016503,
          15212477,
          15408450,
          15604424,
          15800397,
          15996371,
          16192344,
          16388318,
          16587634,
          16797085,
          17006536,
          17215987,
          17425438,
          17634889,
          17844340,
          18053791,
          18272139,
          18491330,
          18710520,
          18929710,
          19148901,
          19368091,
          19587595,
          19810916,
          20034237,
          20257557,
          20480878,
          20704198,
          20927519,
          21153898,
          21383266,
          21612634,
          21842002,
          22071370,
          22300737,
          22530105,
          22770752,
          23012365,
          23253978,
          23495591,
          23737204,
          23978817,
          24229817,
          24482724,
          24735632,
          24988539,
          25241447,
          25495873,
          25758405,
          26020937,
          26283469,
          26546001,
          26808533,
          27076970,
          27346678,
          27616386,
          27886093,
          28155801,
          28433288,
          28713388,
          28993488,
          29273588,
          29553688,
          29848108,
          30143123,
          30438138,
          30733153,
          31034937,
          31342687,
          31650437,
          31958187,
          32269636,
          32586782,
          32903929,
          33221075,
          33540586,
          33862683,
          34184781,
          34506878,
          34835588,
          35166940,
          35498293,
          35832269,
          36178277,
          36524285,
          36870294,
          37224768,
          37580458,
          37936149,
          38294324,
          38653331,
          39012338,
          39368291,
          39723236,
          40078182,
          40430394,
          40782053,
          41133712,
          41485372,
          41837031,
          42189077,
          42542564,
          42896050,
          43249536,
          43603022,
          43956508,
          44310287,
          44664127,
          45017967,
          45371807,
          45725647,
          46078632,
          46431160,
          46783687,
          47136215,
          47488743,
          47839146,
          48188511,
          48537875,
          48887240,
          49236605,
          49581760,
          49926255,
          50270750,
          50615245,
          50959579,
          51300037,
          51640496,
          51980955,
          52321414,
          52661048,
          52999525,
          53338002,
          53676479,
          54014956,
          54353790,
          54692629,
          55031469,
          55370309,
          55711361,
          56053220,
          56395080,
          56736939,
          57080624,
          57425269,
          57769913,
          58114558,
          58459731,
          58805527,
          59151322,
          59497117,
          59843894,
          60191785,
          60539676,
          60887567,
          61237555,
          61588600,
          61939645,
          62290817,
          62646177,
          63001537,
          63356897,
          63714690,
          64074533,
          64434375,
          64794218,
          65158323,
          65522516,
          65886710,
          66254019,
          66623380,
          66992740,
          67364466,
          67739844,
          68115221,
          68492867,
          68875117,
          69257366,
          69642756,
          70032971,
          70423187,
          70817610,
          71216436,
          71615261,
          72020462,
          72428071,
          72836666,
          73253297,
          73669929,
          74092561,
          74518540,
          74949041,
          75388833,
          75828625,
          76268417,
          76708209,
          77148002,
          77603422,
          78060004,
          78516586,
          78973167,
          79429749,
          79899515,
          80369937,
          80840358,
          81310780,
          81784390,
          82264392,
          82744395,
          83224398,
          83704445,
          84188414,
          84672382,
          85156351,
          85640319,
          86131347,
          86622920,
          87114493,
          87609218,
          88115196,
          88621175,
          89127153,
          89642222,
          90159292,
          90676362,
          91197651,
          91720995,
          92244340,
          92767737,
          93291155,
          93814573,
          94338154,
          94861753,
          95385352,
          95908951,
          96432808,
          96961859,
          97490910,
          98019960,
          98549011,
          99081287,
          99616411,
          100151535,
          100686659,
          101223937,
          101765760,
          102307582,
          102849405,
          103394063,
          103943183,
          104492303,
          105041423,
          105598963,
          106160090,
          106721217,
          107293837,
          107869837,
          108447159,
          109033827,
          109620496,
          110210105,
          110801093,
          111390765,
          111978184,
          112565120,
          113148324,
          113731735,
          114316152,
          114901262,
          115487788,
          116078651
  // ... (rest of your impression_upper data)
];

export const audienceSizeLowerBound = 11400000;
export const audienceSizeUpperBound = 13400000;
export const buyingType = 'RESERVED';
export const currency = 'INR';

export const externalBudget = 368496;
export const externalImpression = 288242;
export const externalReach = 200000;
export const externalMaximumBudget = 1791493312;
export const externalMaximumImpression = 116078651;
export const externalMaximumReach = 11118120;
export const externalMinimumBudget = 368496;
export const externalMinimumImpression = 288242;
export const externalMinimumReach = 200000;

// Frequency Distribution Map
export const frequencyDistributionMap = [
  {
    "key": 27795,
    "value": [
      0.76744628086684,
      0.13959201392698,
      0.04513574754384,
      0.022717329889615,
      0.0092662792970797,
      0.0059782447077934,
      0.0047825957662347,
      0.0011956489415587,
      0.0014945611769483,
      0.00029891223538967,
      0.00029891223538967,
      0.000896736706169,
      0.00059782447077934,
      0,
      0,
      0.00029891223538967
    ]
  },
  {
    "key": 55590,
    "value": [
      0.76743679894075,
      0.13959770552014,
      0.045137587866255,
      0.022718256144605,
      0.0092666571116152,
      0.0059784884591066,
      0.0047827907672853,
      0.0011956976918213,
      0.0014946221147766,
      0.00029892442295533,
      0.00029892442295533,
      0.00089677326886599,
      0.00059784884591066,
      0,
      0,
      0.00029892442295533
    ]
  },
  {
    "key": 83385,
    "value": [
      0.76743995958278,
      0.13959580832242,
      0.04513697442545,
      0.022717947392942,
      0.0092665311734367,
      0.0059784072086688,
      0.0047827257669351,
      0.0011956814417338,
      0.0014946018021672,
      0.00029892036043344,
      0.00029892036043344,
      0.00089676108130033,
      0.00059784072086688,
      0,
      0,
      0.00029892036043344
    ]
  },
  {
    "key": 111180,
    "value": [
      0.76743679894075,
      0.13959770552014,
      0.045137587866255,
      0.022718256144605,
      0.0092666571116152,
      0.0059784884591066,
      0.0047827907672853,
      0.0011956976918213,
      0.0014946221147766,
      0.00029892442295533,
      0.00029892442295533,
      0.00089677326886599,
      0.00059784884591066,
      0,
      0,
      0.00029892442295533
    ]
  },
  {
    "key": 138975,
    "value": [
      0.76743869532597,
      0.13959656720151,
      0.045137219801772,
      0.022718070893607,
      0.0092665815487081,
      0.0059784397088439,
      0.0047827517670751,
      0.0011956879417688,
      0.001494609927211,
      0.0002989219854422,
      0.0002989219854422,
      0.00089676595632659,
      0.00059784397088439,
      0,
      0,
      0.0002989219854422
    ]
  },
  {
    "key": 166770,
    "value": [
      0.76743679894075,
      0.13959770552014,
      0.045137587866255,
      0.022718256144605,
      0.0092666571116152,
      0.0059784884591066,
      0.0047827907672853,
      0.0011956976918213,
      0.0014946221147766,
      0.00029892442295533,
      0.00029892442295533,
      0.00089677326886599,
      0.00059784884591066,
      0,
      0,
      0.00029892442295533
    ]
  },
  {
    "key": 194565,
    "value": [
      0.76743815350162,
      0.1395968924354,
      0.045137324963053,
      0.022718123822464,
      0.0092666031381101,
      0.0059784536374904,
      0.0047827629099923,
      0.0011956907274981,
      0.0014946134093726,
      0.00029892268187452,
      0.00029892268187452,
      0.00089676804562356,
      0.00059784536374904,
      0,
      0,
      0.00029892268187452
    ]
  },
  {
    "key": 200000,
    "value": [
      0.76743808943089,
      0.13959693089431,
      0.045137337398374,
      0.022718130081301,
      0.0092666056910569,
      0.0059784552845528,
      0.0047827642276423,
      0.0011956910569106,
      0.0014946138211382,
      0.00029892276422764,
      0.00029892276422764,
      0.00089676829268293,
      0.00059784552845528,
      0,
      0,
      0.00029892276422764
    ]
  },
  {
    "key": 222360,
    "value": [
      0.76466333556122,
      0.14126249652045,
      0.045675882172565,
      0.022989185729238,
      0.0093771678632419,
      0.0060497857182206,
      0.0048398285745764,
      0.0012099571436441,
      0.0015124464295551,
      0.00030248928591103,
      0.00030248928591103,
      0.00090746785773308,
      0.00060497857182206,
      0,
      0,
      0.00030248928591103
    ]
  },
  {
    "key": 250155,
    "value": [
      0.75829306155387,
      0.14508629852743,
      0.046912272114866,
      0.02361147470682,
      0.0096309962619924,
      0.0062135459754789,
      0.0049708367803832,
      0.0012427091950958,
      0.0015533864938697,
      0.00031067729877395,
      0.00031067729877395,
      0.00093203189632184,
      0.00062135459754789,
      0,
      0,
      0.00031067729877395
    ]
  },
  {
    "key": 277950,
    "value": [
      0.75319314097446,
      0.14413293822758,
      0.048924302760803,
      0.025306836130943,
      0.010590092456533,
      0.0069676046647408,
      0.0050736357001655,
      0.0017353762784963,
      0.001574497775324,
      0.00040300660288649,
      0.00029727814550046,
      0.00090945584606572,
      0.00055931347187224,
      0.000017621409564338,
      0,
      0.00029727814550046,
      0.000017621409564338
    ]
  },
  {
    "key": 305745,
    "value": [
      0.7537071427698,
      0.13530413105133,
      0.050993966895845,
      0.027797387592788,
      0.012173835683564,
      0.0082764952692286,
      0.005058642573516,
      0.0027226772458252,
      0.0015464386202038,
      0.00058438519620042,
      0.00025426822960883,
      0.00081782418325842,
      0.00039849747035379,
      0.000055019494431931,
      0,
      0.00025426822960883,
      0.000055019494431931
    ]
  },
  {
    "key": 333540,
    "value": [
      0.75392671864822,
      0.12822592349004,
      0.052720038273021,
      0.029847369813451,
      0.013472977639329,
      0.0093487350861585,
      0.0050505213852489,
      0.0035286291884765,
      0.0015248445639713,
      0.00073251586414601,
      0.00021945952252391,
      0.00074388795784209,
      0.00026790026450713,
      0.000085509390270348,
      0,
      0.00021945952252392,
      0.000085509390270348
    ]
  },
  {
    "key": 361335,
    "value": [
      0.74995367546564,
      0.12671613081655,
      0.054482806043392,
      0.031396871279243,
      0.014364581631026,
      0.010055207141718,
      0.0051302077253665,
      0.0040015620257859,
      0.0015390623176099,
      0.00082083323605864,
      0.00020520830901466,
      0.00071822908155131,
      0.00020520830901466,
      0.00010260415450733,
      0,
      0.00020520830901466,
      0.00010260415450733
    ]
  },
  {
    "key": 389130,
    "value": [
      0.74118868544442,
      0.13115797024052,
      0.056392617164142,
      0.032497440399675,
      0.014868109986779,
      0.010407676990746,
      0.0053100392809926,
      0.0041418306391743,
      0.0015930117842978,
      0.00084960628495882,
      0.00021240157123971,
      0.00074340549933897,
      0.00021240157123971,
      0.00010620078561985,
      0,
      0.00021240157123971,
      0.00010620078561985
    ]
  },
  {
    "key": 416925,
    "value": [
      0.73359348267414,
      0.13500699585451,
      0.058047542347161,
      0.033451126098364,
      0.015304436777029,
      0.010713105743921,
      0.0054658702775105,
      0.0042633788164582,
      0.0016397610832531,
      0.00087453924440168,
      0.00021863481110042,
      0.00076522183885147,
      0.00021863481110042,
      0.00010931740555021,
      0,
      0.00021863481110042,
      0.00010931740555021
    ]
  },
  {
    "key": 444720,
    "value": [
      0.72491771569957,
      0.13940361965984,
      0.059937912582491,
      0.034540491996689,
      0.01580283947561,
      0.011061987632927,
      0.0056438712412891,
      0.0044022195682055,
      0.0016931613723867,
      0.00090301939860626,
      0.00022575484965157,
      0.00079014197378048,
      0.00022575484965157,
      0.00011287742482578,
      0,
      0.00022575484965157,
      0.00011287742482578
    ]
  },
  {
    "key": 472515,
    "value": [
      0.71630240013301,
      0.14376960846768,
      0.06181511100918,
      0.035622267361223,
      0.016297769380952,
      0.011408438566666,
      0.0058206319217684,
      0.0045400928989794,
      0.0017461895765305,
      0.00093130110748294,
      0.00023282527687074,
      0.00081488846904758,
      0.00023282527687074,
      0.00011641263843537,
      0,
      0.00023282527687074,
      0.00011641263843537
    ]
  },
  {
    "key": 500310,
    "value": [
      0.70816191849729,
      0.14789496539017,
      0.063588847467353,
      0.036644420574407,
      0.016765421177833,
      0.011735794824483,
      0.0059876504206547,
      0.0046703673281107,
      0.0017962951261964,
      0.00095802406730476,
      0.00023950601682619,
      0.00083827105889166,
      0.00023950601682619,
      0.00011975300841309,
      0,
      0.00023950601682619,
      0.00011975300841309
    ]
  },
  {
    "key": 528105,
    "value": [
      0.69827430641855,
      0.15290571668982,
      0.065743267661776,
      0.037885950855939,
      0.017333441568076,
      0.012133409097654,
      0.0061905148457416,
      0.0048286015796784,
      0.0018571544537225,
      0.00099048237531866,
      0.00024762059382966,
      0.00086667207840382,
      0.00024762059382966,
      0.00012381029691483,
      0,
      0.00024762059382966,
      0.00012381029691483
    ]
  },
  {
    "key": 555900,
    "value": [
      0.68937629698378,
      0.15741496644441,
      0.067682062495533,
      0.039003222455053,
      0.017844611580743,
      0.01249122810652,
      0.0063730755645511,
      0.0049709989403499,
      0.0019119226693653,
      0.0010196920903282,
      0.00025492302258205,
      0.00089223057903716,
      0.00025492302258205,
      0.00012746151129102,
      0,
      0.00025492302258205,
      0.00012746151129102
    ]
  },
  {
    "key": 583695,
    "value": [
      0.67850329998582,
      0.16292508187013,
      0.070051189046997,
      0.040368481823693,
      0.018469240050056,
      0.012928468035039,
      0.0065961571607342,
      0.0051450025853727,
      0.0019788471482203,
      0.0010553851457175,
      0.00026384628642937,
      0.00092346200250279,
      0.00026384628642937,
      0.00013192314321468,
      0,
      0.00026384628642937,
      0.00013192314321468
    ]
  },
  {
    "key": 611490,
    "value": [
      0.66815596741467,
      0.16816880600857,
      0.072305778130001,
      0.041667736549492,
      0.019063670316761,
      0.013344569221733,
      0.0068084536845575,
      0.0053105938739548,
      0.0020425361053672,
      0.0010893525895292,
      0.0002723381473823,
      0.00095318351583805,
      0.0002723381473823,
      0.00013616907369115,
      0,
      0.0002723381473823,
      0.00013616907369115
    ]
  },
  {
    "key": 639285,
    "value": [
      0.67671517564863,
      0.15623865024472,
      0.069403055653165,
      0.041017874438296,
      0.020213677105685,
      0.014369937669403,
      0.0079315035083535,
      0.0063305329608985,
      0.0030429548799684,
      0.0017097679521843,
      0.00088184157897481,
      0.0011803799857019,
      0.00036712345827676,
      0.0001996466704102,
      0.000040212353179535,
      0.00023844392810225,
      0.00011922196405113
    ]
  },
  {
    "key": 667080,
    "value": [
      0.68795480742803,
      0.14201837447581,
      0.065788941339586,
      0.040083586648644,
      0.021411170023779,
      0.015455589329044,
      0.0091589298243801,
      0.0074495524379047,
      0.0041561114572738,
      0.0024009693966983,
      0.0015648052196319,
      0.0014307757855544,
      0.00047220303008733,
      0.00027024533346693,
      0.000085359546058167,
      0.0001990524827012,
      0.000099526241350598
    ]
  },
  {
    "key": 694875,
    "value": [
      0.6968063448142,
      0.13021424182582,
      0.062859995888709,
      0.039381676570333,
      0.022485101310884,
      0.01641974764652,
      0.010229087652319,
      0.0084230373997336,
      0.0051167036728639,
      0.0029970074821749,
      0.0021518060743014,
      0.0016480407947351,
      0.00056307210898297,
      0.00033118399090769,
      0.0001241198410405,
      0.00016588861765336,
      0.000082944308826682
    ]
  },
  {
    "key": 722670,
    "value": [
      0.70394174027604,
      0.12020691530983,
      0.060431699357853,
      0.038843372787803,
      0.02345715493606,
      0.017285683472165,
      0.011175879163775,
      0.0092827179668292,
      0.0059592669935161,
      0.0035194907000491,
      0.002664932472681,
      0.0018394906977908,
      0.00064291783461378,
      0.00038464687474649,
      0.000157969893599,
      0.00013741417509697,
      0.000068707087548483
    ]
  },
  {
    "key": 750465,
    "value": [
      0.70980417521841,
      0.11157975287421,
      0.058381237264298,
      0.038423774386061,
      0.024343397412376,
      0.018070217346057,
      0.012023060481398,
      0.010050765311161,
      0.0067077112278148,
      0.0039833679918123,
      0.003119415442945,
      0.0020102211925538,
      0.00071395171764443,
      0.00043214660023786,
      0.00018792685353911,
      0.00011258578631928,
      0.000056292893159641
    ]
  },
  {
    "key": 778260,
    "value": [
      0.71469878413426,
      0.10404042941201,
      0.056623303996425,
      0.038092272381107,
      0.025156071965987,
      0.018785915825048,
      0.012787922356423,
      0.010743276939271,
      0.007379256251845,
      0.004399398474743,
      0.0035261853070237,
      0.0021639218819087,
      0.00077776966005682,
      0.00047477281899612,
      0.00021471997241929,
      0.000090665748315107,
      0.000045332874157553
    ]
  },
  {
    "key": 806055,
    "value": [
      0.70717837726604,
      0.10591611861028,
      0.057997398848197,
      0.039144869664804,
      0.02600659157041,
      0.019437452523213,
      0.013273260403583,
      0.011158537559622,
      0.0076939916237717,
      0.0045893985124252,
      0.0036895164511654,
      0.0022497051531496,
      0.00080989385513387,
      0.00049493513369292,
      0.00022497051531496,
      0.000089988206125985,
      0.000044994103062993
    ]
  },
  {
    "key": 833850,
    "value": [
      0.69804101954473,
      0.10922118008477,
      0.059807179749054,
      0.040366366471433,
      0.026818114736193,
      0.020043988868574,
      0.013687446102382,
      0.011506734350477,
      0.0079340789271437,
      0.0047326084828577,
      0.0038046460352385,
      0.0023199061190479,
      0.00083516620285724,
      0.00051037934619053,
      0.00023199061190479,
      0.000092796244761915,
      0.000046398122380958
    ]
  },
  {
    "key": 861645,
    "value": [
      0.68949355312993,
      0.11231287276155,
      0.061500124464586,
      0.041509005651039,
      0.027577247432529,
      0.020611368323275,
      0.014074892720755,
      0.011832452185584,
      0.0081586666279629,
      0.0048665730763287,
      0.0039123430613623,
      0.002385575037416,
      0.00085880701346978,
      0.00052482650823153,
      0.0002385575037416,
      0.000095423001496642,
      0.000047711500748321
    ]
  },
  {
    "key": 889440,
    "value": [
      0.68148030336605,
      0.11521133464602,
      0.063087260135396,
      0.04258022988192,
      0.028288934335344,
      0.021143286562057,
      0.014438123925479,
      0.012137812655996,
      0.0083692175974808,
      0.0049921648827078,
      0.0040133090233534,
      0.0024471396483862,
      0.00088097027341903,
      0.00053837072264496,
      0.00024471396483862,
      0.000097885585935448,
      0.000048942792967724
    ]
  },
  {
    "key": 917235,
    "value": [
      0.67395234404061,
      0.11793426277326,
      0.064578277278987,
      0.043586579699549,
      0.028957520765907,
      0.021642991299086,
      0.01477935748433,
      0.012424680190216,
      0.0085670173892217,
      0.0051101507233954,
      0.0041081603854748,
      0.0025049758448017,
      0.0009017913041286,
      0.00055109468585637,
      0.00025049758448017,
      0.00010019903379207,
      0.000050099516896034
    ]
  },
  {
    "key": 945030,
    "value": [
      0.66467904024516,
      0.12128849712091,
      0.066414984192378,
      0.044826249997958,
      0.029781117814736,
      0.022258551723124,
      0.015199705459078,
      0.012778057470682,
      0.0088106767237367,
      0.005255491379071,
      0.0042250028733708,
      0.0025762212642505,
      0.00092743965513018,
      0.00056676867813511,
      0.00025762212642505,
      0.00010304885057002,
      0.00005152442528501
    ]
  },
  {
    "key": 972825,
    "value": [
      0.65527282179315,
      0.12469080785171,
      0.068278016703846,
      0.046083688543325,
      0.03061651951499,
      0.022882935000823,
      0.015626078299173,
      0.013136499722695,
      0.0090578284378259,
      0.0054029152085277,
      0.0043435200696007,
      0.0026484878473175,
      0.00095345562503431,
      0.00058266732640985,
      0.00026484878473175,
      0.0001059395138927,
      0.00005296975694635
    ]
  },
  {
    "key": 1000620,
    "value": [
      0.6463895020068,
      0.12790398160356,
      0.070037481855138,
      0.047271225146602,
      0.031405480614639,
      0.023472608348658,
      0.016028748756606,
      0.013475015903859,
      0.009291240804677,
      0.0055421436378775,
      0.0044554488069211,
      0.0027167370773909,
      0.00097802534786073,
      0.000597682157026,
      0.00027167370773909,
      0.00010866948309564,
      0.000054334741547819
    ]
  },
  {
    "key": 1028415,
    "value": [
      0.63798603963972,
      0.1309435867683,
      0.07170190456429,
      0.048394613631445,
      0.032151823768937,
      0.024030428837683,
      0.016409667840547,
      0.013795246184596,
      0.0095120447482495,
      0.0056738512533418,
      0.0045613313997454,
      0.0027812996339911,
      0.0010012678682368,
      0.00061188591947804,
      0.00027812996339911,
      0.00011125198535964,
      0.000055625992679822
    ]
  },
  {
    "key": 1056210,
    "value": [
      0.62941845095516,
      0.13404255784443,
      0.073398834775475,
      0.049539942788723,
      0.032912743599864,
      0.024599144005435,
      0.016798026577785,
      0.014121730817935,
      0.0097371611688179,
      0.0058081312235054,
      0.0046692819639945,
      0.0028471231487772,
      0.0010249643335598,
      0.00062636709273098,
      0.00028471231487772,
      0.00011388492595109,
      0.000056942462975543
    ]
  },
  {
    "key": 1084005,
    "value": [
      0.61994382408544,
      0.13746961249276,
      0.075275416526409,
      0.050806526282371,
      0.033754220909437,
      0.02522806822297,
      0.017227500291149,
      0.014482779905779,
      0.0099861103382591,
      0.0059566272193125,
      0.0047886610978786,
      0.0029199153035845,
      0.0010511695092904,
      0.0006423813667886,
      0.00029199153035845,
      0.00011679661214338,
      0.000058398306071691
    ]
  },
  {
    "key": 1111800,
    "value": [
      0.61094263068271,
      0.14072542215318,
      0.077058228188381,
      0.052009820421949,
      0.034553650809065,
      0.025825566002623,
      0.017635513821235,
      0.014825787890395,
      0.010222619876038,
      0.0060977030839526,
      0.0049020750282756,
      0.0029890701391924,
      0.0010760652501093,
      0.00065759543062234,
      0.00029890701391924,
      0.0001195628055677,
      0.000059781402783849
    ]
  },
  {
    "key": 1139595,
    "value": [
      0.602380519885,
      0.14382241183017,
      0.078754073427818,
      0.053154417286425,
      0.035314084128222,
      0.026393917549121,
      0.018023624252293,
      0.015152063778199,
      0.010447592363194,
      0.0062318971990981,
      0.0050099565718239,
      0.0030548515681853,
      0.0010997465645467,
      0.00067206734500077,
      0.00030548515681853,
      0.00012219406272741,
      0.000061097031363707
    ]
  },
  {
    "key": 1167390,
    "value": [
      0.59402101941299,
      0.14684611559647,
      0.080409788871644,
      0.054271928873802,
      0.036056522860986,
      0.026948819854577,
      0.01840255059514,
      0.015470618805406,
      0.010667241192437,
      0.0063629157989974,
      0.0051152852501744,
      0.0031190763720576,
      0.0011228674939407,
      0.00068619680185267,
      0.00031190763720576,
      0.0001247630548823,
      0.000062381527441151
    ]
  },
  {
    "key": 1195185,
    "value": [
      0.58546372406384,
      0.14994136348398,
      0.082104680344455,
      0.0554158820013,
      0.036816528502013,
      0.02751685175237,
      0.018790442747567,
      0.015796711191175,
      0.01089208715198,
      0.0064970344415317,
      0.0052231061196628,
      0.0031848208046724,
      0.0011465354896821,
      0.00070066057702793,
      0.00031848208046724,
      0.0001273928321869,
      0.000063696416093448
    ]
  },
  {
    "key": 1222980,
    "value": [
      0.57729539668511,
      0.15289591828569,
      0.083722531295774,
      0.056507837259366,
      0.037541988432085,
      0.028059064018444,
      0.019160703438521,
      0.016107981195773,
      0.011106712840634,
      0.0066250567821326,
      0.005326026040538,
      0.0032475768539866,
      0.0011691276674352,
      0.00071446690787705,
      0.00032475768539866,
      0.00012990307415946,
      0.000064951537079732
    ]
  },
  {
    "key": 1250775,
    "value": [
      0.56949010607876,
      0.15571915954066,
      0.085268477760368,
      0.05755126117263,
      0.038235205698598,
      0.028577177961582,
      0.019514508098765,
      0.016405416977945,
      0.011311799609793,
      0.006747389240929,
      0.0054243717427077,
      0.0033075437455534,
      0.0011907157483992,
      0.00072765962402176,
      0.00033075437455534,
      0.00013230174982214,
      0.000066150874911069
    ]
  },
  {
    "key": 1278570,
    "value": [
      0.56986863641919,
      0.15354775887788,
      0.084516975508867,
      0.05711477407093,
      0.038272355007542,
      0.028462181948039,
      0.019817241017625,
      0.016710521480183,
      0.011645021937679,
      0.0073630370616034,
      0.0058478721968585,
      0.0037459651147455,
      0.001509319032803,
      0.00094736037640876,
      0.00041120484350368,
      0.00015583830345015,
      0.000063936802686169
    ]
  },
  {
    "key": 1306365,
    "value": [
      0.58110614814019,
      0.14470217413397,
      0.080701438345498,
      0.054705726083691,
      0.037440608674905,
      0.027505051897843,
      0.020059577789021,
      0.017033163272612,
      0.012156171716324,
      0.0086475780591164,
      0.0067126824204412,
      0.0046967196966943,
      0.0022293916275131,
      0.0014461498794361,
      0.00059230708239121,
      0.00020796732023051,
      0.000057143860117353
    ]
  },
  {
    "key": 1334160,
    "value": [
      0.5908565849183,
      0.136843314605,
      0.077329689486942,
      0.052580604582115,
      0.03672532258997,
      0.026666460138844,
      0.020298749134218,
      0.017341980049471,
      0.012630686853038,
      0.009818296061219,
      0.0075017468726047,
      0.0055615353636378,
      0.0028830575989985,
      0.0018988424606605,
      0.00075673908507473,
      0.00025533515725092,
      0.000051055042659469
    ]
  },
  {
    "key": 1361955,
    "value": [
      0.59939070089476,
      0.12980179429043,
      0.074324342326877,
      0.050689662288547,
      0.036104935459225,
      0.025925277809963,
      0.020533716798744,
      0.017637876192692,
      0.013073505401035,
      0.010892777698315,
      0.0082266950997496,
      0.0063538316279762,
      0.0034807943363735,
      0.0023127189352364,
      0.00090712850252484,
      0.00029868930690768,
      0.000045553030641357
    ]
  },
  {
    "key": 1389750,
    "value": [
      0.60691716671571,
      0.1234461286616,
      0.071625442814704,
      0.048994380895775,
      0.035562862603536,
      0.025265179409434,
      0.020763828158156,
      0.017921696103751,
      0.013488591422978,
      0.011884863737969,
      0.008896687564721,
      0.0070841545063758,
      0.0040308242287948,
      0.0026934922779791,
      0.0010455377139771,
      0.00033861677341346,
      0.000040546411125654
    ]
  },
  {
    "key": 1417545,
    "value": [
      0.61360052476031,
      0.1176722185451,
      0.069185575880197,
      0.047464308006433,
      0.035086041173561,
      0.02467327328546,
      0.020988634656484,
      0.018194171596837,
      0.013879141550613,
      0.012805596348232,
      0.0095190359726472,
      0.0077609109469204,
      0.0045396968558458,
      0.0030457128213047,
      0.0011736096332016,
      0.00037558530946588,
      0.000035962657385539
    ]
  },
  {
    "key": 1445340,
    "value": [
      0.61957098712605,
      0.11239689749134,
      0.066966957600793,
      0.046075195639405,
      0.03466416112783,
      0.024139322765226,
      0.021207905010225,
      0.018456005345014,
      0.014247818152691,
      0.013663954181369,
      0.010099692097098,
      0.0083909245030784,
      0.0050127188785926,
      0.0033730667060922,
      0.0012926756133024,
      0.00040997422072502,
      0.000031743541163204
    ]
  },
  {
    "key": 1473135,
    "value": [
      0.61322315874947,
      0.11416461182667,
      0.068048540457814,
      0.046823570371728,
      0.035246313961136,
      0.024537351781338,
      0.02157871958752,
      0.018780882621627,
      0.014503729558825,
      0.013924866738296,
      0.010290894587193,
      0.0085543061256042,
      0.0051132882480115,
      0.0034410178775927,
      0.0013185208689841,
      0.00041806759260471,
      0.000032159045584978
    ]
  },
  {
    "key": 1500930,
    "value": [
      0.60673258174371,
      0.11608043026605,
      0.069190476181119,
      0.047609325765458,
      0.035837789175097,
      0.024949117829014,
      0.021940836255922,
      0.019096048246585,
      0.014747119450702,
      0.014158542621184,
      0.01046358808032,
      0.0086978575917663,
      0.0051990953274092,
      0.0034987622643571,
      0.0013406472227911,
      0.00042508326576302,
      0.000032698712751001
    ]
  },
  {
    "key": 1528725,
    "value": [
      0.60047785535009,
      0.11792663286831,
      0.070290916943478,
      0.048366528860918,
      0.036407771724977,
      0.025345921374231,
      0.022289794550602,
      0.019399761576083,
      0.014981665189749,
      0.014383727332952,
      0.010630006343059,
      0.0088361927726678,
      0.0052817844017074,
      0.0035544083709603,
      0.0013619695627044,
      0.00043184400768677,
      0.000033218769822059
    ]
  },
  {
    "key": 1556520,
    "value": [
      0.59444667936953,
      0.1197068502734,
      0.071352026810849,
      0.049096668731851,
      0.036957382506943,
      0.025728542748903,
      0.022626280713648,
      0.019692619875962,
      0.015207828020649,
      0.014600863709403,
      0.010790476644363,
      0.0089695837106266,
      0.0053615180826678,
      0.0036080656279588,
      0.001382529820059,
      0.00043836311367724,
      0.000033720239513634
    ]
  },
  {
    "key": 1584315,
    "value": [
      0.5886271235988,
      0.12142460390989,
      0.07237590475305,
      0.049801189659943,
      0.037487708700067,
      0.026097738812182,
      0.022950960344658,
      0.019975202446021,
      0.015426055313623,
      0.014810381265629,
      0.010945316408779,
      0.0090982942647974,
      0.005438454090612,
      0.0036598401741854,
      0.0014023686648748,
      0.00044465347910664,
      0.000034204113777434
    ]
  },
  {
    "key": 1612110,
    "value": [
      0.58269304319545,
      0.12317616169087,
      0.073419931869828,
      0.050519575048426,
      0.038028471327661,
      0.026474200385954,
      0.023282029435092,
      0.020263346035907,
      0.015648577161291,
      0.015024021975253,
      0.011103203307346,
      0.0092295377492317,
      0.0055169041433377,
      0.0037126336058939,
      0.0014225979237538,
      0.00045106763436095,
      0.000034697510335457
    ]
  },
  {
    "key": 1639905,
    "value": [
      0.5758926929941,
      0.12518341563739,
      0.074616368306683,
      0.051342831878323,
      0.038648175644672,
      0.026905618628544,
      0.023661428702167,
      0.020593553445701,
      0.015903583226046,
      0.015268850414364,
      0.011284138874357,
      0.009379940439309,
      0.005606806503196,
      0.003773133936113,
      0.0014457802932769,
      0.00045841814177074,
      0.000035262933982365
    ]
  },
  {
    "key": 1667700,
    "value": [
      0.5693188649604,
      0.12712380721631,
      0.075772951005554,
      0.052138665720267,
      0.039247237382839,
      0.027322666170717,
      0.024028190040041,
      0.020912761525162,
      0.016150094944945,
      0.015505523528074,
      0.011459047411048,
      0.0095253331604335,
      0.0056937141823644,
      0.0038316189780691,
      0.0014681904495405,
      0.00046552380107382,
      0.000035809523159524
    ]
  },
  {
    "key": 1695495,
    "value": [
      0.56296072588426,
      0.12900053405761,
      0.076891585934058,
      0.052908388052924,
      0.039826643754124,
      0.027726030277734,
      0.024382917845819,
      0.021221496306942,
      0.016388518552108,
      0.015734431337168,
      0.011628217154489,
      0.0096659555096689,
      0.0057777703986367,
      0.0038881851110322,
      0.0014898653229189,
      0.00047239632190111,
      0.000036338178607778
    ]
  },
  {
    "key": 1723290,
    "value": [
      0.55680768806863,
      0.13081672132338,
      0.077974135864868,
      0.053653280632915,
      0.040387359597304,
      0.028116382639364,
      0.024726202819152,
      0.021520271902213,
      0.016619251075168,
      0.015955955023388,
      0.011791929809432,
      0.0098020416540902,
      0.0058591151240615,
      0.0039429265300288,
      0.0015108410068335,
      0.00047904714850817,
      0.000036849780654475
    ]
  },
  {
    "key": 1751085,
    "value": [
      0.5508150319181,
      0.13258556886096,
      0.079028468650644,
      0.054378757256776,
      0.040933460132848,
      0.028496560293214,
      0.025060539917099,
      0.021811259778817,
      0.016843969452477,
      0.01617170459628,
      0.011951375221269,
      0.0099345806526802,
      0.0059383395630682,
      0.0039962410896119,
      0.0015312699502251,
      0.00048552461836407,
      0.000037348047566467
    ]
  },
  {
    "key": 1778880,
    "value": [
      0.54399237835242,
      0.13459940607374,
      0.080228829085082,
      0.055204714153062,
      0.041555196917415,
      0.028929393474441,
      0.02544118351422,
      0.022142550182272,
      0.017099811870214,
      0.016417336008431,
      0.012132904209464,
      0.010085476624117,
      0.0060285367790775,
      0.0040569398450396,
      0.0015545283518376,
      0.00049289923350948,
      0.000037915325654576
    ]
  },
  {
    "key": 1806675,
    "value": [
      0.53737965258876,
      0.1365512790646,
      0.081392255352307,
      0.056005256991001,
      0.04215780333938,
      0.02934890871163,
      0.025810115000661,
      0.022463647034852,
      0.017347782213559,
      0.016655409530978,
      0.01230884769033,
      0.010231729642587,
      0.0061159586961327,
      0.0041157709464541,
      0.0015770711103235,
      0.00050004693741965,
      0.000038465149032281
    ]
  },
  {
    "key": 1834470,
    "value": [
      0.53096717047939,
      0.13844404629568,
      0.082520451256806,
      0.056781558142679,
      0.04274216189861,
      0.029755720372846,
      0.026167874666029,
      0.022775020573712,
      0.017588243627987,
      0.016886273815783,
      0.012479463328061,
      0.010373553891451,
      0.0062007333411305,
      0.0041728205503205,
      0.0015989312389079,
      0.00050697819770249,
      0.000038998322900192
    ]
  },
  {
    "key": 1862265,
    "value": [
      0.5247462456023,
      0.14028027173126,
      0.08361494506573,
      0.057534669194566,
      0.043309064173932,
      0.030150379529845,
      0.026514947135683,
      0.023077092589029,
      0.017821521845295,
      0.017110241594263,
      0.012644982240564,
      0.010511141487469,
      0.0062829755507803,
      0.0042281659366886,
      0.0016201383495723,
      0.00051370240352291,
      0.000039515569501763
    ]
  },
  {
    "key": 1890060,
    "value": [
      0.51854224110457,
      0.14211150279195,
      0.08470646194585,
      0.058285731849318,
      0.043874424523937,
      0.03054396524796,
      0.026861075598141,
      0.023378342994507,
      0.018054165565963,
      0.017333600199694,
      0.012810050955894,
      0.010648354857087,
      0.0063649940687099,
      0.0042833607883771,
      0.0016412877787239,
      0.0005204083200832,
      0.000040031409237169
    ]
  },
  {
    "key": 1917855,
    "value": [
      0.51205117527244,
      0.14402746551782,
      0.08584848367203,
      0.05907154642083,
      0.044465944283812,
      0.03095576230707,
      0.02722321953872,
      0.023693532355608,
      0.01829757378832,
      0.017567293681469,
      0.012982757455127,
      0.010791917134575,
      0.0064508076105165,
      0.0043411095240582,
      0.0016634157989382,
      0.00052742452161455,
      0.000040571117047273
    ]
  },
  {
    "key": 1945650,
    "value": [
      0.5057455684641,
      0.14588868645152,
      0.086957876206034,
      0.059834909147441,
      0.045040563479118,
      0.031355793735919,
      0.027575016509569,
      0.023999716306391,
      0.018534027490038,
      0.017794310206622,
      0.013150529482954,
      0.010931377632706,
      0.0065341693368428,
      0.0043972082958628,
      0.0016849115900035,
      0.00053424026024501,
      0.000041095404634232
    ]
  },
  {
    "key": 1973445,
    "value": [
      0.49961758438274,
      0.14769747862653,
      0.088036018246122,
      0.060576768698655,
      0.045598996218218,
      0.031744556673814,
      0.027916903706592,
      0.024297275357153,
      0.018763820524103,
      0.018014931900081,
      0.013313575538166,
      0.0110669096661,
      0.0066151828455262,
      0.0044517268205742,
      0.0017058018658275,
      0.00054086400623799,
      0.000041604923556769
    ]
  },
  {
    "key": 2001240,
    "value": [
      0.49365982208143,
      0.14945602657445,
      0.089084211896208,
      0.061298021040113,
      0.046141916936788,
      0.032122520641213,
      0.02824929403703,
      0.024586568878727,
      0.018987230418332,
      0.018229425213165,
      0.013472092536289,
      0.01119867692079,
      0.0066939459789684,
      0.0045047309418215,
      0.001726111856212,
      0.00054730375928672,
      0.000042100289175902
    ]
  },
  {
    "key": 2029035,
    "value": [
      0.49947069800804,
      0.14483203740849,
      0.086828235647028,
      0.06002360413992,
      0.045246133691576,
      0.031977581807566,
      0.028065322659303,
      0.024597804196314,
      0.019183991059702,
      0.018322836940963,
      0.013847102551979,
      0.011683262617456,
      0.0074710851168373,
      0.0052595944047643,
      0.0023112941302079,
      0.00081636605413934,
      0.000063049565716062
    ]
  },
  {
    "key": 2056830,
    "value": [
      0.50801141728672,
      0.13875090338664,
      0.083815726768171,
      0.058290855330273,
      0.044019569027422,
      0.031709555642202,
      0.027760451162346,
      0.024542186867806,
      0.019371242504654,
      0.018385734192822,
      0.014268079923455,
      0.012244767630754,
      0.008403365944966,
      0.0061707879308887,
      0.00302264865494,
      0.0011441364266805,
      0.000088571319265566
    ]
  },
  {
    "key": 2084625,
    "value": [
      0.515876210654,
      0.133070228633,
      0.081007360408203,
      0.056679377131463,
      0.042879901636929,
      0.031468956426627,
      0.02748360332822,
      0.024499122219936,
      0.019555194585905,
      0.018452060852865,
      0.014670454173766,
      0.012778867308805,
      0.0092855890402389,
      0.0070322757667165,
      0.0036945131335686,
      0.0014536160301611,
      0.00011266866958821
    ]
  },
  {
    "key": 2112420,
    "value": [
      0.52314290225846,
      0.12774701266816,
      0.078380949974444,
      0.055175816694555,
      0.041817517653983,
      0.031252387333321,
      0.027231402803451,
      0.024466851561222,
      0.019735784231435,
      0.018521098807339,
      0.01505580146229,
      0.013288062091467,
      0.010122605686269,
      0.0078489192959645,
      0.0043307880707122,
      0.0017466166831642,
      0.00013548272376591
    ]
  },
  {
    "key": 2140215,
    "value": [
      0.5298777794557,
      0.12274447338475,
      0.075917531653987,
      0.053768769982016,
      0.040824208735173,
      0.031056967091572,
      0.027000981276274,
      0.02444389265655,
      0.019912979971478,
      0.018592252636526,
      0.015425492717012,
      0.013774514650334,
      0.010918593830307,
      0.0086249025250881,
      0.0049348282192261,
      0.0020246963929533,
      0.00015713482105541
    ]
  },
  {
    "key": 2168010,
    "value": [
      0.53613775855786,
      0.11803091852681,
      0.073600775882088,
      0.052448423547101,
      0.039892913096024,
      0.030880230218368,
      0.026789881232608,
      0.024428983913101,
      0.020086770471163,
      0.018665022542704,
      0.015780725092458,
      0.014240105112869,
      0.01167717308715,
      0.0093638484784504,
      0.0055095369564126,
      0.0022892033270994,
      0.00017772995773709
    ]
  },
  {
    "key": 2195805,
    "value": [
      0.54197160688731,
      0.1135789769085,
      0.071416599274614,
      0.051206326494047,
      0.039017552767219,
      0.030720080873878,
      0.026596007447997,
      0.024421066200503,
      0.020257176876828,
      0.018739002806786,
      0.016122563753784,
      0.014686490045366,
      0.012401508383454,
      0.010068921476723,
      0.0060574469909347,
      0.0025413131198456,
      0.00019735969221271
    ]
  },
  {
    "key": 2223600,
    "value": [
      0.54742187673323,
      0.10936473708872,
      0.069352701179169,
      0.050035099335054,
      0.038192820781836,
      0.030574692510092,
      0.026417533093149,
      0.024419218242323,
      0.020424221704056,
      0.01881384238158,
      0.016451943309654,
      0.015115120906202,
      0.013094369669305,
      0.010742890476663,
      0.0065807741861833,
      0.0027820542782739,
      0.00021610412450903
    ]
  },
  {
    "key": 2251395,
    "value": [
      0.55252553845365,
      0.10536729171614,
      0.067398338297578,
      0.048928304588943,
      0.037414089639286,
      0.030442488377694,
      0.02625287736963,
      0.024422652985091,
      0.020587943413219,
      0.018889250061456,
      0.016769699905989,
      0.015527286696488,
      0.013758202457275,
      0.011388197967572,
      0.0070814713052937,
      0.0030123329406319,
      0.00023403382406651
    ]
  },
  {
    "key": 2279190,
    "value": [
      0.5573149432427,
      0.10156825044675,
      0.065544069247477,
      0.047880290401239,
      0.036677298022336,
      0.030322096052193,
      0.026100661577302,
      0.024430691314687,
      0.020748389231493,
      0.018964980929514,
      0.017076582553385,
      0.015924135433731,
      0.014395174662415,
      0.012007007667545,
      0.00756126693027,
      0.0032329510422526,
      0.0002512112447083
    ]
  },
  {
    "key": 2306985,
    "value": [
      0.56181852360132,
      0.097951363827251,
      0.063781559497764,
      0.046886072502233,
      0.035978865640312,
      0.030212316020544,
      0.025959678198553,
      0.024442745125745,
      0.020905613028869,
      0.019040828758309,
      0.017373265325879,
      0.016306694356434,
      0.015007217062086,
      0.012601245252114,
      0.008021698298572,
      0.0034446215897557,
      0.0002676919142614
    ]
  },
  {
    "key": 2334780,
    "value": [
      0.56492573903993,
      0.095066770862969,
      0.062400490167074,
      0.046123735804216,
      0.035447989293705,
      0.03016650202354,
      0.025881167156645,
      0.02449031285774,
      0.021069563095569,
      0.019133644274121,
      0.017648813333398,
      0.016652660930129,
      0.015543736556678,
      0.013119139197776,
      0.0084203071068822,
      0.0036274983741702,
      0.00028192992545365
    ]
  },
  {
    "key": 2362575,
    "value": [
      0.56023748394083,
      0.096091187412618,
      0.063072902769848,
      0.046620754035303,
      0.035829968260221,
      0.03049156895952,
      0.026160056359663,
      0.024754214550937,
      0.021296603615964,
      0.019339823801116,
      0.01783899268099,
      0.016832105980146,
      0.015711232105622,
      0.013260507871492,
      0.0085110423014735,
      0.0036665874200544,
      0.00028496793420112
    ]
  },
  {
    "key": 2390370,
    "value": [
      0.55565834031772,
      0.097091762341151,
      0.063729666068134,
      0.047106204979277,
      0.036203057290512,
      0.030809070493782,
      0.026432454872235,
      0.025011974363488,
      0.021518360139271,
      0.019541204836554,
      0.018024745915054,
      0.01700737473987,
      0.015874829469382,
      0.013398586420349,
      0.008599665782688,
      0.0037047667322741,
      0.00028793523825964
    ]
  },
  {
    "key": 2418165,
    "value": [
      0.5511843830223,
      0.098069353320944,
      0.064371343026005,
      0.047580504754764,
      0.036567576188869,
      0.03111927878215,
      0.026698596188798,
      0.025263813241833,
      0.021735022750648,
      0.019737960000143,
      0.018206232259463,
      0.017178617446097,
      0.016034668880273,
      0.013533493202455,
      0.0086862535167621,
      0.0037420690373551,
      0.00029083438114159
    ]
  },
  {
    "key": 2445960,
    "value": [
      0.5468121065745,
      0.099024726323923,
      0.064998436416652,
      0.048044024989899,
      0.036923810566809,
      0.031422436882146,
      0.026958688839075,
      0.025509928509306,
      0.021946761211767,
      0.019930243455467,
      0.018383593914228,
      0.017345968272637,
      0.01619087557728,
      0.013665333921332,
      0.0087708733477891,
      0.0037785235627752,
      0.00029366763441258
    ]
  },
  {
    "key": 2473755,
    "value": [
      0.54253808353003,
      0.099958630270655,
      0.065611437820992,
      0.048497129039974,
      0.037272039677828,
      0.03171878244057,
      0.027212936710694,
      0.025750513096612,
      0.02215374150522,
      0.020118205934268,
      0.018556969913829,
      0.017509558406445,
      0.016343572011434,
      0.013794211927425,
      0.0088535916095796,
      0.0038141588853769,
      0.00029643721907074
    ]
  },
  {
    "key": 2501550,
    "value": [
      0.53835911740584,
      0.10087176361505,
      0.066210805694341,
      0.048940155775275,
      0.037612523957689,
      0.032008537090186,
      0.027461529952141,
      0.025985746933653,
      0.022356118428722,
      0.020301988011096,
      0.018726489923791,
      0.017669510194333,
      0.016492872382295,
      0.013920223606822,
      0.0089344701659834,
      0.0038490016563277,
      0.00029914520645034
    ]
  },
  {
    "key": 2529345,
    "value": [
      0.53427191892464,
      0.10176484508723,
      0.066797011801029,
      0.049373453903531,
      0.037945531402633,
      0.032291928897786,
      0.027704664231933,
      0.026215815173717,
      0.022554051273781,
      0.0204817343414,
      0.018892287373845,
      0.017825949534853,
      0.016638894204654,
      0.01404346814371,
      0.0090135726767653,
      0.0038830793004815,
      0.0003017937280167
    ]
  },
  {
    "key": 2557140,
    "value": [
      0.53027357258433,
      0.10263851174479,
      0.067370474296701,
      0.04979733250726,
      0.038271299555295,
      0.032569160013917,
      0.027942512983903,
      0.026440881930302,
      0.022747681230904,
      0.020657573142784,
      0.019054480531507,
      0.017978988020144,
      0.01678174163957,
      0.014164033451535,
      0.0090909555677476,
      0.0039164161262841,
      0.00030438467302726
    ]
  },
  {
    "key": 2584935,
    "value": [
      0.52636128828077,
      0.10349337324503,
      0.067931593351817,
      0.050212087375108,
      0.038590055741424,
      0.032840423894478,
      0.028175242182365,
      0.026661104258258,
      0.022937143417888,
      0.020829627118117,
      0.019213182577517,
      0.018128732442684,
      0.016921514368058,
      0.014282003662521,
      0.0091666728378355,
      0.0039490353966568,
      0.0003069198494811
    ]
  },
  {
    "key": 2612730,
    "value": [
      0.52253216878433,
      0.10433006265288,
      0.068480784501297,
      0.050618025652464,
      0.038902036014893,
      0.033105921423067,
      0.028403024174182,
      0.026876645242527,
      0.023122578140347,
      0.020998023681422,
      0.019368511038168,
      0.018275293695226,
      0.017058315898365,
      0.014397466139128,
      0.0092407805592111,
      0.0039809612676959,
      0.00030940113479501
    ]
  },
  {
    "key": 2640525,
    "value": [
      0.51822378068425,
      0.10527147560476,
      0.069098714710916,
      0.051074772861623,
      0.039253065043611,
      0.033404649732235,
      0.028659316312329,
      0.027119164237447,
      0.023331222647873,
      0.021187497462564,
      0.019543281058298,
      0.018440199166829,
      0.017212240080099,
      0.014527380381994,
      0.0093241639127983,
      0.0040168831142189,
      0.00031219298815173
    ]
  },
  {
    "key": 2668320,
    "value": [
      0.51375591683346,
      0.10624773512426,
      0.069739517716991,
      0.051548426649848,
      0.03961708747417,
      0.033714435522822,
      0.028925095149487,
      0.027370660116036,
      0.0235475901689,
      0.021383984649366,
      0.019724520221763,
      0.018611208643751,
      0.017371861792756,
      0.014662103423632,
      0.0094106337160277,
      0.0040541346142709,
      0.00031508818245628
    ]
  },
  {
    "key": 2696115,
    "value": [
      0.50938017388681,
      0.1072038655815,
      0.070367108289951,
      0.052012314380584,
      0.039973604287605,
      0.034017833977521,
      0.029185394010621,
      0.027616970512592,
      0.023759496503926,
      0.021576420553967,
      0.01990202249526,
      0.018778692152077,
      0.017528192336081,
      0.014794048670598,
      0.0094953206367163,
      0.0040906180421568,
      0.00031792368203291
    ]
  },
  {
    "key": 2723910,
    "value": [
      0.50509373181662,
      0.10814048317226,
      0.070981890892034,
      0.052466735014775,
      0.040322845247704,
      0.034315040627023,
      0.0294403806501,
      0.027858254166362,
      0.023967078219871,
      0.021764929195208,
      0.02007590227338,
      0.018942757629621,
      0.017681332460154,
      0.014923301157422,
      0.009578279252901,
      0.0041263569102899,
      0.00032070131427124
    ]
  },
  {
    "key": 2751705,
    "value": [
      0.50089388453573,
      0.10905817919554,
      0.07158425364357,
      0.052911975434133,
      0.040665030834872,
      0.034606243101786,
      0.029690216044336,
      0.028094663402883,
      0.024170466365796,
      0.021949629581071,
      0.020246269328709,
      0.019103508653073,
      0.017831378844347,
      0.015049942482895,
      0.0096595619374456,
      0.0041613737810871,
      0.00032342283272697
    ]
  },
  {
    "key": 2779500,
    "value": [
      0.49677803420046,
      0.10995752129834,
      0.072174569140076,
      0.053348311045104,
      0.041000372710296,
      0.034891621527055,
      0.029935054730688,
      0.028326344454674,
      0.024369786748803,
      0.022130635959216,
      0.020413229042931,
      0.019261044656056,
      0.017978424300856,
      0.015174050981859,
      0.0097392189682994,
      0.0041956903144683,
      0.0003260899208136
    ]
  },
  {
    "key": 2807295,
    "value": [
      0.49274375591873,
      0.11083903933658,
      0.072753185171497,
      0.053775998918932,
      0.041329068443808,
      0.035171344036221,
      0.030175040958178,
      0.028553433818814,
      0.024565156800376,
      0.02230805497126,
      0.020576879781939,
      0.019415458452394,
      0.0181225554629,
      0.015295699774008,
      0.0098172972761539,
      0.0042293267283431,
      0.00032870414987122
    ]
  },
  {
    "key": 2835090,
    "value": [
      0.48878851175294,
      0.11170328786736,
      0.073320465741327,
      0.05419530811121,
      0.041651324815706,
      0.035445586600852,
      0.030410325700544,
      0.028776074355708,
      0.024756699426514,
      0.022481998230323,
      0.020737324497321,
      0.019566847182776,
      0.018263863002433,
      0.015414965387785,
      0.0098938459795526,
      0.0042623041831555,
      0.00033126716449395
    ]
  },
  {
    "key": 2862885,
    "value": [
      0.48491006844474,
      0.11255075487327,
      0.073876731154461,
      0.054606475377424,
      0.041967323782323,
      0.035714504067141,
      0.030641041807136,
      0.02899439177538,
      0.024944522778359,
      0.022652563950374,
      0.020894653781337,
      0.01971529632616,
      0.018402426706247,
      0.015531915164402,
      0.009968908300361,
      0.0042946412990394,
      0.00033378041184244
    ]
  },
  {
    "key": 2890680,
    "value": [
      0.48096467584199,
      0.11341285076859,
      0.074442598764671,
      0.055024740171235,
      0.042288777491015,
      0.035988063559427,
      0.030875740511732,
      0.029216477768182,
      0.02513558831783,
      0.022826073958565,
      0.021054698867478,
      0.019866307983584,
      0.018543382282645,
      0.015650883716187,
      0.010045266339329,
      0.0043275366149342,
      0.00033633704261146
    ]
  },
  {
    "key": 2918475,
    "value": [
      0.47662575138341,
      0.11436093612851,
      0.075064908649001,
      0.055484724645978,
      0.042642294491571,
      0.036288909151098,
      0.03113384915954,
      0.029460715653509,
      0.02534571162516,
      0.023016890664061,
      0.021230707596811,
      0.020032382247896,
      0.018698397425519,
      0.015781718746085,
      0.010129240685166,
      0.0043637130630293,
      0.00033914868365513
    ]
  },
  {
    "key": 2946270,
    "value": [
      0.47236869342405,
      0.11529113308541,
      0.075675476837401,
      0.055936030168368,
      0.042989141360041,
      0.036584078410852,
      0.031387087832862,
      0.029700345276847,
      0.025551870341785,
      0.02320410705436,
      0.021403395406723,
      0.020195323035523,
      0.018850487754377,
      0.015910085190514,
      0.010211630609384,
      0.004399206936632,
      0.00034190727486777
    ]
  },
  {
    "key": 2974065,
    "value": [
      0.4681912066415,
      0.11620394318331,
      0.076274632536297,
      0.056378900073516,
      0.043329505109475,
      0.03687373048818,
      0.031635593073037,
      0.029935495841806,
      0.025754175624455,
      0.023387824072877,
      0.021572855407103,
      0.020355218200952,
      0.018999735273349,
      0.016036052262149,
      0.010292480535018,
      0.004434037373345,
      0.00034461430362785
    ]
  },
  {
    "key": 3001860,
    "value": [
      0.4640910807253,
      0.11709984939051,
      0.076862692759289,
      0.056813568684125,
      0.043663565826512,
      0.03715801863815,
      0.031879496364319,
      0.030166291766673,
      0.025952734513001,
      0.023568138924384,
      0.021739177259329,
      0.020512152344798,
      0.019146218949377,
      0.016159686610236,
      0.010371833239808,
      0.0044682228019707,
      0.0003472712022257
    ]
  },
  {
    "key": 3029655,
    "value": [
      0.46006612155309,
      0.11797933113809,
      0.077439972198192,
      0.057240268606736,
      0.043991502278853,
      0.037437094993403,
      0.032118928227985,
      0.030392856558507,
      0.026147653263305,
      0.023745148101735,
      0.021902449968103,
      0.020666209448072,
      0.019290017171056,
      0.016281054395885,
      0.01044973118819,
      0.0045017815163407,
      0.00034987939246171
    ]
  },
  {
    "key": 3057450,
    "value": [
      0.45611440779071,
      0.11884280825102,
      0.078006746420203,
      0.057659203528668,
      0.044313471008585,
      0.037711092772417,
      0.032354002958018,
      0.030615298369134,
      0.026339024920797,
      0.023918936101135,
      0.022062751472461,
      0.020817463050693,
      0.019431198581177,
      0.016400213554609,
      0.010526211565136,
      0.0045347295358732,
      0.00035244011936839
    ]
  },
  {
    "key": 3085245,
    "value": [
      0.4522338960781,
      0.11969072721777,
      0.078563308494069,
      0.058070590073628,
      0.044629638499944,
      0.037980153654512,
      0.032584842107329,
      0.030833732219208,
      0.026526948440317,
      0.02408959278523,
      0.022220164661425,
      0.020965991363176,
      0.019569836182107,
      0.016517225701464,
      0.010601313917272,
      0.0045670838973962,
      0.00035495470705152
    ]
  },
  {
    "key": 3113040,
    "value": [
      0.4484226792175,
      0.1205235047744,
      0.079109931959474,
      0.058474630430286,
      0.044940160143243,
      0.038244409877999,
      0.032811559128974,
      0.031048265464817,
      0.026711516182702,
      0.024257202028537,
      0.022374766900586,
      0.021111867384366,
      0.019705998111592,
      0.016632148345697,
      0.010675075155977,
      0.0045988605024634,
      0.00035742439138316
    ]
  },
  {
    "key": 3140835,
    "value": [
      0.44448696885592,
      0.12138348503226,
      0.079674410895038,
      0.058891868776031,
      0.04526082498435,
      0.038517298038113,
      0.033045681868213,
      0.031269806444649,
      0.026902112835343,
      0.024430286232274,
      0.022534419226036,
      0.0212625084497,
      0.019846607774155,
      0.016750824941186,
      0.010751245807523,
      0.0046316750911874,
      0.00035997474801975
    ]
  },
  {
    "key": 3168630,
    "value": [
      0.44044425668905,
      0.12226684593342,
      0.080254236555744,
      0.05932045075536,
      0.045590207874739,
      0.038797605322882,
      0.033286169800379,
      0.031497370551848,
      0.027097891318973,
      0.024608076148719,
      0.022698412086098,
      0.021417245056744,
      0.019991040250482,
      0.016872728046961,
      0.010829487342462,
      0.0046653818238731,
      0.00036259444226993
    ]
  },
  {
    "key": 3196425,
    "value": [
      0.4364719140967,
      0.12313483059007,
      0.080823969466,
      0.059741572611314,
      0.045913857353276,
      0.039073033431605,
      0.033522471673097,
      0.031720973558493,
      0.027290261979333,
      0.024782771360358,
      0.022859550400173,
      0.021569288237011,
      0.020132958659151,
      0.016992509243153,
      0.010906366964087,
      0.0046985018394391,
      0.00036516853674398
    ]
  },
  {
    "key": 3224220,
    "value": [
      0.43256806016973,
      0.12398784999402,
      0.081383879395044,
      0.060155433745614,
      0.04623192666839,
      0.039343712779833,
      0.033754699375595,
      0.031940721340886,
      0.02747931590417,
      0.024954454585589,
      0.023017910467454,
      0.021718709983135,
      0.020272430198705,
      0.017110225246307,
      0.010981921074994,
      0.0047310508202541,
      0.00036769825027881
    ]
  },
  {
    "key": 3252015,
    "value": [
      0.4287308783036,
      0.12482630108607,
      0.081934226889237,
      0.060562226742827,
      0.046544563829247,
      0.039609769324465,
      0.033982960971831,
      0.032156716155625,
      0.027665141067119,
      0.025123205714832,
      0.023173565978612,
      0.021865579826465,
      0.020409519770301,
      0.017225930833942,
      0.011056184833246,
      0.004763043912537,
      0.00037018476004173
    ]
  },
  {
    "key": 3279810,
    "value": [
      0.42495879339059,
      0.12565052803829,
      0.08247523785827,
      0.060962118585601,
      0.046851897168884,
      0.039871312277868,
      0.034207350159891,
      0.032369046665459,
      0.027847813746723,
      0.025289094017988,
      0.023326580827987,
      0.022009958054948,
      0.020544283647226,
      0.017339673501528,
      0.011129188723044,
      0.0047944942489898,
      0.00037262908670905
    ]
  },
  {
    "key": 3307605,
    "value": [
      0.42125010486268,
      0.12646090243669,
      0.083007156205975,
      0.061355289557067,
      0.04715406524231,
      0.040128459551382,
      0.034427968101092,
      0.032577808595297,
      0.028027416297258,
      0.025452194282434,
      0.02347702399922,
      0.022151909758582,
      0.020676782585042,
      0.017451504527642,
      0.01120096565671,
      0.0048254160083594,
      0.00037503233225591
    ]
  },
  {
    "key": 3335400,
    "value": [
      0.41760322781023,
      0.12725777059512,
      0.083530209247884,
      0.061741907679008,
      0.047451197181178,
      0.040381321037004,
      0.034644909076607,
      0.032783091159636,
      0.028204025471951,
      0.02561257620914,
      0.023624959784266,
      0.02229149560049,
      0.020807073207229,
      0.01756147170332,
      0.011271546308148,
      0.0048558224050728,
      0.00037739552371032
    ]
  },
  {
    "key": 3363195,
    "value": [
      0.4177499053458,
      0.12634477191067,
      0.083050115673346,
      0.061464868406322,
      0.047345823511247,
      0.040279311032838,
      0.034569183483329,
      0.032728945832593,
      0.028259503439897,
      0.025719883189523,
      0.023723419793725,
      0.022547521592986,
      0.02101213476264,
      0.017945587340332,
      0.01168093251167,
      0.0051635609563535,
      0.00041314285728778,
      0.0000013883594473944
    ]
  },
  {
    "key": 3390990,
    "value": [
      0.42526431689179,
      0.12209484236178,
      0.08061336211204,
      0.059894120782537,
      0.046457010042217,
      0.039486403752426,
      0.033923826533317,
      0.032170109616019,
      0.02808086583314,
      0.025726185425835,
      0.023727694687047,
      0.023034468891737,
      0.021366252004342,
      0.018869236597721,
      0.012755859744463,
      0.0060155642417261,
      0.00051438199512614,
      0.0000054984867336293
    ]
  },
  {
    "key": 3418785,
    "value": [
      0.43235010732382,
      0.1180499836768,
      0.078296140658676,
      0.058401883520096,
      0.045614934057422,
      0.038734830589013,
      0.033312440435262,
      0.031641204058506,
      0.027915070594658,
      0.02573695051883,
      0.023736151821007,
      0.023504732053732,
      0.021709085420246,
      0.019756671685191,
      0.013786948030163,
      0.0068320666575813,
      0.0006113658717002,
      0.0000094330272875761
    ]
  },
  {
    "key": 3446580,
    "value": [
      0.43904256896325,
      0.11419420095524,
      0.076089073153148,
      0.056981956454378,
      0.044815841662479,
      0.03802128095695,
      0.03273229578071,
      0.0311398110783,
      0.027760997036404,
      0.025751696013233,
      0.02374834129463,
      0.023959420369178,
      0.022041351536484,
      0.020610481710249,
      0.014777390351887,
      0.0076156790160485,
      0.00070440863201688,
      0.000013205035414126
    ]
  },
  {
    "key": 3474375,
    "value": [
      0.44537309204843,
      0.11051320326336,
      0.073983784715842,
      0.055628805793309,
      0.044056387170499,
      0.037342803579734,
      0.032180960031477,
      0.030663776587982,
      0.02761765296105,
      0.025770001125682,
      0.023763870595063,
      0.024399539637496,
      0.022363703055194,
      0.021432997965736,
      0.015730056845127,
      0.0083687460881365,
      0.00079379231215832,
      0.000016826223730319
    ]
  },
  {
    "key": 3502170,
    "value": [
      0.45136982032871,
      0.10699415176257,
      0.071972753118094,
      0.054337462374257,
      0.043333568168169,
      0.036696749709706,
      0.03165625025944,
      0.030211167933073,
      0.027484150629379,
      0.025791491939239,
      0.023782390876973,
      0.024825999334036,
      0.022676731362284,
      0.02222632206745,
      0.016647532936347,
      0.0090933792321478,
      0.00087977083377081,
      0.00002030713434816
    ]
  },
  {
    "key": 3529965,
    "value": [
      0.45705820859312,
      0.10362544950919,
      0.070049182766387,
      0.053103436325999,
      0.04264467071855,
      0.03608072525767,
      0.031156193259518,
      0.029780237905225,
      0.027359686038674,
      0.025815828296564,
      0.023803584820724,
      0.025239617518036,
      0.022980967706192,
      0.022992348120534,
      0.017532149906929,
      0.0097914827400635,
      0.00096257323847392,
      0.000023657278154159
    ]
  },
  {
    "key": 3557760,
    "value": [
      0.46246101434171,
      0.10039665607935,
      0.068206960460194,
      0.051922692069833,
      0.041987260792792,
      0.035492582280197,
      0.030679019413544,
      0.029369420669027,
      0.027243545520242,
      0.025842715425733,
      0.023827177304563,
      0.025641147048127,
      0.023276904310786,
      0.023732801427547,
      0.018386025855742,
      0.010464784487509,
      0.0010424072408802,
      0.00002688527222697
    ]
  },
  {
    "key": 3585555,
    "value": [
      0.46759899886964,
      0.097298276881559,
      0.066440524878442,
      0.050791556928943,
      0.041359121051492,
      0.034930364374957,
      0.030223116612212,
      0.028977289303047,
      0.027135075872062,
      0.025871880532511,
      0.023852913779496,
      0.026031265824155,
      0.023564983053488,
      0.024449242706354,
      0.019211079034694,
      0.011114850252937,
      0.001119461113567,
      0.000029998930449606
    ]
  },
  {
    "key": 3613350,
    "value": [
      0.46984107151896,
      0.095439540227557,
      0.065405668740853,
      0.050147561652586,
      0.041031284814812,
      0.034632205129744,
      0.029985637217721,
      0.028779780493148,
      0.027123737258068,
      0.025950036712816,
      0.023924197415533,
      0.026351988954341,
      0.023811650787906,
      0.024985351333158,
      0.019808206462323,
      0.011576224555903,
      0.0011737005452513,
      0.000032156179321953
    ]
  },
  {
    "key": 3641145,
    "value": [
      0.46612874322828,
      0.096107835876658,
      0.065863658414125,
      0.050498709585461,
      0.041318597903846,
      0.03487470998624,
      0.030195605443054,
      0.028981304956068,
      0.027313665620607,
      0.02613174647994,
      0.024091721661804,
      0.026536513308936,
      0.023978386949685,
      0.025160306090351,
      0.019946909332891,
      0.011657284675066,
      0.0011819191406664,
      0.000032381346319629
    ]
  },
  {
    "key": 3668940,
    "value": [
      0.46247270812383,
      0.096765997591342,
      0.066314703201075,
      0.050844532764049,
      0.041601554220537,
      0.035113537535672,
      0.030402389741889,
      0.02917977353243,
      0.027500713938106,
      0.026310700827565,
      0.024256705595673,
      0.026718239564052,
      0.024142594749457,
      0.025332607859998,
      0.020083508934052,
      0.01173711561081,
      0.0011900131105404,
      0.000032603098918916
    ]
  },
  {
    "key": 3696735,
    "value": [
      0.45887160554727,
      0.097414270318172,
      0.066758970966025,
      0.051185159892584,
      0.041880259072098,
      0.035348776661951,
      0.030606067072674,
      0.029375260085837,
      0.027684951823915,
      0.026486966356727,
      0.024419210618841,
      0.026897235352339,
      0.02430433530007,
      0.025502320767257,
      0.020218056103772,
      0.011815747073633,
      0.0011979854671878,
      0.00003282151964898
    ]
  },
  {
    "key": 3724530,
    "value": [
      0.45532429587483,
      0.098052859212925,
      0.067196602304275,
      0.051520698767708,
      0.042154800658926,
      0.035580501810081,
      0.030806701892201,
      0.029567826480986,
      0.027866437582919,
      0.026660598849336,
      0.024579288158496,
      0.027073557319741,
      0.024463659786783,
      0.025669498520365,
      0.02035059342155,
      0.011893203947659,
      0.0012058387335821,
      0.000033036677632387
    ]
  },
  {
    "key": 3752325,
    "value": [
      0.45182949416791,
      0.098681995591049,
      0.06762775573861,
      0.051851270931348,
      0.042425278427958,
      0.035808796917642,
      0.031004366876231,
      0.029757542471021,
      0.028045236954532,
      0.026831661200127,
      0.024736996199374,
      0.027247269335197,
      0.024620625921554,
      0.025834201675959,
      0.020481168896256,
      0.011969514290019,
      0.0012135757544048,
      0.00003324865080561
    ]
  },
  {
    "key": 3780120,
    "value": [
      0.44838613104492,
      0.099301871963284,
      0.068052563198558,
      0.052176977535964,
      0.042691775143245,
      0.036033731841124,
      0.03119912250868,
      0.029944466107527,
      0.028221404649943,
      0.02700020575282,
      0.024892382998883,
      0.027418424553205,
      0.024775281734775,
      0.025996480631898,
      0.020609822482946,
      0.012044701451072,
      0.0012211988971226,
      0.000033457504030756
    ]
  },
  {
    "key": 3807915,
    "value": [
      0.44499299187993,
      0.099912706987346,
      0.068471174532433,
      0.05249793347263,
      0.042954384809924,
      0.03625538592499,
      0.031391037488443,
      0.03012866332671,
      0.028395002811262,
      0.027166291960508,
      0.025045503368796,
      0.027587083347753,
      0.024927681780367,
      0.026156392631121,
      0.020736599563411,
      0.012118791952643,
      0.0012287108507541,
      0.000033663310979564
    ]
  },
  {
    "key": 3835710,
    "value": [
      0.44150265872339,
      0.10054103893425,
      0.06890177668203,
      0.052828082957535,
      0.043224516738579,
      0.036483389128096,
      0.031588449732543,
      0.030318136740618,
      0.028573573565041,
      0.027337135586233,
      0.025203009759799,
      0.027760573250208,
      0.025084447213886,
      0.026320885192693,
      0.020867008080694,
      0.012195004722483,
      0.0012364379788073,
      0.000033875013118009
    ]
  },
  {
    "key": 3863505,
    "value": [
      0.43762185594659,
      0.10123966346519,
      0.069380551040495,
      0.053195166837587,
      0.043524868794332,
      0.036736899444746,
      0.031807946826579,
      0.030528806873768,
      0.028772121338573,
      0.027527091784503,
      0.02537813666378,
      0.027953471768774,
      0.025258750268184,
      0.026503779822254,
      0.02101200562485,
      0.01227974354699,
      0.0012450295540699,
      0.00003411039874164
    ]
  },
  {
    "key": 3891300,
    "value": [
      0.43379649320945,
      0.10192830764568,
      0.069852485765268,
      0.05355700666221,
      0.043820930106432,
      0.036986788185444,
      0.032024308247843,
      0.030736467433586,
      0.028967832715341,
      0.027714334322798,
      0.025550761754847,
      0.028143614594217,
      0.02543056327885,
      0.026684061671393,
      0.021154931775519,
      0.012363271816862,
      0.0012534983925429,
      0.000034342421713504
    ]
  },
  {
    "key": 3919095,
    "value": [
      0.43002539093668,
      0.10260718382362,
      0.070317726380469,
      0.053913714007051,
      0.044112791967296,
      0.037233132404998,
      0.032237600712777,
      0.03094118245355,
      0.029160768044211,
      0.027898920938564,
      0.025720938263063,
      0.028331060358306,
      0.025599939225535,
      0.026861786331182,
      0.021295830604901,
      0.012445615288579,
      0.0012618471056476,
      0.000034571153579385
    ]
  },
  {
    "key": 3946890,
    "value": [
      0.42630740278042,
      0.10327649836524,
      0.07077641431095,
      0.054265397304782,
      0.044400543097725,
      0.037476006987656,
      0.032447889058486,
      0.031143014163373,
      0.029350985974084,
      0.02808090774284,
      0.025888717919049,
      0.028515866041211,
      0.025766929595506,
      0.027037007826749,
      0.021434744943729,
      0.012526798993088,
      0.0012700782312437,
      0.00003479666386969
    ]
  },
  {
    "key": 3974685,
    "value": [
      0.42264141445851,
      0.10393645186433,
      0.071228687025619,
      0.054612161954992,
      0.044684269736819,
      0.037715484723005,
      0.032655236308451,
      0.031342023052079,
      0.029538543513328,
      0.028260349277126,
      0.026054151006421,
      0.02869808702925,
      0.025931584435826,
      0.027209778672029,
      0.021571716424671,
      0.012606847261172,
      0.0012781942362021,
      0.000035019020169921
    ]
  },
  {
    "key": 4002480,
    "value": [
      0.41902634264108,
      0.10458723934259,
      0.071674678174807,
      0.054954110429504,
      0.044964055728148,
      0.037951636378696,
      0.0328597037355,
      0.031538267928443,
      0.02972349608675,
      0.028437298567881,
      0.026217286412024,
      0.028877777170233,
      0.026093952403365,
      0.027380149922235,
      0.021706785523934,
      0.012685783747753,
      0.0012861975188694,
      0.000035238288188204
    ]
  },
  {
    "key": 4030275,
    "value": [
      0.4154611338832,
      0.10522905044184,
      0.072114517721937,
      0.055291342373334,
      0.045239982602356,
      0.03818453077017,
      0.033061350922176,
      0.031731805978925,
      0.029905897590194,
      0.028611807178763,
      0.026378171674101,
      0.029054988826513,
      0.026254080812731,
      0.027548171224162,
      0.021839991601137,
      0.01276363145521,
      0.001294090411431,
      0.000035454531820028
    ]
  },
  {
    "key": 4058070,
    "value": [
      0.41157645215557,
      0.10592837326392,
      0.072593770626285,
      0.05565879316209,
      0.045540634866834,
      0.03843829447616,
      0.03328106740856,
      0.031942686681674,
      0.030104643816751,
      0.028801953242582,
      0.026553473621414,
      0.029248080151544,
      0.026428558086905,
      0.027731248661074,
      0.021985134073644,
      0.012848454978104,
      0.0013026905741688,
      0.000035690152716954
    ]
  },
  {
    "key": 4085865,
    "value": [
      0.40770996274878,
      0.10662442109308,
      0.073070779145326,
      0.056024523145101,
      0.045839879149182,
      0.038690869783439,
      0.033499754942486,
      0.032152579810751,
      0.030302459296501,
      0.028991208834945,
      0.026727954613629,
      0.02944026721219,
      0.026602218268001,
      0.027913468729557,
      0.022129596830639,
      0.012932881264659,
      0.0013112504615557,
      0.000035924670179609
    ]
  },
  {
    "key": 4113660,
    "value": [
      0.40389576403667,
      0.10731105551884,
      0.073541336565137,
      0.056385306968206,
      0.04613507642926,
      0.038940029243192,
      0.03371548492969,
      0.032359634329301,
      0.030497599504765,
      0.029177904920386,
      0.026900075911731,
      0.029629855120516,
      0.026773529855695,
      0.028093224440074,
      0.022272105862401,
      0.013016165763741,
      0.0013196945843793,
      0.000036156016010392
    ]
  },
  {
    "key": 4141455,
    "value": [
      0.40013276262271,
      0.10798847337513,
      0.074005577777971,
      0.05674124805543,
      0.04642631132974,
      0.039185844280666,
      0.033928319212369,
      0.032563909592568,
      0.030690120381376,
      0.029362095018103,
      0.027069886856839,
      0.029816898224703,
      0.026942541958991,
      0.028270567322263,
      0.022412702021254,
      0.013098332350083,
      0.0013280253632723,
      0.000036384256528009
    ]
  },
  {
    "key": 4169250,
    "value": [
      0.39641989426761,
      0.10865686624697,
      0.074463634078952,
      0.057092447072825,
      0.046713666216688,
      0.039428384416436,
      0.034138317983591,
      0.03276546337299,
      0.030880076374433,
      0.029543831220115,
      0.027237435474307,
      0.030001449423649,
      0.027109302377318,
      0.028445547531635,
      0.022551425070125,
      0.013179404261761,
      0.0013362451543175,
      0.000036609456282671
    ]
  },
  {
    "key": 4197045,
    "value": [
      0.3927562430033,
      0.10931639902749,
      0.074915618470995,
      0.057438990661513,
      0.046997211980821,
      0.039667709485379,
      0.034345533050247,
      0.032964345394071,
      0.031067514346256,
      0.029723158360911,
      0.027402763098535,
      0.030183554246303,
      0.027273852250625,
      0.02861820823597,
      0.02268830923212,
      0.013259401499291,
      0.0013443559853448,
      0.000036831670831364
    ]
  },
  {
    "key": 4224840,
    "value": [
      0.38912831204438,
      0.10996950141641,
      0.07536319605154,
      0.057782155478061,
      0.047277993196541,
      0.039904701154134,
      0.034550727786657,
      0.03316128833143,
      0.031253124812917,
      0.029900737076496,
      0.027566478791714,
      0.030363883561572,
      0.027436797775892,
      0.028789185512314,
      0.022823858784537,
      0.013338618770184,
      0.0013523877364214,
      0.000037051718806067
    ]
  },
  {
    "key": 4252635,
    "value": [
      0.38539702103702,
      0.11064121074556,
      0.0758235251538,
      0.058135097088177,
      0.047566773891961,
      0.040148444734829,
      0.034761768537816,
      0.033363842189111,
      0.031444023336888,
      0.030083375024148,
      0.027734858758322,
      0.030549350473716,
      0.027604385632443,
      0.028965033945183,
      0.02296327015474,
      0.013420092947575,
      0.0013606483127403,
      0.000037278035965487
    ]
  },
  {
    "key": 4280430,
    "value": [
      0.38171418835443,
      0.11130419657694,
      0.076277875956031,
      0.058483455041018,
      0.047851804188739,
      0.040389022814477,
      0.0349700685,
      0.033563765477211,
      0.031632442659249,
      0.030263641050402,
      0.027901051972118,
      0.030732408724664,
      0.027769797023324,
      0.029138598632171,
      0.023100870987667,
      0.013500509018767,
      0.0013688016088472,
      0.000037501413941018
    ]
  },
  {
    "key": 4308225,
    "value": [
      0.37807891508711,
      0.11195862073279,
      0.076726359356634,
      0.058827314364145,
      0.048133153657357,
      0.040626494113616,
      0.035175678515271,
      0.033761106993209,
      0.031818428769578,
      0.030441579154771,
      0.028065098997707,
      0.030913102995458,
      0.027933072322314,
      0.029309921937121,
      0.023236694869069,
      0.013579886611794,
      0.0013768496148069,
      0.000037721907254982
    ]
  },
  {
    "key": 4336020,
    "value": [
      0.37449020914372,
      0.11260466181,
      0.077169097749844,
      0.059166768899155,
      0.04841089908004,
      0.040860923439814,
      0.035378654696032,
      0.033955920592975,
      0.032002032424775,
      0.030617237897799,
      0.028227044604663,
      0.031091482598819,
      0.028094256088377,
      0.029479050615353,
      0.023370778866226,
      0.013658247389353,
      0.0013847945269761,
      0.000037939576081536
    ]
  },
  {
    "key": 4363815,
    "value": [
      0.37094725769643,
      0.11324246613435,
      0.077606191414173,
      0.059501895531171,
      0.048685103365037,
      0.041092363890395,
      0.035579043015593,
      0.034148250401025,
      0.032183295210351,
      0.030790657065505,
      0.028386925473031,
      0.031267587937028,
      0.028253384829004,
      0.029646022973851,
      0.023503153348638,
      0.013735609099854,
      0.0013926381448463,
      0.000038154469721816
    ]
  },
  {
    "key": 4391610,
    "value": [
      0.36744911548264,
      0.1138722038726,
      0.078037756966598,
      0.059832783672276,
      0.048955839670295,
      0.041320877213877,
      0.035776896937735,
      0.034338147731124,
      0.032362265487377,
      0.030961882926276,
      0.028544784259169,
      0.031441465995146,
      0.028410500999885,
      0.029810883560987,
      0.023633853633935,
      0.013811992383469,
      0.0014003825611017,
      0.000038366645509635
    ]
  },
  {
    "key": 4419405,
    "value": [
      0.36992571337683,
      0.11199387190475,
      0.077082906473651,
      0.059123562836539,
      0.048360677645096,
      0.041034016026774,
      0.035629560014382,
      0.034121773462058,
      0.032230751289091,
      0.030743263919984,
      0.028752514665116,
      0.031482334282713,
      0.028670324730103,
      0.030358382386836,
      0.024236420599054,
      0.014661949837423,
      0.0015506298670637,
      0.000041346682534896
    ]
  },
  {
    "key": 4447200,
    "value": [
      0.37789066070949,
      0.10781119193163,
      0.07485429890438,
      0.057458755242056,
      0.046969980088859,
      0.040273696139474,
      0.035165131180144,
      0.033532175271968,
      0.031814026958604,
      0.030166505550186,
      0.02900615819123,
      0.031401072899272,
      0.029024612633615,
      0.031257580594445,
      0.025272661298423,
      0.016222793500734,
      0.0018318286564002,
      0.000046870249083555
    ]
  },
  {
    "key": 4474995,
    "value": [
      0.38546168194754,
      0.10380345328639,
      0.072721442540498,
      0.055865700219511,
      0.045639071103796,
      0.039548192324084,
      0.034723509999562,
      0.032969911248421,
      0.031417805616395,
      0.029616101710731,
      0.029254377065417,
      0.031327484595843,
      0.029369647463776,
      0.032127108933977,
      0.026273142600812,
      0.01772658840452,
      0.0021025905463519,
      0.000052190392372236
    ]
  },
  {
    "key": 4502790,
    "value": [
      0.39266705351962,
      0.099958668820927,
      0.070677722773367,
      0.054339436391718,
      0.044363819645512,
      0.038855055244763,
      0.034303062206265,
      0.032433051936019,
      0.031040617450541,
      0.029090199422148,
      0.029497433474897,
      0.03126095330882,
      0.029705945685116,
      0.032968832692815,
      0.027240154610618,
      0.019177069641714,
      0.0023636027070633,
      0.000057320468076693
    ]
  },
  {
    "key": 4530585,
    "value": [
      0.39953241441734,
      0.096265955839189,
      0.068717135783181,
      0.052875460626759,
      0.043140476146988,
      0.038192062833357,
      0.03390230589828,
      0.031919847066585,
      0.030681129697649,
      0.028587117611897,
      0.029735568191573,
      0.031200921996985,
      0.030033979120193,
      0.033784449496378,
      0.028175780440008,
      0.020577632522004,
      0.0026154896957027,
      0.000062272615926656
    ]
  },
  {
    "key": 4558380,
    "value": [
      0.40608110000334,
      0.092715405509791,
      0.066834215403475,
      0.051469673089713,
      0.041965626827538,
      0.037557192328317,
      0.033519892300613,
      0.031428703406359,
      0.030338129328134,
      0.028105325982281,
      0.029969001231046,
      0.031146884118483,
      0.030354178217178,
      0.034575506263424,
      0.029081917943772,
      0.021931369771866,
      0.0028588203811618,
      0.000067057893503307
    ]
  },
  {
    "key": 4586175,
    "value": [
      0.41233426760124,
      0.089297995388202,
      0.065023988223624,
      0.050118343855047,
      0.040836165701342,
      0.036948606406538,
      0.033154598333095,
      0.030958174228124,
      0.030010516403136,
      0.027643434452661,
      0.030197940652989,
      0.031098384813465,
      0.030666943212081,
      0.035343423365601,
      0.029960306503816,
      0.023241109730569,
      0.0030941147181,
      0.000071686410372091
    ]
  },
  {
    "key": 4613970,
    "value": [
      0.40967079048771,
      0.089510544172571,
      0.065231587701746,
      0.050281776796082,
      0.040967117481798,
      0.037099288497484,
      0.033303890767558,
      0.031087494383288,
      0.030145888076245,
      0.027755656681444,
      0.030377668090287,
      0.031261329393819,
      0.030855714369247,
      0.035592718406216,
      0.030189346828878,
      0.023467726421681,
      0.0031290301895574,
      0.000072431254387903
    ]
  },
  {
    "key": 4641765,
    "value": [
      0.40629548549535,
      0.090022335528558,
      0.065604560104401,
      0.050569271179742,
      0.04120135375866,
      0.037311409821757,
      0.033494311276931,
      0.031265242279379,
      0.03031825218238,
      0.027914354243845,
      0.030551357437026,
      0.031440071220364,
      0.031032137024733,
      0.035796225666559,
      0.030361959417626,
      0.023601907032896,
      0.0031469209377194,
      0.000072845392076839
    ]
  },
  {
    "key": 4669560,
    "value": [
      0.40296036270527,
      0.090528034130306,
      0.065973092359406,
      0.050853343011214,
      0.041432801508417,
      0.037521005892169,
      0.033682464875477,
      0.031440874129088,
      0.030488564334871,
      0.02807116254955,
      0.030722979053448,
      0.031616685168021,
      0.031206459410512,
      0.035997310221421,
      0.030532517094604,
      0.023734490255882,
      0.0031645987007843,
      0.000073254599555192
    ]
  },
  {
    "key": 4697355,
    "value": [
      0.39966470882396,
      0.091027748133218,
      0.066337263285949,
      0.051134053045865,
      0.041661510231549,
      0.037728121535713,
      0.033868391804219,
      0.031614427495369,
      0.03065686095893,
      0.02822611513566,
      0.030892569644823,
      0.031791209009789,
      0.031378718809476,
      0.03619601507741,
      0.030701056337535,
      0.023865504446644,
      0.0031820672595525,
      0.000073658964341494
    ]
  },
  {
    "key": 4725150,
    "value": [
      0.39640782734124,
      0.09152158314786,
      0.066697149848651,
      0.05141146060952,
      0.041887528263821,
      0.037932800524627,
      0.034052131357328,
      0.031785939057341,
      0.030823177622706,
      0.02837924475017,
      0.03106016505277,
      0.031963679629889,
      0.031548951627277,
      0.036392382229211,
      0.030867612765843,
      0.023994977293985,
      0.0031993303058647,
      0.000074058571895016
    ]
  },
  {
    "key": 4752945,
    "value": [
      0.39318903803962,
      0.092009642314378,
      0.067052827211789,
      0.051685623640266,
      0.042110902810335,
      0.038135085607238,
      0.034233721909816,
      0.031955444636131,
      0.030987549062344,
      0.028530583375036,
      0.031225800280507,
      0.032134133049754,
      0.031717193417968,
      0.036586452689177,
      0.031032221165749,
      0.024122935839018,
      0.0032163914452024,
      0.000074453505675982
    ]
  },
  {
    "key": 4780740,
    "value": [
      0.38971312460433,
      0.092536688745548,
      0.067436916883186,
      0.051981687430943,
      0.042352121018354,
      0.038353529677512,
      0.034429818324327,
      0.032138490702047,
      0.031165050862516,
      0.028694011269861,
      0.031404666823016,
      0.032318202672422,
      0.031898874741547,
      0.036796025934263,
      0.03120997885511,
      0.024261116000613,
      0.0032348154667484,
      0.000074879987656213
    ]
  },
  {
    "key": 4808535,
    "value": [
      0.38621790183773,
      0.093066663015501,
      0.067823140242563,
      0.052279395909824,
      0.042594679237391,
      0.038573187244327,
      0.034627004090085,
      0.03232255362215,
      0.031343538717472,
      0.028858347036365,
      0.0315845270017,
      0.032503294835321,
      0.032081565337921,
      0.037006763396842,
      0.031388724020764,
      0.024400063778137,
      0.003253341837085,
      0.000075308838821412
    ]
  },
  {
    "key": 4836330,
    "value": [
      0.38276282302743,
      0.093590550330386,
      0.068204927680486,
      0.052573685094152,
      0.042834451583481,
      0.038790321960854,
      0.034821925102696,
      0.032504502509954,
      0.031519976571861,
      0.029020795344395,
      0.031762321418161,
      0.032686261144679,
      0.032262157663654,
      0.037215080459906,
      0.031565416230543,
      0.02453741568785,
      0.0032716554250467,
      0.000075732764468672
    ]
  },
  {
    "key": 4864125,
    "value": [
      0.37934726167288,
      0.094108445685339,
      0.068582348425487,
      0.052864608346628,
      0.043071481533928,
      0.039004973199572,
      0.035014616706683,
      0.032684370357782,
      0.031694396418707,
      0.029181385650285,
      0.031938082311403,
      0.032867134777304,
      0.032440684465087,
      0.037421014897051,
      0.031740087523587,
      0.024673196635418,
      0.0032897595513891,
      0.00007615184146734
    ]
  },
  {
    "key": 4891920,
    "value": [
      0.37597048285,
      0.094620460515567,
      0.068955483686939,
      0.053152228265016,
      0.04330582009031,
      0.039217187146847,
      0.035205120363374,
      0.032862195867682,
      0.031866835787813,
      0.029340152508145,
      0.032111847499781,
      0.033045954651658,
      0.032617184155714,
      0.037624611019056,
      0.031912775483807,
      0.02480743583674,
      0.0033076581115654,
      0.000076566159989939
    ]
  },
  {
    "key": 4919715,
    "value": [
      0.3725855724053,
      0.095133708328817,
      0.069329517495495,
      0.053440540800991,
      0.04354072295742,
      0.039429912126574,
      0.035396082771962,
      0.03304044959923,
      0.0320396904082,
      0.029499301692509,
      0.032286031132146,
      0.033225205142189,
      0.032794108875284,
      0.037828697420926,
      0.03208587929394,
      0.024941998299512,
      0.0033255997732682,
      0.000076981476233061
    ]
  },
  {
    "key": 4947510,
    "value": [
      0.36899291265959,
      0.095678456790665,
      0.069726507675735,
      0.053746548554847,
      0.043790043017317,
      0.039655693128483,
      0.035598765522211,
      0.0332296436758,
      0.032223154002488,
      0.029668218677928,
      0.032470905306688,
      0.03341545715395,
      0.0329818923716,
      0.038045309651184,
      0.032269607372026,
      0.025084819550231,
      0.0033446426066975,
      0.000077422282562442
    ]
  },
  {
    "key": 4975305,
    "value": [
      0.36544039436355,
      0.096217118677521,
      0.070119062211503,
      0.054049137227654,
      0.044036577378221,
      0.039878951437632,
      0.035799183660725,
      0.033416723852073,
      0.032404567724214,
      0.029835248322727,
      0.032653713847995,
      0.033603583444908,
      0.033167577728292,
      0.038259501633058,
      0.032451282622423,
      0.025226045032786,
      0.0033634726710381,
      0.000077858163681438
    ]
  },
  {
    "key": 5003100,
    "value": [
      0.36192734849302,
      0.0967497954323,
      0.070507255030206,
      0.054348363804097,
      0.044280372468449,
      0.040099729098903,
      0.035997374931034,
      0.033601725359721,
      0.032583965737921,
      0.030000422082584,
      0.032834491183287,
      0.033789619443745,
      0.033351199914355,
      0.038471313704023,
      0.032630939258927,
      0.025365701343312,
      0.0033820935124416,
      0.000078289201676889
    ]
  },
  {
    "key": 5030895,
    "value": [
      0.3584531208089,
      0.097276586256087,
      0.070891158425499,
      0.054644284009529,
      0.044521473690276,
      0.040318067228004,
      0.036193376242554,
      0.033784682651815,
      0.032761381453134,
      0.030163770718023,
      0.033013270978963,
      0.033973599796186,
      0.033532793125986,
      0.038680785310116,
      0.032808610739227,
      0.025503814490186,
      0.0034005085986915,
      0.000078715476821562
    ]
  },
  {
    "key": 5058690,
    "value": [
      0.35501707145099,
      0.097797588169722,
      0.071270843102162,
      0.054936952344571,
      0.044759925448127,
      0.040534006037006,
      0.036387223693509,
      0.033965629424215,
      0.032936847545103,
      0.030325324313512,
      0.0331900861615,
      0.034155558386513,
      0.033712390807818,
      0.038887955030427,
      0.032984329785678,
      0.025640409910172,
      0.0034187213213562,
      0.000079137067623986
    ]
  },
  {
    "key": 5086485,
    "value": [
      0.3514444210103,
      0.098339302657047,
      0.071665622247076,
      0.055241255789385,
      0.045007856920882,
      0.040758529552468,
      0.036588777602938,
      0.034153769785082,
      0.033119289339588,
      0.030493300516411,
      0.033373930680017,
      0.034344750790404,
      0.033899128444653,
      0.039103360839677,
      0.033167034590919,
      0.025782435718468,
      0.0034376580957958,
      0.000079575418884162
    ]
  },
  {
    "key": 5114280,
    "value": [
      0.34787332241275,
      0.098880781841222,
      0.072060229912773,
      0.055545427054682,
      0.045255680700271,
      0.040982955542219,
      0.036790243963905,
      0.034341828423897,
      0.033301651887293,
      0.030661203755912,
      0.033557695342457,
      0.034533861015271,
      0.034085784968733,
      0.039318673083651,
      0.033349660035136,
      0.025924399835375,
      0.0034565866447166,
      0.00008001357973881
    ]
  },
  {
    "key": 5142075,
    "value": [
      0.34434083028652,
      0.099416407196378,
      0.072450571549651,
      0.055846309981976,
      0.045500825303667,
      0.041204955305053,
      0.036989532310159,
      0.034527853996347,
      0.033482042947995,
      0.030827291825257,
      0.033739473359897,
      0.034720926805273,
      0.034270423584445,
      0.039531657627691,
      0.033530311150227,
      0.026064829205071,
      0.0034753105606761,
      0.000080447003719354
    ]
  },
  {
    "key": 5169870,
    "value": [
      0.34084635096346,
      0.099946268739339,
      0.072836712758252,
      0.05614395513744,
      0.0457433319299,
      0.041424566150097,
      0.03718667613396,
      0.034711877765758,
      0.033660492838091,
      0.030991592637089,
      0.033919295281824,
      0.034905979598558,
      0.034453075322025,
      0.039742350265829,
      0.033709018296291,
      0.026203747428019,
      0.0034938329904025,
      0.000080875763666725
    ]
  },
  {
    "key": 5197665,
    "value": [
      0.33738921688911,
      0.10047046769017,
      0.073218727303584,
      0.056438419380577,
      0.045983246905293,
      0.041641830029864,
      0.037381713095923,
      0.034893934886407,
      0.03383703564707,
      0.031154137577985,
      0.03409719545983,
      0.035089054745977,
      0.034633775073647,
      0.039950791246925,
      0.033885815611963,
      0.026341181041929,
      0.0035121574722572,
      0.000081299941487435
    ]
  },
  {
    "key": 5225460,
    "value": [
      0.34308008263708,
      0.097752240139566,
      0.071043850820719,
      0.055335460979089,
      0.045020545465607,
      0.040732248631384,
      0.036939769290401,
      0.034556594917905,
      0.033393265852569,
      0.031174507186747,
      0.033501559426717,
      0.035014156320322,
      0.034921544796326,
      0.040492569690986,
      0.03495949950945,
      0.028024024077578,
      0.0039566323081263,
      0.00010144794943147
    ]
  },
  {
    "key": 5253255,
    "value": [
      0.35144343247606,
      0.094090438953362,
      0.068125061211724,
      0.05382548249473,
      0.04370763470146,
      0.039494562312002,
      0.036311977561971,
      0.034067593362028,
      0.032768581284001,
      0.031152773194804,
      0.032680541616751,
      0.034863446567292,
      0.035239437943634,
      0.041129981160353,
      0.036292503233288,
      0.030154570365564,
      0.0045246646835137,
      0.00012731687746375
    ]
  },
  {
    "key": 5281050,
    "value": [
      0.35938725450858,
      0.090583334049375,
      0.065328529206325,
      0.052381588687496,
      0.042451711641694,
      0.03831033763725,
      0.035713788651381,
      0.0336025331174,
      0.032172839460184,
      0.031136628447381,
      0.031895977164985,
      0.034723835318599,
      0.03555052878832,
      0.041749244819845,
      0.037579963473859,
      0.032208071916925,
      0.0050716192382669,
      0.00015221387213927
    ]
  },
  {
    "key": 5308845,
    "value": [
      0.36694168958943,
      0.087220243510871,
      0.062645831385394,
      0.050999175738285,
      0.041248814281607,
      0.037175862242803,
      0.035143103913385,
      0.033159702898217,
      0.031603996675732,
      0.031125604312527,
      0.031145317624396,
      0.03459447245098,
      0.035855169714018,
      0.042351458359724,
      0.03882483438419,
      0.034189619275997,
      0.0055988997367806,
      0.00017620390566663
    ]
  },
  {
    "key": 5336640,
    "value": [
      0.37413407146055,
      0.083991471518976,
      0.060069321544139,
      0.049674065682935,
      0.040095346935026,
      0.036087766907676,
      0.034598019586533,
      0.032737550618696,
      0.031060198840274,
      0.031119277160288,
      0.030426250466054,
      0.034474588082988,
      0.03615368304346,
      0.042937621408733,
      0.040029801418552,
      0.036103837884651,
      0.0061077814430478,
      0.00019934599741986
    ]
  },
  {
    "key": 5364435,
    "value": [
      0.37093374608418,
      0.08433192930216,
      0.060302296470121,
      0.04989840516016,
      0.040273127651461,
      0.036245814886315,
      0.034769133539095,
      0.032903145291244,
      0.031211673929882,
      0.031292220185185,
      0.030567303887459,
      0.034661738532024,
      0.036366634269269,
      0.043199641594134,
      0.040299976403229,
      0.036380058645153,
      0.0061617885306736,
      0.00020136563825731
    ]
  },
  {
    "key": 5392230,
    "value": [
      0.3673459836515,
      0.084812900783212,
      0.060646219407543,
      0.050182991437631,
      0.040502817948048,
      0.036452536153243,
      0.034967432828481,
      0.033090802263555,
      0.031389683909737,
      0.031470689545633,
      0.030741638822568,
      0.034859425313953,
      0.036574044607087,
      0.043446022718939,
      0.04052981982668,
      0.036587545546403,
      0.0061969311460513,
      0.00020251408974024
    ]
  },
  {
    "key": 5420025,
    "value": [
      0.36379501878223,
      0.085288939223433,
      0.060986614930223,
      0.050464658881487,
      0.04073015244672,
      0.036657137202048,
      0.035163698279002,
      0.033276534548971,
      0.031565868146208,
      0.031647328451102,
      0.030914185707061,
      0.035055084539144,
      0.036779327659389,
      0.043689876857849,
      0.040757305881685,
      0.036792904376871,
      0.0062317133243482,
      0.0002036507622336
    ]
  },
  {
    "key": 5447820,
    "value": [
      0.36028028824836,
      0.085760120128549,
      0.061323537029201,
      0.050743452167752,
      0.040955167205611,
      0.03685965048505,
      0.035357961020844,
      0.033460371606984,
      0.031740254584348,
      0.03182216491876,
      0.031084971909059,
      0.035248747241629,
      0.036982515986667,
      0.043931242689219,
      0.040982470650415,
      0.036996167709068,
      0.0062661405824585,
      0.00020477583602805
    ]
  },
  {
    "key": 5475615,
    "value": [
      0.35663085366668,
      0.086249359309985,
      0.061673371859353,
      0.051032930365364,
      0.041188805783183,
      0.037069925204865,
      0.035559668992815,
      0.03365125432486,
      0.031921324481967,
      0.032003702093533,
      0.031262303589436,
      0.035449832177393,
      0.037193491622214,
      0.044181859003428,
      0.041216264987038,
      0.037207221224142,
      0.006301887284827,
      0.00020594402891592
    ]
  },
  {
    "key": 5503410,
    "value": [
      0.35286886672124,
      0.08675368713737,
      0.062033995960055,
      0.051331336372112,
      0.041429650017846,
      0.037286685016062,
      0.035767597848741,
      0.03384802406458,
      0.032107978763831,
      0.032190838063867,
      0.031445104363545,
      0.035657118782026,
      0.037410973966115,
      0.04444020458581,
      0.041457269784525,
      0.037424783849454,
      0.0063387364527305,
      0.00020714825008923
    ]
  },
  {
    "key": 5531205,
    "value": [
      0.34914468868982,
      0.087252946343374,
      0.062390995697937,
      0.051626743323515,
      0.041668073707438,
      0.037501266336694,
      0.035973436967421,
      0.034042816218977,
      0.032292757123264,
      0.032376093270679,
      0.031626067943945,
      0.035862322104201,
      0.037626270557816,
      0.044695953730178,
      0.041695852423243,
      0.037640159915719,
      0.006375215277238,
      0.00020834036853719
    ]
  },
  {
    "key": 5559000,
    "value": [
      0.34545775243872,
      0.087747212957318,
      0.062744425438439,
      0.051919196205405,
      0.041904113160133,
      0.03771370184412,
      0.036177217694915,
      0.034235660451829,
      0.032475687699103,
      0.032559495925423,
      0.031805221888541,
      0.036065473393154,
      0.0378394141836,
      0.044949145383103,
      0.041932049235573,
      0.03785338222132,
      0.0064113293135003,
      0.00020952056580066
    ]
  },
  {
    "key": 5586795,
    "value": [
      0.34171624528583,
      0.088248795286265,
      0.063103086346053,
      0.052215977726687,
      0.042143646268513,
      0.037929281641661,
      0.036384014611816,
      0.034431359001375,
      0.032661325858097,
      0.032745613150634,
      0.031987027517801,
      0.0362716315551,
      0.038055712580467,
      0.045206084564024,
      0.042171742032692,
      0.038069760462556,
      0.0064479778790824,
      0.00021071823134256
    ]
  },
  {
    "key": 5614590,
    "value": [
      0.33787192969709,
      0.088764160000914,
      0.063471602471205,
      0.052520914155269,
      0.042389761222977,
      0.03815078510068,
      0.036596493855837,
      0.034632434919172,
      0.032852064947807,
      0.032936844470253,
      0.03217382876824,
      0.036483454492576,
      0.038277954384349,
      0.045470083871847,
      0.042418021063793,
      0.038292084304756,
      0.0064856334671155,
      0.00021194880611489
    ]
  },
  {
    "key": 5642385,
    "value": [
      0.33406546471117,
      0.08927445050543,
      0.063836490237248,
      0.052822848221694,
      0.042633452963433,
      0.03837010766709,
      0.036806881058431,
      0.034831531071125,
      0.033040926046661,
      0.033126192952588,
      0.032358790799246,
      0.036693191850528,
      0.03849800802598,
      0.045731483878776,
      0.042661875265409,
      0.038512219176968,
      0.0065229183034053,
      0.00021316726481717
    ]
  },
  {
    "key": 5670180,
    "value": [
      0.33029634231729,
      0.089779734903175,
      0.064197798342098,
      0.053121820222079,
      0.042874754012977,
      0.03858727861168,
      0.03701520429787,
      0.035028674028602,
      0.033227934360057,
      0.033313683868083,
      0.03254193829585,
      0.036900871620503,
      0.038715902873719,
      0.045990319471254,
      0.042903337182319,
      0.03873019445839,
      0.0065598373639855,
      0.00021437377006489
    ]
  },
  {
    "key": 5697975,
    "value": [
      0.32650678415933,
      0.09028775889695,
      0.064561065419468,
      0.05342241321553,
      0.043117363370081,
      0.038805627033073,
      0.037224657042837,
      0.035226885873356,
      0.033415956611813,
      0.033502191338553,
      0.032726078797891,
      0.037109677407183,
      0.038934979123183,
      0.04625055844164,
      0.043146108278994,
      0.03894935157764,
      0.0065969565956224,
      0.00021558681685041
    ]
  },
  {
    "key": 5725770,
    "value": [
      0.32269176796129,
      0.090799195767545,
      0.064926772904778,
      0.053725025575926,
      0.043361602563298,
      0.039025442306968,
      0.037435516879647,
      0.035426429294214,
      0.033605241986556,
      0.033691965191682,
      0.032911456345543,
      0.037319885939478,
      0.039155527114658,
      0.046512545682897,
      0.04339051029834,
      0.039169980982179,
      0.0066343251921845,
      0.00021680801281649
    ]
  },
  {
    "key": 5753565,
    "value": [
      0.31891358786755,
      0.09130569443056,
      0.065288949280814,
      0.054024716045589,
      0.043603483491194,
      0.039243135142075,
      0.037644340747397,
      0.035624046012305,
      0.033792699705675,
      0.033879906672658,
      0.033095043969816,
      0.037528064791421,
      0.039373945592548,
      0.046772003291554,
      0.043632552480188,
      0.039388480087045,
      0.0066713329741527,
      0.00021801741745597
    ]
  },
  {
    "key": 5781360,
    "value": [
      0.31517176026897,
      0.091807319718104,
      0.065647640906355,
      0.054321522985067,
      0.043843037114663,
      0.039458733403196,
      0.037851155375659,
      0.035819761322679,
      0.033978353763864,
      0.034066039838093,
      0.033276865170029,
      0.03773424061002,
      0.03959026251454,
      0.047028964478328,
      0.043872265806072,
      0.039604876860245,
      0.0067079846785434,
      0.00021921518557331
    ]
  },
  {
    "key": 5809155,
    "value": [
      0.32054615741507,
      0.089076170980779,
      0.064238922964212,
      0.052984694172295,
      0.04319700652958,
      0.038742149638705,
      0.036609427565834,
      0.035291366581087,
      0.033578505530495,
      0.033704438837364,
      0.033055868065266,
      0.037392214676564,
      0.039449735361759,
      0.047618196460833,
      0.044855983778234,
      0.042074987227357,
      0.0073465343898953,
      0.000237639824675
    ]
  },
  {
    "key": 5836950,
    "value": [
      0.32914661974633,
      0.085204758862995,
      0.062206420347021,
      0.051071452541844,
      0.042238147995886,
      0.037696443891815,
      0.034856851883455,
      0.034507184508249,
      0.032971707569745,
      0.033149180820984,
      0.032692245918599,
      0.036856275909327,
      0.039182609716739,
      0.048323222743701,
      0.046100510451234,
      0.045337450440848,
      0.0081967935467281,
      0.00026212310449527
    ]
  },
  {
    "key": 5864745,
    "value": [
      0.33730426594179,
      0.08149711447205,
      0.06026276280979,
      0.049240614944167,
      0.041323318082466,
      0.036697381931719,
      0.033177803864135,
      0.033759004337457,
      0.032393796396668,
      0.032620844559724,
      0.032348055928051,
      0.03634711955757,
      0.038932247394515,
      0.049008898049772,
      0.047304447546426,
      0.048481122328455,
      0.0090154997594575,
      0.00028570209578891
    ]
  },
  {
    "key": 5892540,
    "value": [
      0.3429973963699,
      0.078650859606208,
      0.058791327086978,
      0.04784572796193,
      0.040646012528168,
      0.035947785525537,
      0.031884454063802,
      0.033205036788866,
      0.031973339439527,
      0.032239995566704,
      0.032113016458525,
      0.035985879257991,
      0.038779419637934,
      0.049610737565621,
      0.048315550662193,
      0.051032903577228,
      0.0096758080432565,
      0.00030474985963013
    ]
  },
  {
    "key": 5920335,
    "value": [
      0.33913177806506,
      0.079113619115692,
      0.059137238699654,
      0.048127238751683,
      0.040885162219782,
      0.03615929217251,
      0.032072053212706,
      0.033400405874642,
      0.032161461564952,
      0.032429686621689,
      0.032301960404195,
      0.036197610037758,
      0.039007586822622,
      0.049902633174848,
      0.048599825756411,
      0.051333166810779,
      0.0097327377730315,
      0.00030654292198524
    ]
  },
  {
    "key": 5948130,
    "value": [
      0.3353022870341,
      0.079572053769947,
      0.059479917493518,
      0.048406118599477,
      0.041122076867549,
      0.036368822121847,
      0.032257899098537,
      0.033593949081112,
      0.032347825539672,
      0.032617604863076,
      0.032489138518598,
      0.03640736202519,
      0.039233621603716,
      0.050191800787727,
      0.048881444074047,
      0.051630623845886,
      0.0097891354492572,
      0.00030831922674826
    ]
  },
  {
    "key": 5975925,
    "value": [
      0.33150839753754,
      0.080026226506107,
      0.059819410513929,
      0.048682405791897,
      0.041356788996779,
      0.036576404139295,
      0.032442017235524,
      0.033785692979249,
      0.032532456949044,
      0.032803776089604,
      0.032674576498861,
      0.036615164016517,
      0.039457555012859,
      0.050478280103223,
      0.049160444277646,
      0.051925315519542,
      0.0098450088146034,
      0.00031007901778279
    ]
  },
  {
    "key": 6003720,
    "value": [
      0.32756731917227,
      0.08049801946323,
      0.060172074606838,
      0.048969411904658,
      0.041600607087794,
      0.036792039570617,
      0.03263327847468,
      0.03398487583086,
      0.032724251373654,
      0.032997170070575,
      0.032867208786327,
      0.036831027955892,
      0.039690176209348,
      0.050775873755705,
      0.049450268656375,
      0.052231440139283,
      0.0099030498596999,
      0.00031190708219527
    ]
  },
  {
    "key": 6031515,
    "value": [
      0.32363649483645,
      0.080968584893663,
      0.060523821126519,
      0.049255671275319,
      0.041843790804749,
      0.037007113954466,
      0.03282404208395,
      0.034183540441868,
      0.032915546781118,
      0.03319006087262,
      0.033059339876667,
      0.037046330253252,
      0.039922192164231,
      0.051072693119074,
      0.049739338960347,
      0.052536768273754,
      0.0099609398916648,
      0.00031373039028865
    ]
  },
  {
    "key": 6059310,
    "value": [
      0.31974171176901,
      0.081434835764727,
      0.060872342523521,
      0.049539305967306,
      0.042084744798659,
      0.037220216346455,
      0.033013056604009,
      0.034380383520304,
      0.033105088223375,
      0.033381183081473,
      0.033249709339521,
      0.03725965846904,
      0.040152080791972,
      0.051366790980431,
      0.050025758812526,
      0.052839296890287,
      0.0100182991367,
      0.00031553698068348
    ]
  },
  {
    "key": 6087105,
    "value": [
      0.31582973047732,
      0.081903145463433,
      0.061222402889198,
      0.049824193107236,
      0.042326762775016,
      0.037434259736354,
      0.033202905756971,
      0.034578095800271,
      0.03329546662527,
      0.033573149230167,
      0.033440919418312,
      0.037473928679911,
      0.040382984540737,
      0.05166218749203,
      0.050313443411102,
      0.053143161384814,
      0.010075911663406,
      0.00031735154845373
    ]
  },
  {
    "key": 6114900,
    "value": [
      0.31193571901067,
      0.082369303964901,
      0.06157085524015,
      0.050107771607967,
      0.042567669033201,
      0.037647319910338,
      0.033391882831105,
      0.034774899881856,
      0.033484970517214,
      0.033764233575538,
      0.033631251166812,
      0.037687214632956,
      0.040612827624929,
      0.051956227089259,
      0.050599806520253,
      0.05344563006699,
      0.010133259544923,
      0.00031915778094246
    ]
  },
  {
    "key": 6142695,
    "value": [
      0.30807694746679,
      0.082831243837396,
      0.061916154176162,
      0.050388783787425,
      0.042806395144253,
      0.037858451937951,
      0.033579149705473,
      0.034969922931029,
      0.033672759441809,
      0.033953588650815,
      0.03381986045605,
      0.03789857039638,
      0.040840590681209,
      0.052247605694657,
      0.050883578108055,
      0.053745361476024,
      0.010190088441087,
      0.00032094766743583
    ]
  },
  {
    "key": 6170490,
    "value": [
      0.30431693832941,
      0.083281360700172,
      0.062252615441039,
      0.050662603667783,
      0.043039011236883,
      0.038064180197318,
      0.033761623622559,
      0.035159954509356,
      0.033855742047632,
      0.034138097322851,
      0.03400364242989,
      0.038104516665206,
      0.041062524310353,
      0.052531526679944,
      0.05116008677174,
      0.05403742148111,
      0.010245462843644,
      0.0003226917431069
    ]
  },
  {
    "key": 6198285,
    "value": [
      0.30061167986623,
      0.083724923269913,
      0.062584177387745,
      0.050932436370848,
      0.043268240133515,
      0.038266912782874,
      0.033941440479617,
      0.035347218978176,
      0.034036060186251,
      0.034319919306152,
      0.034184748296676,
      0.038307464085717,
      0.041281226294206,
      0.052811313402575,
      0.051432569105912,
      0.054325228708715,
      0.01030003092213,
      0.00032441042274425
    ]
  },
  {
    "key": 6226080,
    "value": [
      0.29693948329931,
      0.084164527945808,
      0.062912780818387,
      0.051199861365806,
      0.043495423628436,
      0.038467836392409,
      0.034119652836927,
      0.035532812492458,
      0.034214769352203,
      0.034500118898031,
      0.034364238161923,
      0.038508600613242,
      0.041497976807636,
      0.053088603597719,
      0.051702620089409,
      0.054610467842138,
      0.010354112091493,
      0.00032611376666119
    ]
  },
  {
    "key": 6253875,
    "value": [
      0.30281232598572,
      0.081716529814273,
      0.061500507340331,
      0.050011269641332,
      0.042311849909185,
      0.037457912812431,
      0.033574509647809,
      0.034628401209029,
      0.033417762509957,
      0.033928505888814,
      0.033910299823786,
      0.038079739305503,
      0.04138940005155,
      0.053026758634708,
      0.052771978350469,
      0.057554833285004,
      0.011535064623,
      0.0003723511671006
    ]
  },
  {
    "key": 6281670,
    "value": [
      0.31031804594855,
      0.078779684179322,
      0.059793031188018,
      0.048575836034356,
      0.040889351574265,
      0.03624287580606,
      0.032906387268171,
      0.033539310659351,
      0.032455344098165,
      0.033229114086929,
      0.033348491723406,
      0.037543419332681,
      0.041224468464503,
      0.052905774114746,
      0.053973655225409,
      0.060944086696256,
      0.012905060694192,
      0.00042606290562204
    ]
  },
  {
    "key": 6309465,
    "value": [
      0.31752214849983,
      0.07594036047793,
      0.05814375473347,
      0.047189148149997,
      0.039514382193308,
      0.035068577247484,
      0.032262058011004,
      0.032486848724451,
      0.031525596177111,
      0.032554498398077,
      0.032807299744406,
      0.037027392396361,
      0.04106896085116,
      0.052794133629219,
      0.055144675485848,
      0.064237083814863,
      0.014234890152936,
      0.00047819131254806
    ]
  },
  {
    "key": 6337260,
    "value": [
      0.32444234274404,
      0.073193091257222,
      0.056549392180167,
      0.045848456633288,
      0.038184272640698,
      0.033932727209387,
      0.031640163417866,
      0.031468954785373,
      0.030626676248136,
      0.031903246098246,
      0.032285538151956,
      0.036530482890172,
      0.040922288636134,
      0.05269123203607,
      0.056286616761154,
      0.067438972587101,
      0.015526723622985,
      0.00052882210000155
    ]
  },
  {
    "key": 6365055,
    "value": [
      0.33109503471248,
      0.070532818156795,
      0.055006902955805,
      0.04455121739161,
      0.036896553380474,
      0.032833206939928,
      0.031039446000156,
      0.030483722210666,
      0.029756879375476,
      0.031274049516819,
      0.031782109109077,
      0.036051602128175,
      0.040783905626859,
      0.052596507236829,
      0.057400934757362,
      0.07055450893848,
      0.016782567080188,
      0.00057803448282514
    ]
  },
  {
    "key": 6392850,
    "value": [
      0.33548847980164,
      0.068564003863598,
      0.053880524206689,
      0.043602088446852,
      0.035946503251685,
      0.032023436167778,
      0.030611132017572,
      0.029759266022209,
      0.029120366525687,
      0.030824098516412,
      0.031429371723644,
      0.035722327989748,
      0.040721436330955,
      0.052580307687451,
      0.058319194393052,
      0.073025091576155,
      0.01776588950855,
      0.00061648197032824
    ]
  },
  {
    "key": 6420645,
    "value": [
      0.33234526786489,
      0.06888831907684,
      0.054135384960335,
      0.043808331078781,
      0.036116534131016,
      0.032174910512102,
      0.030755926009294,
      0.029900030594901,
      0.029258109034106,
      0.030969899862892,
      0.031578036078381,
      0.035891298495649,
      0.040914053164322,
      0.052829018275209,
      0.058595050540591,
      0.073370508220633,
      0.017849924102794,
      0.00061939799725784
    ]
  },
  {
    "key": 6448440,
    "value": [
      0.32922917098648,
      0.069209836570392,
      0.054388047146293,
      0.044012794549424,
      0.036285098231363,
      0.032325078156222,
      0.030899470929171,
      0.030039580855712,
      0.029394663300618,
      0.031114443447536,
      0.031725417973415,
      0.036058811369927,
      0.041105008379963,
      0.053075583349961,
      0.058868527002739,
      0.073712945112981,
      0.017933233768853,
      0.00062228886895072
    ]
  },
  {
    "key": 6476235,
    "value": [
      0.32613982172075,
      0.069528594257133,
      0.054638540558123,
      0.044215502968816,
      0.036452215429561,
      0.032473956807688,
      0.031041783703814,
      0.030177933260208,
      0.029530045427502,
      0.031257746314716,
      0.031871534787805,
      0.036224886365454,
      0.041294324495041,
      0.053320031986303,
      0.059139656027442,
      0.074052442632863,
      0.018015828330482,
      0.00062515492629432
    ]
  },
  {
    "key": 6504030,
    "value": [
      0.3230768771496,
      0.069844627519202,
      0.054886893000622,
      0.044416478837616,
      0.036617904275638,
      0.032621562992048,
      0.031182880129956,
      0.030315103165519,
      0.029664270442192,
      0.031399824371065,
      0.032016402740533,
      0.036389541916576,
      0.041482022523665,
      0.05356239131806,
      0.059408467710055,
      0.074389038464541,
      0.018097716955687,
      0.0006279964874213
    ]
  },
  {
    "key": 6531825,
    "value": [
      0.32004000019183,
      0.070157971136486,
      0.055133131805311,
      0.044615744273489,
      0.036782183003876,
      0.032767912953562,
      0.031322775735449,
      0.030451105667381,
      0.02979735311633,
      0.031540693252466,
      0.032160037774515,
      0.036552796143858,
      0.041668123122258,
      0.053802688017207,
      0.059674991633666,
      0.074722769650843,
      0.018178908656422,
      0.00063081386504933
    ]
  },
  {
    "key": 6559620,
    "value": [
      0.31702355859041,
      0.070469206242768,
      0.055377713651951,
      0.044813668838379,
      0.036945356289121,
      0.032913278116002,
      0.03146172997368,
      0.030586192998945,
      0.029929540267894,
      0.031680614217363,
      0.032302706278359,
      0.036714951822086,
      0.041852971436975,
      0.054041367743146,
      0.059939722099251,
      0.075054255136772,
      0.018259554012553,
      0.00063361228434727
    ]
  },
  {
    "key": 6587415,
    "value": [
      0.31403147325084,
      0.070777928280755,
      0.055620320622134,
      0.045009995261098,
      0.037107212031451,
      0.033057469527239,
      0.031599562225722,
      0.030720189567665,
      0.030060660074122,
      0.031819405390237,
      0.032444222805172,
      0.036875798174067,
      0.04203632719372,
      0.054278120249309,
      0.060202314998328,
      0.075383064042688,
      0.018339548197645,
      0.00063638810780473
    ]
  },
  {
    "key": 6615210,
    "value": [
      0.31106453148556,
      0.071084056015903,
      0.055860888878281,
      0.045204671881946,
      0.037267707641491,
      0.033200449245943,
      0.031736236223546,
      0.030853060114799,
      0.030190678033238,
      0.031957030250733,
      0.032584550117475,
      0.037035292876031,
      0.042218142145787,
      0.054512883238614,
      0.060462701234387,
      0.075709109848555,
      0.018418870162695,
      0.00063914060501466
    ]
  },
  {
    "key": 6643005,
    "value": [
      0.30812241768484,
      0.071387622012681,
      0.056099444010946,
      0.045397719409732,
      0.037426860192034,
      0.033342232481646,
      0.031871766505907,
      0.030984818774509,
      0.03031960797596,
      0.032093503438756,
      0.032723703142645,
      0.037193452894297,
      0.042398435633819,
      0.054745681684075,
      0.060720908506127,
      0.076032427237636,
      0.018497528345611,
      0.00064187006877513
    ]
  },
  {
    "key": 6670800,
    "value": [
      0.30520482149913,
      0.07168865829282,
      0.056336011184173,
      0.04558915820812,
      0.037584686471321,
      0.033482834190385,
      0.032006167369248,
      0.031115479445388,
      0.030447463502492,
      0.032228839350213,
      0.032861696559272,
      0.037350294912411,
      0.042577226676118,
      0.054976540142491,
      0.060976964050603,
      0.076353050315141,
      0.018575531043668,
      0.00064457678700426
    ]
  },
  {
    "key": 6698595,
    "value": [
      0.30232658527203,
      0.071985633429889,
      0.056569386937629,
      0.045778014393047,
      0.037740383588304,
      0.033621539105639,
      0.03213875509188,
      0.031244377432787,
      0.030573594188467,
      0.032362349506653,
      0.032997828369693,
      0.037505021046437,
      0.042753605730062,
      0.055204284194803,
      0.061229565266587,
      0.076669348013034,
      0.018652481442925,
      0.00064724699013306
    ]
  },
  {
    "key": 6726390,
    "value": [
      0.29948019907052,
      0.072279322295448,
      0.056800180198499,
      0.045964780730634,
      0.037894357790011,
      0.033758709138155,
      0.032269875623487,
      0.031371849059083,
      0.030698329135781,
      0.032494382264587,
      0.033132453770874,
      0.037658035009905,
      0.042928033006271,
      0.055429508073883,
      0.061479371244599,
      0.076982145619559,
      0.018728580323408,
      0.00064988764529175
    ]
  },
  {
    "key": 6754185,
    "value": [
      0.29665725748655,
      0.072570592155769,
      0.057029072501681,
      0.046150008743819,
      0.038047063763863,
      0.033894749352466,
      0.032399916164362,
      0.031498270749316,
      0.030822036688031,
      0.032625327518124,
      0.033265970313025,
      0.03780978865464,
      0.043101023590307,
      0.055652876868189,
      0.061727119664291,
      0.077292366829301,
      0.018804052405901,
      0.00065250655036248
    ]
  },
  {
    "key": 6781980,
    "value": [
      0.29385743726943,
      0.072859476363716,
      0.05725609005728,
      0.046333719642775,
      0.038198518995985,
      0.034029675326326,
      0.032528891605249,
      0.031623656979838,
      0.030944731010779,
      0.032755200261602,
      0.033398393284921,
      0.037960299357718,
      0.043272597291055,
      0.055874416155337,
      0.061972838894951,
      0.077600047165214,
      0.018878906332596,
      0.00065510400523204
    ]
  },
  {
    "key": 6809775,
    "value": [
      0.29108047272754,
      0.073146002333232,
      0.057481254408345,
      0.046515930860924,
      0.038348737858865,
      0.034163499863666,
      0.032656814185394,
      0.031748019649294,
      0.031066423747219,
      0.032884012819419,
      0.033529735252964,
      0.038109581401996,
      0.043442770390164,
      0.056094146958507,
      0.062216552254341,
      0.077905215824915,
      0.018953149206829,
      0.00065768025638838
    ]
  },
  {
    "key": 6837570,
    "value": [
      0.28836296734213,
      0.073426393333359,
      0.057701597638296,
      0.046694240651752,
      0.038495740300815,
      0.034294459008241,
      0.032781997742914,
      0.031869719519384,
      0.031185510851736,
      0.033010067298797,
      0.033658264983937,
      0.038255667084096,
      0.043609299816919,
      0.056309172981329,
      0.062455047329323,
      0.078203850346058,
      0.019025802424943,
      0.00066020134597593
    ]
  },
  {
    "key": 6865365,
    "value": [
      0.28568540898445,
      0.073702662616886,
      0.057918701847208,
      0.046869929308433,
      0.038640581823174,
      0.034423493067916,
      0.032905341116024,
      0.031989630414882,
      0.031302847389026,
      0.033134268791309,
      0.03378490534212,
      0.038399605322873,
      0.043773381279573,
      0.056521038145466,
      0.062690036553157,
      0.078498094972865,
      0.019097387648809,
      0.00066268537582618
    ]
  },
  {
    "key": 6893160,
    "value": [
      0.28302946105571,
      0.073976702145943,
      0.058134053819773,
      0.047044199991453,
      0.038784254337427,
      0.034551485700664,
      0.033027688991429,
      0.032108573516018,
      0.031419236909459,
      0.033257467860282,
      0.033910523592811,
      0.038542381843897,
      0.043936138449601,
      0.056731193357303,
      0.062923129191653,
      0.078789964767177,
      0.019168395112199,
      0.00066514935720564
    ]
  },
  {
    "key": 6920955,
    "value": [
      0.28039482889483,
      0.074248542323528,
      0.058347677447964,
      0.04721707203507,
      0.038926773783154,
      0.034678451106477,
      0.033149054942873,
      0.032226562018794,
      0.031534692325736,
      0.033379678173893,
      0.03403513367258,
      0.038684012487344,
      0.04409758938391,
      0.056939661932265,
      0.063154351105005,
      0.079079492110149,
      0.019238832692953,
      0.00066759356347785
    ]
  },
  {
    "key": 6948750,
    "value": [
      0.27778127379124,
      0.074518207779691,
      0.05855959208713,
      0.047388561102338,
      0.039068153073315,
      0.034804400789043,
      0.033269449966705,
      0.032343606613549,
      0.031649224098682,
      0.033500910804994,
      0.034158746871711,
      0.038824510085643,
      0.044257748710744,
      0.057146462758629,
      0.06338372324305,
      0.079366703234378,
      0.01930870677306,
      0.00067001821609989
    ]
  },
  {
    "key": 6976545,
    "value": [
      0.28234338416354,
      0.072668289935692,
      0.05699613842373,
      0.046884316271293,
      0.038475627942837,
      0.034418552976763,
      0.032631225194039,
      0.031780613927502,
      0.03113222249795,
      0.032676453527741,
      0.033770060904405,
      0.038124390700144,
      0.043701271650454,
      0.056893733651519,
      0.063655332824138,
      0.0823957236485,
      0.020692934892548,
      0.00075972686720664
    ]
  },
  {
    "key": 7004340,
    "value": [
      0.28788479769429,
      0.070532717626142,
      0.055193520359814,
      0.046288387605387,
      0.037783849465978,
      0.033963292618379,
      0.031890589934325,
      0.031125753392866,
      0.030529863540595,
      0.031724539814376,
      0.03331191206368,
      0.037310740507014,
      0.043047717405188,
      0.05657800603549,
      0.063930944508852,
      0.085789675832921,
      0.022252590298659,
      0.00086110129603792
    ]
  },
  {
    "key": 7032135,
    "value": [
      0.29327834807218,
      0.068445025345639,
      0.053431123525214,
      0.04570700773317,
      0.0371082476993,
      0.033519084373753,
      0.031166891593909,
      0.030485999025538,
      0.029941484467851,
      0.030793980960533,
      0.032864846754507,
      0.036515798576735,
      0.042409774264731,
      0.056271417650489,
      0.064203637765842,
      0.089116370035584,
      0.023780571331531,
      0.00096039082349588
    ]
  },
  {
    "key": 7059930,
    "value": [
      0.2985300144381,
      0.066403386681449,
      0.051707416437533,
      0.04513960464449,
      0.036448196007918,
      0.033085494533129,
      0.030459479346364,
      0.029860768509915,
      0.029366545198055,
      0.02988396218954,
      0.032428430546887,
      0.035738844587294,
      0.04178683299037,
      0.055973590322251,
      0.064473477152505,
      0.092378245136448,
      0.025278038993778,
      0.0010576722839764
    ]
  },
  {
    "key": 7087725,
    "value": [
      0.3033539962191,
      0.06449342004785,
      0.050094146561919,
      0.044613270105708,
      0.035833225297213,
      0.032683051644711,
      0.029798939956102,
      0.029277458506871,
      0.028830474407529,
      0.029032681500088,
      0.032023217974254,
      0.03501375444842,
      0.041207676967867,
      0.0557027327608,
      0.064738197054632,
      0.095463032645085,
      0.026691336217823,
      0.0011493876840211
    ]
  },
  {
    "key": 7115520,
    "value": [
      0.30112985323288,
      0.064699324606376,
      0.050254079360101,
      0.044755704414179,
      0.035947628044499,
      0.03278739700762,
      0.029894077375883,
      0.029370931021805,
      0.028922519861167,
      0.029125372529075,
      0.032125456722869,
      0.035125540916663,
      0.041339238428364,
      0.05588057178049,
      0.064944883099106,
      0.095767812164883,
      0.026776552163827,
      0.0011530572702126
    ]
  },
  {
    "key": 7143315,
    "value": [
      0.29892300299685,
      0.064903628253397,
      0.050412768678009,
      0.044897031293439,
      0.036061141308447,
      0.032890931083528,
      0.029988475100579,
      0.029463676786319,
      0.029013849659811,
      0.029217342883707,
      0.032226900563444,
      0.035236458243181,
      0.04146977699623,
      0.056057028098726,
      0.065149962156008,
      0.096070222018641,
      0.026861105554376,
      0.0011566983253081
    ]
  },
  {
    "key": 7171110,
    "value": [
      0.29673326012699,
      0.065106348151216,
      0.050570227846167,
      0.045037262615495,
      0.036173774624611,
      0.0329936625697,
      0.030082141059968,
      0.029555703591418,
      0.029104471475519,
      0.029308600289854,
      0.032327558017658,
      0.035346515745462,
      0.041599303637213,
      0.056232116538525,
      0.06535345145278,
      0.096370287609968,
      0.026945003492285,
      0.0011603111551702
    ]
  },
  {
    "key": 7198905,
    "value": [
      0.29456042646848,
      0.065307502644419,
      0.050726471113412,
      0.045176411070199,
      0.036285538185439,
      0.033095600762543,
      0.030175083730095,
      0.029647019764818,
      0.029194393508866,
      0.029399153005606,
      0.032427438194234,
      0.035455723382861,
      0.041727830072474,
      0.056405852944041,
      0.06555536940363,
      0.096668036092481,
      0.02702825356967,
      0.0011638960867322
    ]
  },
  {
    "key": 7226700,
    "value": [
      0.29241715525275,
      0.065505920334383,
      0.050880588616161,
      0.04531366634352,
      0.036395781149483,
      0.03319615203744,
      0.030266761870672,
      0.029737093537935,
      0.029283092109875,
      0.029488473708283,
      0.032525959453161,
      0.035563445198039,
      0.041854607844015,
      0.056577225582534,
      0.065754540164034,
      0.096961733564259,
      0.027110370989873,
      0.0011674322435831
    ]
  },
  {
    "key": 7254495,
    "value": [
      0.2903082573539,
      0.065701155844657,
      0.051032234415973,
      0.045448720346667,
      0.036504256060884,
      0.033295090692894,
      0.030356969697201,
      0.0298257227275,
      0.029370368182042,
      0.029576361904987,
      0.032622900649599,
      0.035669439394211,
      0.041979352381272,
      0.056745849783982,
      0.065950516667169,
      0.097250720779962,
      0.027191171428779,
      0.0011709116883206
    ]
  },
  {
    "key": 7282290,
    "value": [
      0.28821545791229,
      0.065894901007524,
      0.05118272261426,
      0.045582743403225,
      0.036611902919527,
      0.033393274091437,
      0.030446488914368,
      0.029913675358366,
      0.029456978024651,
      0.029663579199427,
      0.032719101836904,
      0.035774624474382,
      0.042103144670154,
      0.056913186777786,
      0.066144997166464,
      0.097537501986385,
      0.027271355070441,
      0.0011743645724113
    ]
  },
  {
    "key": 7310085,
    "value": [
      0.28613857329533,
      0.066087172823146,
      0.051332066415602,
      0.045715747273041,
      0.03671873117088,
      0.033490710848165,
      0.030535327377031,
      0.030000959147933,
      0.029542929237278,
      0.029750133244479,
      0.032814571456245,
      0.035879009668011,
      0.042225995572809,
      0.057079251246922,
      0.0663379987266,
      0.097822102347131,
      0.027350928950569,
      0.0011777911988283
    ]
  },
  {
    "key": 7337880,
    "value": [
      0.28407742265275,
      0.066277988034103,
      0.051480278824509,
      0.045847743537782,
      0.036824750117298,
      0.033587409447645,
      0.030623492821038,
      0.03008758169667,
      0.029628229304354,
      0.029836031577068,
      0.032909317820894,
      0.035982604064719,
      0.042347915786807,
      0.057244057651897,
      0.066529538153705,
      0.098104546644539,
      0.027429899998272,
      0.0011811918659543
    ]
  },
  {
    "key": 7365675,
    "value": [
      0.28202637294031,
      0.066467868130918,
      0.051627764899708,
      0.045979092938087,
      0.036930249504422,
      0.033683634163374,
      0.030711226199104,
      0.03017377974062,
      0.029713111347633,
      0.029921508953984,
      0.033003599868966,
      0.036085690783947,
      0.042469238515332,
      0.057408056402182,
      0.066720138917553,
      0.098385606787843,
      0.02750848403834,
      0.0011845758676797
    ]
  },
  {
    "key": 7393470,
    "value": [
      0.279990668542,
      0.06665632760408,
      0.0517741475301,
      0.046109459623152,
      0.037034959578042,
      0.033779138955797,
      0.030798303183403,
      0.030259332877694,
      0.029797358329943,
      0.03000634681583,
      0.033097176528164,
      0.036188006240499,
      0.042589653545049,
      0.057570828164976,
      0.066909313665944,
      0.098664564126831,
      0.027586480137134,
      0.0011879345513598
    ]
  },
  {
    "key": 7421265,
    "value": [
      0.27797019770034,
      0.066843376799767,
      0.051919434751898,
      0.046238850749938,
      0.037138886086603,
      0.033873929068001,
      0.030884728554348,
      0.030344245804647,
      0.029880974876332,
      0.030090549820093,
      0.033190052935726,
      0.036289556051359,
      0.042709167486584,
      0.057732381876234,
      0.06709707278432,
      0.098941433975893,
      0.027663892576537,
      0.001191268101382
    ]
  },
  {
    "key": 7449060,
    "value": [
      0.27596480499929,
      0.067029030105934,
      0.052063637740698,
      0.046367276271299,
      0.037242037024204,
      0.033968011791307,
      0.030970508959837,
      0.03042852505304,
      0.029963967418643,
      0.030174124443727,
      0.033282236235768,
      0.036390348027809,
      0.042827789533032,
      0.057892729962782,
      0.067283430715247,
      0.099216237632051,
      0.027740727311169,
      0.0011945767741652
    ]
  },
  {
    "key": 7476855,
    "value": [
      0.27394102573507,
      0.067216385585564,
      0.052209162863243,
      0.04649687926975,
      0.037346133707359,
      0.034062957117701,
      0.031057075848116,
      0.030513577020774,
      0.030047720883052,
      0.030258465326307,
      0.033375264723921,
      0.036492064121536,
      0.042947499172823,
      0.05805454821037,
      0.067471497280031,
      0.099493560841999,
      0.027818266509669,
      0.001197915782713
    ]
  },
  {
    "key": 7504650,
    "value": [
      0.27192013489989,
      0.067403473663005,
      0.05235448028577,
      0.046626297292957,
      0.037450081819033,
      0.034157766933843,
      0.031143519184227,
      0.030598507598503,
      0.030131354810739,
      0.030342685833775,
      0.033468160437621,
      0.036593635041467,
      0.043067037957617,
      0.058216135503658,
      0.067659295427733,
      0.099770488243755,
      0.027895695040729,
      0.0012012500256773
    ]
  },
  {
    "key": 7532445,
    "value": [
      0.26991414344662,
      0.067589182399876,
      0.052498726329409,
      0.046754761158462,
      0.037553263554519,
      0.034251877747528,
      0.031229325201263,
      0.030682812010241,
      0.030214372132222,
      0.030426285410373,
      0.033560371260929,
      0.036694457111484,
      0.043185695421175,
      0.058376531465504,
      0.067845708999744,
      0.10004537394833,
      0.027972552715988,
      0.0012045596863344
    ]
  },
  {
    "key": 7560240,
    "value": [
      0.26792291681717,
      0.067773524253166,
      0.052641910669909,
      0.046882279483378,
      0.037655685835051,
      0.03434529587153,
      0.031314499654928,
      0.030766495910967,
      0.030296778416143,
      0.030509269663801,
      0.033651903379171,
      0.03679453709454,
      0.043303479522815,
      0.058535746854962,
      0.068030750500331,
      0.10031823639454,
      0.028048844690914,
      0.0012078449866901
    ]
  },
  {
    "key": 7588035,
    "value": [
      0.27016881694802,
      0.066533118320877,
      0.051670839255423,
      0.046312898312637,
      0.037602851290499,
      0.03420356989217,
      0.031139675147232,
      0.030759202390803,
      0.029916724353849,
      0.030210393813354,
      0.033178192780156,
      0.03649997338172,
      0.042983818407914,
      0.05820501110064,
      0.067890563843793,
      0.10226970345102,
      0.029166270291912,
      0.0012883770179747
    ]
  },
  {
    "key": 7615830,
    "value": [
      0.27455161859424,
      0.064575809975177,
      0.050139101655315,
      0.045392726253285,
      0.037471616952324,
      0.033943311840013,
      0.030833850816457,
      0.030705888265085,
      0.029303894019183,
      0.029719260847294,
      0.032420002116516,
      0.036006668004809,
      0.042443837760715,
      0.057627419049434,
      0.067586246843873,
      0.1050641541527,
      0.0308068597358,
      0.0014077331177825
    ]
  },
  {
    "key": 7643625,
    "value": [
      0.27883368161479,
      0.062655846481627,
      0.048636577470674,
      0.044490553818416,
      0.037343863128572,
      0.033688766630607,
      0.030534472792763,
      0.030654444345108,
      0.028703024021652,
      0.029237896822001,
      0.031676495726679,
      0.035523356178555,
      0.04191488552306,
      0.057061983875799,
      0.067289431851085,
      0.10781110027261,
      0.032418649987874,
      0.0015249694581256
    ]
  },
  {
    "key": 7671420,
    "value": [
      0.28301845504093,
      0.060772024919986,
      0.047162325843339,
      0.043605795936196,
      0.03721946606101,
      0.033439741335748,
      0.030241326143277,
      0.030604800216736,
      0.028113725912366,
      0.028765982318996,
      0.030947198010919,
      0.035049709065176,
      0.041396597802164,
      0.056508300437896,
      0.066999856994065,
      0.11051200266569,
      0.034002538855189,
      0.0016401524403129
    ]
  },
  {
    "key": 7699215,
    "value": [
      0.28436853190168,
      0.059840936420686,
      0.046433179439636,
      0.043187090907382,
      0.037198964857199,
      0.033348015107754,
      0.030122088616073,
      0.030616058610112,
      0.027833697011037,
      0.028549449451378,
      0.030595896569539,
      0.034840006110157,
      0.041170886850081,
      0.05628233625955,
      0.066927893682098,
      0.11209086456563,
      0.034890411211589,
      0.0017036924284191
    ]
  },
  {
    "key": 7727010,
    "value": [
      0.28247563362385,
      0.059999220133104,
      0.046555998641017,
      0.043301323963986,
      0.037297358876542,
      0.033436223079567,
      0.0302017637732,
      0.030697040354487,
      0.027907319202746,
      0.028624964861346,
      0.030676824983823,
      0.034932160508761,
      0.041279786897506,
      0.056431207210768,
      0.067104922921779,
      0.11238735321091,
      0.034982698935423,
      0.001708198821175
    ]
  },
  {
    "key": 7754805,
    "value": [
      0.28059631850645,
      0.060156368024817,
      0.046677936509823,
      0.04341473730093,
      0.037395046834834,
      0.033523798083911,
      0.030280867193085,
      0.030777440985743,
      0.027980413092406,
      0.028699938383808,
      0.030757172667675,
      0.035023653620918,
      0.041387905494163,
      0.056579009885875,
      0.0672806818256,
      0.11268171429716,
      0.035074324416087,
      0.0017126728767173
    ]
  },
  {
    "key": 7782600,
    "value": [
      0.27873042706845,
      0.06031239343159,
      0.046799003393852,
      0.04352734054261,
      0.037492037021996,
      0.03361074755251,
      0.030359405588543,
      0.030857267326775,
      0.028052984882854,
      0.028774376381109,
      0.030836946439501,
      0.035114493210845,
      0.04149525181513,
      0.056725756827589,
      0.06745518530868,
      0.11297397280422,
      0.035165295429032,
      0.0017171149747201
    ]
  },
  {
    "key": 7810395,
    "value": [
      0.27686416864629,
      0.060468449525492,
      0.046920094089356,
      0.043639965931134,
      0.03758904628522,
      0.03369771412236,
      0.030437959430959,
      0.030937109368079,
      0.028125570946746,
      0.028848829018901,
      0.030916735901258,
      0.035205350667133,
      0.041602619249008,
      0.05687253263154,
      0.067629723113164,
      0.11326628879278,
      0.035256284334186,
      0.0017215579463963
    ]
  },
  {
    "key": 7838190,
    "value": [
      0.27499571917856,
      0.060624688834113,
      0.047041326949112,
      0.043752723545374,
      0.037686169440343,
      0.033784782793674,
      0.030516605498034,
      0.031017045146429,
      0.02819824222894,
      0.02892336906641,
      0.030996619038331,
      0.035296314792907,
      0.0417101127356,
      0.057019480754861,
      0.067804465830472,
      0.11355894796942,
      0.035347380063151,
      0.0017260061342596
    ]
  },
  {
    "key": 7865985,
    "value": [
      0.27314047430072,
      0.060779823978009,
      0.04716170303954,
      0.043864684286016,
      0.037782606212745,
      0.033871236138688,
      0.030594695762515,
      0.031096416007617,
      0.028270399933168,
      0.028997382329131,
      0.031075937630266,
      0.035386636062669,
      0.041816846550908,
      0.057165390375543,
      0.067977973616914,
      0.11384953888334,
      0.035437832006046,
      0.0017304228861664
    ]
  },
  {
    "key": 7893780,
    "value": [
      0.27129829452765,
      0.060933866620892,
      0.047281231411022,
      0.043975856570738,
      0.037878363852947,
      0.03395708065733,
      0.030672236095557,
      0.031175227919078,
      0.028342049484549,
      0.029070874371692,
      0.031154697640567,
      0.03547632126715,
      0.04192282871963,
      0.057310272463686,
      0.068150259517537,
      0.11413808338236,
      0.035527646963428,
      0.0017348085341864
    ]
  },
  {
    "key": 7921575,
    "value": [
      0.26944030828871,
      0.061089230996763,
      0.047401785372488,
      0.044087982747664,
      0.037974943122988,
      0.034043661748196,
      0.03075044174837,
      0.031254716060844,
      0.028414313810994,
      0.029144996998456,
      0.031234133435845,
      0.035566775998115,
      0.042029720247773,
      0.057456397684455,
      0.068324023683879,
      0.11442910368144,
      0.035618232560613,
      0.0017392318124078
    ]
  },
  {
    "key": 7949370,
    "value": [
      0.26758088210967,
      0.06124471578009,
      0.047522432763324,
      0.044200195822423,
      0.038071597242003,
      0.034130309939444,
      0.030828708010598,
      0.031334265805952,
      0.028486634142323,
      0.029219177070285,
      0.031313630793897,
      0.035657300831535,
      0.042136694616895,
      0.057602636152332,
      0.068497922517523,
      0.11472034952136,
      0.035708888361673,
      0.0017436585186717
    ]
  },
  {
    "key": 7977165,
    "value": [
      0.26573441360434,
      0.061399117045763,
      0.047642239405792,
      0.044311626924537,
      0.038167577813151,
      0.034216354310543,
      0.030906428863332,
      0.031413261197436,
      0.028558450499217,
      0.029292840207817,
      0.031392574163391,
      0.035747194829878,
      0.04224292352003,
      0.057747855536808,
      0.068670609512605,
      0.11500956577356,
      0.035798912414991,
      0.0017480543768083
    ]
  },
  {
    "key": 8004960,
    "value": [
      0.26389904571269,
      0.061552590080711,
      0.047761325793759,
      0.044422388124287,
      0.038262981367558,
      0.034301881399426,
      0.030983682473242,
      0.031491781683814,
      0.02862983510998,
      0.029366060496727,
      0.031471042940525,
      0.035836548402786,
      0.042348513795422,
      0.057892201890264,
      0.068842258346671,
      0.11529704331325,
      0.035888395261007,
      0.0017524238078909
    ]
  },
  {
    "key": 8032755,
    "value": [
      0.26201978772533,
      0.061709733195222,
      0.047883259955727,
      0.044535798013533,
      0.038360666356194,
      0.034389453741542,
      0.031062783488431,
      0.031572179870938,
      0.028702926777629,
      0.029441031740039,
      0.031551388181856,
      0.035928038733606,
      0.042456629105338,
      0.058040000072258,
      0.069018011907527,
      0.11559139545109,
      0.035980017956311,
      0.0017568977274246
    ]
  },
  {
    "key": 8060550,
    "value": [
      0.26015349013789,
      0.061865792564116,
      0.048004353192439,
      0.044648425765612,
      0.038457677655254,
      0.034476422136472,
      0.031141338979377,
      0.031652023587807,
      0.028775514364812,
      0.029515485940293,
      0.031631179318075,
      0.036018898096628,
      0.042563998792428,
      0.058186778956446,
      0.06919255337486,
      0.11588371757419,
      0.036071008770958,
      0.0017613407923409
    ]
  },
  {
    "key": 8088345,
    "value": [
      0.25830001933799,
      0.062020779360028,
      0.048124614173229,
      0.044760279443794,
      0.03855402220999,
      0.034562792810474,
      0.031219354570041,
      0.031731318550607,
      0.028847603068234,
      0.02958942842783,
      0.031710422061605,
      0.036109132996674,
      0.042670630543523,
      0.058332549051051,
      0.069365895244479,
      0.11617403061054,
      0.036161374219181,
      0.0017657533207286
    ]
  },
  {
    "key": 8116140,
    "value": [
      0.25998494319401,
      0.061237689517,
      0.047349246781824,
      0.044225988094142,
      0.037920163397665,
      0.034450909690002,
      0.031031220303768,
      0.031616787689485,
      0.02875658781498,
      0.029621899956561,
      0.031659253391284,
      0.036055639619227,
      0.042218106697245,
      0.057968677176067,
      0.069253501875493,
      0.11755593469743,
      0.037226814105791,
      0.0018666359980271
    ]
  },
  {
    "key": 8143935,
    "value": [
      0.26455893267998,
      0.059688969760837,
      0.045842919251241,
      0.04316432806524,
      0.036690356302531,
      0.034177169855525,
      0.030625832100229,
      0.031344019762601,
      0.028532411663914,
      0.029620464154591,
      0.031501595267252,
      0.035884780104375,
      0.041309204145355,
      0.05718879582245,
      0.068907787236781,
      0.11982834174204,
      0.039087855573211,
      0.0020462365118413
    ]
  },
  {
    "key": 8171730,
    "value": [
      0.26903048479887,
      0.058169624099855,
      0.044364819231703,
      0.04212286724641,
      0.035483574247345,
      0.033909284681068,
      0.030228553561299,
      0.031077009644763,
      0.028313045362736,
      0.029619884865071,
      0.031347640558195,
      0.035717983065093,
      0.04041771660151,
      0.056424467259391,
      0.068570188742663,
      0.12206346640035,
      0.04091670748499,
      0.0022226821486822
    ]
  },
  {
    "key": 8199525,
    "value": [
      0.27340302244627,
      0.056678720168287,
      0.042914054495208,
      0.041100963803843,
      0.034299089674883,
      0.033647061089945,
      0.029839122678237,
      0.030815568190523,
      0.028098330143078,
      0.029620125837495,
      0.031197263918974,
      0.035555110571485,
      0.039543089369317,
      0.055675189506595,
      0.068240431737424,
      0.12426242846996,
      0.042714357600109,
      0.0023960702983752
    ]
  },
  {
    "key": 8227320,
    "value": [
      0.27167913242156,
      0.056790014274269,
      0.042991830528199,
      0.041182863621462,
      0.034360749063608,
      0.03372568621337,
      0.029905686947548,
      0.030887147716097,
      0.028164075191593,
      0.029693999330802,
      0.031272034291998,
      0.03564049692848,
      0.039624072989061,
      0.055798931341322,
      0.068403966702097,
      0.12461665111251,
      0.042857120226634,
      0.0024055410993845
    ]
  },
  {
    "key": 8255115,
    "value": [
      0.26984232665882,
      0.056933237172484,
      0.043100254775781,
      0.041286725702852,
      0.034447405954412,
      0.033810741492639,
      0.029981108290763,
      0.030965044277139,
      0.028235104236506,
      0.029768886803505,
      0.031350901526698,
      0.035730381309197,
      0.039724003842136,
      0.055939654754869,
      0.068576479677938,
      0.12493093097608,
      0.042965204738435,
      0.002411607809746
    ]
  },
  {
    "key": 8282910,
    "value": [
      0.26801783574434,
      0.057075499834023,
      0.043207952093937,
      0.04138989144182,
      0.034533481854845,
      0.033895226519528,
      0.030056023972237,
      0.031042418581365,
      0.028305657067805,
      0.029843272193798,
      0.031429239996709,
      0.035819663060864,
      0.039823264709676,
      0.056079434689512,
      0.068747836042032,
      0.12524310375304,
      0.043072564598567,
      0.0024176338459007
    ]
  },
  {
    "key": 8310705,
    "value": [
      0.26616609828429,
      0.057219887014825,
      0.043317257740128,
      0.041494597835217,
      0.034620843193823,
      0.033980973227205,
      0.030132058428003,
      0.031120948376413,
      0.028377263519551,
      0.029918768439131,
      0.031508748356181,
      0.035910278126552,
      0.039924007917155,
      0.056221302066921,
      0.068921751404336,
      0.12555993844951,
      0.04318152774721,
      0.0024237498735524
    ]
  },
  {
    "key": 8338500,
    "value": [
      0.26430080443056,
      0.057365331240433,
      0.04342736360255,
      0.041600070774153,
      0.034708844096846,
      0.034067347678366,
      0.030208649524782,
      0.031200053080615,
      0.028449394195314,
      0.029994817385289,
      0.031588838788784,
      0.03600155657651,
      0.040025488656066,
      0.056364208041896,
      0.069096939984452,
      0.12587909266263,
      0.043291288604691,
      0.0024299106760603
    ]
  },
  {
    "key": 8366295,
    "value": [
      0.26244791713647,
      0.057509808078272,
      0.043536737121945,
      0.041704842184853,
      0.034796259682736,
      0.034153147630353,
      0.030284731194048,
      0.03127863163864,
      0.028521045110997,
      0.03007036050992,
      0.031668396518872,
      0.036092227909509,
      0.040126294419913,
      0.056506163511674,
      0.069270963339281,
      0.1261961240972,
      0.043400319413864,
      0.0024360305014517
    ]
  },
  {
    "key": 8394090,
    "value": [
      0.26057763363316,
      0.057655641366288,
      0.043647137516871,
      0.041810597263252,
      0.034884495987634,
      0.03423975313264,
      0.030361527171539,
      0.031357947947439,
      0.028593368735873,
      0.030146612886541,
      0.03174870119289,
      0.036183750528758,
      0.040228046619176,
      0.056649451759252,
      0.069446620547771,
      0.12651613204588,
      0.043510373880964,
      0.0024422077840685
    ]
  },
  {
    "key": 8421885,
    "value": [
      0.25867405438069,
      0.057804070853527,
      0.043759503316428,
      0.041918235047966,
      0.034974303120628,
      0.034327900430636,
      0.030439690310532,
      0.031438676285974,
      0.02866697990313,
      0.030224222747202,
      0.03183043549203,
      0.036276902480763,
      0.04033161026344,
      0.056795290897933,
      0.069625404896259,
      0.1268418369407,
      0.043622387594308,
      0.0024484950378485
    ]
  },
  {
    "key": 8449680,
    "value": [
      0.25678301113232,
      0.057951522859708,
      0.043871129132019,
      0.042025163985014,
      0.035063518829552,
      0.034415467235391,
      0.030517338706874,
      0.03151887298876,
      0.028740106304704,
      0.030301321508821,
      0.031911631530676,
      0.036369440981423,
      0.040434491890254,
      0.056940169614274,
      0.069803011862024,
      0.12716539690886,
      0.043733663642348,
      0.0024547408869751
    ]
  },
  {
    "key": 8477475,
    "value": [
      0.25487619729585,
      0.058100204560058,
      0.043983685864849,
      0.042132984669103,
      0.035153478563871,
      0.034503764314301,
      0.030595634661583,
      0.031599738501828,
      0.028813842552912,
      0.030379063245059,
      0.031993504713688,
      0.036462751218308,
      0.040538231511067,
      0.057086256564516,
      0.069982100002957,
      0.12749165524522,
      0.043845867690698,
      0.0024610388241299
    ]
  },
  {
    "key": 8505270,
    "value": [
      0.25292487192673,
      0.058252356997945,
      0.044098870055374,
      0.042243322255372,
      0.035245538264938,
      0.034594122547915,
      0.030675758310676,
      0.031682491691529,
      0.028889300056419,
      0.030458619738336,
      0.032077289095785,
      0.036558239634088,
      0.040644392768136,
      0.057235753681986,
      0.070165368671364,
      0.12782552956293,
      0.043960690963885,
      0.0024674837765988
    ]
  },
  {
    "key": 8533065,
    "value": [
      0.25098627110966,
      0.058403517252957,
      0.044213303132194,
      0.042352940332541,
      0.035336997646613,
      0.034683891557373,
      0.030755359475125,
      0.031764705249406,
      0.028964265503118,
      0.030537657445379,
      0.032160527121672,
      0.0366531053719,
      0.04074986174986,
      0.05738427593187,
      0.070347442248605,
      0.12815722669316,
      0.044074765476901,
      0.0024738867016671
    ]
  },
  {
    "key": 8560860,
    "value": [
      0.25168750306609,
      0.057802950453146,
      0.043831888840999,
      0.042064647055918,
      0.035034959440026,
      0.03425855718763,
      0.030724759376396,
      0.0315886336776,
      0.029009535437547,
      0.030425274928128,
      0.032069964596945,
      0.036497370656303,
      0.040677269994403,
      0.057317253068875,
      0.070121101686527,
      0.12933538038113,
      0.04499589976125,
      0.0025570503910826
    ]
  },
  {
    "key": 8588655,
    "value": [
      0.25634532547934,
      0.056074177043265,
      0.042706352830342,
      0.041179272584886,
      0.034142399011729,
      0.033060093422454,
      0.030528984228861,
      0.031025037209659,
      0.029010498596954,
      0.030025758491162,
      0.031718741375344,
      0.035965698029057,
      0.040337704575361,
      0.05692713352618,
      0.06928218307459,
      0.13178580998892,
      0.047129329842352,
      0.0027555006895433
    ]
  },
  {
    "key": 8616450,
    "value": [
      0.26088539879717,
      0.054381350776492,
      0.041604439177492,
      0.040312747541651,
      0.033268581059242,
      0.031886344843882,
      0.030338132579686,
      0.030473609274757,
      0.029012465876915,
      0.029635151241134,
      0.031375538100871,
      0.035445740875048,
      0.040006233977367,
      0.056546687193878,
      0.068462192091876,
      0.13419273702282,
      0.049222500878657,
      0.0029501486910562
    ]
  },
  {
    "key": 8644245,
    "value": [
      0.26216903572365,
      0.053601404530349,
      0.041105201334404,
      0.039930502736414,
      0.032873061553212,
      0.03133762874009,
      0.030283174880477,
      0.030236926904178,
      0.029052978710928,
      0.029478460092877,
      0.031245132787493,
      0.035231708344452,
      0.039893504355375,
      0.056431780679838,
      0.068141768278703,
      0.13558981691292,
      0.050345546998911,
      0.0030523664357231
    ]
  },
  {
    "key": 8672040,
    "value": [
      0.2601448490996,
      0.053748456160511,
      0.041217970522401,
      0.040040049222593,
      0.032963246452883,
      0.031423601289355,
      0.030366254610787,
      0.030319879756464,
      0.029132683485792,
      0.029559332145565,
      0.031330851580709,
      0.035328364023363,
      0.040002949339134,
      0.056586597245087,
      0.068328710359704,
      0.13596179790456,
      0.050483666416162,
      0.0030607403853268
    ]
  },
  {
    "key": 8699835,
    "value": [
      0.25813360836687,
      0.053894567307024,
      0.041330018483592,
      0.040148895093084,
      0.033052854565861,
      0.031509023992441,
      0.030448802996237,
      0.030402302075351,
      0.029211878500666,
      0.029639686972819,
      0.031416022150669,
      0.035424401531053,
      0.040111694356375,
      0.056740423665255,
      0.068514456833623,
      0.13633139985395,
      0.05062090247664,
      0.0030690607784845
    ]
  },
  {
    "key": 8727630,
    "value": [
      0.25606023500199,
      0.054045192219578,
      0.041445527907472,
      0.040261103505076,
      0.033145230914302,
      0.031597085632429,
      0.030533901523192,
      0.030487270641208,
      0.029293520062415,
      0.029722524176668,
      0.031503823868461,
      0.035523405895491,
      0.040223798799489,
      0.056899002197005,
      0.06870594151538,
      0.136712419801,
      0.050762378127897,
      0.0030776382109509
    ]
  },
  {
    "key": 8755425,
    "value": [
      0.25397181229185,
      0.054196910425964,
      0.041561875743397,
      0.040374126369092,
      0.033238277765984,
      0.031685786457837,
      0.030619617728146,
      0.030572855941756,
      0.029375754210173,
      0.029805962644961,
      0.031592262885057,
      0.035623128871872,
      0.04033671693998,
      0.05705873175303,
      0.068898816066968,
      0.13709620533809,
      0.050904880664111,
      0.0030862779017374
    ]
  },
  {
    "key": 8783220,
    "value": [
      0.25188509141862,
      0.054348504998547,
      0.041678128768515,
      0.040487057131791,
      0.033331248794622,
      0.031774415001739,
      0.030705264083735,
      0.030658371499612,
      0.029457921346063,
      0.029889333119995,
      0.031680629833493,
      0.035722770584895,
      0.040449543064492,
      0.057218331146874,
      0.069091533446816,
      0.13747967813179,
      0.051047267076288,
      0.0030949105521174
    ]
  },
  {
    "key": 8811015,
    "value": [
      0.24971797525601,
      0.054505940069343,
      0.041798860684756,
      0.040604338788499,
      0.033427801726738,
      0.031866458145804,
      0.03079421014444,
      0.030747181723327,
      0.029543254142848,
      0.029975915617083,
      0.031772401303579,
      0.035826251203473,
      0.040566716051609,
      0.057384079441425,
      0.069291675667101,
      0.13787792501751,
      0.051195139223026,
      0.0031038757934225
    ]
  },
  {
    "key": 8838810,
    "value": [
      0.24756447714872,
      0.054662385825611,
      0.041918833927354,
      0.040720883452832,
      0.033523747924801,
      0.031957922895111,
      0.030882597272312,
      0.030835433867804,
      0.02962805071238,
      0.030061954033861,
      0.031863596086094,
      0.035929081554746,
      0.040683152729225,
      0.057548786181545,
      0.069490560203154,
      0.13827366933868,
      0.051342082148197,
      0.0031127846975758
    ]
  },
  {
    "key": 8866605,
    "value": [
      0.24535506237907,
      0.054822893774691,
      0.042041922335587,
      0.04084045425804,
      0.033622185414193,
      0.032051762572675,
      0.030973279416452,
      0.030925977523635,
      0.029715049067524,
      0.030150226481439,
      0.031957158787041,
      0.036034581947851,
      0.040802612743786,
      0.05771776961508,
      0.069694608876298,
      0.13867968936034,
      0.051492840520387,
      0.0031219249259099
    ]
  },
  {
    "key": 8894400,
    "value": [
      0.24312085366771,
      0.054985202935923,
      0.042166392035762,
      0.040961366880824,
      0.033721727564153,
      0.032146655314393,
      0.031064979191063,
      0.031017537255829,
      0.029803023713845,
      0.030239489517996,
      0.032051771443925,
      0.036141266261075,
      0.040923413332638,
      0.057888649372228,
      0.069900947373415,
      0.13909026571832,
      0.051645290695466,
      0.0031311677254279
    ]
  },
  {
    "key": 8922195,
    "value": [
      0.24356780947299,
      0.05455907507401,
      0.04165118255637,
      0.040622880293541,
      0.033399180576808,
      0.031915118957364,
      0.030824132768748,
      0.030881944614491,
      0.029694771523779,
      0.030192464740725,
      0.03196004125976,
      0.036085998524275,
      0.04089633832507,
      0.057873775813667,
      0.069897845913666,
      0.14014171457723,
      0.052583846000548,
      0.0032518790069628
    ]
  },
  {
    "key": 8949990,
    "value": [
      0.24938915497935,
      0.052951100658968,
      0.039850840737685,
      0.039361672049857,
      0.032228768818132,
      0.031028000366212,
      0.029915383626506,
      0.03029033261653,
      0.029192647441136,
      0.029872078964605,
      0.031494357083862,
      0.035705897764016,
      0.040572829753314,
      0.057486674131494,
      0.069475147947882,
      0.14248448795587,
      0.055103872405617,
      0.0035967526989661
    ]
  },
  {
    "key": 8977785,
    "value": [
      0.25505030879129,
      0.05138007399286,
      0.03809094780978,
      0.038129340994248,
      0.031084952030302,
      0.03016135165103,
      0.029027515086404,
      0.02971282567974,
      0.028702659626414,
      0.029559968639723,
      0.03104013745413,
      0.035335627456315,
      0.040258169542492,
      0.057110582836076,
      0.069064789774415,
      0.14478381550784,
      0.057572524057976,
      0.0039344090689595
    ]
  },
  {
    "key": 9005580,
    "value": [
      0.26055778301534,
      0.049844559619361,
      0.036369942325568,
      0.036924766339883,
      0.029966700231392,
      0.029314376600209,
      0.028159715921713,
      0.029148870229532,
      0.028224330040057,
      0.029255802283121,
      0.030596928620539,
      0.0349747937086,
      0.039951998481058,
      0.056745051069336,
      0.068666263303278,
      0.1470412717751,
      0.059991725637718,
      0.0042651207981956
    ]
  },
  {
    "key": 9033375,
    "value": [
      0.26591774162797,
      0.048343198012398,
      0.034686345305247,
      0.035746886571122,
      0.028873037880271,
      0.028486321130645,
      0.027311217816488,
      0.02859794204419,
      0.027757206011985,
      0.028959266072709,
      0.030164300940675,
      0.034623023615756,
      0.039653976554552,
      0.056389652115193,
      0.068279087684744,
      0.14925834945208,
      0.062363300921839,
      0.0045891462421415
    ]
  },
  {
    "key": 9061170,
    "value": [
      0.27113601272536,
      0.046874701270219,
      0.033038755299125,
      0.034594696053207,
      0.027803040704679,
      0.027676470922999,
      0.026481292932005,
      0.02805954473519,
      0.027300858972597,
      0.028670063104752,
      0.029741847729229,
      0.034279964380804,
      0.03936378225803,
      0.056043982630236,
      0.067902808509311,
      0.15143646697142,
      0.064688980357497,
      0.0049067304433388
    ]
  },
  {
    "key": 9088965,
    "value": [
      0.26930886550165,
      0.046914493267548,
      0.033024781933417,
      0.034619371150024,
      0.027812992967559,
      0.027703889494844,
      0.026503751294976,
      0.028106733086407,
      0.027351401352225,
      0.028736176198226,
      0.029802033200906,
      0.034359201330472,
      0.039461886823615,
      0.056188288448342,
      0.068080566974302,
      0.15203988551608,
      0.065042454887924,
      0.0049432265714822
    ]
  },
  {
    "key": 9116760,
    "value": [
      0.26697760815085,
      0.047064173142019,
      0.033130146925554,
      0.034729823651311,
      0.027901729837684,
      0.027792278272237,
      0.026588311052325,
      0.028196407129271,
      0.027438665522333,
      0.028827858468385,
      0.029897116069286,
      0.034468823764477,
      0.039587789286901,
      0.056367556204977,
      0.068297776838651,
      0.15252496612537,
      0.065249971708524,
      0.0049589978498478
    ]
  },
  {
    "key": 9144555,
    "value": [
      0.26466052257725,
      0.047212943108439,
      0.033234871401021,
      0.034839604708822,
      0.027989927273948,
      0.027880129731835,
      0.026672356768596,
      0.028285536041174,
      0.027525399211163,
      0.028918983399516,
      0.029991620926309,
      0.034577779800707,
      0.039712926385668,
      0.056545734188015,
      0.068513666278293,
      0.15300709791637,
      0.065456227028695,
      0.0049746732541808
    ]
  },
  {
    "key": 9172350,
    "value": [
      0.26235749051435,
      0.047360810760186,
      0.033338960705068,
      0.034948719925897,
      0.028077589778042,
      0.02796744835767,
      0.026755892733572,
      0.028374124371353,
      0.027611606845697,
      0.029009555642733,
      0.030085552595603,
      0.034686075000394,
      0.039837304507047,
      0.05672283149185,
      0.068728246312455,
      0.15348630549759,
      0.065661231375928,
      0.0049902535845705
    ]
  },
  {
    "key": 9200145,
    "value": [
      0.26006836351714,
      0.047507785628264,
      0.033442421546909,
      0.035057176335705,
      0.02816472300037,
      0.028054239777979,
      0.026838924331674,
      0.028462177829885,
      0.02769729398256,
      0.029099581035989,
      0.030178917131658,
      0.034793716343849,
      0.039960931667996,
      0.056898859531526,
      0.068941530772179,
      0.153962619757,
      0.065864997964052,
      0.0050057398452679
    ]
  },
  {
    "key": 9227940,
    "value": [
      0.25765453960938,
      0.047662766741519,
      0.033551518269745,
      0.03517154075291,
      0.028256602680034,
      0.02814575903645,
      0.026926478957015,
      0.028555027874302,
      0.027787648803329,
      0.029194510433445,
      0.030277367566929,
      0.03490722129513,
      0.040091293241256,
      0.057084476446237,
      0.069166433596994,
      0.15446488055266,
      0.06607986444486,
      0.0050220696978094
    ]
  },
  {
    "key": 9255735,
    "value": [
      0.25524834310708,
      0.04781725813231,
      0.033660270259506,
      0.035285543791731,
      0.028348192030496,
      0.028236989104607,
      0.027013756919827,
      0.028647584523275,
      0.027877718113273,
      0.029289139864943,
      0.030375506910167,
      0.035020367583842,
      0.040221242886962,
      0.057269506832883,
      0.069390625754794,
      0.15496555426206,
      0.066294051972344,
      0.0050383479498981
    ]
  },
  {
    "key": 9283530,
    "value": [
      0.25285656540833,
      0.047970823754565,
      0.033768370567838,
      0.03539886368293,
      0.028439232544298,
      0.028327672489055,
      0.02710051188138,
      0.028739586539184,
      0.027967247695193,
      0.02938320224251,
      0.030473058166808,
      0.035132835858889,
      0.040350413827185,
      0.057453428450234,
      0.06961347447174,
      0.15546322775272,
      0.066506956010353,
      0.0050545286567869
    ]
  },
  {
    "key": 9311325,
    "value": [
      0.25047905656934,
      0.048123473235521,
      0.033875825971695,
      0.035511507530684,
      0.028529729928894,
      0.028417814874858,
      0.027186749280461,
      0.02883103968976,
      0.028056243161818,
      0.029476703463045,
      0.030570027452475,
      0.03524463317106,
      0.040478814159825,
      0.057636252828589,
      0.069834993718524,
      0.15595793222445,
      0.066718589906134,
      0.0050706128328662
    ]
  },
  {
    "key": 9339120,
    "value": [
      0.24808261096763,
      0.048277338563481,
      0.033984137253542,
      0.035625048582176,
      0.028620948121534,
      0.028508675241154,
      0.027273673556972,
      0.02892322126102,
      0.028145947473772,
      0.029570949417059,
      0.030667769094619,
      0.035357320944346,
      0.040608237195973,
      0.057820533395798,
      0.070058277357238,
      0.1564565769975,
      0.066931909457421,
      0.005086825118764
    ]
  },
  {
    "key": 9366915,
    "value": [
      0.24563424119148,
      0.048434537716389,
      0.034094795333451,
      0.035741049745993,
      0.028714142753508,
      0.028601504293703,
      0.027362481235842,
      0.029017400145293,
      0.028237595423562,
      0.029667237413401,
      0.030767628520733,
      0.035472450341842,
      0.040740464461978,
      0.058008806799861,
      0.070286398918666,
      0.15696602598749,
      0.067149851037927,
      0.0051033886788824
    ]
  },
  {
    "key": 9394710,
    "value": [
      0.24320034849275,
      0.048590807359247,
      0.034204799098146,
      0.035856365001233,
      0.028806786330688,
      0.028693784453108,
      0.027450763799732,
      0.029111022154941,
      0.028328701464005,
      0.029762956064054,
      0.030866897483486,
      0.035586898985467,
      0.040871909875345,
      0.058195966953517,
      0.070513171609698,
      0.1574724626333,
      0.067366503941711,
      0.0051198542995701
    ]
  },
  {
    "key": 9422505,
    "value": [
      0.24078081504299,
      0.048746155057311,
      0.03431415387308,
      0.035970999930484,
      0.028898883338091,
      0.028785520186795,
      0.027538525522538,
      0.029204091822349,
      0.028419270005684,
      0.029858110002904,
      0.030965580788642,
      0.035700672415855,
      0.041002579799548,
      0.058382022917477,
      0.070738606408748,
      0.15797591145228,
      0.067581878657273,
      0.0051362227779527
    ]
  },
  {
    "key": 9450300,
    "value": [
      0.23837506283483,
      0.048900617921911,
      0.03442288578224,
      0.036084981919121,
      0.028990455776961,
      0.028876733409701,
      0.027625787369838,
      0.029296631381124,
      0.028509322684706,
      0.029952721961471,
      0.03106370201086,
      0.035813797812577,
      0.041132505450595,
      0.058567019139033,
      0.070962757170402,
      0.15847649271437,
      0.067796026635924,
      0.0051524980243302
    ]
  },
  {
    "key": 9478095,
    "value": [
      0.23594331061778,
      0.04905675013377,
      0.034532792804363,
      0.036200195760609,
      0.029083017878947,
      0.02896893241352,
      0.027713992293819,
      0.029390171055098,
      0.028600348602139,
      0.030048356432564,
      0.031162883671739,
      0.03592814580459,
      0.041263835264577,
      0.058754014695097,
      0.071189330426681,
      0.15898248397556,
      0.06801248900478,
      0.0051689491643633
    ]
  },
  {
    "key": 9505890,
    "value": [
      0.23352578937096,
      0.049211968637053,
      0.034642056634491,
      0.036314735353818,
      0.029175038293952,
      0.029060591855261,
      0.027801681029662,
      0.029483163321197,
      0.028690841822568,
      0.030143431236721,
      0.031261484907008,
      0.036041824615401,
      0.041394396517249,
      0.058939915925772,
      0.071414577743072,
      0.15948551410176,
      0.068227684604143,
      0.0051853040299149
    ]
  },
  {
    "key": 9533685,
    "value": [
      0.23112235429064,
      0.049366282728973,
      0.034750683817265,
      0.036428607559395,
      0.029266522533778,
      0.029151717225106,
      0.027888858829713,
      0.029575613749433,
      0.028780807766319,
      0.030237952068695,
      0.031359511622645,
      0.0361548410541,
      0.041524197028915,
      0.059124733966096,
      0.071638512611347,
      0.15998561322327,
      0.068441626323711,
      0.005201563600602
    ]
  },
  {
    "key": 9561480,
    "value": [
      0.22873290280782,
      0.049519698995041,
      0.034858678988459,
      0.036541817236949,
      0.029357474502606,
      0.02924231241192,
      0.027975529414372,
      0.029667526285222,
      0.02887025027278,
      0.030331922962258,
      0.031456968002038,
      0.036267199943774,
      0.041653242338942,
      0.059308476703363,
      0.071861144588153,
      0.16048280268232,
      0.068654323293662,
      0.0052177285703183
    ]
  },
  {
    "key": 9589275,
    "value": [
      0.23429629095055,
      0.047795725892316,
      0.033644884077239,
      0.03567662655902,
      0.028405589914889,
      0.028412387189088,
      0.026740049632964,
      0.029053123244068,
      0.027892903988649,
      0.029556803849409,
      0.030790445218224,
      0.035692996779652,
      0.041396314447612,
      0.058917076478991,
      0.071121129097071,
      0.16316984397574,
      0.071607749292984,
      0.0058300594115298
    ]
  },
  {
    "key": 9617070,
    "value": [
      0.24030789995234,
      0.045967972587714,
      0.032358022028019,
      0.034757186555191,
      0.027396030036081,
      0.027531500560861,
      0.025431588316053,
      0.028399506820437,
      0.026856575488703,
      0.028733528903999,
      0.03008151735696,
      0.035080563178092,
      0.041117568537293,
      0.058493207226302,
      0.070327188024475,
      0.16597459701283,
      0.074710522319531,
      0.0064750250951201
    ]
  },
  {
    "key": 9644865,
    "value": [
      0.24615538621656,
      0.044181158368283,
      0.031099983549115,
      0.033858904134542,
      0.02640918000548,
      0.026670604977489,
      0.024152072588094,
      0.027761109783521,
      0.025843497682745,
      0.027929082976148,
      0.029389061870679,
      0.034482800270776,
      0.040846718034638,
      0.058081148713393,
      0.069553469149742,
      0.16872798253325,
      0.077751195464327,
      0.0071066436812133
    ]
  },
  {
    "key": 9672660,
    "value": [
      0.25007784108383,
      0.042843389612039,
      0.030158098655073,
      0.033195077812293,
      0.025671837219073,
      0.026030086771131,
      0.023188516460488,
      0.027292102238608,
      0.025085610679341,
      0.027332812414978,
      0.028879799117047,
      0.034049991516068,
      0.040669466193869,
      0.057808450445739,
      0.069011890982829,
      0.17096645668445,
      0.080142053202452,
      0.0075965189106866
    ]
  },
  {
    "key": 9700455,
    "value": [
      0.24763862040842,
      0.042982743384299,
      0.030256191846341,
      0.033303049178599,
      0.025755338253648,
      0.026114753059598,
      0.023263940166949,
      0.02738087339874,
      0.02516720493482,
      0.027421715990325,
      0.028973734470564,
      0.034160743601889,
      0.040801748993648,
      0.057996480051031,
      0.069236361255287,
      0.17152254762134,
      0.08040272579469,
      0.0076212275898045
    ]
  },
  {
    "key": 9728250,
    "value": [
      0.24521332856965,
      0.043121301395869,
      0.030353724889833,
      0.03341040398916,
      0.025838362466966,
      0.026198935872785,
      0.023338933176631,
      0.027469137643283,
      0.025248333257444,
      0.027510111893944,
      0.02906713341907,
      0.034270863253045,
      0.04093327641056,
      0.058183435938934,
      0.069459549720902,
      0.17207546307686,
      0.08066190985168,
      0.0076457951733839
    ]
  },
  {
    "key": 9756045,
    "value": [
      0.24280186559756,
      0.043259069358077,
      0.030450701805837,
      0.033517146669114,
      0.025920913281265,
      0.026282638680686,
      0.02341349858073,
      0.027556898610466,
      0.025328998991303,
      0.027598003769491,
      0.029159999812447,
      0.034380355008643,
      0.041064053866133,
      0.058369325815725,
      0.069681465579449,
      0.17262522584206,
      0.080919616056927,
      0.0076702226740946
    ]
  },
  {
    "key": 9783840,
    "value": [
      0.24031762262176,
      0.04340099527457,
      0.030550605567656,
      0.033627110933946,
      0.026005955549358,
      0.026368867710529,
      0.023490314432151,
      0.02764730827829,
      0.025412099285624,
      0.027688548296604,
      0.02925566899257,
      0.034493151318558,
      0.041198778296556,
      0.058560826007117,
      0.06991007904737,
      0.17319158091513,
      0.081185100054655,
      0.007695387417555
    ]
  },
  {
    "key": 9811635,
    "value": [
      0.23782847334907,
      0.043543201490008,
      0.030650706636068,
      0.033737292374527,
      0.026091165773089,
      0.026455267039824,
      0.023567281992312,
      0.027737896502187,
      0.02549536370025,
      0.027779271646134,
      0.029351527116127,
      0.034606170397418,
      0.041333768803229,
      0.05875270440499,
      0.070139144019253,
      0.17375905452056,
      0.081451108374411,
      0.0077206018605431
    ]
  },
  {
    "key": 9839430,
    "value": [
      0.23535338706662,
      0.0436846042805,
      0.03075024216172,
      0.033846851321094,
      0.026175894583127,
      0.026541178237316,
      0.0236438147075,
      0.027827972928209,
      0.025578157694455,
      0.027869482434367,
      0.029446843668364,
      0.034718550950409,
      0.041467996651672,
      0.058943498744118,
      0.070366914838753,
      0.17432332206042,
      0.081715613822304,
      0.007745673849051
    ]
  },
  {
    "key": 9867225,
    "value": [
      0.23285244930254,
      0.043827483977743,
      0.030850817303983,
      0.033957554575686,
      0.026261508358385,
      0.026627986749685,
      0.023721146782329,
      0.027918990173583,
      0.025661816445349,
      0.027960635445322,
      0.02954315577139,
      0.034832105282197,
      0.0416036264669,
      0.059136285868867,
      0.07059706465134,
      0.17489348319359,
      0.081982881944684,
      0.0077710077064299
    ]
  },
  {
    "key": 9895020,
    "value": [
      0.23034623245461,
      0.043970665271417,
      0.030951604744456,
      0.034068491507329,
      0.026347302850775,
      0.026714978501087,
      0.023798642092929,
      0.028010199541959,
      0.025745651786628,
      0.028051980865858,
      0.029639671174024,
      0.03494589930921,
      0.041739542575205,
      0.059329479936727,
      0.070827700273761,
      0.17546484784668,
      0.082250714227776,
      0.0077963950395728
    ]
  },
  {
    "key": 9922815,
    "value": [
      0.22785404665756,
      0.044113044964854,
      0.03105182792661,
      0.034178807358744,
      0.02643261702284,
      0.026801483229312,
      0.023875703546162,
      0.028100898274837,
      0.025829017775887,
      0.028142814889209,
      0.029735646235336,
      0.035059056260551,
      0.041874697757402,
      0.059521592407918,
      0.071057044683031,
      0.1760330137157,
      0.082517047052273,
      0.007821640241773
    ]
  },
  {
    "key": 9950610,
    "value": [
      0.22537529730752,
      0.044254657028042,
      0.031151510762423,
      0.034288528449891,
      0.02651747122946,
      0.02688752157329,
      0.02395234952791,
      0.028191108011782,
      0.025911934303192,
      0.028233159187218,
      0.029831103853756,
      0.035171603134032,
      0.042009124259801,
      0.059712669118035,
      0.071285152597812,
      0.17659811635782,
      0.082781943961806,
      0.0078467493362151
    ]
  },
  {
    "key": 9978405,
    "value": [
      0.22291016127103,
      0.044395491356515,
      0.031250646139212,
      0.034397646951827,
      0.026601859416019,
      0.026973087393915,
      0.024028574569243,
      0.028280822316047,
      0.02599439545219,
      0.028323007313535,
      0.029926037218084,
      0.035283531899078,
      0.042142812490649,
      0.059902696433154,
      0.071512007741889,
      0.17716011545118,
      0.083045386055146,
      0.0078717205312863
    ]
  },
  {
    "key": 10006200,
    "value": [
      0.22045872043475,
      0.044535543272052,
      0.031349230763907,
      0.034506159239862,
      0.02668577877932,
      0.027058177848869,
      0.024104376138123,
      0.02837003820751,
      0.026076398483693,
      0.028412356283595,
      0.030020443174832,
      0.035394838837651,
      0.042275758009103,
      0.060091668040967,
      0.071737602579611,
      0.17771899232736,
      0.083307364581301,
      0.0078965529974961
    ]
  },
  {
    "key": 10033995,
    "value": [
      0.22561637072119,
      0.043333712336763,
      0.030035114441789,
      0.033620042913044,
      0.025656680487123,
      0.026326506828266,
      0.023339538795161,
      0.027632363980921,
      0.025379719001739,
      0.027712125444856,
      0.029139334000021,
      0.034574092126838,
      0.041337912301352,
      0.058990566067544,
      0.071307965069736,
      0.1803042761991,
      0.086889051380736,
      0.0088046279038208
    ]
  },
  {
    "key": 10061790,
    "value": [
      0.23145514667974,
      0.042013223570273,
      0.028596322244938,
      0.032645951685407,
      0.024529323213888,
      0.025522613327186,
      0.022500426445569,
      0.026821556302548,
      0.024614179792123,
      0.026942040246155,
      0.028172000580726,
      0.033670857192879,
      0.040305274538914,
      0.057775116099316,
      0.070819602178454,
      0.18306489837658,
      0.090761247515303,
      0.0097902200099954
    ]
  },
  {
    "key": 10089585,
    "value": [
      0.23714327172116,
      0.040720927157655,
      0.027187481857799,
      0.031692731012097,
      0.023425529191824,
      0.02473588047652,
      0.021679041889723,
      0.026028105016373,
      0.023864996281093,
      0.0261885051804,
      0.027225203476437,
      0.032787126301504,
      0.039295015839222,
      0.05658646799742,
      0.070344131518327,
      0.18577866423745,
      0.094560315735239,
      0.010756605109758
    ]
  },
  {
    "key": 10117380,
    "value": [
      0.24268652916766,
      0.039455788944687,
      0.025807502166224,
      0.030759614588027,
      0.022344439074903,
      0.023965678811896,
      0.020874736687108,
      0.025251373001748,
      0.023131568393984,
      0.025450912077383,
      0.026298190521793,
      0.031922181827221,
      0.038306312004384,
      0.055423630184591,
      0.069881055784054,
      0.18844717304301,
      0.098288844120709,
      0.011704469600616
    ]
  },
  {
    "key": 10145175,
    "value": [
      0.2477277349929,
      0.038280571263108,
      0.024522419680785,
      0.029893126693818,
      0.021338085593625,
      0.023250209650747,
      0.020126819756643,
      0.024530037704917,
      0.022450317116891,
      0.024766196453008,
      0.02543658257662,
      0.031119628579064,
      0.037389262439669,
      0.054346984157415,
      0.069461144035228,
      0.19097624696415,
      0.10179203845127,
      0.012592593890133
    ]
  },
  {
    "key": 10172970,
    "value": [
      0.24542501700618,
      0.038397748732078,
      0.024597483217623,
      0.029984630054661,
      0.021403401830557,
      0.023321378931403,
      0.020188428288587,
      0.024605124560655,
      0.022519037913121,
      0.024842006194624,
      0.025514444381375,
      0.031214886282694,
      0.03750371159742,
      0.054513341185003,
      0.069673765759022,
      0.19156082845064,
      0.10210362558369,
      0.012631140030672
    ]
  },
  {
    "key": 10200765,
    "value": [
      0.24313484789132,
      0.038514287631734,
      0.024672137688866,
      0.030075634759587,
      0.021468362120694,
      0.023392160368568,
      0.020249701079212,
      0.024679802223718,
      0.022587384209099,
      0.024917402804133,
      0.025591881871116,
      0.031309624870773,
      0.03761753705404,
      0.054678791634784,
      0.069885228781324,
      0.19214222420695,
      0.10241351469358,
      0.012669476110499
    ]
  },
  {
    "key": 10228560,
    "value": [
      0.24085713399486,
      0.038630192727783,
      0.02474638614741,
      0.030166144530113,
      0.021532969120502,
      0.023462556856755,
      0.02031064063419,
      0.024754073747952,
      0.022655358799757,
      0.024992389364781,
      0.025668898212551,
      0.031403848217511,
      0.03773074346427,
      0.054843342272638,
      0.070095541749637,
      0.19272045800852,
      0.10272171845346,
      0.012707603697318
    ]
  },
  {
    "key": 10256355,
    "value": [
      0.23859175680748,
      0.038745470050759,
      0.024820232456397,
      0.030256164075459,
      0.021597226191478,
      0.02353257205869,
      0.0203712501242,
      0.0248279429977,
      0.022722965221808,
      0.025066969778113,
      0.025745497412833,
      0.031497561225344,
      0.037843336718234,
      0.055007001660123,
      0.070304715606532,
      0.19329555994079,
      0.10302825289915,
      0.012745524774906
    ]
  },
  {
    "key": 10284150,
    "value": [
      0.23631088867705,
      0.038861535655023,
      0.024894583736023,
      0.030346799186131,
      0.02166192266064,
      0.023603066033657,
      0.020432274069765,
      0.02490231737496,
      0.022791033945344,
      0.025142060181986,
      0.025822620408382,
      0.031591915054879,
      0.037956699899473,
      0.055171780171727,
      0.070515319821392,
      0.19387459449468,
      0.10333688346715,
      0.012783705161742
    ]
  },
  {
    "key": 10311945,
    "value": [
      0.23401788847101,
      0.038978218619259,
      0.024969330494606,
      0.030437916390442,
      0.021726963254238,
      0.023673934970344,
      0.020493622605389,
      0.024977087354032,
      0.022859464730538,
      0.025217549996261,
      0.025900153625812,
      0.031686770758144,
      0.038070666066333,
      0.055337435150207,
      0.070727044252816,
      0.1944567089684,
      0.10364715565981,
      0.012822088632365
    ]
  },
  {
    "key": 10339740,
    "value": [
      0.23173721622307,
      0.03909427425573,
      0.025043675388895,
      0.03052854371731,
      0.021791654167224,
      0.023744422891241,
      0.020554641310177,
      0.02505145534397,
      0.022927527608286,
      0.025292633951319,
      0.025977269997987,
      0.031781116484509,
      0.038184019511865,
      0.055502199510523,
      0.070937630380845,
      0.19503569379441,
      0.1039557597224,
      0.012860265740243
    ]
  },
  {
    "key": 10367535,
    "value": [
      0.22946877278074,
      0.039209707609968,
      0.025117621651042,
      0.030618685106769,
      0.021855998212044,
      0.023814532860821,
      0.020615332836923,
      0.02512542457793,
      0.022995225537627,
      0.025367315311444,
      0.026053972877549,
      0.031874956335666,
      0.038296765164124,
      0.055666080415824,
      0.071147087360735,
      0.19561157414414,
      0.10426270907152,
      0.01289823814513
    ]
  },
  {
    "key": 10395330,
    "value": [
      0.22719413296441,
      0.039325456276558,
      0.025191769901341,
      0.030709072722232,
      0.021920518016048,
      0.023884834339514,
      0.020676190145804,
      0.02519959586279,
      0.023063108387466,
      0.02544220066768,
      0.02613088527511,
      0.031969052515371,
      0.038409818787134,
      0.05583040897054,
      0.071357116483514,
      0.1961890275417,
      0.10457049686913,
      0.012936314273662
    ]
  },
  {
    "key": 10423125,
    "value": [
      0.22489305701685,
      0.039442550187752,
      0.025266779911318,
      0.030800510833182,
      0.021985787676795,
      0.023955952870253,
      0.020737754745481,
      0.025274629175037,
      0.023131780179762,
      0.025517956350325,
      0.026208691557593,
      0.032064242291934,
      0.038524186332634,
      0.055996647371029,
      0.071569586589437,
      0.1967731921705,
      0.10488186181269,
      0.012974832927434
    ]
  },
  {
    "key": 10450920,
    "value": [
      0.22260422929811,
      0.039559020828332,
      0.025341390656,
      0.030891462234901,
      0.02205070991844,
      0.024026692849367,
      0.020798991647454,
      0.025349263097956,
      0.023200086443999,
      0.025593308798589,
      0.026286083690707,
      0.032158925389799,
      0.038637945119494,
      0.056162000913298,
      0.071780925753778,
      0.19735424739213,
      0.10519156941456,
      0.013013146553081
    ]
  },
  {
    "key": 10478715,
    "value": [
      0.22032753546079,
      0.039674874017048,
      0.025415605862862,
      0.030981931471223,
      0.022115287984428,
      0.024097057810952,
      0.020859903911053,
      0.025423501360178,
      0.023268030592685,
      0.025668261777,
      0.02636306554088,
      0.032253106539232,
      0.03875110083098,
      0.056326477858233,
      0.071991144534815,
      0.1979322222355,
      0.10549963514742,
      0.013051257064713
    ]
  },
  {
    "key": 10506510,
    "value": [
      0.21803902156842,
      0.039791328688075,
      0.025490206377495,
      0.031072870402389,
      0.022180201324437,
      0.024167788090747,
      0.020921132416695,
      0.025498125049871,
      0.023336327491295,
      0.025743603893518,
      0.026440447062583,
      0.032347776654883,
      0.038864844020113,
      0.056491808728502,
      0.072202454721963,
      0.19851319778733,
      0.10580930028459,
      0.013089565437092
    ]
  },
  {
    "key": 10534305,
    "value": [
      0.21572512064864,
      0.039909075218388,
      0.025565634453331,
      0.031164818140687,
      0.022245834763523,
      0.024239302998312,
      0.020983040144673,
      0.025573576557853,
      0.0234053820236,
      0.025819781798006,
      0.02651868699586,
      0.03244349696858,
      0.038979848989423,
      0.056658973653329,
      0.072416109023137,
      0.19910061823876,
      0.10612240061057,
      0.013128298773332
    ]
  },
  {
    "key": 10562100,
    "value": [
      0.21342339815474,
      0.040026202030121,
      0.025640665539295,
      0.031256281943521,
      0.022311122763456,
      0.024310441511628,
      0.021044622042503,
      0.025648630952635,
      0.023474073110998,
      0.025895558766154,
      0.026596515140015,
      0.032538713491153,
      0.03909424866942,
      0.056825258762763,
      0.072628638827989,
      0.1996849470036,
      0.10643385304009,
      0.013166828249908
    ]
  },
  {
    "key": 10589895,
    "value": [
      0.21113375819363,
      0.040142714002947,
      0.025715302761291,
      0.031347265621406,
      0.02237606804423,
      0.024381206594427,
      0.021105880675778,
      0.025723291361092,
      0.023542403615261,
      0.025970937954941,
      0.026673934737481,
      0.03263343018946,
      0.039208047826162,
      0.056990670984482,
      0.07284005299082,
      0.20026620842585,
      0.10674367054873,
      0.013205155472014
    ]
  },
  {
    "key": 10617690,
    "value": [
      0.21482309375714,
      0.039471354498637,
      0.024413140441415,
      0.030796569520533,
      0.021578612641418,
      0.023881530399686,
      0.020397882449955,
      0.025162599029835,
      0.023076492144852,
      0.025162056170978,
      0.02608712072182,
      0.03203905818006,
      0.038244241179534,
      0.056235544544735,
      0.071764807158937,
      0.20247474096403,
      0.11020878704674,
      0.014182369149684
    ]
  },
  {
    "key": 10645485,
    "value": [
      0.2209185967937,
      0.038483394971734,
      0.022558048854871,
      0.029988029302879,
      0.020434766888631,
      0.023152661272706,
      0.01938089742419,
      0.024346644059131,
      0.022395926751945,
      0.023997981395763,
      0.02523344234463,
      0.031167799453803,
      0.036847522513611,
      0.055110432582098,
      0.070172473431252,
      0.20533509629743,
      0.11493986772832,
      0.015536417933299
    ]
  },
  {
    "key": 10673280,
    "value": [
      0.22685822108722,
      0.037516841590579,
      0.020740966997957,
      0.029196941529581,
      0.019314644678763,
      0.022439354320235,
      0.018385058123084,
      0.023548041534663,
      0.02172992637473,
      0.022858190448181,
      0.024397908159563,
      0.030315296570463,
      0.035480294851054,
      0.054010194450075,
      0.068614998942253,
      0.20814739864984,
      0.11958159204269,
      0.01686412964906
    ]
  },
  {
    "key": 10701075,
    "value": [
      0.22634288834329,
      0.037389221654714,
      0.020381795688488,
      0.029088874703969,
      0.019108874967367,
      0.022332603184171,
      0.018205025934618,
      0.023424754098743,
      0.021632120183791,
      0.022656482420906,
      0.024268346529309,
      0.030196089769087,
      0.035257644352481,
      0.053891998577655,
      0.068428903854366,
      0.20927871145774,
      0.12091240435599,
      0.017203259923321
    ]
  },
  {
    "key": 10728870,
    "value": [
      0.223967723491,
      0.037504008378432,
      0.020444368789693,
      0.029178178960013,
      0.019167540140226,
      0.022401165358995,
      0.018260916247113,
      0.02349666922984,
      0.021698531841833,
      0.022726038920694,
      0.024342851530078,
      0.030288793229076,
      0.035365887030508,
      0.05405744962685,
      0.068638983907747,
      0.20992120725116,
      0.12128361130116,
      0.01725607476558
    ]
  },
  {
    "key": 10756665,
    "value": [
      0.22159429959032,
      0.03761871096562,
      0.02050689602598,
      0.0292674177577,
      0.019226162312606,
      0.022469677279021,
      0.018316765593051,
      0.023568531648485,
      0.021764894821366,
      0.022795544436862,
      0.02441730192007,
      0.030381428739156,
      0.035474050368668,
      0.054222779403507,
      0.068848909976361,
      0.21056323210712,
      0.12165454615856,
      0.017308850895543
    ]
  },
  {
    "key": 10784460,
    "value": [
      0.21915883208717,
      0.037736411989673,
      0.020571057784862,
      0.029358989344101,
      0.019286316925423,
      0.022539980167079,
      0.018374074895052,
      0.023642272620444,
      0.021832992593542,
      0.022866866894629,
      0.024493698515457,
      0.030476485831305,
      0.035585041201382,
      0.054392431060861,
      0.069064323715992,
      0.21122204011545,
      0.12203517761286,
      0.017363006644725
    ]
  },
  {
    "key": 10812255,
    "value": [
      0.21673587834072,
      0.037853508250174,
      0.020634889872073,
      0.029450090423484,
      0.01934616245582,
      0.022609921829526,
      0.018431089734221,
      0.023715634701459,
      0.021900740470286,
      0.022937822888099,
      0.024569702574952,
      0.030571054507443,
      0.035695461748402,
      0.054561211025382,
      0.069278630631111,
      0.2118774630804,
      0.122413853332,
      0.017416884134448
    ]
  },
  {
    "key": 10840050,
    "value": [
      0.21430210404324,
      0.037971127445158,
      0.020699007023891,
      0.029541598346736,
      0.019406275247455,
      0.022680175841034,
      0.01848835919318,
      0.023789324406616,
      0.021968790898971,
      0.023009095760482,
      0.024646046057272,
      0.030666045513223,
      0.03580637541716,
      0.054730744736121,
      0.069493894609037,
      0.21253581306682,
      0.12279422016058,
      0.017471002233026
    ]
  },
  {
    "key": 10867845,
    "value": [
      0.21186990314544,
      0.038088670601042,
      0.020763082724903,
      0.029633047111447,
      0.019466349177044,
      0.022750384434345,
      0.018545591628267,
      0.02386296647245,
      0.022036797334044,
      0.02308032255599,
      0.024722340184641,
      0.030760975108698,
      0.035917217381845,
      0.054900168845781,
      0.069709019421932,
      0.21319373743956,
      0.12317434108754,
      0.01752508534504
    ]
  },
  {
    "key": 10895640,
    "value": [
      0.20946046367964,
      0.038205113744772,
      0.0208265587819,
      0.029723640064929,
      0.019525860912669,
      0.022819935989776,
      0.018602288461143,
      0.023935919368703,
      0.022104167339844,
      0.023150882784906,
      0.02479792032346,
      0.030855016318048,
      0.036027022046591,
      0.055068007422209,
      0.069922131017577,
      0.21384550471364,
      0.12355090470283,
      0.017578662327369
    ]
  },
  {
    "key": 10923435,
    "value": [
      0.20708096399958,
      0.038320109961603,
      0.020889246083017,
      0.029813107306952,
      0.019584633153221,
      0.022888623294954,
      0.018658280777034,
      0.024007965749514,
      0.022170700203409,
      0.023220566229755,
      0.024872561300622,
      0.030947888967782,
      0.036135462274429,
      0.055233760430152,
      0.070132594480493,
      0.21448917310297,
      0.12392278912442,
      0.017631573560093
    ]
  },
  {
    "key": 10951230,
    "value": [
      0.20569483150433,
      0.038280702036043,
      0.020779879475688,
      0.029803044412498,
      0.019520580415627,
      0.022838531028549,
      0.018650768039855,
      0.023935296698348,
      0.02206691089562,
      0.023259820655265,
      0.024815666328515,
      0.030867928383523,
      0.036087196907894,
      0.055186986812334,
      0.070080788090653,
      0.21537914586585,
      0.12486686537651,
      0.017885057072898
    ]
  },
  {
    "key": 10979025,
    "value": [
      0.21178833088898,
      0.037070173217665,
      0.019363333438443,
      0.029038580704254,
      0.018523833928457,
      0.021886860873053,
      0.018161874479894,
      0.022763798389933,
      0.020669248739227,
      0.023069850308251,
      0.023760320572881,
      0.029475867450516,
      0.034850078070795,
      0.053528496040514,
      0.06804022957013,
      0.21816494362774,
      0.13017757786659,
      0.019666601832683
    ]
  },
  {
    "key": 11006820,
    "value": [
      0.21634385320788,
      0.036102473862072,
      0.018218343629636,
      0.028430419655217,
      0.017720737016026,
      0.021122286703092,
      0.017772725766702,
      0.021820421355023,
      0.019540343289675,
      0.022927039047977,
      0.022912185119212,
      0.028356150011395,
      0.033859530618635,
      0.052204132642769,
      0.066411915505996,
      0.22055113429502,
      0.1345765946062,
      0.021129713667475
    ]
  },
  {
    "key": 11034615,
    "value": [
      0.21408219951926,
      0.036206666617418,
      0.018270922281943,
      0.028512470646262,
      0.017771879561646,
      0.021183246216815,
      0.017824018353319,
      0.021883395704994,
      0.019596737270197,
      0.022993207127745,
      0.022978310330124,
      0.028437986658157,
      0.033957250176673,
      0.052354795238394,
      0.066603582162714,
      0.22118765107403,
      0.13496498644469,
      0.021190694615625
    ]
  },
  {
    "key": 11062410,
    "value": [
      0.21182665081121,
      0.03631057812071,
      0.018323359006398,
      0.028594300153482,
      0.017822884055958,
      0.02124404117986,
      0.017875172483616,
      0.021946200065551,
      0.019652979023984,
      0.023059196597127,
      0.023044257046367,
      0.028519602399686,
      0.034054705956041,
      0.052505051143894,
      0.066794731445256,
      0.2218224496755,
      0.13535232988012,
      0.02125151095524
    ]
  },
  {
    "key": 11090205,
    "value": [
      0.20957168711168,
      0.036414462673081,
      0.018375782130645,
      0.028676108437061,
      0.017873875321533,
      0.02130482037487,
      0.017926313346365,
      0.022008988136908,
      0.019709206190676,
      0.023125168951204,
      0.023110186658394,
      0.028601196973014,
      0.034152136458872,
      0.052655268078396,
      0.066985831150522,
      0.22245708363296,
      0.13573957285255,
      0.021312311521274
    ]
  },
  {
    "key": 11118120,
    "value": [
      0.20730470556701,
      0.036518900878916,
      0.018428484644308,
      0.028758352718471,
      0.017925138345421,
      0.021365923493034,
      0.017977726764708,
      0.02207211083774,
      0.019765733020454,
      0.023191492905413,
      0.02317646764276,
      0.028683226405205,
      0.034250086218263,
      0.052806285595124,
      0.067177949323033,
      0.22309509987663,
      0.13612887963916,
      0.02137343612436
    ]
  },
  // ... (add the rest of your frequency distribution data here)
];

// Frequency Distribution Map Aggregated
export const frequencyDistributionMapAgg = [
  {
    "key": 27795,
    "value": [
      27795,
      6464,
      2584,
      1329,
      698,
      440,
      274,
      141,
      108,
      66
    ]
  },
  {
    "key": 55590,
    "value": [
      55590,
      12928,
      5168,
      2659,
      1396,
      881,
      548,
      282,
      216,
      133
    ]
  },
  {
    "key": 83385,
    "value": [
      83385,
      19392,
      7752,
      3988,
      2094,
      1321,
      823,
      424,
      324,
      199
    ]
  },
  {
    "key": 111180,
    "value": [
      111180,
      25856,
      10336,
      5318,
      2792,
      1761,
      1097,
      565,
      432,
      266
    ]
  },
  {
    "key": 138975,
    "value": [
      138975,
      32320,
      12920,
      6647,
      3490,
      2202,
      1371,
      706,
      540,
      332
    ]
  },
  {
    "key": 166770,
    "value": [
      166770,
      38785,
      15504,
      7976,
      4188,
      2642,
      1645,
      847,
      648,
      399
    ]
  },
  {
    "key": 194565,
    "value": [
      194565,
      45248,
      18088,
      9306,
      4885,
      3082,
      1919,
      989,
      756,
      465
    ]
  },
  {
    "key": 200000,
    "value": [
      200000,
      46512,
      18593,
      9566,
      5022,
      3169,
      1973,
      1016,
      777,
      478
    ]
  },
  {
    "key": 222360,
    "value": [
      222360,
      52329,
      20918,
      10762,
      5650,
      3565,
      2220,
      1143,
      874,
      538
    ]
  },
  {
    "key": 250155,
    "value": [
      250155,
      60464,
      24170,
      12435,
      6528,
      4119,
      2565,
      1321,
      1010,
      622
    ]
  },
  {
    "key": 277950,
    "value": [
      277950,
      68600,
      28538,
      14940,
      7906,
      4962,
      3026,
      1615,
      1133,
      695
    ]
  },
  {
    "key": 305745,
    "value": [
      305745,
      75303,
      33934,
      18343,
      9844,
      6122,
      3592,
      2045,
      1212,
      740
    ]
  },
  {
    "key": 333540,
    "value": [
      333540,
      82075,
      39307,
      21723,
      11767,
      7273,
      4155,
      2471,
      1294,
      785
    ]
  },
  {
    "key": 361335,
    "value": [
      361335,
      90350,
      44564,
      24877,
      13532,
      8342,
      4708,
      2855,
      1409,
      853
    ]
  },
  {
    "key": 389130,
    "value": [
      389130,
      100711,
      49674,
      27730,
      15084,
      9298,
      5248,
      3182,
      1570,
      950
    ]
  },
  {
    "key": 416925,
    "value": [
      416925,
      111072,
      54784,
      30582,
      16636,
      10255,
      5788,
      3509,
      1732,
      1048
    ]
  },
  {
    "key": 444720,
    "value": [
      444720,
      122335,
      60339,
      33683,
      18323,
      11295,
      6375,
      3865,
      1908,
      1155
    ]
  },
  {
    "key": 472515,
    "value": [
      472515,
      134051,
      66118,
      36910,
      20077,
      12377,
      6986,
      4236,
      2090,
      1265
    ]
  },
  {
    "key": 500310,
    "value": [
      500310,
      146010,
      72016,
      40202,
      21868,
      13481,
      7609,
      4613,
      2277,
      1378
    ]
  },
  {
    "key": 528105,
    "value": [
      528105,
      159343,
      78593,
      43873,
      23865,
      14712,
      8304,
      5035,
      2485,
      1504
    ]
  },
  {
    "key": 555900,
    "value": [
      555900,
      172676,
      85169,
      47544,
      25862,
      15943,
      8999,
      5456,
      2693,
      1630
    ]
  },
  {
    "key": 583695,
    "value": [
      583695,
      187656,
      92557,
      51669,
      28106,
      17326,
      9779,
      5929,
      2926,
      1771
    ]
  },
  {
    "key": 611490,
    "value": [
      611490,
      202919,
      100086,
      55872,
      30392,
      18735,
      10575,
      6411,
      3164,
      1915
    ]
  },
  {
    "key": 639285,
    "value": [
      639285,
      206671,
      106790,
      62422,
      36200,
      23277,
      14091,
      9020,
      4973,
      3028
    ]
  },
  {
    "key": 667080,
    "value": [
      667080,
      208159,
      113421,
      69535,
      42796,
      28513,
      18203,
      12093,
      7124,
      4351
    ]
  },
  {
    "key": 694875,
    "value": [
      694875,
      210682,
      120199,
      76519,
      49154,
      33530,
      22120,
      15012,
      9159,
      5604
    ]
  },
  {
    "key": 722670,
    "value": [
      722670,
      213952,
      127082,
      83410,
      55339,
      38388,
      25896,
      17819,
      11111,
      6804
    ]
  },
  {
    "key": 750465,
    "value": [
      750465,
      217782,
      134045,
      90232,
      61396,
      43127,
      29566,
      20544,
      13001,
      7967
    ]
  },
  {
    "key": 778260,
    "value": [
      778260,
      222039,
      141068,
      97000,
      67355,
      47777,
      33156,
      23204,
      14843,
      9100
    ]
  },
  {
    "key": 806055,
    "value": [
      806055,
      236030,
      150656,
      103907,
      72354,
      51391,
      35724,
      25025,
      16030,
      9829
    ]
  },
  {
    "key": 833850,
    "value": [
      833850,
      251788,
      160714,
      110844,
      77185,
      54822,
      38109,
      26695,
      17101,
      10485
    ]
  },
  {
    "key": 861645,
    "value": [
      861645,
      267546,
      170773,
      117781,
      82015,
      58253,
      40494,
      28366,
      18171,
      11141
    ]
  },
  {
    "key": 889440,
    "value": [
      889440,
      283304,
      180831,
      124718,
      86846,
      61684,
      42879,
      30037,
      19241,
      11797
    ]
  },
  {
    "key": 917235,
    "value": [
      917235,
      299062,
      190889,
      131655,
      91676,
      65115,
      45264,
      31708,
      20311,
      12453
    ]
  },
  {
    "key": 945030,
    "value": [
      945030,
      316888,
      202267,
      139503,
      97141,
      68997,
      47962,
      33598,
      21522,
      13196
    ]
  },
  {
    "key": 972825,
    "value": [
      972825,
      335359,
      214057,
      147634,
      102803,
      73018,
      50757,
      35556,
      22776,
      13965
    ]
  },
  {
    "key": 1000620,
    "value": [
      1000620,
      353830,
      225846,
      155766,
      108465,
      77040,
      53553,
      37514,
      24031,
      14734
    ]
  },
  {
    "key": 1028415,
    "value": [
      1028415,
      372301,
      237636,
      163897,
      114127,
      81062,
      56349,
      39473,
      25285,
      15503
    ]
  },
  {
    "key": 1056210,
    "value": [
      1056210,
      391412,
      249835,
      172310,
      119986,
      85223,
      59241,
      41499,
      26583,
      16299
    ]
  },
  {
    "key": 1084005,
    "value": [
      1084005,
      411983,
      262965,
      181366,
      126292,
      89702,
      62354,
      43680,
      27980,
      17155
    ]
  },
  {
    "key": 1111800,
    "value": [
      1111800,
      432554,
      276095,
      190422,
      132598,
      94181,
      65468,
      45861,
      29378,
      18012
    ]
  },
  {
    "key": 1139595,
    "value": [
      1139595,
      453125,
      289226,
      199478,
      138904,
      98660,
      68581,
      48042,
      30775,
      18869
    ]
  },
  {
    "key": 1167390,
    "value": [
      1167390,
      473936,
      302509,
      208640,
      145283,
      103191,
      71731,
      50248,
      32188,
      19735
    ]
  },
  {
    "key": 1195185,
    "value": [
      1195185,
      495448,
      316240,
      218110,
      151877,
      107875,
      74987,
      52529,
      33649,
      20631
    ]
  },
  {
    "key": 1222980,
    "value": [
      1222980,
      516959,
      329971,
      227580,
      158472,
      112559,
      78243,
      54810,
      35110,
      21527
    ]
  },
  {
    "key": 1250775,
    "value": [
      1250775,
      538471,
      343701,
      237050,
      165066,
      117242,
      81499,
      57091,
      36571,
      22423
    ]
  },
  {
    "key": 1278570,
    "value": [
      1278570,
      549953,
      353631,
      245571,
      172545,
      123612,
      87221,
      61883,
      40517,
      25628
    ]
  },
  {
    "key": 1306365,
    "value": [
      1306365,
      547228,
      358194,
      252769,
      181303,
      132392,
      96460,
      70255,
      48004,
      32123
    ]
  },
  {
    "key": 1334160,
    "value": [
      1334160,
      545863,
      363292,
      260122,
      189971,
      140973,
      105396,
      78314,
      55177,
      38326
    ]
  },
  {
    "key": 1361955,
    "value": [
      1361955,
      545612,
      368828,
      267601,
      198564,
      149391,
      114082,
      86116,
      62094,
      44288
    ]
  },
  {
    "key": 1389750,
    "value": [
      1389750,
      546287,
      374728,
      275186,
      207096,
      157673,
      122560,
      93704,
      68797,
      50051
    ]
  },
  {
    "key": 1417545,
    "value": [
      1417545,
      547739,
      380933,
      282859,
      215577,
      165840,
      130865,
      101113,
      75322,
      55647
    ]
  },
  {
    "key": 1445340,
    "value": [
      1445340,
      549849,
      387398,
      290608,
      224013,
      173912,
      139022,
      108370,
      81694,
      61101
    ]
  },
  {
    "key": 1473135,
    "value": [
      1473135,
      569775,
      401595,
      301350,
      232372,
      180450,
      144303,
      112515,
      84848,
      63482
    ]
  },
  {
    "key": 1500930,
    "value": [
      1500930,
      590267,
      416038,
      312188,
      240730,
      186940,
      149493,
      116561,
      87900,
      65765
    ]
  },
  {
    "key": 1528725,
    "value": [
      1528725,
      610759,
      430482,
      323027,
      249087,
      193430,
      154683,
      120608,
      90951,
      68048
    ]
  },
  {
    "key": 1556520,
    "value": [
      1556520,
      631252,
      444926,
      333865,
      257445,
      199920,
      159873,
      124655,
      94003,
      70332
    ]
  },
  {
    "key": 1584315,
    "value": [
      1584315,
      651744,
      459369,
      344703,
      265802,
      206410,
      165063,
      128701,
      97054,
      72615
    ]
  },
  {
    "key": 1612110,
    "value": [
      1612110,
      672745,
      474171,
      355810,
      274367,
      213061,
      170382,
      132848,
      100182,
      74955
    ]
  },
  {
    "key": 1639905,
    "value": [
      1639905,
      695496,
      490207,
      367843,
      283646,
      220266,
      176144,
      137341,
      103570,
      77489
    ]
  },
  {
    "key": 1667700,
    "value": [
      1667700,
      718247,
      506243,
      379876,
      292924,
      227472,
      181906,
      141834,
      106958,
      80024
    ]
  },
  {
    "key": 1695495,
    "value": [
      1695495,
      740998,
      522278,
      391909,
      302203,
      234677,
      187668,
      146327,
      110346,
      82559
    ]
  },
  {
    "key": 1723290,
    "value": [
      1723290,
      763749,
      538314,
      403942,
      311482,
      241882,
      193430,
      150819,
      113734,
      85094
    ]
  },
  {
    "key": 1751085,
    "value": [
      1751085,
      786561,
      554392,
      416007,
      320785,
      249107,
      199207,
      155324,
      117131,
      87635
    ]
  },
  {
    "key": 1778880,
    "value": [
      1778880,
      811183,
      571747,
      429029,
      330827,
      256905,
      205443,
      160186,
      120797,
      90379
    ]
  },
  {
    "key": 1806675,
    "value": [
      1806675,
      835805,
      589101,
      442051,
      340868,
      264703,
      211679,
      165048,
      124464,
      93122
    ]
  },
  {
    "key": 1834470,
    "value": [
      1834470,
      860427,
      606455,
      455074,
      350910,
      272501,
      217915,
      169910,
      128130,
      95865
    ]
  },
  {
    "key": 1862265,
    "value": [
      1862265,
      885048,
      623809,
      468096,
      360951,
      280298,
      224150,
      174773,
      131797,
      98609
    ]
  },
  {
    "key": 1890060,
    "value": [
      1890060,
      909984,
      641385,
      481284,
      371121,
      288196,
      230466,
      179697,
      135510,
      101387
    ]
  },
  {
    "key": 1917855,
    "value": [
      1917855,
      935815,
      659591,
      494946,
      381656,
      296376,
      237008,
      184798,
      139357,
      104265
    ]
  },
  {
    "key": 1945650,
    "value": [
      1945650,
      961646,
      677798,
      508608,
      392190,
      304557,
      243550,
      189899,
      143203,
      107143
    ]
  },
  {
    "key": 1973445,
    "value": [
      1973445,
      987477,
      696004,
      522270,
      402725,
      312738,
      250092,
      194999,
      147050,
      110021
    ]
  },
  {
    "key": 2001240,
    "value": [
      2001240,
      1013308,
      714211,
      535932,
      413260,
      320919,
      256634,
      200100,
      150897,
      112899
    ]
  },
  {
    "key": 2029035,
    "value": [
      2029035,
      1015591,
      721722,
      545545,
      423755,
      331949,
      267065,
      210120,
      160210,
      121285
    ]
  },
  {
    "key": 2056830,
    "value": [
      2056830,
      1011937,
      726550,
      554155,
      434261,
      343720,
      278499,
      221400,
      170921,
      131078
    ]
  },
  {
    "key": 2084625,
    "value": [
      2084625,
      1009217,
      731815,
      562945,
      444790,
      355401,
      289800,
      232507,
      181436,
      140671
    ]
  },
  {
    "key": 2112420,
    "value": [
      2112420,
      1007322,
      737467,
      571894,
      455339,
      367003,
      300985,
      243461,
      191776,
      150086
    ]
  },
  {
    "key": 2140215,
    "value": [
      2140215,
      1006163,
      743463,
      580983,
      465906,
      378534,
      312065,
      254277,
      201962,
      159344
    ]
  },
  {
    "key": 2168010,
    "value": [
      2168010,
      1005658,
      749766,
      590199,
      476490,
      390002,
      323053,
      264972,
      212010,
      168462
    ]
  },
  {
    "key": 2195805,
    "value": [
      2195805,
      1005741,
      756344,
      599527,
      487088,
      401413,
      333957,
      275558,
      221934,
      177453
    ]
  },
  {
    "key": 2223600,
    "value": [
      2223600,
      1006353,
      763169,
      608957,
      497699,
      412773,
      344787,
      286045,
      231747,
      186331
    ]
  },
  {
    "key": 2251395,
    "value": [
      2251395,
      1007442,
      770218,
      618478,
      508321,
      424087,
      355549,
      296444,
      241459,
      195107
    ]
  },
  {
    "key": 2279190,
    "value": [
      2279190,
      1008963,
      777470,
      628083,
      518954,
      435360,
      366250,
      306762,
      251079,
      203790
    ]
  },
  {
    "key": 2306985,
    "value": [
      2306985,
      1010878,
      784906,
      637763,
      529597,
      446594,
      376895,
      317007,
      260618,
      212389
    ]
  },
  {
    "key": 2334780,
    "value": [
      2334780,
      1015803,
      793843,
      648151,
      540462,
      457699,
      387267,
      326840,
      269661,
      220468
    ]
  },
  {
    "key": 2362575,
    "value": [
      2362575,
      1038972,
      811949,
      662935,
      552790,
      468139,
      396100,
      334295,
      275811,
      225497
    ]
  },
  {
    "key": 2390370,
    "value": [
      2390370,
      1062141,
      830056,
      677718,
      565117,
      478578,
      404933,
      341750,
      281962,
      230525
    ]
  },
  {
    "key": 2418165,
    "value": [
      2418165,
      1085310,
      848162,
      692502,
      577444,
      489018,
      413766,
      349205,
      288113,
      235554
    ]
  },
  {
    "key": 2445960,
    "value": [
      2445960,
      1108479,
      866269,
      707285,
      589772,
      499457,
      422599,
      356660,
      294263,
      240582
    ]
  },
  {
    "key": 2473755,
    "value": [
      2473755,
      1131649,
      884376,
      722069,
      602099,
      509897,
      431433,
      364114,
      300414,
      245611
    ]
  },
  {
    "key": 2501550,
    "value": [
      2501550,
      1154818,
      902482,
      736852,
      614426,
      520336,
      440266,
      371569,
      306565,
      250640
    ]
  },
  {
    "key": 2529345,
    "value": [
      2529345,
      1177987,
      920589,
      751636,
      626753,
      530776,
      449099,
      379024,
      312715,
      255668
    ]
  },
  {
    "key": 2557140,
    "value": [
      2557140,
      1201156,
      938695,
      766419,
      639081,
      541216,
      457932,
      386479,
      318866,
      260697
    ]
  },
  {
    "key": 2584935,
    "value": [
      2584935,
      1224325,
      956802,
      781203,
      651408,
      551655,
      466765,
      393934,
      325016,
      265725
    ]
  },
  {
    "key": 2612730,
    "value": [
      2612730,
      1247495,
      974908,
      795986,
      663735,
      562095,
      475598,
      401388,
      331167,
      270754
    ]
  },
  {
    "key": 2640525,
    "value": [
      2640525,
      1272142,
      994170,
      811713,
      676849,
      573200,
      484995,
      409319,
      337710,
      276103
    ]
  },
  {
    "key": 2668320,
    "value": [
      2668320,
      1297455,
      1013952,
      827865,
      690317,
      584606,
      494645,
      417463,
      344430,
      281597
    ]
  },
  {
    "key": 2696115,
    "value": [
      2696115,
      1322767,
      1033734,
      844016,
      703785,
      596011,
      504295,
      425608,
      351149,
      287091
    ]
  },
  {
    "key": 2723910,
    "value": [
      2723910,
      1348080,
      1053515,
      860167,
      717252,
      607416,
      513945,
      433752,
      357869,
      292585
    ]
  },
  {
    "key": 2751705,
    "value": [
      2751705,
      1373393,
      1073297,
      876318,
      730720,
      618822,
      523596,
      441897,
      364589,
      298079
    ]
  },
  {
    "key": 2779500,
    "value": [
      2779500,
      1398705,
      1093079,
      892469,
      744188,
      630227,
      533246,
      450041,
      371308,
      303572
    ]
  },
  {
    "key": 2807295,
    "value": [
      2807295,
      1424018,
      1112860,
      908620,
      757655,
      641632,
      542896,
      458186,
      378028,
      309066
    ]
  },
  {
    "key": 2835090,
    "value": [
      2835090,
      1449331,
      1132642,
      924772,
      771123,
      653038,
      552546,
      466330,
      384748,
      314560
    ]
  },
  {
    "key": 2862885,
    "value": [
      2862885,
      1474643,
      1152423,
      940923,
      784591,
      664443,
      562197,
      474475,
      391467,
      320054
    ]
  },
  {
    "key": 2890680,
    "value": [
      2890680,
      1500365,
      1172525,
      957335,
      798276,
      676033,
      572003,
      482751,
      398295,
      325637
    ]
  },
  {
    "key": 2918475,
    "value": [
      2918475,
      1527455,
      1193695,
      974620,
      812689,
      688239,
      582331,
      491467,
      405487,
      331516
    ]
  },
  {
    "key": 2946270,
    "value": [
      2946270,
      1554544,
      1214865,
      991905,
      827102,
      700445,
      592658,
      500183,
      412678,
      337395
    ]
  },
  {
    "key": 2974065,
    "value": [
      2974065,
      1581634,
      1236036,
      1009190,
      841516,
      712651,
      602986,
      508900,
      419870,
      343275
    ]
  },
  {
    "key": 3001860,
    "value": [
      3001860,
      1608724,
      1257206,
      1026475,
      855929,
      724857,
      613314,
      517616,
      427061,
      349154
    ]
  },
  {
    "key": 3029655,
    "value": [
      3029655,
      1635813,
      1278377,
      1043760,
      870342,
      737063,
      623641,
      526332,
      434252,
      355034
    ]
  },
  {
    "key": 3057450,
    "value": [
      3057450,
      1662903,
      1299547,
      1061045,
      884755,
      749269,
      633969,
      535048,
      441444,
      360913
    ]
  },
  {
    "key": 3085245,
    "value": [
      3085245,
      1689993,
      1320717,
      1078330,
      899168,
      761475,
      644297,
      543765,
      448635,
      366793
    ]
  },
  {
    "key": 3113040,
    "value": [
      3113040,
      1717082,
      1341888,
      1095615,
      913582,
      773681,
      654625,
      552481,
      455826,
      372672
    ]
  },
  {
    "key": 3140835,
    "value": [
      3140835,
      1744775,
      1363529,
      1113285,
      928315,
      786159,
      665182,
      561391,
      463178,
      378683
    ]
  },
  {
    "key": 3168630,
    "value": [
      3168630,
      1773025,
      1385607,
      1131311,
      943346,
      798888,
      675952,
      570481,
      470677,
      384814
    ]
  },
  {
    "key": 3196425,
    "value": [
      3196425,
      1801275,
      1407684,
      1149336,
      958377,
      811617,
      686723,
      579571,
      478177,
      390946
    ]
  },
  {
    "key": 3224220,
    "value": [
      3224220,
      1829525,
      1429761,
      1167362,
      973407,
      824346,
      697493,
      588660,
      485676,
      397077
    ]
  },
  {
    "key": 3252015,
    "value": [
      3252015,
      1857776,
      1451839,
      1185387,
      988438,
      837075,
      708263,
      597750,
      493176,
      403208
    ]
  },
  {
    "key": 3279810,
    "value": [
      3279810,
      1886026,
      1473916,
      1203413,
      1003469,
      849803,
      719033,
      606840,
      500675,
      409340
    ]
  },
  {
    "key": 3307605,
    "value": [
      3307605,
      1914276,
      1495993,
      1221438,
      1018499,
      862532,
      729803,
      615929,
      508175,
      415471
    ]
  },
  {
    "key": 3335400,
    "value": [
      3335400,
      1942526,
      1518071,
      1239464,
      1033530,
      875261,
      740573,
      625019,
      515674,
      421602
    ]
  },
  {
    "key": 3363195,
    "value": [
      3363195,
      1958221,
      1533299,
      1253985,
      1047266,
      888033,
      752566,
      636303,
      526229,
      431187
    ]
  },
  {
    "key": 3390990,
    "value": [
      3390990,
      1948923,
      1534901,
      1261541,
      1058441,
      900906,
      767008,
      651972,
      542884,
      447662
    ]
  },
  {
    "key": 3418785,
    "value": [
      3418785,
      1940673,
      1537085,
      1269408,
      1069744,
      913797,
      781371,
      667482,
      559308,
      463872
    ]
  },
  {
    "key": 3446580,
    "value": [
      3446580,
      1933385,
      1539805,
      1277558,
      1081165,
      926704,
      795660,
      682846,
      575520,
      479840
    ]
  },
  {
    "key": 3474375,
    "value": [
      3474375,
      1926982,
      1543018,
      1285970,
      1092695,
      939626,
      809883,
      698075,
      591537,
      495583
    ]
  },
  {
    "key": 3502170,
    "value": [
      3502170,
      1921396,
      1546684,
      1294624,
      1104325,
      952563,
      824045,
      713179,
      607375,
      511120
    ]
  },
  {
    "key": 3529965,
    "value": [
      3529965,
      1916566,
      1550771,
      1303500,
      1116047,
      965513,
      838149,
      728169,
      623046,
      526467
    ]
  },
  {
    "key": 3557760,
    "value": [
      3557760,
      1912435,
      1555247,
      1312583,
      1127855,
      978474,
      852200,
      743052,
      638562,
      541636
    ]
  },
  {
    "key": 3585555,
    "value": [
      3585555,
      1908953,
      1560085,
      1321859,
      1139743,
      991447,
      866203,
      757836,
      653936,
      556642
    ]
  },
  {
    "key": 3613350,
    "value": [
      3613350,
      1915650,
      1570793,
      1334460,
      1153259,
      1004999,
      879860,
      771512,
      667520,
      569513
    ]
  },
  {
    "key": 3641145,
    "value": [
      3641145,
      1943903,
      1593960,
      1354141,
      1170268,
      1019821,
      892837,
      782890,
      677365,
      577912
    ]
  },
  {
    "key": 3668940,
    "value": [
      3668940,
      1972155,
      1617127,
      1373822,
      1187277,
      1034643,
      905813,
      794269,
      687210,
      586312
    ]
  },
  {
    "key": 3696735,
    "value": [
      3696735,
      2000408,
      1640294,
      1393503,
      1204285,
      1049465,
      918790,
      805648,
      697055,
      594711
    ]
  },
  {
    "key": 3724530,
    "value": [
      3724530,
      2028661,
      1663460,
      1413184,
      1221294,
      1064287,
      931767,
      817026,
      706900,
      603110
    ]
  },
  {
    "key": 3752325,
    "value": [
      3752325,
      2056914,
      1686627,
      1432866,
      1238303,
      1079109,
      944743,
      828405,
      716745,
      611510
    ]
  },
  {
    "key": 3780120,
    "value": [
      3780120,
      2085167,
      1709794,
      1452547,
      1255312,
      1093932,
      957720,
      839783,
      726590,
      619909
    ]
  },
  {
    "key": 3807915,
    "value": [
      3807915,
      2113420,
      1732960,
      1472228,
      1272320,
      1108754,
      970696,
      851162,
      736434,
      628309
    ]
  },
  {
    "key": 3835710,
    "value": [
      3835710,
      2142234,
      1756588,
      1492300,
      1289667,
      1123870,
      983931,
      862767,
      746475,
      636875
    ]
  },
  {
    "key": 3863505,
    "value": [
      3863505,
      2172751,
      1781611,
      1513559,
      1308039,
      1139880,
      997947,
      875057,
      757109,
      645948
    ]
  },
  {
    "key": 3891300,
    "value": [
      3891300,
      2203268,
      1806634,
      1534817,
      1326411,
      1155890,
      1011964,
      887347,
      767743,
      655020
    ]
  },
  {
    "key": 3919095,
    "value": [
      3919095,
      2233785,
      1831657,
      1556075,
      1344783,
      1171900,
      1025980,
      899638,
      778376,
      664093
    ]
  },
  {
    "key": 3946890,
    "value": [
      3946890,
      2264302,
      1856681,
      1577334,
      1363154,
      1187910,
      1039997,
      911928,
      789010,
      673165
    ]
  },
  {
    "key": 3974685,
    "value": [
      3974685,
      2294819,
      1881704,
      1598592,
      1381526,
      1203920,
      1054013,
      924219,
      799644,
      682238
    ]
  },
  {
    "key": 4002480,
    "value": [
      4002480,
      2325335,
      1906727,
      1619851,
      1399898,
      1219930,
      1068030,
      936509,
      810278,
      691310
    ]
  },
  {
    "key": 4030275,
    "value": [
      4030275,
      2355852,
      1931750,
      1641109,
      1418270,
      1235940,
      1082046,
      948800,
      820912,
      700383
    ]
  },
  {
    "key": 4058070,
    "value": [
      4058070,
      2387864,
      1957999,
      1663409,
      1437541,
      1252734,
      1096749,
      961692,
      832066,
      709900
    ]
  },
  {
    "key": 4085865,
    "value": [
      4085865,
      2420017,
      1984364,
      1685807,
      1456898,
      1269603,
      1111517,
      974641,
      843270,
      719459
    ]
  },
  {
    "key": 4113660,
    "value": [
      4113660,
      2452170,
      2010729,
      1708205,
      1476255,
      1286471,
      1126285,
      987591,
      854474,
      729018
    ]
  },
  {
    "key": 4141455,
    "value": [
      4141455,
      2484323,
      2037094,
      1730603,
      1495612,
      1303339,
      1141053,
      1000540,
      865678,
      738576
    ]
  },
  {
    "key": 4169250,
    "value": [
      4169250,
      2516476,
      2063459,
      1753001,
      1514969,
      1320208,
      1155821,
      1013490,
      876882,
      748135
    ]
  },
  {
    "key": 4197045,
    "value": [
      4197045,
      2548629,
      2089824,
      1775399,
      1534325,
      1337076,
      1170589,
      1026439,
      888086,
      757694
    ]
  },
  {
    "key": 4224840,
    "value": [
      4224840,
      2580835,
      2116232,
      1797834,
      1553714,
      1353972,
      1185381,
      1039410,
      899308,
      767269
    ]
  },
  {
    "key": 4252635,
    "value": [
      4252635,
      2613682,
      2143165,
      1820716,
      1573488,
      1371204,
      1200468,
      1052638,
      910754,
      777034
    ]
  },
  {
    "key": 4280430,
    "value": [
      4280430,
      2646529,
      2170099,
      1843597,
      1593263,
      1388437,
      1215554,
      1065867,
      922200,
      786799
    ]
  },
  {
    "key": 4308225,
    "value": [
      4308225,
      2679376,
      2197033,
      1866479,
      1613037,
      1405669,
      1230641,
      1079096,
      933646,
      796565
    ]
  },
  {
    "key": 4336020,
    "value": [
      4336020,
      2712223,
      2223967,
      1889360,
      1632812,
      1422901,
      1245727,
      1092325,
      945091,
      806330
    ]
  },
  {
    "key": 4363815,
    "value": [
      4363815,
      2745070,
      2250901,
      1912242,
      1652586,
      1440134,
      1260814,
      1105554,
      956537,
      816095
    ]
  },
  {
    "key": 4391610,
    "value": [
      4391610,
      2777917,
      2277834,
      1935123,
      1672361,
      1457366,
      1275901,
      1118783,
      967983,
      825860
    ]
  },
  {
    "key": 4419405,
    "value": [
      4419405,
      2784553,
      2289607,
      1948947,
      1687656,
      1473930,
      1292584,
      1135123,
      984325,
      841884
    ]
  },
  {
    "key": 4447200,
    "value": [
      4447200,
      2766645,
      2287187,
      1954295,
      1698764,
      1489879,
      1310774,
      1154388,
      1005263,
      863780
    ]
  },
  {
    "key": 4474995,
    "value": [
      4474995,
      2750056,
      2285536,
      1960108,
      1710109,
      1505875,
      1328897,
      1173509,
      1025969,
      885374
    ]
  },
  {
    "key": 4502790,
    "value": [
      4502790,
      2734693,
      2284600,
      1966353,
      1721674,
      1521913,
      1346957,
      1192497,
      1046458,
      906689
    ]
  },
  {
    "key": 4530585,
    "value": [
      4530585,
      2720469,
      2284328,
      1973000,
      1733443,
      1537991,
      1364959,
      1211361,
      1066746,
      927742
    ]
  },
  {
    "key": 4558380,
    "value": [
      4558380,
      2707308,
      2284676,
      1980020,
      1745402,
      1554107,
      1382907,
      1230110,
      1086846,
      948554
    ]
  },
  {
    "key": 4586175,
    "value": [
      4586175,
      2695138,
      2285602,
      1987390,
      1757539,
      1570257,
      1400804,
      1248751,
      1106772,
      969138
    ]
  },
  {
    "key": 4613970,
    "value": [
      4613970,
      2723761,
      2310762,
      2009786,
      1777787,
      1588766,
      1417591,
      1263928,
      1120491,
      981399
    ]
  },
  {
    "key": 4641765,
    "value": [
      4641765,
      2755837,
      2337974,
      2033453,
      1798723,
      1607476,
      1434285,
      1278812,
      1133686,
      992956
    ]
  },
  {
    "key": 4669560,
    "value": [
      4669560,
      2787912,
      2365186,
      2057121,
      1819658,
      1626185,
      1450979,
      1293696,
      1146881,
      1004513
    ]
  },
  {
    "key": 4697355,
    "value": [
      4697355,
      2819988,
      2392398,
      2080789,
      1840594,
      1644895,
      1467673,
      1308581,
      1160077,
      1016070
    ]
  },
  {
    "key": 4725150,
    "value": [
      4725150,
      2852064,
      2419610,
      2104456,
      1861529,
      1663605,
      1484366,
      1323465,
      1173272,
      1027628
    ]
  },
  {
    "key": 4752945,
    "value": [
      4752945,
      2884139,
      2446822,
      2128124,
      1882465,
      1682314,
      1501060,
      1338349,
      1186467,
      1039185
    ]
  },
  {
    "key": 4780740,
    "value": [
      4780740,
      2917623,
      2475229,
      2152831,
      1904320,
      1701845,
      1518487,
      1353887,
      1200241,
      1051249
    ]
  },
  {
    "key": 4808535,
    "value": [
      4808535,
      2951393,
      2503878,
      2177748,
      1926361,
      1721543,
      1536063,
      1369557,
      1214133,
      1063417
    ]
  },
  {
    "key": 4836330,
    "value": [
      4836330,
      2985163,
      2532528,
      2202666,
      1948403,
      1741241,
      1553638,
      1385228,
      1228025,
      1075584
    ]
  },
  {
    "key": 4864125,
    "value": [
      4864125,
      3018933,
      2561177,
      2227584,
      1970444,
      1760939,
      1571214,
      1400898,
      1241918,
      1087752
    ]
  },
  {
    "key": 4891920,
    "value": [
      4891920,
      3052702,
      2589827,
      2252502,
      1992486,
      1780637,
      1588790,
      1416569,
      1255810,
      1099920
    ]
  },
  {
    "key": 4919715,
    "value": [
      4919715,
      3086700,
      2618669,
      2277588,
      2014676,
      1800468,
      1606484,
      1432345,
      1269796,
      1112169
    ]
  },
  {
    "key": 4947510,
    "value": [
      4947510,
      3121914,
      2648544,
      2303571,
      2037660,
      1821008,
      1624811,
      1448686,
      1284282,
      1124857
    ]
  },
  {
    "key": 4975305,
    "value": [
      4975305,
      3157128,
      2678418,
      2329554,
      2060643,
      1841548,
      1643138,
      1465026,
      1298768,
      1137545
    ]
  },
  {
    "key": 5003100,
    "value": [
      5003100,
      3192341,
      2708292,
      2355538,
      2083627,
      1862088,
      1661465,
      1481367,
      1313254,
      1150233
    ]
  },
  {
    "key": 5030895,
    "value": [
      5030895,
      3227555,
      2738167,
      2381521,
      2106611,
      1882628,
      1679792,
      1497707,
      1327740,
      1162921
    ]
  },
  {
    "key": 5058690,
    "value": [
      5058690,
      3262769,
      2768041,
      2407504,
      2129595,
      1903168,
      1698119,
      1514048,
      1342226,
      1175609
    ]
  },
  {
    "key": 5086485,
    "value": [
      5086485,
      3298868,
      2798667,
      2434141,
      2153157,
      1924225,
      1716907,
      1530799,
      1357077,
      1188616
    ]
  },
  {
    "key": 5114280,
    "value": [
      5114280,
      3335158,
      2829454,
      2460918,
      2176843,
      1945393,
      1735795,
      1547639,
      1372005,
      1201692
    ]
  },
  {
    "key": 5142075,
    "value": [
      5142075,
      3371449,
      2860242,
      2487696,
      2200530,
      1966561,
      1754682,
      1564479,
      1386934,
      1214767
    ]
  },
  {
    "key": 5169870,
    "value": [
      5169870,
      3407739,
      2891029,
      2514473,
      2224216,
      1987729,
      1773569,
      1581319,
      1401863,
      1227843
    ]
  },
  {
    "key": 5197665,
    "value": [
      5197665,
      3444029,
      2921817,
      2541251,
      2247903,
      2008897,
      1792457,
      1598159,
      1416792,
      1240919
    ]
  },
  {
    "key": 5225460,
    "value": [
      5225460,
      3432709,
      2921908,
      2550672,
      2261518,
      2026265,
      1813420,
      1620393,
      1439819,
      1265324
    ]
  },
  {
    "key": 5253255,
    "value": [
      5253255,
      3407033,
      2912752,
      2554874,
      2272115,
      2042507,
      1835032,
      1644276,
      1465310,
      1293169
    ]
  },
  {
    "key": 5281050,
    "value": [
      5281050,
      3383108,
      2904733,
      2559730,
      2283100,
      2058910,
      1856591,
      1667985,
      1490528,
      1320622
    ]
  },
  {
    "key": 5308845,
    "value": [
      5308845,
      3360808,
      2897770,
      2565193,
      2294446,
      2075462,
      1878102,
      1691532,
      1515492,
      1347712
    ]
  },
  {
    "key": 5336640,
    "value": [
      5336640,
      3340021,
      2891789,
      2571221,
      2306128,
      2092154,
      1899566,
      1714929,
      1540220,
      1374463
    ]
  },
  {
    "key": 5364435,
    "value": [
      5364435,
      3374585,
      2922192,
      2598704,
      2331027,
      2114985,
      1920546,
      1734030,
      1557523,
      1390090
    ]
  },
  {
    "key": 5392230,
    "value": [
      5392230,
      3411416,
      2954085,
      2627067,
      2356469,
      2138068,
      1941508,
      1752955,
      1574522,
      1405262
    ]
  },
  {
    "key": 5420025,
    "value": [
      5420025,
      3448247,
      2985979,
      2655430,
      2381910,
      2161152,
      1962469,
      1771881,
      1591521,
      1420433
    ]
  },
  {
    "key": 5447820,
    "value": [
      5447820,
      3485078,
      3017872,
      2683793,
      2407351,
      2184235,
      1983430,
      1790806,
      1608520,
      1435605
    ]
  },
  {
    "key": 5475615,
    "value": [
      5475615,
      3522842,
      3050573,
      2712874,
      2433437,
      2207903,
      2004922,
      1810211,
      1625950,
      1451161
    ]
  },
  {
    "key": 5503410,
    "value": [
      5503410,
      3561428,
      3083987,
      2742588,
      2460091,
      2232087,
      2026883,
      1830039,
      1643759,
      1467056
    ]
  },
  {
    "key": 5531205,
    "value": [
      5531205,
      3600014,
      3117400,
      2772303,
      2486745,
      2256270,
      2048843,
      1849866,
      1661569,
      1482951
    ]
  },
  {
    "key": 5559000,
    "value": [
      5559000,
      3638600,
      3150814,
      2802017,
      2513399,
      2280454,
      2070803,
      1869694,
      1679378,
      1498846
    ]
  },
  {
    "key": 5586795,
    "value": [
      5586795,
      3677696,
      3184668,
      2832124,
      2540404,
      2304957,
      2093053,
      1889783,
      1697422,
      1514950
    ]
  },
  {
    "key": 5614590,
    "value": [
      5614590,
      3717578,
      3219203,
      2862836,
      2567953,
      2329952,
      2115751,
      1910276,
      1715829,
      1531379
    ]
  },
  {
    "key": 5642385,
    "value": [
      5642385,
      3757459,
      3253738,
      2893548,
      2595501,
      2354947,
      2138448,
      1930769,
      1734237,
      1547807
    ]
  },
  {
    "key": 5670180,
    "value": [
      5670180,
      3797340,
      3288273,
      2924260,
      2623050,
      2379942,
      2161145,
      1951262,
      1752644,
      1564235
    ]
  },
  {
    "key": 5697975,
    "value": [
      5697975,
      3837548,
      3323090,
      2955223,
      2650823,
      2405142,
      2184028,
      1971923,
      1771201,
      1580798
    ]
  },
  {
    "key": 5725770,
    "value": [
      5725770,
      3878111,
      3358216,
      2986460,
      2678843,
      2430564,
      2207114,
      1992767,
      1789923,
      1597507
    ]
  },
  {
    "key": 5753565,
    "value": [
      5753565,
      3918675,
      3393342,
      3017697,
      2706863,
      2455987,
      2230199,
      2013610,
      1808645,
      1614216
    ]
  },
  {
    "key": 5781360,
    "value": [
      5781360,
      3959239,
      3428467,
      3048935,
      2734883,
      2481410,
      2253285,
      2034454,
      1827367,
      1630926
    ]
  },
  {
    "key": 5809155,
    "value": [
      5809155,
      3947053,
      3429595,
      3056422,
      2748625,
      2497687,
      2272628,
      2059958,
      1854945,
      1659882
    ]
  },
  {
    "key": 5836950,
    "value": [
      5836950,
      3915738,
      3418402,
      3055306,
      2757204,
      2510662,
      2290630,
      2087173,
      1885756,
      1693302
    ]
  },
  {
    "key": 5864745,
    "value": [
      5864745,
      3886541,
      3408582,
      3055156,
      2766372,
      2524022,
      2308801,
      2114221,
      1916233,
      1726252
    ]
  },
  {
    "key": 5892540,
    "value": [
      5892540,
      3871414,
      3407961,
      3061531,
      2779598,
      2540089,
      2328266,
      2140385,
      1944723,
      1756319
    ]
  },
  {
    "key": 5920335,
    "value": [
      5920335,
      3912561,
      3444182,
      3094070,
      2809140,
      2567087,
      2353012,
      2163134,
      1965393,
      1774986
    ]
  },
  {
    "key": 5948130,
    "value": [
      5948130,
      3953708,
      3480403,
      3126609,
      2838683,
      2594084,
      2377757,
      2185883,
      1986062,
      1793653
    ]
  },
  {
    "key": 5975925,
    "value": [
      5975925,
      3994856,
      3516625,
      3159149,
      2868226,
      2621081,
      2402503,
      2208632,
      2006731,
      1812320
    ]
  },
  {
    "key": 6003720,
    "value": [
      6003720,
      4037098,
      3553810,
      3192554,
      2898555,
      2648797,
      2427908,
      2231986,
      2027951,
      1831484
    ]
  },
  {
    "key": 6031515,
    "value": [
      6031515,
      4079497,
      3591133,
      3226083,
      2928997,
      2676615,
      2453406,
      2255428,
      2049249,
      1850718
    ]
  },
  {
    "key": 6059310,
    "value": [
      6059310,
      4121896,
      3628457,
      3259613,
      2959439,
      2704434,
      2478905,
      2278869,
      2070547,
      1869953
    ]
  },
  {
    "key": 6087105,
    "value": [
      6087105,
      4164616,
      3666063,
      3293396,
      2990111,
      2732463,
      2504597,
      2302488,
      2092007,
      1889334
    ]
  },
  {
    "key": 6114900,
    "value": [
      6114900,
      4207444,
      3703764,
      3327265,
      3020861,
      2760564,
      2530354,
      2326166,
      2113521,
      1908764
    ]
  },
  {
    "key": 6142695,
    "value": [
      6142695,
      4250272,
      3741465,
      3361133,
      3051610,
      2788664,
      2556111,
      2349844,
      2135035,
      1928193
    ]
  },
  {
    "key": 6170490,
    "value": [
      6170490,
      4292705,
      3778819,
      3394689,
      3082076,
      2816505,
      2581630,
      2373304,
      2156350,
      1947443
    ]
  },
  {
    "key": 6198285,
    "value": [
      6198285,
      4335008,
      3816057,
      3428143,
      3112449,
      2844260,
      2607071,
      2396692,
      2177600,
      1966635
    ]
  },
  {
    "key": 6226080,
    "value": [
      6226080,
      4377311,
      3853296,
      3461596,
      3142821,
      2872016,
      2632512,
      2420080,
      2198850,
      1985826
    ]
  },
  {
    "key": 6253875,
    "value": [
      6253875,
      4360125,
      3849080,
      3464463,
      3151699,
      2887086,
      2652829,
      2442858,
      2226296,
      2017306
    ]
  },
  {
    "key": 6281670,
    "value": [
      6281670,
      4332354,
      3837486,
      3461886,
      3156749,
      2899896,
      2672230,
      2465523,
      2254840,
      2050966
    ]
  },
  {
    "key": 6309465,
    "value": [
      6309465,
      4306070,
      3826927,
      3460071,
      3162333,
      2913018,
      2691754,
      2488198,
      2283223,
      2084314
    ]
  },
  {
    "key": 6337260,
    "value": [
      6337260,
      4281185,
      3817341,
      3458973,
      3168419,
      2926435,
      2711395,
      2510883,
      2311456,
      2117367
    ]
  },
  {
    "key": 6365055,
    "value": [
      6365055,
      4257617,
      3808672,
      3458550,
      3174979,
      2940130,
      2731145,
      2533577,
      2339547,
      2150142
    ]
  },
  {
    "key": 6392850,
    "value": [
      6392850,
      4248122,
      3809803,
      3465353,
      3186611,
      2956811,
      2752090,
      2556397,
      2366151,
      2179989
    ]
  },
  {
    "key": 6420645,
    "value": [
      6420645,
      4286774,
      3844467,
      3496882,
      3215605,
      2983713,
      2777130,
      2579657,
      2387679,
      2199823
    ]
  },
  {
    "key": 6448440,
    "value": [
      6448440,
      4325425,
      3879130,
      3528412,
      3244598,
      3010616,
      2802169,
      2602916,
      2409208,
      2219658
    ]
  },
  {
    "key": 6476235,
    "value": [
      6476235,
      4364077,
      3913793,
      3559941,
      3273591,
      3037518,
      2827209,
      2626175,
      2430736,
      2239492
    ]
  },
  {
    "key": 6504030,
    "value": [
      6504030,
      4402728,
      3948457,
      3591471,
      3302585,
      3064421,
      2852249,
      2649435,
      2452264,
      2259327
    ]
  },
  {
    "key": 6531825,
    "value": [
      6531825,
      4441380,
      3983120,
      3623000,
      3331578,
      3091323,
      2877289,
      2672694,
      2473793,
      2279162
    ]
  },
  {
    "key": 6559620,
    "value": [
      6559620,
      4480066,
      4017815,
      3654558,
      3360597,
      3118250,
      2902351,
      2695974,
      2495340,
      2299014
    ]
  },
  {
    "key": 6587415,
    "value": [
      6587415,
      4518759,
      4052516,
      3686122,
      3389622,
      3145182,
      2927418,
      2719259,
      2516892,
      2318870
    ]
  },
  {
    "key": 6615210,
    "value": [
      6615210,
      4557453,
      4087217,
      3717685,
      3418647,
      3172113,
      2952485,
      2742543,
      2538444,
      2338726
    ]
  },
  {
    "key": 6643005,
    "value": [
      6643005,
      4596146,
      4121918,
      3749249,
      3447672,
      3199045,
      2977552,
      2765828,
      2559996,
      2358582
    ]
  },
  {
    "key": 6670800,
    "value": [
      6670800,
      4634840,
      4156619,
      3780813,
      3476697,
      3225977,
      3002619,
      2789113,
      2581547,
      2378439
    ]
  },
  {
    "key": 6698595,
    "value": [
      6698595,
      4673432,
      4191229,
      3812294,
      3505645,
      3252838,
      3027621,
      2812336,
      2603043,
      2398243
    ]
  },
  {
    "key": 6726390,
    "value": [
      6726390,
      4711969,
      4225790,
      3843730,
      3534553,
      3279661,
      3052587,
      2835527,
      2624508,
      2418019
    ]
  },
  {
    "key": 6754185,
    "value": [
      6754185,
      4750507,
      4260352,
      3875167,
      3563461,
      3306484,
      3077553,
      2858718,
      2645973,
      2437795
    ]
  },
  {
    "key": 6781980,
    "value": [
      6781980,
      4789045,
      4294913,
      3906604,
      3592369,
      3333308,
      3102519,
      2881909,
      2667438,
      2457571
    ]
  },
  {
    "key": 6809775,
    "value": [
      6809775,
      4827582,
      4329475,
      3938040,
      3621277,
      3360131,
      3127485,
      2905100,
      2688903,
      2477347
    ]
  },
  {
    "key": 6837570,
    "value": [
      6837570,
      4865868,
      4363810,
      3969271,
      3649996,
      3386779,
      3152288,
      2928139,
      2710227,
      2496994
    ]
  },
  {
    "key": 6865365,
    "value": [
      6865365,
      4904030,
      4398035,
      4000402,
      3678623,
      3413341,
      3177011,
      2951104,
      2731483,
      2516578
    ]
  },
  {
    "key": 6893160,
    "value": [
      6893160,
      4942193,
      4432259,
      4031532,
      3707249,
      3439903,
      3201734,
      2974069,
      2752739,
      2536161
    ]
  },
  {
    "key": 6920955,
    "value": [
      6920955,
      4980355,
      4466484,
      4062663,
      3735875,
      3466465,
      3226457,
      2997034,
      2773995,
      2555745
    ]
  },
  {
    "key": 6948750,
    "value": [
      6948750,
      5018517,
      4500709,
      4093793,
      3764502,
      3493027,
      3251180,
      3019999,
      2795251,
      2575329
    ]
  },
  {
    "key": 6976545,
    "value": [
      6976545,
      5006764,
      4499790,
      4102154,
      3775063,
      3506636,
      3266514,
      3038861,
      2817142,
      2599946
    ]
  },
  {
    "key": 7004340,
    "value": [
      7004340,
      4987897,
      4493862,
      4107268,
      3783048,
      3518397,
      3280507,
      3057134,
      2839119,
      2625277
    ]
  },
  {
    "key": 7032135,
    "value": [
      7032135,
      4969762,
      4488447,
      4112713,
      3791295,
      3530344,
      3294634,
      3075464,
      2861082,
      2650530
    ]
  },
  {
    "key": 7059930,
    "value": [
      7059930,
      4952329,
      4483526,
      4118475,
      3799793,
      3542471,
      3308890,
      3093848,
      2883033,
      2675707
    ]
  },
  {
    "key": 7087725,
    "value": [
      7087725,
      4937635,
      4480524,
      4125470,
      3809264,
      3555287,
      3323639,
      3112432,
      2904922,
      2700579
    ]
  },
  {
    "key": 7115520,
    "value": [
      7115520,
      4972825,
      4512455,
      4154871,
      3836411,
      3580625,
      3347326,
      3134614,
      2925624,
      2719826
    ]
  },
  {
    "key": 7143315,
    "value": [
      7143315,
      5008014,
      4544387,
      4184272,
      3863559,
      3605963,
      3371012,
      3156795,
      2946327,
      2739072
    ]
  },
  {
    "key": 7171110,
    "value": [
      7171110,
      5043203,
      4576318,
      4213674,
      3890707,
      3631300,
      3394699,
      3178977,
      2967030,
      2758318
    ]
  },
  {
    "key": 7198905,
    "value": [
      7198905,
      5078392,
      4608250,
      4243075,
      3917854,
      3656638,
      3418386,
      3201158,
      2987732,
      2777565
    ]
  },
  {
    "key": 7226700,
    "value": [
      7226700,
      5113489,
      4640097,
      4272399,
      3944930,
      3681909,
      3442010,
      3223281,
      3008380,
      2796760
    ]
  },
  {
    "key": 7254495,
    "value": [
      7254495,
      5148455,
      4671826,
      4301613,
      3971906,
      3707086,
      3465547,
      3245322,
      3028952,
      2815885
    ]
  },
  {
    "key": 7282290,
    "value": [
      7282290,
      5183421,
      4703556,
      4330828,
      3998881,
      3732263,
      3489083,
      3267363,
      3049523,
      2835009
    ]
  },
  {
    "key": 7310085,
    "value": [
      7310085,
      5218388,
      4735285,
      4360043,
      4025857,
      3757440,
      3512620,
      3289404,
      3070095,
      2854133
    ]
  },
  {
    "key": 7337880,
    "value": [
      7337880,
      5253354,
      4767014,
      4389258,
      4052833,
      3782617,
      3536157,
      3311445,
      3090666,
      2873258
    ]
  },
  {
    "key": 7365675,
    "value": [
      7365675,
      5288360,
      4798780,
      4418506,
      4079839,
      3807823,
      3559720,
      3333511,
      3111261,
      2892404
    ]
  },
  {
    "key": 7393470,
    "value": [
      7393470,
      5323367,
      4830546,
      4447755,
      4106846,
      3833029,
      3583284,
      3355578,
      3131857,
      2911551
    ]
  },
  {
    "key": 7421265,
    "value": [
      7421265,
      5358375,
      4862312,
      4477004,
      4133853,
      3858236,
      3606849,
      3377645,
      3152452,
      2930697
    ]
  },
  {
    "key": 7449060,
    "value": [
      7449060,
      5393382,
      4894078,
      4506253,
      4160861,
      3883442,
      3630413,
      3399711,
      3173048,
      2949844
    ]
  },
  {
    "key": 7476855,
    "value": [
      7476855,
      5428638,
      4926071,
      4535710,
      4188060,
      3908828,
      3654144,
      3421935,
      3193789,
      2969127
    ]
  },
  {
    "key": 7504650,
    "value": [
      7504650,
      5463985,
      4958145,
      4565243,
      4215329,
      3934279,
      3677937,
      3444216,
      3214585,
      2988460
    ]
  },
  {
    "key": 7532445,
    "value": [
      7532445,
      5499332,
      4990220,
      4594776,
      4242598,
      3959730,
      3701730,
      3466497,
      3235380,
      3007792
    ]
  },
  {
    "key": 7560240,
    "value": [
      7560240,
      5534678,
      5022294,
      4624309,
      4269868,
      3985182,
      3725523,
      3488778,
      3256176,
      3027125
    ]
  },
  {
    "key": 7588035,
    "value": [
      7588035,
      5537985,
      5033129,
      4641049,
      4289625,
      4004293,
      3744755,
      3508466,
      3275064,
      3048055
    ]
  },
  {
    "key": 7615830,
    "value": [
      7615830,
      5524892,
      5033093,
      4651242,
      4305539,
      4020162,
      3761655,
      3526830,
      3292979,
      3069805
    ]
  },
  {
    "key": 7643625,
    "value": [
      7643625,
      5512325,
      5033407,
      4661647,
      4321578,
      4036136,
      3778631,
      3545237,
      3310926,
      3091531
    ]
  },
  {
    "key": 7671420,
    "value": [
      7671420,
      5500267,
      5034059,
      4672257,
      4337738,
      4052212,
      3795682,
      3563688,
      3328906,
      3113234
    ]
  },
  {
    "key": 7699215,
    "value": [
      7699215,
      5509801,
      5049072,
      4691573,
      4359067,
      4072664,
      3815910,
      3583994,
      3348274,
      3133977
    ]
  },
  {
    "key": 7727010,
    "value": [
      7727010,
      5544318,
      5080703,
      4720965,
      4386375,
      4098178,
      3839816,
      3606447,
      3369250,
      3153610
    ]
  },
  {
    "key": 7754805,
    "value": [
      7754805,
      5578835,
      5112334,
      4750356,
      4413683,
      4123692,
      3863721,
      3628899,
      3390226,
      3173244
    ]
  },
  {
    "key": 7782600,
    "value": [
      7782600,
      5613353,
      5143965,
      4779747,
      4440992,
      4149206,
      3887627,
      3651352,
      3411202,
      3192877
    ]
  },
  {
    "key": 7810395,
    "value": [
      7810395,
      5647976,
      5175694,
      4809230,
      4468384,
      4174799,
      3911606,
      3673874,
      3432243,
      3212571
    ]
  },
  {
    "key": 7838190,
    "value": [
      7838190,
      5682721,
      5207533,
      4838815,
      4495872,
      4200481,
      3935670,
      3696475,
      3453357,
      3232334
    ]
  },
  {
    "key": 7865985,
    "value": [
      7865985,
      5717466,
      5239373,
      4868400,
      4523361,
      4226163,
      3959733,
      3719075,
      3474471,
      3252097
    ]
  },
  {
    "key": 7893780,
    "value": [
      7893780,
      5752211,
      5271212,
      4897985,
      4550849,
      4251846,
      3983796,
      3741676,
      3495586,
      3271860
    ]
  },
  {
    "key": 7921575,
    "value": [
      7921575,
      5787183,
      5303260,
      4927764,
      4578517,
      4277696,
      4008017,
      3764425,
      3516838,
      3291752
    ]
  },
  {
    "key": 7949370,
    "value": [
      7949370,
      5822271,
      5335414,
      4957640,
      4606277,
      4303631,
      4032317,
      3787248,
      3538160,
      3311710
    ]
  },
  {
    "key": 7977165,
    "value": [
      7977165,
      5857358,
      5367567,
      4987517,
      4634036,
      4329567,
      4056617,
      3810071,
      3559483,
      3331667
    ]
  },
  {
    "key": 8004960,
    "value": [
      8004960,
      5892459,
      5399733,
      5017405,
      4661806,
      4355512,
      4080927,
      3832904,
      3580813,
      3351633
    ]
  },
  {
    "key": 8032755,
    "value": [
      8032755,
      5928014,
      5432315,
      5047681,
      4689935,
      4381794,
      4105552,
      3856032,
      3602420,
      3371857
    ]
  },
  {
    "key": 8060550,
    "value": [
      8060550,
      5963570,
      5464897,
      5077956,
      4718065,
      4408075,
      4130176,
      3879160,
      3624027,
      3392081
    ]
  },
  {
    "key": 8088345,
    "value": [
      8088345,
      5999125,
      5497480,
      5108231,
      4746195,
      4434357,
      4154801,
      3902288,
      3645634,
      3412305
    ]
  },
  {
    "key": 8116140,
    "value": [
      8116140,
      6006066,
      5509052,
      5124759,
      4765815,
      4458049,
      4178441,
      3926587,
      3669981,
      3436588
    ]
  },
  {
    "key": 8143935,
    "value": [
      8143935,
      5989384,
      5503281,
      5129939,
      4778412,
      4479608,
      4201271,
      3951857,
      3696593,
      3464227
    ]
  },
  {
    "key": 8171730,
    "value": [
      8171730,
      5973286,
      5497939,
      5135402,
      4791185,
      4501223,
      4224125,
      3977106,
      3723153,
      3491786
    ]
  },
  {
    "key": 8199525,
    "value": [
      8199525,
      5957750,
      5493011,
      5141137,
      4804128,
      4522892,
      4247002,
      4002335,
      3749662,
      3519269
    ]
  },
  {
    "key": 8227320,
    "value": [
      8227320,
      5992129,
      5524899,
      5171192,
      4832367,
      4549670,
      4272198,
      4026155,
      3772036,
      3540321
    ]
  },
  {
    "key": 8255115,
    "value": [
      8255115,
      6027536,
      5557545,
      5201748,
      4860921,
      4576554,
      4297442,
      4049945,
      3794325,
      3561241
    ]
  },
  {
    "key": 8282910,
    "value": [
      8282910,
      6062942,
      5590191,
      5232304,
      4889475,
      4603437,
      4322686,
      4073735,
      3816613,
      3582160
    ]
  },
  {
    "key": 8310705,
    "value": [
      8310705,
      6098677,
      5623139,
      5263143,
      4918293,
      4630570,
      4348164,
      4097745,
      3839108,
      3603273
    ]
  },
  {
    "key": 8338500,
    "value": [
      8338500,
      6134628,
      5656287,
      5294168,
      4947286,
      4657866,
      4373795,
      4121901,
      3861739,
      3624514
    ]
  },
  {
    "key": 8366295,
    "value": [
      8366295,
      6170578,
      5689434,
      5325193,
      4976278,
      4685162,
      4399427,
      4146056,
      3884370,
      3645754
    ]
  },
  {
    "key": 8394090,
    "value": [
      8394090,
      6206778,
      5722811,
      5356433,
      5005471,
      4712648,
      4425236,
      4170379,
      3907157,
      3667142
    ]
  },
  {
    "key": 8421885,
    "value": [
      8421885,
      6243362,
      5756543,
      5388005,
      5034975,
      4740425,
      4451319,
      4194960,
      3930187,
      3688757
    ]
  },
  {
    "key": 8449680,
    "value": [
      8449680,
      6279946,
      5790274,
      5419577,
      5064478,
      4768202,
      4477403,
      4219541,
      3953216,
      3710372
    ]
  },
  {
    "key": 8477475,
    "value": [
      8477475,
      6316768,
      5824225,
      5451355,
      5094173,
      4796161,
      4503656,
      4244282,
      3976396,
      3732128
    ]
  },
  {
    "key": 8505270,
    "value": [
      8505270,
      6354076,
      5858624,
      5483551,
      5124260,
      4824487,
      4530255,
      4269349,
      3999881,
      3754170
    ]
  },
  {
    "key": 8533065,
    "value": [
      8533065,
      6391383,
      5893022,
      5515747,
      5154346,
      4852814,
      4556854,
      4294416,
      4023366,
      3776212
    ]
  },
  {
    "key": 8560860,
    "value": [
      8560860,
      6406199,
      5911356,
      5536117,
      5176007,
      4876078,
      4582795,
      4319765,
      4049339,
      3800992
    ]
  },
  {
    "key": 8588655,
    "value": [
      8588655,
      6386993,
      5905392,
      5538602,
      5184927,
      4891690,
      4607748,
      4345545,
      4079082,
      3829921
    ]
  },
  {
    "key": 8616450,
    "value": [
      8616450,
      6368544,
      5899970,
      5541487,
      5194134,
      4907477,
      4632730,
      4371323,
      4108749,
      3858765
    ]
  },
  {
    "key": 8644245,
    "value": [
      8644245,
      6377992,
      5914648,
      5559325,
      5214155,
      4929993,
      4659103,
      4397327,
      4135952,
      3884811
    ]
  },
  {
    "key": 8672040,
    "value": [
      8672040,
      6416053,
      5949945,
      5592501,
      5245272,
      4959413,
      4686907,
      4423569,
      4160634,
      3907994
    ]
  },
  {
    "key": 8699835,
    "value": [
      8699835,
      6454115,
      5985241,
      5625677,
      5276388,
      4988834,
      4714711,
      4449811,
      4185316,
      3931177
    ]
  },
  {
    "key": 8727630,
    "value": [
      8727630,
      6492831,
      6021145,
      5659423,
      5308039,
      5018760,
      4742992,
      4476504,
      4210422,
      3954759
    ]
  },
  {
    "key": 8755425,
    "value": [
      8755425,
      6531794,
      6057277,
      5693385,
      5339892,
      5048877,
      4771455,
      4503367,
      4235688,
      3978491
    ]
  },
  {
    "key": 8783220,
    "value": [
      8783220,
      6570858,
      6093503,
      5727435,
      5371828,
      5079072,
      4799991,
      4530300,
      4261020,
      4002285
    ]
  },
  {
    "key": 8811015,
    "value": [
      8811015,
      6610746,
      6130494,
      5762203,
      5404438,
      5109905,
      4829129,
      4557801,
      4286887,
      4026581
    ]
  },
  {
    "key": 8838810,
    "value": [
      8838810,
      6650635,
      6167484,
      5796972,
      5437047,
      5140737,
      4858267,
      4585302,
      4312753,
      4050877
    ]
  },
  {
    "key": 8866605,
    "value": [
      8866605,
      6691139,
      6205046,
      5832277,
      5470160,
      5172046,
      4887855,
      4613228,
      4339019,
      4075548
    ]
  },
  {
    "key": 8894400,
    "value": [
      8894400,
      6731986,
      6242925,
      5867881,
      5503554,
      5203619,
      4917694,
      4641390,
      4365507,
      4100427
    ]
  },
  {
    "key": 8922195,
    "value": [
      8922195,
      6749036,
      6262249,
      5890629,
      5528184,
      5230190,
      4945437,
      4670418,
      4394883,
      4129940
    ]
  },
  {
    "key": 8949990,
    "value": [
      8949990,
      6717960,
      6244048,
      5887383,
      5535097,
      5246649,
      4968949,
      4701207,
      4430109,
      4168835
    ]
  },
  {
    "key": 8977785,
    "value": [
      8977785,
      6687998,
      6226719,
      5884747,
      5542430,
      5263356,
      4992573,
      4731971,
      4465215,
      4207529
    ]
  },
  {
    "key": 9005580,
    "value": [
      9005580,
      6659106,
      6210227,
      5882694,
      5550166,
      5280298,
      5016305,
      4762710,
      4500208,
      4246032
    ]
  },
  {
    "key": 9033375,
    "value": [
      9033375,
      6631240,
      6194538,
      5881203,
      5558288,
      5297467,
      5040140,
      4793427,
      4535091,
      4284350
    ]
  },
  {
    "key": 9061170,
    "value": [
      9061170,
      6604360,
      6179621,
      5880251,
      5566783,
      5314855,
      5064073,
      4824122,
      4569870,
      4322492
    ]
  },
  {
    "key": 9088965,
    "value": [
      9088965,
      6641226,
      6214822,
      5914661,
      5600007,
      5347215,
      5095416,
      4854524,
      4599063,
      4350467
    ]
  },
  {
    "key": 9116760,
    "value": [
      9116760,
      6682789,
      6253716,
      5951677,
      5635053,
      5380680,
      5127304,
      4884905,
      4627845,
      4377694
    ]
  },
  {
    "key": 9144555,
    "value": [
      9144555,
      6724352,
      6292611,
      5988693,
      5670100,
      5414145,
      5159193,
      4915287,
      4656628,
      4404920
    ]
  },
  {
    "key": 9172350,
    "value": [
      9172350,
      6765915,
      6331505,
      6025709,
      5705147,
      5447609,
      5191082,
      4945668,
      4685410,
      4432147
    ]
  },
  {
    "key": 9200145,
    "value": [
      9200145,
      6807478,
      6370400,
      6062725,
      5740194,
      5481074,
      5222971,
      4976049,
      4714193,
      4459374
    ]
  },
  {
    "key": 9227940,
    "value": [
      9227940,
      6850319,
      6410490,
      6100879,
      5776318,
      5515568,
      5255840,
      5007364,
      4743860,
      4487438
    ]
  },
  {
    "key": 9255735,
    "value": [
      9255735,
      6893224,
      6450640,
      6139090,
      5812496,
      5550113,
      5288758,
      5038726,
      4773572,
      4515543
    ]
  },
  {
    "key": 9283530,
    "value": [
      9283530,
      6936128,
      6490790,
      6177300,
      5848674,
      5584657,
      5321677,
      5070088,
      4803283,
      4543649
    ]
  },
  {
    "key": 9311325,
    "value": [
      9311325,
      6979033,
      6530940,
      6215511,
      5884852,
      5619202,
      5354595,
      5101450,
      4832995,
      4571754
    ]
  },
  {
    "key": 9339120,
    "value": [
      9339120,
      7022247,
      6571379,
      6253997,
      5921290,
      5653996,
      5387750,
      5133038,
      4862920,
      4600062
    ]
  },
  {
    "key": 9366915,
    "value": [
      9366915,
      7066080,
      6612398,
      6293035,
      5958251,
      5689288,
      5421381,
      5165078,
      4893275,
      4628776
    ]
  },
  {
    "key": 9394710,
    "value": [
      9394710,
      7109913,
      6653417,
      6332073,
      5995212,
      5724581,
      5455011,
      5197119,
      4923630,
      4657490
    ]
  },
  {
    "key": 9422505,
    "value": [
      9422505,
      7153747,
      6694436,
      6371110,
      6032173,
      5759874,
      5488642,
      5229160,
      4953984,
      4686204
    ]
  },
  {
    "key": 9450300,
    "value": [
      9450300,
      7197584,
      6735459,
      6410152,
      6069138,
      5795170,
      5522276,
      5261204,
      4984342,
      4714920
    ]
  },
  {
    "key": 9478095,
    "value": [
      9478095,
      7241802,
      6776837,
      6449532,
      6106423,
      5830772,
      5556201,
      5293526,
      5014963,
      4743886
    ]
  },
  {
    "key": 9505890,
    "value": [
      9505890,
      7286020,
      6818216,
      6488912,
      6143709,
      5866374,
      5590127,
      5325847,
      5045584,
      4772852
    ]
  },
  {
    "key": 9533685,
    "value": [
      9533685,
      7330237,
      6859595,
      6528293,
      6180994,
      5901976,
      5624053,
      5358169,
      5076204,
      4801817
    ]
  },
  {
    "key": 9561480,
    "value": [
      9561480,
      7374455,
      6900973,
      6567673,
      6218279,
      5937578,
      5657978,
      5390491,
      5106825,
      4830783
    ]
  },
  {
    "key": 9589275,
    "value": [
      9589275,
      7342543,
      6884217,
      6561587,
      6219474,
      5947085,
      5674631,
      5418213,
      5139615,
      4872142
    ]
  },
  {
    "key": 9617070,
    "value": [
      9617070,
      7306012,
      6863935,
      6552746,
      6218483,
      5955014,
      5690241,
      5445664,
      5172544,
      4914262
    ]
  },
  {
    "key": 9644865,
    "value": [
      9644865,
      7270730,
      6844608,
      6544653,
      6218089,
      5963376,
      5706141,
      5473198,
      5205446,
      4956188
    ]
  },
  {
    "key": 9672660,
    "value": [
      9672660,
      7253742,
      6839333,
      6547623,
      6226539,
      5978224,
      5726444,
      5502149,
      5238162,
      4995517
    ]
  },
  {
    "key": 9700455,
    "value": [
      9700455,
      7298248,
      6881296,
      6587797,
      6264742,
      6014903,
      5761578,
      5535908,
      5270301,
      5026167
    ]
  },
  {
    "key": 9728250,
    "value": [
      9728250,
      7342753,
      6923259,
      6627970,
      6302945,
      6051583,
      5796713,
      5569666,
      5302440,
      5056818
    ]
  },
  {
    "key": 9756045,
    "value": [
      9756045,
      7387259,
      6965222,
      6668143,
      6341148,
      6088263,
      5831848,
      5603425,
      5334579,
      5087468
    ]
  },
  {
    "key": 9783840,
    "value": [
      9783840,
      7432611,
      7007982,
      6709080,
      6380078,
      6125640,
      5867651,
      5637826,
      5367329,
      5118701
    ]
  },
  {
    "key": 9811635,
    "value": [
      9811635,
      7478149,
      7050919,
      6750185,
      6419167,
      6163170,
      5903601,
      5672367,
      5400213,
      5150062
    ]
  },
  {
    "key": 9839430,
    "value": [
      9839430,
      7523687,
      7093855,
      6791290,
      6458257,
      6200701,
      5939551,
      5706909,
      5433098,
      5181423
    ]
  },
  {
    "key": 9867225,
    "value": [
      9867225,
      7569617,
      7137162,
      6832750,
      6497683,
      6238555,
      5975811,
      5741749,
      5466266,
      5213055
    ]
  },
  {
    "key": 9895020,
    "value": [
      9895020,
      7615739,
      7180649,
      6874382,
      6537274,
      6276567,
      6012221,
      5776733,
      5499572,
      5244818
    ]
  },
  {
    "key": 9922815,
    "value": [
      9922815,
      7661861,
      7224136,
      6916014,
      6576864,
      6314578,
      6048632,
      5811718,
      5532878,
      5276581
    ]
  },
  {
    "key": 9950610,
    "value": [
      9950610,
      7707988,
      7267627,
      6957651,
      6616459,
      6352594,
      6085047,
      5846706,
      5566188,
      5308348
    ]
  },
  {
    "key": 9978405,
    "value": [
      9978405,
      7754117,
      7311121,
      6999289,
      6656056,
      6390612,
      6121463,
      5881696,
      5599499,
      5340116
    ]
  },
  {
    "key": 10006200,
    "value": [
      10006200,
      7800246,
      7354614,
      7040928,
      6695652,
      6428629,
      6157879,
      5916686,
      5632810,
      5371884
    ]
  },
  {
    "key": 10033995,
    "value": [
      10033995,
      7770161,
      7335351,
      7033979,
      6696636,
      6439197,
      6175037,
      5940848,
      5663585,
      5408925
    ]
  },
  {
    "key": 10061790,
    "value": [
      10061790,
      7732937,
      7310209,
      7022478,
      6694002,
      6447193,
      6190390,
      5963995,
      5694122,
      5446460
    ]
  },
  {
    "key": 10089585,
    "value": [
      10089585,
      7696908,
      7286051,
      7011740,
      6691974,
      6455620,
      6206045,
      5987312,
      5724700,
      5483912
    ]
  },
  {
    "key": 10117380,
    "value": [
      10117380,
      7662028,
      7262839,
      7001735,
      6690528,
      6464461,
      6221991,
      6010793,
      5755315,
      5521285
    ]
  },
  {
    "key": 10145175,
    "value": [
      10145175,
      7631934,
      7243571,
      6994786,
      6691515,
      6475037,
      6239159,
      6034969,
      5786108,
      5558345
    ]
  },
  {
    "key": 10172970,
    "value": [
      10172970,
      7676269,
      7285650,
      7035420,
      6730387,
      6512651,
      6275403,
      6070027,
      5819720,
      5590634
    ]
  },
  {
    "key": 10200765,
    "value": [
      10200765,
      7720604,
      7327728,
      7076054,
      6769259,
      6550265,
      6311648,
      6105085,
      5853332,
      5622924
    ]
  },
  {
    "key": 10228560,
    "value": [
      10228560,
      7764938,
      7369807,
      7116687,
      6808131,
      6587880,
      6347892,
      6140143,
      5886944,
      5655213
    ]
  },
  {
    "key": 10256355,
    "value": [
      10256355,
      7809273,
      7411886,
      7157321,
      6847003,
      6625494,
      6384136,
      6175201,
      5920557,
      5687502
    ]
  },
  {
    "key": 10284150,
    "value": [
      10284150,
      7853893,
      7454236,
      7198216,
      6886125,
      6663350,
      6420613,
      6210484,
      5954385,
      5719999
    ]
  },
  {
    "key": 10311945,
    "value": [
      10311945,
      7898765,
      7496824,
      7239342,
      6925468,
      6701420,
      6457296,
      6245967,
      5988405,
      5752679
    ]
  },
  {
    "key": 10339740,
    "value": [
      10339740,
      7943637,
      7539413,
      7280468,
      6964811,
      6739490,
      6493979,
      6281450,
      6022424,
      5785359
    ]
  },
  {
    "key": 10367535,
    "value": [
      10367535,
      7988509,
      7582001,
      7321594,
      7004153,
      6777561,
      6530663,
      6316932,
      6056444,
      5818040
    ]
  },
  {
    "key": 10395330,
    "value": [
      10395330,
      8033572,
      7624771,
      7362894,
      7043663,
      6815792,
      6567501,
      6352566,
      6090608,
      5850859
    ]
  },
  {
    "key": 10423125,
    "value": [
      10423125,
      8079037,
      7667922,
      7404563,
      7083526,
      6854365,
      6604669,
      6388517,
      6125076,
      5883971
    ]
  },
  {
    "key": 10450920,
    "value": [
      10450920,
      8124501,
      7711073,
      7446232,
      7123388,
      6892938,
      6641837,
      6424468,
      6159545,
      5917083
    ]
  },
  {
    "key": 10478715,
    "value": [
      10478715,
      8169966,
      7754224,
      7487901,
      7163250,
      6931510,
      6679004,
      6460419,
      6194014,
      5950194
    ]
  },
  {
    "key": 10506510,
    "value": [
      10506510,
      8215681,
      7797613,
      7529800,
      7203332,
      6970296,
      6716377,
      6496569,
      6228672,
      5983489
    ]
  },
  {
    "key": 10534305,
    "value": [
      10534305,
      8261791,
      7841376,
      7572060,
      7243761,
      7009416,
      6754072,
      6533030,
      6263630,
      6017071
    ]
  },
  {
    "key": 10562100,
    "value": [
      10562100,
      8307901,
      7885140,
      7614321,
      7284189,
      7048536,
      6791767,
      6569492,
      6298588,
      6050653
    ]
  },
  {
    "key": 10589895,
    "value": [
      10589895,
      8354011,
      7928904,
      7656581,
      7324617,
      7087657,
      6829462,
      6605953,
      6333546,
      6084235
    ]
  },
  {
    "key": 10617690,
    "value": [
      10617690,
      8336765,
      7917670,
      7658459,
      7331471,
      7102356,
      6848789,
      6632211,
      6365042,
      6120023
    ]
  },
  {
    "key": 10645485,
    "value": [
      10645485,
      8293699,
      7884025,
      7643884,
      7324647,
      7107108,
      6860637,
      6654318,
      6395136,
      6156721
    ]
  },
  {
    "key": 10673280,
    "value": [
      10673280,
      8251959,
      7851531,
      7630157,
      7318530,
      7112379,
      6872878,
      6676649,
      6425314,
      6193384
    ]
  },
  {
    "key": 10701075,
    "value": [
      10701075,
      8278963,
      7878858,
      7660751,
      7349469,
      7144983,
      6906000,
      6711187,
      6460517,
      6229030
    ]
  },
  {
    "key": 10728870,
    "value": [
      10728870,
      8325949,
      7923574,
      7704229,
      7391180,
      7185534,
      6945195,
      6749276,
      6497183,
      6264382
    ]
  },
  {
    "key": 10756665,
    "value": [
      10756665,
      8373049,
      7968397,
      7747812,
      7432992,
      7226182,
      6984484,
      6787456,
      6533938,
      6299820
    ]
  },
  {
    "key": 10784460,
    "value": [
      10784460,
      8420950,
      8013984,
      7792136,
      7475515,
      7267522,
      7024441,
      6826286,
      6571317,
      6335860
    ]
  },
  {
    "key": 10812255,
    "value": [
      10812255,
      8468851,
      8059570,
      7836460,
      7518038,
      7308862,
      7064398,
      6865117,
      6608697,
      6371901
    ]
  },
  {
    "key": 10840050,
    "value": [
      10840050,
      8517004,
      8105396,
      7881017,
      7560785,
      7350420,
      7104566,
      6904151,
      6646273,
      6408131
    ]
  },
  {
    "key": 10867845,
    "value": [
      10867845,
      8565276,
      8151334,
      7925684,
      7603637,
      7392079,
      7144832,
      6943281,
      6683942,
      6444450
    ]
  },
  {
    "key": 10895640,
    "value": [
      10895640,
      8613434,
      8197165,
      7970246,
      7646388,
      7433642,
      7185004,
      6982320,
      6721523,
      6480684
    ]
  },
  {
    "key": 10923435,
    "value": [
      10923435,
      8661400,
      8242812,
      8014630,
      7688968,
      7475037,
      7225015,
      7021202,
      6758953,
      6516772
    ]
  },
  {
    "key": 10951230,
    "value": [
      10951230,
      8698619,
      8279398,
      8051833,
      7725453,
      7511678,
      7261568,
      7057319,
      6795198,
      6553539
    ]
  },
  {
    "key": 10979025,
    "value": [
      10979025,
      8653796,
      8246801,
      8034211,
      7715395,
      7512022,
      7271725,
      7072326,
      6822401,
      6595473
    ]
  },
  {
    "key": 11006820,
    "value": [
      11006820,
      8625562,
      8228189,
      8027663,
      7714734,
      7519685,
      7287196,
      7091575,
      6851401,
      6636324
    ]
  },
  {
    "key": 11034615,
    "value": [
      11034615,
      8672300,
      8272774,
      8071161,
      7756537,
      7560431,
      7326682,
      7130001,
      6888526,
      6672284
    ]
  },
  {
    "key": 11062410,
    "value": [
      11062410,
      8719097,
      8317414,
      8114714,
      7798392,
      7601228,
      7366218,
      7168475,
      6925697,
      6708288
    ]
  },
  {
    "key": 11090205,
    "value": [
      11090205,
      8766012,
      8362168,
      8158377,
      7840353,
      7642128,
      7405853,
      7207047,
      6962963,
      6744383
    ]
  },
  {
    "key": 11118120,
    "value": [
      11118120,
      8813281,
      8407260,
      8202370,
      7882631,
      7683337,
      7445788,
      7245910,
      7000509,
      6780752
    ]
  },
  // ... (add the rest of your aggregated frequency distribution data here)
];


// Impression Curve data
export const impressionCurve = [
  6297,
  15789,
  26567,
  37513,
  44914,
  53806,
  59913,
  66683,
  72626,
  77873,
  84393,
  88788,
  94930,
  99827,
  103378,
  107995,
  112197,
  116740,
  120546,
  125307,
  129388,
  133889,
  137953,
  141805,
  145963,
  152256,
  156160,
  161119,
  164777,
  167678,
  172384,
  175708,
  180053,
  183763,
  188076,
  193649,
  198979,
  202298,
  206359,
  209698,
  214952,
  219664,
  224084,
  228239,
  231983,
  235528,
  239621,
  242470,
  246050,
  249445,
  253239,
  256933,
  260993,
  266740,
  269927,
  273514,
  274885,
  277435,
  280064,
  282092,
  285213,
  288242,
];

// Reach Curve data
export const reachCurve = [
  6297, 15604, 25799, 35500, 41869, 49270, 54231, 60025, 65437, 69517,
  74551, 78374, 83304, 87320, 90233, 93948, 96629, 100105, 103012,
  106300, 109448, 113057, 115800, 118610, 121680, 126560, 129664,
  132849, 135058, 136724, 139652, 141304, 143996, 145905, 148199,
  151349, 154614, 156469, 159061, 160978, 164027, 167131, 169352,
  171467, 173585, 175122, 177240, 178589, 180200, 182222, 183667,
  185551, 187456, 190153, 191849, 193511, 194129, 195379, 196482,
  197577, 199016, 200000,
];

export const targetAudienceSize = 13400000;