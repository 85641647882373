//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : fileManagerApi.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (27 Nov 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20241127     Jecyline     Create fileManagerApi.jsx
//
//***************************************************************************/
import { api } from '../services/api'; //apiClient
import API_ENDPOINTS from './endpoints';

export const getFileManagerListApi = (name) => {
  return api.post(API_ENDPOINTS.FILE_MANAGER_LIST(), {name: name});

};

export const getFileChatDetailByIdApi = (chat_id, page) => {

  return api.get(API_ENDPOINTS.FILE_MANAGER_DETAIL_BY_ID(chat_id, page));
};