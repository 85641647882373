//****************************************************************************
//* Filename  : chatActions.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (29 Aug 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240829     Jecyline     Create chatActions.jsx
//* 20240830     Jecyline     add fetchChatMemoryAction
//* 20240905     Jecyline     add chatClickAction, updateReactionAction, storePromptAction, createChatAction, uploadImageAction
//* 20241001     Jecyline     add chatClickExternalAction
//* 20241002     Jecyline     add loadMoreAdsAction
//* 20241004     Jecyline     add createMoreAdsAction
//
//***************************************************************************/

import { createAction } from '@reduxjs/toolkit';

export const chatClickAction = createAction('chat-detail/handleChatClick');
export const chatClickExternalAction = createAction('chat-detail/handleChatClickExternal');
export const fetchChatMemoryAction = createAction('chat-detail/handleFetchChatMemory');
export const updateReactionAction = createAction('chat-detail/handleUpdateReaction');
export const storePromptAction = createAction('chat-detail/handleStorePrompt');
export const fetchMoreChatDetailsAction = createAction('chat-detail/handleFetchMoreChatDetails');
export const createChatAction = createAction('chat-detail/handleCreateChat');

export const cameraAction = createAction('chat-detail/camera');
export const uploadImageAction = createAction('chat-detail/handleUploadImage');
export const selectionModeAction = createAction('chat-detail/handleSelectionMode');
export const loadMoreAdsAction = createAction('chat-detail/handleLoadMoreAds');
export const createMoreAdsAction = createAction('chat-detail/handleCreateMoreAds');
export const selectLocationAction = createAction('chat-detail/handleSelectLocation');
export const createMoreCopyCraftAction = createAction('chat-detail/handleCreateMoreCopyCraft');

export const fetchCountryListAction = createAction('chat-detail/fetchCountryList');

export const updateFavouriteAction = createAction('chat-detail/handleUpdateFavourite');
export const updateContentFlagAction = createAction('chat-detail/handleUpdateContentFlag');
