//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : GoogleLoginButton.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240705     Jecyline     create GoogleLoginButton.jsx
//* 20240717     Jecyline     amend handleLoginSuccess(), get REACT_APP_GOOGLE_CLIENT_ID from env, change plugin to react-oauth/google
//
//***************************************************************************/
import React,  { useEffect, useState }  from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { googleicon } from "../Routes/ImagePath";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { api_url } from "../base_urls";
import { useTranslation } from 'react-i18next';
import { guestApi } from '../services/api';
import { handleApiError } from '../utils/errorHandler'; // Adjust the import path as needed
import { useDispatch } from 'react-redux';

const GoogleLoginButton = ({referralCode, setErrorMessage, setSuccessMessage}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const responseGoogle = async (credentialResponse) => {
    const { credential } = credentialResponse;
    try {

      // const response = await axios.post(api_url + '/auth/google/callback', { id_token: credential, referralCode });
      const response = await guestApi.post('/auth/google/callback', { id_token: credential, referralCode });

      console.log('Google login response:', response);
      const { user } = response.data;

      if (user.status === 1) { // register: pending user
        const redirect = 'first-time-login';
        navigate('/otp', { state: { email: user.email , redirect: redirect } });
        // console.log(user);
        const email = user.email;

        // const response = await axios.post(api_url + '/resend-otp', {
        //   email , redirect
        // });
        const response = await guestApi.post('/resend-otp', { email, redirect });

        const responseData = response.data;
        // console.log(responseData);
        
      } else { //login
        const { access_token, token_type, expires_in } = response.data;

        dispatch({ type: 'RESET_ALL' });
        localStorage.clear();  // Clears all local storage
        sessionStorage.clear();

        localStorage.setItem('access_token', access_token);
        localStorage.setItem('token_type', token_type);
        localStorage.setItem('expires_in', expires_in);
        localStorage.setItem('refresh_token', user.refresh_token);
        localStorage.setItem('feedbackModalShown', 'false');
        localStorage.setItem('user_id', user.id);

        if (user.status === 2) { // first time login user
          navigate('/first-time-login');
        } else if (user.status === 3) { // active user
          navigate('/dashboard');
        }
      }
    } catch (error) {
      console.error('API Error:', error);
      setErrorMessage('')

      window.scrollTo({ top: 0, behavior: 'smooth' });

      handleApiError(error, setErrorMessage, null, t); // Call the error handler function
      
      setTimeout(() => setErrorMessage(''), 10000);
    }
  };
  
  
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <GoogleLogin
        onSuccess={responseGoogle}
        onFailure={(response) => console.log('Login Failed:', response)}
        isSignedIn={true}
        style={{ display: 'none' }} // Hide the default button
        >
        {({ onClick }) => (
          <button className="account-icon-button" onClick={onClick}>
            <img src={googleicon} alt="Google" className="account-img-icon"/>
            Login with Google
          </button>
        )}
      </GoogleLogin>
    </GoogleOAuthProvider>
  );
  
  };
  

export default GoogleLoginButton;


