//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : downloadUtils.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (21 Nov 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20241121     Jecyline     create downloadUtils.jsx
//
//***************************************************************************/

/**
 * Downloads an image from a given URL.
 * @param {string} imageUrl - The URL of the image to download.
 * @param {string} fileName - The name to save the downloaded file as.
 */
export const downloadImage = async (imageUrl, fileName = 'image.jpg') => {
  try {
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();

    URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error('Failed to download image:', error);
  }
};
