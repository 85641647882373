//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : chatSidebar.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240701     Glynnis      Function features short description
//* 20240724     Jecyline     amend chatSidebar, implement reducer and api call to show list of chats
//* 20240725     Jecyline     import scrollToDiv
//* 20240726     Jecyline     language translation
//* 20240801     Jecyline     import more chat reducer function, add jumpToDiv, chat detail pagination
//* 20240805     Jecyline     import setLastChatDetailId chat reducer to set last chat detail id
//* 20240806     Jecyline     amend chatSlice path
//* 20240829     Jecyline     add chat memory
//* 20240830     Jecyline     set chat memory and chat details for selected chat room
//* 20240905     Jecyline     move api and function call to chatSagas
//* 20240919     Glynnis      rename chat to campaign
//
//***************************************************************************/
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import {
//   Avatar_02,
//   Avatar_05,
//   Avatar_09,
//   Avatar_10,
//   User,
// } from "../../Routes/ImagePath";

import useAxiosInterceptors from '../../hooks/useAxiosInterceptors'; // Import the custom hook
import { api } from '../../services/api';
import { useSelector, useDispatch } from 'react-redux';
import { 
  setChatList, 
} from '../../store/slices/chatSlice';
import NewChatModal from '../modelpopup/NewChatModal'; // Import the new modal component
import { scrollToDiv } from '../../utils/scrollChatUtils'; // Adjust the path as needed
import { useTranslation } from 'react-i18next';
import { 
  createChatAction,
  fetchChatMemoryAction,
  chatClickAction
} from '../../store/actions/chatActions';

const ChatSidebar = ({ bottomRef }) => {
  useAxiosInterceptors(); 
  const { t } = useTranslation();

  // const chatList = useSelector((state) => state.chat.chatList);
  const todayChats = useSelector((state) => state.chat.todayChats);
  const yesterdayChats = useSelector((state) => state.chat.yesterdayChats);
  const last7DaysChats = useSelector((state) => state.chat.last7DaysChats);
  const olderDaysChats = useSelector((state) => state.chat.olderDaysChats);
  const currentChatData = useSelector((state) => state.chat.currentChatData);

  const dispatch = useDispatch();
  const [showNewChatModal, setShowNewChatModal] = useState(false);
  const [selectedChatId, setSelectedChatId] = useState('');

  useEffect(() => {
    if (currentChatData == null) document.body.classList.toggle("slide-nav");
    const getChatList = async () => {
      try {
        const dataRes = await api.get('/chat');

        const groupedChats = {
          chatList: dataRes.data.chats,
          today: dataRes.data.today_chats,
          yesterday: dataRes.data.yesterday_chats,
          last7Days: dataRes.data.last_7_days_chats,
          olderDays: dataRes.data.older_days_chats
        };
        
        dispatch(setChatList(groupedChats));
        
      } catch (error) {
        console.error('Failed to fetch chat list:', error);
      }
    };

    getChatList();

  }, [dispatch]);

  useEffect(() => {
    if (currentChatData && currentChatData._id) {
      setSelectedChatId(currentChatData._id);
    }
  }, [currentChatData]);

  const handleChatClick = async (chatId) => {
    if(currentChatData && chatId === currentChatData._id){
      scrollToDiv(bottomRef);
      return;
    }

    /////////////////////////////////////
    // chatAction: chatClickAction
    dispatch(chatClickAction({ 
      chatId
    }));

    /////////////////////////////////////
    // chatAction: fetchChatMemoryAction
    dispatch(fetchChatMemoryAction({ 
      chatId
    }));
    document.body.classList.toggle("slide-nav");
  };

  const handleOpenNewChatModal = () => {
    setShowNewChatModal(true);
  };

  const handleCloseNewChatModal = () => {
    setShowNewChatModal(false);
  };

  const handleCreateChat = async (chatName) => {
    /////////////////////////////////////
    // chatAction: createChatAction
    dispatch(createChatAction({ 
      chat_name: chatName,
      campaign_type: 'Normal',
      brand_from: 'Same',
    }));

    // Close the modal after successful chat creation
    handleCloseNewChatModal();
  };

  return (
    <div>
      <div className="sidebar" id="sidebar">
        <div className="sidebar-inner slimscroll autoscroll">
          <div id="sidebar-menu" className="sidebar-menu">
            <nav className="greedy">
              <ul className="link-item">
                {/* <li>
                  <Link to="/dashboard">
                    <i className="la la-home" /> <span>Back to Dashboard</span>
                  </Link>
                </li> */}
                <li>
                  {/* 
                  <Link 
                    to="#" 
                    onClick={handleOpenNewChatModal}
                  >
                   <i className="fa fa-plus" />  <span>{t('Start a new campaign')}</span>
                  </Link>
                  */}
                  <Link
                    to="/dashboard"
                  >
                    <i className="la la-magic" />
                    <span> {t("dashboard")}</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/chat-list">
                    <span>Chat List</span>
                  </Link>
                </li> */}
                {/* <li className="menu-title">
                  <span> Chat List</span>
                </li> */}

                { todayChats && todayChats.length > 0 && (
                  <>
                    <li className="menu-title">
                      <span> {t('Today')}</span>
                    </li>

                    { todayChats.map((chat) => (
                      // <li key={chat._id} onClick={() => handleChatClick(chat._id)}>
                      <li
                        key={chat._id}
                        onClick={() => handleChatClick(chat._id)}
                        className={chat._id === selectedChatId ? 'active' : ''}
                      >
                        <Link to="#">
                          <span>{chat.name}</span>
                        </Link>
                      </li>
                    ))}
                  </>
                )}

                { yesterdayChats && yesterdayChats.length > 0 && (
                  <>
                    <li className="menu-title">
                      <span> {t('Yesterday')}</span>
                    </li>

                    { yesterdayChats.map((chat) => (
                      // <li key={chat._id} onClick={() => handleChatClick(chat._id)}>
                      <li
                        key={chat._id}
                        onClick={() => handleChatClick(chat._id)}
                        className={chat._id === selectedChatId ? 'active' : ''}
                      >
                        <Link to="#">
                          <span>{chat.name}</span>
                        </Link>
                      </li>
                    ))}
                  </>
                )}
                
                { last7DaysChats && last7DaysChats.length > 0 && (
                  <>
                    <li className="menu-title">
                      <span> {t('Previous 7 Days')}</span>
                    </li>

                    { last7DaysChats.map((chat) => (
                      // <li key={chat._id} onClick={() => handleChatClick(chat._id)}>
                      <li
                        key={chat._id}
                        onClick={() => handleChatClick(chat._id)}
                        className={chat._id === selectedChatId ? 'active' : ''}
                      >
                        <Link to="#">
                          <span>{chat.name}</span>
                        </Link>
                      </li>
                    ))}
                  </>
                )}

                { olderDaysChats && olderDaysChats.length > 0 && (
                  <>
                    <li className="menu-title">
                      <span> {t('Older Days Chats')}</span>
                    </li>

                    { olderDaysChats.map((chat) => (
                      // <li key={chat._id} onClick={() => handleChatClick(chat._id)}>
                      <li
                        key={chat._id}
                        onClick={() => handleChatClick(chat._id)}
                        className={chat._id === selectedChatId ? 'active' : ''}
                      >
                        <Link to="#">
                          <span>{chat.name}</span>
                        </Link>
                      </li>
                    ))}
                  </>
                )}

                {/* <li>
                  <Link to="/file-manager">
                   <span>File Manager</span>
                  </Link>
                </li> */}
              </ul>
            </nav>
          </div>
        </div>
      </div>
      {/* New Chat Modal component */}
      <NewChatModal 
        show={showNewChatModal} 
        onClose={handleCloseNewChatModal} 
        onCreate={handleCreateChat} 
      />
    </div>
  );
};

export default ChatSidebar;
