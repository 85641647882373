//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : urlUtils.js
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (24 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240724     Jecyline     create urlUtils.jsx for image url
//
//***************************************************************************/
import { backend_url } from "../base_urls";

export const getFullImageUrl = (path) => {
  if (!path) {
    // Return a default image URL if the path is empty or invalid
    return `${backend_url}/placeholder.jpg`;
  }

  // Check if the path is already a full URL
  if (path.startsWith('http://') || path.startsWith('https://')) {
    return path;
  }
  // Otherwise, prepend the backend URL
  return `${backend_url}${path}`;
};

export const isValidUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

export const validateFile = (file, t) => {

  if (!file) return { isValid: false, message: `${t('errors.NO_FILE_SELECTED')}` };

  const allowedTypes = ['image/png', 'image/jpeg'];
  if (!allowedTypes.includes(file.type)) {
    return { isValid: false, message: `${t('Only PNG and JPEG files are allowed.')}` };
  }

  const maxSize = 2 * 1024 * 1024; // 2MB
  if (file.size > maxSize) {
    return { isValid: false, message: `${t('File size should not exceed 2MB.')}` };
  }

  return { isValid: true, message: '' };
};
