//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : api.js
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (11 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240711     Jecyline     create api.jsx
//* 20240724     Jecyline     remove alert message
//* 20240805     Jecyline     set feedbackModalShown to localStorage
//* 20240806     Jecyline     remove item localStorage refresh_token and feedbackModalShown during token refresh
//* 20241003     Jecyline     clear all reducer, localstorage and sessionstorage when token expired
//
//***************************************************************************/
import axios from 'axios';
import { api_url } from "../base_urls";

// Create an Axios instance with the base URL for your API
// guest api
const guestApi = axios.create({
  baseURL: api_url,
  headers: {
      'Content-Type': 'application/json',
  },
  // timeout: 20000,
});

// auth api
const api = axios.create({
    baseURL: api_url,
    headers: {
        'Content-Type': 'application/json',
    },
    // timeout: 20000,
});

// Add an interceptor to handle errors globally
api.interceptors.request.use(
    config => {
      const access_token = localStorage.getItem('access_token');
      
      config.headers = {
        ...api.defaults.headers.common,
        ...config.headers,
      };

      // Always set Content-Type if not already set
      if (!config.headers['Content-Type']) {
          config.headers['Content-Type'] = 'application/json';
      }

      if (access_token) {
        config.headers['Authorization'] = `Bearer ${access_token}`;
      } else {
        throw new axios.Cancel('Authorization token missing. Request cancelled.');
      }

      return config;
    },
    error => {
      return Promise.reject(error);
    }
);

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      window.location.href = '/';
    }

    return Promise.reject(error);
  }
);


const setupInterceptors = (navigate, dispatch) => {
  api.interceptors.response.use(
    response => response,
    async (error) => {
      if (error.response && error.response.status === 401) {

        const refreshToken = localStorage.getItem('refresh_token');
        const tokenType = localStorage.getItem('token_type');
        if (refreshToken && tokenType) {
          try {
            // Attempt to refresh the token
            const response = await guestApi.post('/refresh-token', {
                refresh_token: refreshToken,
            });
            const { access_token, token_type, expires_in } = response.data;
            
            // Update local storage with new token data
            localStorage.setItem('access_token', access_token);
            localStorage.setItem('token_type', token_type);
            localStorage.setItem('expires_in', expires_in);

            // Retry the failed request with the new token
            error.config.headers['Authorization'] = `Bearer ${access_token}`;
            return axios.request(error.config);
          } catch (refreshError) {
            console.error('Token refresh failed:', refreshError);
            
            // localStorage.removeItem('access_token');
            // localStorage.removeItem('token_type');
            // localStorage.removeItem('expires_in');
            // localStorage.removeItem('refresh_token');
            // localStorage.removeItem('feedbackModalShown');

            dispatch({ type: 'RESET_ALL' });
            localStorage.clear();  // Clears all local storage
            sessionStorage.clear();  // Clears session storage
            // alert('Token refresh failed. Please log in again.');
            navigate('/');
          }
        } else {
          console.error('Invalid token. Please log in again.');

          // localStorage.removeItem('access_token');
          // localStorage.removeItem('token_type');
          // localStorage.removeItem('expires_in');
          // localStorage.removeItem('refresh_token');
          // localStorage.removeItem('feedbackModalShown');

          dispatch({ type: 'RESET_ALL' });
          localStorage.clear();  // Clears all local storage
          sessionStorage.clear();  // Clears session storage
          // alert('Invalid Token. Please log in again.');
          navigate('/');
        }
      }

      console.error('API Error:', error);
      return Promise.reject(error);
    }
  );
};

// Function to handle standard HTTP errors
// const handleStandardError = (status) => {
//   switch (status) {
//     case 400:
//       alert('Invalid request. Please check your input.');
//       break;
//     case 401:
//       alert('Unauthorized. Please log in.');
//       //PENDING::remove local storage item and then go to login page
//       window.location.href = '/';
//       break;
//     case 403:
//       alert('Access forbidden. You do not have permission to view this page.');
//       break;
//     case 404:
//       alert('Resource not found.');
//       break;
//     case 500:
//       alert('Server error. Please try again later.');
//       break;
//     case 502:
//       alert('Temporary server issue. Please try again later.');
//       break;
//     case 503:
//       alert('Service is unavailable at the moment. Please try again later.');
//       break;
//     case 504:
//       alert('Request timed out. Please try again.');
//       break;
//     default:
//       alert('An unexpected error occurred. Please try again.');
//   }
// };

// // Function to handle custom errors
// const handleCustomError = (errCode) => {
//   switch (errCode) {
//     case '401_':
//       alert('Validation error: Please check your inputs and try again.');
//       break;
//     case 600:
//       alert('Validation error: Please check your inputs and try again.');
//       break;
//     case 601:
//       alert('Service constraint: Your action cannot be completed due to policy.');
//       break;
//     case 999:
//       alert('An unknown error occurred. Please contact support.');
//       break;
//     default:
//       // alert(`An application-specific error occurred (code: ${errCode}).`);
//   }
// };

export { guestApi, api, setupInterceptors };

