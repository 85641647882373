//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : imageModal.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (12 October 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20241012     Jecyline     create imageModal.jsx for manage image
//
//***************************************************************************/
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from '../../../../../services/api';
import { getFullImageUrl } from '../../../../../utils/urlUtils';
import MarkdownRenderer from '../../../../../utils/MarkdownRenderer';
import useAxiosInterceptors from '../../../../../hooks/useAxiosInterceptors';


const CreativeSelectionModal = ({ isOpen, onClose, chatId, modalType, onSubmit }) => {
  useAxiosInterceptors(); 
  const { t } = useTranslation();

  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState('');
  const [oriItem, setOriItems] = useState('');

  useEffect(() => {
    setSelectedItems('');
    setItems([]);

    if (isOpen) {
      const fetchData = async () => {
        try {

          const response = await api.post(`/chat-detail/creative-list/${chatId}`, { modalType });
          console.log('response',response);
          const creativeList = response.data.creativeList;


          const initiallySelected = creativeList
          .filter(item => {
            if (modalType === 'isImage') {
              return item.isImage === 1; 
            } else if (modalType === 'isCopy') {
              return item.isCopy === 1; 
            }
            return false;
          })
          .map(item => item._id);

          const initialItems = initiallySelected.length === 0 ? '' : initiallySelected[0];

          setSelectedItems(initialItems);  
          setOriItems(initialItems);
          setItems(creativeList);

        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }
  }, [isOpen, chatId, modalType]);

  const handleCheckboxChange = (itemId) => {
    console.log('handleCheckboxChange', itemId);
    console.log('handleCheckboxChange', selectedItems);

    if(selectedItems === itemId){
      setSelectedItems('');

    } else {
      setSelectedItems(itemId);
    }
  };

  const handleSubmit = () => {
    let chat_detail_id = selectedItems;
    let value = 1;

    if(selectedItems.length === 0) {  //set the 
      value = 0;
      chat_detail_id = oriItem;
    }

    onSubmit(chat_detail_id, modalType, value);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div>
      {/* Backdrop */}
      <div className={`modal-backdrop ${isOpen ? 'show' : ''}`} onClick={onClose}></div>

      {/* Modal */}
      <div className={`modal custom-modal creative-selection-modal ${isOpen ? 'show d-block' : 'd-none'} fade`} tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {modalType === 'isImage' ? t('Image Selections') : t('Copy Selections')}
              </h5>
              <button 
                type="button"
                className="btn-close" 
                onClick={() => {
                  onClose();
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="creative-selection-body">
                {items.map(item => (
                  <div key={item._id} className="item">
                    {modalType === 'isImage' ? (
                      <label className="creative-copy-wrap">
                        <input
                          type="checkbox"
                          onChange={() => handleCheckboxChange(item._id)}
                          checked={selectedItems.includes(item._id)}
                          className="hidden-checkbox"
                        />
                        <div className={`chat-sidebar-collection-image ${selectedItems.includes(item._id) ? 'selected' : ''}`}>
                          <img 
                            src={getFullImageUrl(item.reply_url)} 
                            alt={`Collection ${item._id}`} 
                          />
                        </div>
                      </label>
                    ) : (
                      <label className="creative-copy-wrap">
                        <input
                          type="checkbox"
                          onChange={() => handleCheckboxChange(item._id)}
                          checked={selectedItems.includes(item._id)}
                          className="hidden-checkbox"
                        />
                        <div className={`chat-sidebar-collection-copy ${selectedItems.includes(item._id) ? 'selected' : ''}`}>
                          {/* <p>{item._id}</p> */}
                          <span>
                            <MarkdownRenderer content={item.reply} />
                          </span>
                        </div>
                      </label>
                    )}

                  </div>
                ))}
              </div>
            </div>
            <div className="submit-section">
              <button type="button" className="btn btn-primary mx-1 mb-2" 
                onClick={handleSubmit}
              >
                {t('Submit')} 
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreativeSelectionModal;
