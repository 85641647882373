//****************************************************************************
//* Filename  : fileManagerSagas.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (27 Nov 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20241127     Jecyline     Create fileManagerSagas.jsx
//
//***************************************************************************/
import { call, put, takeLatest, delay, all, select } from 'redux-saga/effects';
import { 
  getFileChatListAction,
  fileChatClickAction,
  fetchMoreFileChatDetailsAction,
  externalFileChatClickAction,
} from '../actions/fileManagerActions';
import { 
  setFileChatList, 
  setSelectedFileChatId,
  setFileCurrentChatData,
  setFileCurrentChatDetails,
  setFileCurrentPage,
  appendFileCurrentChatDetails,
  setFileHasMore,
  setFileIsLoadingChatDetails,
} from '../slices/fileManagerSlice';
import { 
  selectFileChatData
} from '../selectors/fileManagerSelectors';
import { 
  getFileManagerListApi,
  getFileChatDetailByIdApi,
} from '../../api/fileManagerApi';

function* handleGetFileChatList(action) {
  console.log('fileManagerSagas: handleGetFileChatList', action);
  const { name } = action.payload;

  try {
    const response = yield call(getFileManagerListApi, name);
    const { chats } = response.data;

    yield put(setFileChatList(chats));

  } catch (error) {
    console.error('handleGetFileChatList Saga Error:', error);

  }
}

function* handleExternalFileChatClick(action) {
  console.log('fileManagerSagas: handleFileChatClick', action);

  const { chatId } = action.payload;

  try {
    const name= '';
    const response = yield call(getFileManagerListApi, name);
    const { chats } = response.data;

    yield put(setFileChatList(chats));
      
    yield call(handleFileChatClick, { payload: { chatId } });
  } catch (error) {
    console.error('handleFileChatClick Saga Error:', error);

  }
}
function* handleFileChatClick(action) {
  console.log('fileManagerSagas: handleFileChatClick', action);

  const { chatId } = action.payload;

  try {
    const currentFileChatData = yield select(selectFileChatData);

    if(!currentFileChatData || (currentFileChatData && chatId !== currentFileChatData._id)){

      yield put(setFileCurrentChatData(null));
      yield put(setFileCurrentChatDetails([]));
      yield put(setSelectedFileChatId(chatId));

      yield put(setFileCurrentPage(1));
      yield put(setFileHasMore(true));
      yield put(setFileIsLoadingChatDetails(true));

      yield call(handleFetchMoreFileChatDetails, { payload: { chatId, page: 1 } });
    }
  } catch (error) {
    console.error('handleFileChatClick Saga Error:', error);

  }
}

function* handleFetchMoreFileChatDetails(action) {
  console.log('fileManagerSagas: handleFetchFileChatDetails', action);

  const { chatId, page } = action.payload;
  try {
    
    const response = yield call(getFileChatDetailByIdApi, chatId, page);
    const { chat, todayChatDetails, olderChatDetails, current_page, last_page } = response.data;

    yield put(setFileCurrentPage(current_page));

    if(page === 1) {
      if(chatId === 'all'){
        yield put(setFileCurrentChatData({_id: 'all', name: 'All Campaigns'}));
      } else {
        yield put(setFileCurrentChatData(chat));
      }
    }

    yield put(appendFileCurrentChatDetails({ chat_id: chatId, today: todayChatDetails, older: olderChatDetails }));

    if (Number(current_page) >= Number(last_page)) {
      yield put(setFileHasMore(false)); // No more pages to load
    }

    yield put(setFileIsLoadingChatDetails(false));

  } catch (error) {
    console.error('handleFetchFileChatDetails Saga Error:', error);
    yield put(setFileIsLoadingChatDetails(false));
  }
}

// Root Saga for File Manager
export function* fileManagerSagas() {
  yield takeLatest(getFileChatListAction.type, handleGetFileChatList);
  yield takeLatest(fileChatClickAction.type, handleFileChatClick);
  yield takeLatest(fetchMoreFileChatDetailsAction.type, handleFetchMoreFileChatDetails);
  yield takeLatest(externalFileChatClickAction.type, handleExternalFileChatClick);

}

export default fileManagerSagas;


