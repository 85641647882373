//****************************************************************************
//* Filename  : campaignSummaryActions.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 Oct 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20241001     Jecyline     Create campaignSummaryActions.jsx
//
//***************************************************************************/

import { createAction } from '@reduxjs/toolkit';

export const getCampaignListAction = createAction('campaign-summary/handleGetCampaignList');
export const campaignClickAction = createAction('campaign-summary/handleCampaignClick');
export const openChatRoomAction = createAction('campaign-summary/handleOpenChatRoom');


