//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : index.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 Oct 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20241001     Jecyline     Add index.s file for Campaign Summary
//
//***************************************************************************/
import React, { Suspense, useState, useEffect } from 'react';
import useAxiosInterceptors from '../../../../hooks/useAxiosInterceptors'; // Import the custom hook
import { useTranslation } from 'react-i18next';

const PolotnoDisplayView = React.lazy(() => import('./polotnoDisplayView'));

const PolotnoDisplay = () => {
  useAxiosInterceptors(); 
  const { t } = useTranslation();

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <Suspense fallback={<div>{t('Loading...')}</div>}>
                <PolotnoDisplayView />
              </Suspense>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    </>
  );
};




export default PolotnoDisplay;
