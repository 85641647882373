//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : PersonalInformationModal.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240701     Glynnis      Function features short description
//* 20240716     Jecyline     fetch and update user profile
//* 20240718     Jecyline     language selection module
//* 20240822     Jecyline     use tarun api to get country list
//* 20240906     Glynnis      Revamp UI
//
//***************************************************************************/
import React, { useEffect, useState } from "react";
import Select from "react-select";
import axios from "axios";
import useAxiosInterceptors from '../../../../../hooks/useAxiosInterceptors';
// import countryList from 'react-select-country-list';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { api } from '../../../../../services/api';
import { passwordrgx } from "../../../../../utils/RegEx";
import { useForm, Controller } from "react-hook-form";
import { Modal, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { handleApiError } from '../../../../../utils/errorHandler'; // Adjust the import path as needed

const PersonalInformationModal = ({ show, handleClose, onUpdate, initialData, showSuccessMessage }) => {
  // Call the custom hook to set up interceptors
  useAxiosInterceptors();
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`${t('Name')}${t(' is required')}`),
    phone_no: Yup.string().required(`${t('Phone No')}${t(' is required')}`),
    company_country_code: Yup.string().required(`${t('Country')}${t(' is required')}`),
    birth_year: Yup.string()
      .required(`${t('Birth Year')}${t(' is required')}`)
      .matches(/^\d+$/, `${t('Birth Year')}${t(' must be numeric')}`),
    password: Yup.string().when('new_password', {
      is: (value) => value && value.length > 0,
      then: schema => schema.required(`${t('Password')}${t(' is required')}`),
      otherwise: schema => schema.notRequired(),
    }),
    new_password: Yup.string()
      .matches(passwordrgx, {
        message: `${t('New Password')} ${t(' must be 8-20 characters long, include at least one uppercase letter, one digit, and one special character')}`,
        excludeEmptyString: true
      })
      .notRequired() // Not required initially
      .trim(),
    new_password_confirmation: Yup.string()
      .oneOf([Yup.ref('new_password'), null], `${t('Password must match with new password')}`)
      .when('new_password', {
        is: (value) => value && value.length > 0,
        then: schema => schema.required(`${t('Please confirm your new password')}`)
      })
  });
  
  const [serverErrors, setServerErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [passwordEye, setPasswordEye] = useState(true); // State for password field
  const [newPasswordEye, setNewPasswordEye] = useState(true); // State for mew password field
  const [newPasswordConfirmationEye, setNewPasswordConfirmationEye] = useState(true); // State for new password confirmation field
  const [countryOptions, setCountryOptions] = useState([]);

  useEffect(() => {
    const fetchCountryList = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_MARVIS_AI}/countries`);
        // console.log('response',response);

        const countryList = response.data.all_countries;
        
        const countries = countryList.map(country => ({
          value: country.cca2,
          label: country.name.common,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
        
        setCountryOptions(countries);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchCountryList();

  }, []);
  

  // Initialize the form with react-hook-form and Yup resolver
  const { 
    control, 
    handleSubmit, 
    setValue, 
    reset,
    formState: { errors } 
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: '',
      email: '',
      phone_no: '',
      company_country_code: '',
      birth_year: '',
      password: '',
      new_password: '',
      new_password_confirmation: '',
    }
  });

  useEffect(() => {
    // Update form values when initialData changes
    if (initialData) {
      setValue('name', initialData.name);
      setValue('email', initialData.email);
      setValue('phone_no', initialData.phone_no);
      setValue('company_country_code', countryOptions.find((opt) => opt.value === initialData?.company_country_code)?.value);
      setValue('birth_year', initialData.birth_year);
    }
  }, [initialData, setValue]);

  const onSubmit = async (data) => {  
    // console.log(data);
    setIsSubmitting(true);
    try { //update profile
      await api.put(`/profile`, data);      

      onUpdate({ id: 'name', text: data.name });
      onUpdate({ id: 'phone_no', text: data.phone_no });
      onUpdate({ id: 'company_country_code', text: countryOptions.find((opt) => opt.value === data.company_country_code)?.label || '' });
      onUpdate({ id: 'birth_year', text: data.birth_year });

      reset({
        name: '',
        email: initialData?.email || '',
        phone_no: '',
        company_country_code: '',
        birth_year: '',
        password: '',
        new_password: '',
        new_password_confirmation: '',
      });

      setServerErrors({});

      handleClose(); // Close the modal on success
      showSuccessMessage(`${t('Profile info')} ${t(' updated successfully')}`); // Call the success message handler

    } catch (error) {
      console.error('API Error:', error);
      setErrorMessage('')
      setServerErrors({})

      handleApiError(error, setErrorMessage, setServerErrors, t); // Call the error handler function
      setTimeout(() => setErrorMessage(''), 10000);
      setTimeout(() => setServerErrors({}), 10000);

    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal className="custom-modal" show={show} onHide={handleClose} centered size="lg">
      <Modal.Header>
        <Modal.Title>{t('Personal Information')}</Modal.Title>
        <button type="button" className="btn-close" onClick={handleClose}>
                &times;
              </button>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && (
          <Alert variant="danger">
            {errorMessage}
          </Alert>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-6">
              <div className="input-block mb-3">
                <label className="col-form-label">{t('Name')}</label>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <input
                      className={`form-control`}
                      type="text"
                      {...field}
                      autoComplete="false"
                    />
                  )}
                />
                <span className="text-danger d-block">
                  {errors.name?.message}
                  {serverErrors.name && <p>{serverErrors.name[0]}</p>}
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-block mb-3">
                <label className="col-form-label">{t('Email')}</label>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <input
                      className={`form-control`}
                      type="text"
                      {...field}
                      autoComplete="false"
                      disabled
                    />
                  )}
                />
                <span className="text-danger d-block">
                  {errors.email?.message}
                  {serverErrors.email && <p>{serverErrors.email[0]}</p>}
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-block mb-3">
                <label className="col-form-label">{t('Phone No')}</label>
                <Controller
                  name="phone_no"
                  control={control}
                  render={({ field }) => (
                    <input
                      className={`form-control`}
                      type="text"
                      {...field}
                      autoComplete="false"
                    />
                  )}
                />
                <span className="text-danger d-block">
                  {errors.phone_no?.message}
                  {serverErrors.phone_no && <p>{serverErrors.phone_no[0]}</p>}
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-block mb-3">
                <label className="col-form-label">{t('Country')} <span className="text-danger">*</span></label>
                <Controller
                  name="company_country_code"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={countryOptions}
                      placeholder="Select your country"
                      classNamePrefix="react-select"
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption ? selectedOption.value : null);
                      }}
                      value={countryOptions.find((option) => option.value === field.value)}
                      isClearable
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-block mb-3">
                <label className="col-form-label">{t('Birth Year')}</label>
                <Controller
                  name="birth_year"
                  control={control}
                  render={({ field }) => (
                    <input
                      className={`form-control`}
                      type="text"
                      {...field}
                      autoComplete="false"
                    />
                  )}
                />
                <span className="text-danger d-block">
                  {errors.birth_year?.message}
                  {serverErrors.birth_year && <p>{serverErrors.birth_year[0]}</p>}
                </span>
              </div>
            </div>
          </div>
          <hr />
          <div className="col-md-12">
            <div className="input-block mb-3">
              <label className="col-form-label">{t('Current Password')}</label>
              {/* <input type="text" className="form-control" /> */}
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <div
                    className="pass-group"
                    style={{ position: "relative" }}
                  >
                    <input
                      className={`form-control`}
                      type={passwordEye ? "password" : "text"}
                      {...field}
                      autoComplete="false"
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: "5%",
                        top: "30%",
                      }}
                      onClick={() => setPasswordEye(!passwordEye)}
                      className={`fa toggle-password ${
                        passwordEye ? "fa-eye-slash" : "fa-eye"
                      }`}
                    />
                  </div>
                )}
                defaultValue=""
              />
              <span className="text-danger d-block">
                {errors.password?.message}
                {serverErrors.password && <p>{t(`errors.${serverErrors.password[0]}`)}</p>}
              </span>
            </div>
          </div>
          <div className="col-md-12">
            <div className="input-block mb-3">
              <label className="col-form-label">{t('New Password')}</label>
              <Controller
                name="new_password"
                control={control}
                render={({ field }) => (
                  <div
                    className="pass-group"
                    style={{ position: "relative" }}
                  >
                    <input
                      className={`form-control`}
                      type={newPasswordEye ? "password" : "text"}
                      {...field}
                      autoComplete="false"
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: "5%",
                        top: "30%",
                      }}
                      onClick={() => setNewPasswordEye(!newPasswordEye)}
                      className={`fa toggle-password ${
                        newPasswordEye ? "fa-eye-slash" : "fa-eye"
                      }`}
                    />
                  </div>
                )}
                defaultValue=""
              />
              <span className="text-danger d-block">
                {errors.new_password?.message}
                {serverErrors.new_password && <p>{serverErrors.new_password[0]}</p>}
              </span>
            </div>
          </div>
          <div className="col-md-12">
            <div className="input-block mb-3">
              <label className="col-form-label">{t('Confirm New Password')}</label>
              <Controller
                name="new_password_confirmation"
                control={control}
                render={({ field }) => (
                  <div
                    className="pass-group"
                    style={{ position: "relative" }}
                  >
                    <input
                      className={`form-control`}
                      type={newPasswordConfirmationEye ? "password" : "text"}
                      {...field}
                      autoComplete="false"
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: "5%",
                        top: "30%",
                      }}
                      onClick={() => setNewPasswordConfirmationEye(!newPasswordConfirmationEye)}
                      className={`fa toggle-password ${
                        newPasswordConfirmationEye ? "fa-eye-slash" : "fa-eye"
                      }`}
                    />
                  </div>
                )}
                defaultValue=""
              />
              <span className="text-danger d-block">
                {errors.new_password_confirmation?.message}
                {serverErrors.new_password_confirmation && <p>{serverErrors.new_password_confirmation[0]}</p>}
              </span>
            </div>
          </div>
          <div className="submit-section">
            <button
              className="btn btn-primary submit-btn"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? t('Updating profile...') : t('Submit')}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default PersonalInformationModal;
