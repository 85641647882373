//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : rootSaga.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (29 Aug 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240829     Jecyline      create rootSaga.jsx for chat reducer
//* 20241001     Jecyline      import campaignSummarySagas
//
//***************************************************************************/
import { all } from 'redux-saga/effects';
import chatSagas from './sagas/chatSagas';
import campaignSummarySagas from './sagas/campaignSummarySagas';
import fileManagerSagas from './sagas/fileManagerSagas';

export default function* rootSaga() {
  yield all([
    chatSagas(),
    campaignSummarySagas(),
    fileManagerSagas(),
    // other sagas
  ]);
}
