import moment from 'moment';
import { format, isToday, isThisMinute, isYesterday } from 'date-fns';

//dateTime format: '2024-11-14T03:48:34.406Z'
//result: '14 Nov 2024 03:48 AM'
export const formatDateTime = (dateTime, t) => {

  if (!dateTime || dateTime == null) {
    return ""; // or any other default value you'd prefer
  }

  const formattedDate = moment(dateTime).format('d MMM yyyy h:mm A');

  return formattedDate
  .replace(/AM/g, `${t('AM')}`)
  .replace(/PM/g, `${t('PM')}`);
};

export const formatDate = (dateTime, t) => {
  const date = new Date(dateTime);
  
  let formattedDate;

  if (isToday(date)) {
    formattedDate = `${t('Today')}`;
  } else if (isYesterday(date)) {
    formattedDate = `${t('Yesterday')}`;
  } else {
    formattedDate = `${format(date, 'd MMM yyyy')}`;
  }

  return formattedDate;
};

export const formatTime = (time, t) => {
  // Check if time is null or undefined
  if (time == null) {
    return ""; // or any other default value you'd prefer
  }

  // Parse the input time and format it to "hh.mm A" (12-hour format with AM/PM)
  const formattedTime = moment(time, "HH:mm:ss", true).isValid() 
    ? moment(time, "HH:mm:ss").format("h:mm A")
    : ""; // Return a fallback if the time format is incorrect

  return formattedTime
  .replace(/AM/g, `${t('AM')}`)
  .replace(/PM/g, `${t('PM')}`);
};

