//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : Login.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240701     Glynnis      create Login.jsx
//* 20240705     Jecyline     import FacebookLoginButton & GoogleLoginButton, handleSubmit() - do status checking
//* 20240712     Jecyline     simplify redundant codes, add refresh-token to local storage, navigate to first time login page for user with status = 2
//* 20240718     Jecyline     language selection module
//* 20240805     Jecyline     set feedbackModalShown to localStorage
//* 20240806     Jecyline     amend way to set refresh_token to local storage
//* 20240902     Glynnis      revamp UI
//* 20241010     Chris        revamp UI (tuned the font size , input field size and gap , separate to 2 section : left login , right Cover image)
//* 20241112     Glynnis      revamp UI 
//***************************************************************************/
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Applogo } from "../../../Routes/ImagePath";
import { CoverPhoto } from "../../../Routes/ImagePath";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import axios from 'axios';
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import { api_url } from "../../../base_urls";
import { guestApi } from '../../../services/api';
import { handleApiError } from '../../../utils/errorHandler'; // Adjust the import path as needed

import FacebookLoginButton from "../../../components/FacebookLoginButton";
import GoogleLoginButton from "../../../components/GoogleLoginButton";
// import { useDispatch } from "react-redux";
// import { login } from "../../../user";
// import { resetFunctionwithlogin } from "../../../components/ResetFunction";
// import { login } from "../../../user";
import LanguageSelector from '../../layout/LanguageSelector';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .max(20, "Password must be at most 20 characters")
    .required("Password is required"),
});

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // const response = await axios.post(api_url + '/login', { email, password });
      const response = await guestApi.post('/login', { email, password });

      const { access_token, token_type, expires_in, user } = response.data;

      if (user.status === 1) { //pending verification user

        const redirect = 'first-time-login';
        navigate('/otp', { state: { email: user.email , redirect: redirect } });

        // const response = await axios.post(api_url + '/resend-otp', {
        //   email , redirect
        // });
        const response = await guestApi.post('/resend-otp', { email, redirect });

        const responseData = response.data;
        // console.log(responseData);
        // setSuccessMessage(responseData.message);
        // setTimeout(() => setSuccessMessage(''), 5000);

      } else {
        dispatch({ type: 'RESET_ALL' });
        localStorage.clear();  // Clears all local storage
        sessionStorage.clear();
        
        localStorage.setItem('access_token', access_token);
        localStorage.setItem('token_type', token_type);
        localStorage.setItem('expires_in', expires_in);
        localStorage.setItem('refresh_token', user.refresh_token);
        localStorage.setItem('feedbackModalShown', 'false');
        localStorage.setItem('user_id', user._id);

        console.log("User ID stored in localStorage:", user._id);

        if (user.status === 2) { // first time login user
          navigate('/first-time-login');
        } else if (user.status === 3) { // active user
          navigate('/dashboard');
        }
      }
    }  
    catch (error) {
      console.error('API Error:', error);
      setErrorMessage('')

      window.scrollTo({ top: 0, behavior: 'smooth' });

      handleApiError(error, setErrorMessage, null, t); // Call the error handler function

      setTimeout(() => setErrorMessage(''), 10000);

    } finally {
      setIsSubmitting(false);
    }
  };

  const [eye, seteye] = useState(true);

  const onEyeClick = () => {
    seteye(!eye);
  };

  return (
    <div>
      <div className="account-page">
        {/* /Login Section */}
        <div className="login-wrapper">
          <div className="main-wrapper">
            <div className="account-content">
              
              <div className="container front-div">
                {/* Account Logo */}
                <div className="account-logo">
                  <Link to="/">
                    <img src={Applogo} alt="Omninexus" />
                  </Link>
                </div>
                {/* /Account Logo */}
                <div className="account-box">
                  <div className="account-wrapper">
                    {/* <LanguageSelector /> */}
                    <p className="account-box-title">{t('Welcome Back')}</p>
                    <p className="account-box-subtitle">{t('Enter your credentials to access your account')}</p>

                    {/* Message Display */}
                    {successMessage && (
                      <div className="alert alert-success" role="alert">
                        {successMessage}
                      </div>
                    )}
                    {errorMessage && (
                      <div className="alert alert-danger" role="alert">
                        {errorMessage}
                      </div>
                    )}
                    {/* /Message Display */}
                    {/* Account Form */}
                    <div>
                      <form onSubmit={handleSubmit}>
                        <div className="input-block mb-4">
                          <label className="col-form-label">{t('Email')}</label>
                          <div style={{ position: "relative" }}>
                            <span
                              style={{
                                position: "absolute",
                                left: "5%",
                                top: "50%",
                                transform: "translateY(-50%)",
                                color: "#ccc",
                              }}
                              className="fa fa-envelope" // Add your desired icon class here
                            ></span>
                            <Controller
                              type="text"
                              name="email"
                              control={control}
                              render={({ field }) => (
                                <input
                                  className={`form-control ${
                                    error?.email ? "error-input" : ""
                                  }`}
                                  type="text"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  autoComplete="true"
                                  placeholder="Email"
                                  required
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="input-block mb-4">
                          <div className="row">
                            <div className="col">
                              <label className="col-form-label">{t('Password')}</label>
                            </div>
                          </div>
                          <div style={{ position: "relative" }}>
                            <Controller
                              type="password"
                              name="password"
                              control={control}
                              render={({ field }) => (
                                <input
                                  // className="form-control"
                                  className={`form-control ${
                                    error?.password ? "error-input" : ""
                                  }`}
                                  type={eye ? "password" : "text"}
                                  value={password} 
                                  placeholder="password"
                                  onChange={(e) => setPassword(e.target.value)}
                                  required
                                />
                              )}
                            />
                            <div className="col-auto">
                              <Link className="text-muted" to="/forgot-password">
                                {t('Forgot Password')}?
                              </Link>
                            </div>
                            <span
                              style={{
                                position: "absolute",
                                right: "5%",
                                top: "25%",
                              }}
                              onClick={onEyeClick}
                              className={`eye-icon fa-solid ${
                                eye ? "fa-eye-slash" : "fa-eye"
                              } `}
                            />
                            <span
                              style={{
                                position: "absolute",
                                left: "5%",
                                top: "25%",
                                color: "#ccc",
                              }}
                              className="eye-icon fa fa-unlock-alt"
                            />
                          </div>
                          <span className="text-danger">
                            {" "}
                            {error && <p style={{ color: 'red' }}>{error}</p>}
                          </span>
                        </div>
                        <div className="input-block text-center">
                          <button
                            className="btn btn-primary account-btn"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? t('Securing Your Access...') : t('Login')}

                          </button>
                        </div>
                        
                      </form>
                      
                      <div className="input-block" style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                        <div className="horizontal-line"/>
                        <span style={{ color: '#9A9797', padding: '0 5px' }}>{t('OR')}</span>
                        <div className="horizontal-line"/>
                      </div>
                      {/* <div className="input-block text-center">
                        <button
                          className="btn account-icon-button"
                          type="button"
                        >
                          <i className="fab fa-apple account-icon"></i>
                          <div className="account-text">Sign in with Apple</div>
                        </button>
                      </div>  */}
                      {/* <div className="input-block text-center input-block text-center">
                        <FacebookLoginButton 
                          referralCode=''
                          setErrorMessage={setErrorMessage}
                          setSuccessMessage={setSuccessMessage}
                        />
                      </div> */}
                      <div className="input-block text-center">
                        <GoogleLoginButton 
                          referralCode=''
                          setErrorMessage={setErrorMessage}
                          setSuccessMessage={setSuccessMessage}
                        />
                      </div>
                      
                    </div>
                    {/* /Account Form */}
                  </div>
                  <div className="account-footer mb-4">
                    <p>
                      {t("Don't have an account yet")}?{" "}
                      <Link to="/register"> {t('Register')}</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /End Login Section */}
        
      </div>
    </div>
  );
};

export default Login;
