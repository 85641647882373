//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : Breadcrumbs.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240701     Glynnis      create Breadcrumbs.jsx
//* 20240712     Jecyline     add path /company-info
//* 20240715     Jecyline     add path /affiliate
//* 20240724     Jecyline     add language translation
//* 20240726     Jecyline     new chat button trigger create chat modal
//* 20240919     Glynnis      rename chat to campaign
//
//***************************************************************************/
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Breadcrumbs = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  let addButton = null;
  if (
    location.pathname === "/form-basic-inputs" ||
    location.pathname === "/form-input-groups" ||
    location.pathname === "/form-horizontal" ||
    location.pathname === "/form-vertical" ||
    location.pathname === "/form-mask" ||
    location.pathname === "/form-validation" ||
    location.pathname === "/tables-basic" ||
    location.pathname === "/data-tables" ||
    location.pathname === "/performance" ||
    location.pathname === "/payments" ||
    location.pathname === "/ui/components" ||
    location.pathname === "/adminattendance" ||
    location.pathname === "/attendance-employee" ||
    location.pathname === "/task-board" ||
    location.pathname === "/leads" ||
    location.pathname === "/create-estimate" ||
    location.pathname === "/edit-estimate" ||
    location.pathname === "/create-invoice" ||
    location.pathname === "/edit-invoice" ||
    location.pathname === "/payments" ||
    location.pathname === "/payroll-items" ||
    location.pathname === "/activities" ||
    location.pathname === "/expense-reports" ||
    location.pathname === "/invoice-reports" ||
    location.pathname === "/payments-reports" ||
    location.pathname === "/task-reports" ||
    location.pathname === "/user-reports" ||
    location.pathname === "/payslip-reports" ||
    location.pathname === "/attendance-reports" ||
    location.pathname === "/user-dashboard" ||
    location.pathname === "/user-all-jobs" ||
    location.pathname === "/saved-jobs" ||
    location.pathname === "/interviewing" ||
    location.pathname === "/offered-jobs" ||
    location.pathname === "/visited-jobs" ||
    location.pathname === "/knowledgebase" ||
    location.pathname === "/questions" ||
    location.pathname === "/jobs-dashboard" ||
    location.pathname === "/manage-resumes" ||
    location.pathname === "/shortlist-candidates" ||
    location.pathname === "/offer_approvals" ||
    location.pathname === "/schedule-timing" ||
    location.pathname === "/apptitude-result" ||
    location.pathname === "/job-aptitude" ||
    location.pathname === "/applied-jobs" ||
    location.pathname === "/offered-jobs" ||
    location.pathname === "/archived-jobs" ||
    location.pathname === "/subscriptions-company" ||
    location.pathname === "/subscribed-companies" ||
    location.pathname === "/profile" ||
    location.pathname === "/company-info" ||
    location.pathname === "/affiliate" ||
    location.pathname === "/job-list" ||
    location.pathname === "/job-view" ||
    location.pathname === "/search" ||
    location.pathname === "/faq" ||
    location.pathname === "/blank-page" ||
    location.pathname === "/user-asset-details" ||
    location.pathname === "/leaves-setting" ||
    location.pathname === "/project-reports" ||
    location.pathname === "/client-profile"
  ) {
    addButton = (
      <div className="row">
        <div className="col">
          <h3 className="page-title">{props.maintitle}</h3>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">{props.title}</Link>
            </li>
            <li className="breadcrumb-item active">{props.subtitle}</li>
          </ul>
        </div>
      </div>
    );
  } else {
    addButton = (
      <div className="row align-items-center">
        <div className="col">
          <h3 className="page-title">{props.maintitle}</h3>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">{props.title}</Link>
            </li>
            <li className="breadcrumb-item active">{props.subtitle}</li>
          </ul>
        </div>
        {/* 
        <div className="col-auto float-end ms-auto">
          <Link
            to="#"
            className="btn add-btn"
            onClick={props.handleOpenNewChatModal}
            // data-bs-toggle="modal"
            // data-bs-target={props.modal}
          >
            <i className="fa fa-plus" /> {t('Start a new campaign')}
            {props.name}
          </Link>
        </div>
         */}
      </div>
    );
  }
  return (
    <>
      <div className="page-header">{addButton}</div>
    </>
  );
};

export default Breadcrumbs;
