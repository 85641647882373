export const handleApiError = (error, setErrorMessage, setValidationError = null, t) => {
  
  if (error.response && error.response.data && error.response.data.errCode) {
    const data = error.response.data;
    if (data && data.errCode) {
      // 1. Known error with custom errCode
      if (data.errCode === '422_VALIDATION_FAILED' && setValidationError) {
        // Handle the validation error specifically (only if setValidationError is passed)
        const validationErrors = data.errValidation || {};
        setValidationError(validationErrors);  // Set validation errors to state
      } else {
        // Known error with custom errCode
        setErrorMessage(t(`errors.${data.errCode}`)); // Translate the custom errCode to a user-friendly message
      }
    } else {
      // 2. Unknown error with HTTP status code
      const status = error.response.status;
      const statusMessage = getHttpStatusMessage(status); // Get a user-friendly message for the HTTP status code
      setErrorMessage(t(`errors.${statusMessage}`)); // Translate HTTP status message to a message
    }

  // 3. Unknown error without HTTP status (e.g., network error)
  } else if (error.request) {
    console.error('No response received:', error.request);
    setErrorMessage(t('errors.NETWORK_ERROR')); // Display network error message

  // Catch-all for other errors (e.g., configuration errors, etc.)
  } else {
    console.error('Unexpected Error:', error.message);
    setErrorMessage(t('errors.UNKNOWN_ERROR')); // Display generic unknown error message
  }
};

// Helper function to map HTTP status codes to friendly messages
export const getHttpStatusMessage = (status) => {
  switch (status) {
    case 400:
      return 'Invalid request. Please check your input.';
    case 401:
      return 'Unauthorized. Please log in.';
    case 403:
      return 'Access forbidden. You do not have permission to view this page.';
    case 404:
      return 'Resource not found.';
    case 500:
      return 'Server error. Please try again later.';
    case 502:
      return 'Temporary server issue. Please try again later.';
    case 503:
      return 'Service is unavailable at the moment. Please try again later.';
    case 504:
      return 'Request timed out. Please try again.';
    default:
      return 'An unexpected error occurred. Please try again.';
  }
};