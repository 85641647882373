//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : scrollChatUtils.js
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (24 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240724     Jecyline     create scrollChatUtils.jsx
//* 20240801     Jecyline     add jumpToDiv
//* 20241001     Jecyline     move ref.current checking
//***************************************************************************/
export const scrollToDiv = (ref) => {
  setTimeout(() => {
    if (ref?.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, 100);
};

export const jumpToDiv = (ref) => {
  setTimeout(() => {
    if (ref?.current) {
      ref.current.scrollIntoView();
    }
  }, 100);
};