//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : campaignSummarySlice.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 Oct 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20241001     Jecyline      create campaignSummarySlice.jsx for campaign summary reducer
//* 20241001     Jecyline      add setCampaignList, setCurrentCampaignData for campaign campaign pagination
//
//***************************************************************************/
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  campaignList: [],
  selectedCampaignId: '',
  currentCampaignData: null,
  isLoading: false,
};

export const campaignSummarySlice = createSlice({
  name: "campaign_summary",
  initialState,
  reducers: {
    setCampaignList: (state, action) => {
      // const data = { id: 'all', name: 'All Campaign' };
      // state.campaignList = [data, ...action.payload];
      state.campaignList = action.payload;
    },
    setSelectedCampaignId: (state, action) => {
      state.selectedCampaignId = action.payload;
    },
    setCurrentCampaignData: (state, action) => {
      state.currentCampaignData = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { 
  setCampaignList, 
  setSelectedCampaignId,
  setCurrentCampaignData,
  setIsLoading,
} = campaignSummarySlice.actions;

export default campaignSummarySlice.reducer;
