//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : NewChatModal.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (24 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240724     Jecyline     create NewChatModal.jsx for add new chat room
//* 20240726     Jecyline     language translation
//* 20240919     Glynnis      Rename chat to campaign
//
//***************************************************************************/
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const NewChatModal = ({ showNewChatModal, handleCloseNewChatModal, handleCreateChat, campaignType }) => {
  const { t } = useTranslation();

  const [newChatName, setNewChatName] = useState('');
  const [error, setError] = useState('');

  const handleCreate = () => {
    if(newChatName === ''){
      setError('Please key in campaign name');
    } else {
      handleCreateChat(newChatName);
      setNewChatName('');
    }
  };

  return (
    <div>
      {/* Backdrop */}
      <div className={`modal-backdrop ${showNewChatModal ? 'show' : ''}`} onClick={handleCloseNewChatModal}></div>

      {/* Modal */}
      <div className={`modal custom-modal ${showNewChatModal ? 'show d-block' : 'd-none'} fade`} tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{campaignType === 'Normal' ? t('New Campaign') : t(campaignType)}</h5>
              <button type="button" className="btn-close" onClick={handleCloseNewChatModal}>
                &times;
              </button>
            </div>
            <div className="modal-body">
              <input
                className="form-control"
                type="text"
                value={newChatName}
                onChange={(e) => setNewChatName(e.target.value)}
                placeholder={t('Enter campaign name')}
              />
              <span className="text-danger d-block">
                {error}
              </span>
            </div>
            <div className="submit-section">
              <button type="button" className="btn btn-primary" onClick={handleCreate}>
                {t('Create')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewChatModal;
