//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : chatSmartScan.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign   Description
//* ------------ ----   ------------------------------------------------------
//* 20240924     Glynnis  Added smart scan file
//
//***************************************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  ReferenceDot,
} from 'recharts';
import {
  budget,
  impression,
  reach,
  reach_lower,
  reach_upper,
  impression_lower,
  impression_upper,
  impressionCurve,
  reachCurve,
  audienceSizeLowerBound,
  audienceSizeUpperBound,
  targetAudienceSize,
  buyingType,
  currency,
  externalBudget,
  externalImpression,
  externalReach,
  externalMaximumBudget,
  externalMaximumImpression,
  externalMaximumReach,
  externalMinimumBudget,
  externalMinimumImpression,
  externalMinimumReach,
  frequencyDistributionMap,
  frequencyDistributionMapAgg,
} from './data/smart';

const ChatSmartScan = () => {

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  // Reference to the component to capture
  const componentRef = useRef();

  // Function to generate PDF
  const generatePdf = () => {
    const element = componentRef.current;

    html2canvas(element).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("download.pdf");
    });
  };

  // const [data, setData] = useState(null);

  // // Fetch data from data.json
  // useEffect(() => {
  //   fetch('./data/smart.json')
  //     .then((response) => response.json())
  //     .then((jsonData) => setData(jsonData))
  //     .catch((error) => console.error('Error fetching data:', error));
  // }, []);

  // if (!data) {
  //   // Show a loading state while data is being fetched
  //   return <div>Loading data...</div>;
  // }

  // // Destructure the data
  // const {
  //   audience_size_lower_bound: audienceSizeLowerBound,
  //   audience_size_upper_bound: audienceSizeUpperBound,
  //   buying_type: buyingType,
  //   currency,
  //   curve_budget_reach: {
  //     budget,
  //     reach,
  //     reach_lower: reachLower,
  //     reach_upper: reachUpper,
  //     impression,
  //     impression_lower: impressionLower,
  //     impression_upper: impressionUpper,
  //   },
  //   impression_curve: impressionCurve,
  //   reach_curve: reachCurve,
  //   frequency_distribution_map: frequencyDistributionMap,
  //   frequency_distribution_map_agg: frequencyDistributionMapAgg,
  //   external_budget: externalBudget,
  //   external_impression: externalImpression,
  //   external_reach: externalReach,
  //   external_maximum_budget: externalMaximumBudget,
  //   external_maximum_impression: externalMaximumImpression,
  //   external_maximum_reach: externalMaximumReach,
  //   external_minimum_budget: externalMinimumBudget,
  //   external_minimum_impression: externalMinimumImpression,
  //   external_minimum_reach: externalMinimumReach,
  //   target_audience_size: targetAudienceSize,
  //   curve_lower_confidence_range: {
  //     reach: reach_lower,
  //     impression_lower: impression_lower,
  //   },
  //   curve_upper_confidence_range: {
  //     reach: reach_upper,
  //     impression_upper: impression_upper,
  //   }
  // } = data;

  // Prepare the data in the required format
  const chartData = budget.map((value, index) => ({
    budget: value,
    reach: reach[index],
    reachLower: reach_lower[index],
    reachUpper: reach_upper[index],
    impression: impression[index],
    impressionLower: impression_lower[index],
    impressionUpper: impression_upper[index],
    impressionCurve: impressionCurve[index],
    reachCurve: reachCurve[index],
  }));

  // Utility function to format numbers with 'k', 'M', 'B'
  const kFormatter = (num) => {
    if (Math.abs(num) >= 1.0e9) {
      // Billion
      return (num / 1.0e9).toFixed(1).replace(/\.0$/, '') + 'B';
    } else if (Math.abs(num) >= 1.0e6) {
      // Million
      return (num / 1.0e6).toFixed(1).replace(/\.0$/, '') + 'M';
    } else if (Math.abs(num) >= 1.0e3) {
      // Thousand
      return (num / 1.0e3).toFixed(1).replace(/\.0$/, '') + 'k';
    } else {
      return num.toString();
    }
  };

  // Currency formatter for budgets
  const currencyFormatter = (num) => {
    const currencySymbol = '₹';
    if (Math.abs(num) >= 1.0e9) {
      return currencySymbol + (num / 1.0e9).toFixed(1).replace(/\.0$/, '') + 'B';
    } else if (Math.abs(num) >= 1.0e6) {
      return currencySymbol + (num / 1.0e6).toFixed(1).replace(/\.0$/, '') + 'M';
    } else if (Math.abs(num) >= 1.0e3) {
      return currencySymbol + (num / 1.0e3).toFixed(1).replace(/\.0$/, '') + 'k';
    } else {
      return currencySymbol + num.toString();
    }
  };

  // Format audience size bounds for labels
  const formattedAudienceLower = kFormatter(audienceSizeLowerBound);
  const formattedAudienceUpper = kFormatter(audienceSizeUpperBound);
  const formattedTargetAudienceSize = kFormatter(targetAudienceSize);

  // Format external data for labels
  const formattedExternalBudget = currencyFormatter(externalBudget);
  const formattedExternalReach = kFormatter(externalReach);
  const formattedExternalImpression = kFormatter(externalImpression);

  const formattedExternalMaxBudget = currencyFormatter(externalMaximumBudget);
  const formattedExternalMaxReach = kFormatter(externalMaximumReach);
  const formattedExternalMaxImpression = kFormatter(externalMaximumImpression);

  const formattedExternalMinBudget = currencyFormatter(externalMinimumBudget);
  const formattedExternalMinReach = kFormatter(externalMinimumReach);
  const formattedExternalMinImpression = kFormatter(externalMinimumImpression);

  // Prepare data for the frequency distribution chart
  const frequencyChartData = frequencyDistributionMap.map((item) => {
    const dataPoint = { reach: item.key };
    item.value.forEach((frequencyValue, index) => {
      dataPoint[`frequency_${index + 1}`] = frequencyValue;
    });
    return dataPoint;
  });

  // Determine the list of frequency keys
  const frequencyKeys = Object.keys(frequencyChartData[0]).filter(
    (key) => key !== 'reach'
  );

  // Prepare data for the aggregated frequency distribution chart
  const frequencyAggChartData = frequencyDistributionMapAgg.map((item) => {
    const dataPoint = { reach: item.key };
    item.value.forEach((frequencyValue, index) => {
      dataPoint[`frequency_${index + 1}`] = frequencyValue;
    });
    return dataPoint;
  });

  // Determine the list of frequency keys for aggregated data
  const frequencyAggKeys = Object.keys(frequencyAggChartData[0]).filter(
    (key) => key !== 'reach'
  );

  // Function to generate colors for the bars
  const getColor = (index) => {
    const colors = [
      '#8884d8',
      '#82ca9d',
      '#ffc658',
      '#d0ed57',
      '#a4de6c',
      '#8dd1e1',
      '#83a6ed',
      '#8a8af4',
      '#a28ef5',
      '#c18ff6',
      '#e090f7',
      '#ff91f9',
      '#ff92d3',
      '#ff939b',
      '#ff9464',
      '#ff9533',
    ];
    return colors[index % colors.length];
  };

  return (
    
    <div className="main-wrapper">
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div>
            {/* The component that you want to convert to PDF */}
            <div ref={componentRef} style={{ padding: "30px", backgroundColor: "transparent" }}>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div>
                    <button onClick={goBack}>Go Back</button>
                  </div>
                  <div>
                    <p style={{ padding: "10px", color: "#fff", fontsize: "16px"}}>Summary</p>
                    <p style={{ padding: "10px", color: "#fff" }}>This paper explores the relationship between advertising budget and audience reach, a critical consideration for marketers aiming to maximize their campaign’s visibility. Traditionally, reach models primarily hinge on internal datasets like customer behavior and previous ad performance to predict outcomes. However, the paper introduces a more robust approach by incorporating *external data* (such as demographic trends, social media activity, or market research) to enrich these predictions.</p>

                    <p style={{ padding: "10px", color: "#fff" }}>One of the key contributions is the establishment of *confidence bounds*—a statistical measure that quantifies the uncertainty surrounding reach estimates at different budget levels. By offering confidence bounds, marketers can gain deeper insight into both the potential rewards and risks associated with various spending strategies. The paper emphasizes that incorporating external data not only improves the precision of reach forecasts but also helps reduce the margin of uncertainty, which is crucial to making more informed, data-driven budgetary decisions.</p>

                    <p style={{ padding: "10px", color: "#fff" }}>Ultimately, combining internal performance metrics with external sources of information allows marketers to estimate reach with enhanced accuracy and reliability, fostering more strategic allocation of resources.</p>
                    <h2>Reach vs. Budget with Confidence Bounds and External Data</h2>
                    <p style={{ padding: "10px", color: "#fff" }}>
                      Buying Type: {buyingType} | Currency: {currency}
                    </p>
                    <ResponsiveContainer width="100%" height={400} style={{padding: "20px"}}>
                      <LineChart data={chartData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="budget"
                          tickFormatter={currencyFormatter}
                          label={{ value: 'Budget', position: 'insideBottom', offset: -5 }}
                        />
                        <YAxis
                          tickFormatter={kFormatter}
                          label={{ value: 'Reach', angle: -90, position: 'insideLeft', offset: 0 }}
                        />
                        <Tooltip
                          formatter={(value, name) => {
                            if (name === 'Budget') {
                              return currencyFormatter(value);
                            }
                            return kFormatter(value);
                          }}
                          labelFormatter={(label) => `Budget: ${currencyFormatter(label)}`}
                        />
                        <Legend />
                        {/* Original Reach Line */}
                        <Line
                          type="monotone"
                          dataKey="reach"
                          stroke="#0000FF"
                          strokeWidth={2}
                          dot={false}
                          name="Reach"
                        />
                        {/* Reach Lower Bound Line */}
                        <Line
                          type="monotone"
                          dataKey="reachLower"
                          stroke="#FF0000"
                          strokeWidth={1}
                          dot={false}
                          strokeDasharray="5 5"
                          name="Reach Lower Bound"
                        />
                        {/* Reach Upper Bound Line */}
                        <Line
                          type="monotone"
                          dataKey="reachUpper"
                          stroke="#00FF00"
                          strokeWidth={1}
                          dot={false}
                          strokeDasharray="5 5"
                          name="Reach Upper Bound"
                        />
                        {/* Reach Curve Line */}
                        <Line
                          type="monotone"
                          dataKey="reachCurve"
                          stroke="#FF00FF"
                          strokeWidth={2}
                          dot={false}
                          name="Reach Curve"
                        />
                        {/* Audience Size Lower Bound Reference Line */}
                        <ReferenceLine
                          y={audienceSizeLowerBound}
                          stroke="orange"
                          strokeDasharray="3 3"
                          label={{
                            value: `Audience Size Lower Bound (${formattedAudienceLower})`,
                            position: 'insideTopLeft',
                            fill: 'orange',
                          }}
                        />
                        {/* Audience Size Upper Bound Reference Line */}
                        <ReferenceLine
                          y={audienceSizeUpperBound}
                          stroke="orange"
                          strokeDasharray="3 3"
                          label={{
                            value: `Audience Size Upper Bound (${formattedAudienceUpper})`,
                            position: 'insideTopLeft',
                            fill: 'orange',
                          }}
                        />
                        {/* Target Audience Size Reference Line */}
                        <ReferenceLine
                          y={targetAudienceSize}
                          stroke="red"
                          strokeDasharray="3 3"
                          label={{
                            value: `Target Audience Size (${formattedTargetAudienceSize})`,
                            position: 'insideTopRight',
                            fill: 'red',
                          }}
                        />
                        {/* External Data Point */}
                        <ReferenceDot
                          x={externalBudget}
                          y={externalReach}
                          r={5}
                          fill="purple"
                          stroke="purple"
                          label={{
                            value: `External Data (${formattedExternalBudget}, ${formattedExternalReach})`,
                            position: 'top',
                            fill: 'purple',
                          }}
                        />
                        {/* External Maximum Budget Line */}
                        <ReferenceLine
                          x={externalMaximumBudget}
                          stroke="grey"
                          strokeDasharray="3 3"
                          label={{
                            value: `External Max Budget (${formattedExternalMaxBudget})`,
                            position: 'top',
                            fill: 'grey',
                          }}
                        />
                        {/* External Minimum Budget Line */}
                        <ReferenceLine
                          x={externalMinimumBudget}
                          stroke="grey"
                          strokeDasharray="3 3"
                          label={{
                            value: `External Min Budget (${formattedExternalMinBudget})`,
                            position: 'bottom',
                            fill: 'grey',
                          }}
                        />
                        {/* External Maximum Reach Line */}
                        <ReferenceLine
                          y={externalMaximumReach}
                          stroke="grey"
                          strokeDasharray="3 3"
                          label={{
                            value: `External Max Reach (${formattedExternalMaxReach})`,
                            position: 'insideRight',
                            fill: 'grey',
                          }}
                        />
                        {/* External Minimum Reach Line */}
                        <ReferenceLine
                          y={externalMinimumReach}
                          stroke="grey"
                          strokeDasharray="3 3"
                          label={{
                            value: `External Min Reach (${formattedExternalMinReach})`,
                            position: 'insideRight',
                            fill: 'grey',
                          }}
                        />
                      </LineChart>
                    </ResponsiveContainer>

                    <h2>Impressions vs. Budget with Confidence Bounds and External Data</h2>
                    <p>
                      Buying Type: {buyingType} | Currency: {currency}
                    </p>
                    <ResponsiveContainer width="100%" height={400}>
                      <LineChart data={chartData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="budget"
                          tickFormatter={currencyFormatter}
                          label={{ value: 'Budget', position: 'insideBottom', offset: -5 }}
                        />
                        <YAxis
                          tickFormatter={kFormatter}
                          label={{ value: 'Impressions', angle: -90, position: 'insideLeft', offset: 0 }}
                        />
                        <Tooltip
                          formatter={(value, name) => {
                            if (name === 'Budget') {
                              return currencyFormatter(value);
                            }
                            return kFormatter(value);
                          }}
                          labelFormatter={(label) => `Budget: ${currencyFormatter(label)}`}
                        />
                        <Legend />
                        {/* Original Impressions Line */}
                        <Line
                          type="monotone"
                          dataKey="impression"
                          stroke="#0000FF"
                          strokeWidth={2}
                          dot={false}
                          name="Impression"
                        />
                        {/* Impression Lower Bound Line */}
                        <Line
                          type="monotone"
                          dataKey="impressionLower"
                          stroke="#FF0000"
                          strokeWidth={1}
                          dot={false}
                          strokeDasharray="5 5"
                          name="Impression Lower Bound"
                        />
                        {/* Impression Upper Bound Line */}
                        <Line
                          type="monotone"
                          dataKey="impressionUpper"
                          stroke="#00FF00"
                          strokeWidth={1}
                          dot={false}
                          strokeDasharray="5 5"
                          name="Impression Upper Bound"
                        />
                        {/* Impression Curve Line */}
                        <Line
                          type="monotone"
                          dataKey="impressionCurve"
                          stroke="#FF00FF"
                          strokeWidth={2}
                          dot={false}
                          name="Impression Curve"
                        />
                        {/* Audience Size Lower Bound Reference Line */}
                        <ReferenceLine
                          y={audienceSizeLowerBound}
                          stroke="orange"
                          strokeDasharray="3 3"
                          label={{
                            value: `Audience Size Lower Bound (${formattedAudienceLower})`,
                            position: 'insideTopLeft',
                            fill: 'orange',
                          }}
                        />
                        {/* Audience Size Upper Bound Reference Line */}
                        <ReferenceLine
                          y={audienceSizeUpperBound}
                          stroke="orange"
                          strokeDasharray="3 3"
                          label={{
                            value: `Audience Size Upper Bound (${formattedAudienceUpper})`,
                            position: 'insideTopLeft',
                            fill: 'orange',
                          }}
                        />
                        {/* External Data Point */}
                        <ReferenceDot
                          x={externalBudget}
                          y={externalImpression}
                          r={5}
                          fill="purple"
                          stroke="purple"
                          label={{
                            value: `External Data (${formattedExternalBudget}, ${formattedExternalImpression})`,
                            position: 'top',
                            fill: 'purple',
                          }}
                        />
                        {/* External Maximum Budget Line */}
                        <ReferenceLine
                          x={externalMaximumBudget}
                          stroke="grey"
                          strokeDasharray="3 3"
                          label={{
                            value: `External Max Budget (${formattedExternalMaxBudget})`,
                            position: 'top',
                            fill: 'grey',
                          }}
                        />
                        {/* External Minimum Budget Line */}
                        <ReferenceLine
                          x={externalMinimumBudget}
                          stroke="grey"
                          strokeDasharray="3 3"
                          label={{
                            value: `External Min Budget (${formattedExternalMinBudget})`,
                            position: 'bottom',
                            fill: 'grey',
                          }}
                        />
                        {/* External Maximum Impression Line */}
                        <ReferenceLine
                          y={externalMaximumImpression}
                          stroke="grey"
                          strokeDasharray="3 3"
                          label={{
                            value: `External Max Impression (${formattedExternalMaxImpression})`,
                            position: 'insideRight',
                            fill: 'grey',
                          }}
                        />
                        {/* External Minimum Impression Line */}
                        <ReferenceLine
                          y={externalMinimumImpression}
                          stroke="grey"
                          strokeDasharray="3 3"
                          label={{
                            value: `External Min Impression (${formattedExternalMinImpression})`,
                            position: 'insideRight',
                            fill: 'grey',
                          }}
                        />
                      </LineChart>
                    </ResponsiveContainer>

                    <h2>Frequency Distribution of Reach</h2>
                    <ResponsiveContainer width="100%" height={400}>
                      <BarChart data={frequencyChartData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="reach"
                          tickFormatter={kFormatter}
                          label={{ value: 'Reach', position: 'insideBottom', offset: -5 }}
                        />
                        <YAxis
                          tickFormatter={(value) => (value * 100).toFixed(0) + '%'}
                          label={{ value: 'Percentage', angle: -90, position: 'insideLeft', offset: 0 }}
                        />
                        <Tooltip
                          formatter={(value, name) => [(value * 100).toFixed(2) + '%', name]}
                          labelFormatter={(label) => `Reach: ${kFormatter(label)}`}
                        />
                        <Legend />
                        {frequencyKeys.map((key, index) => (
                          <Bar
                            key={key}
                            dataKey={key}
                            stackId="a"
                            fill={getColor(index)}
                            name={`Frequency ${index + 1}`}
                          />
                        ))}
                      </BarChart>
                    </ResponsiveContainer>

                    <h2>Aggregated Frequency Distribution</h2>
                    <ResponsiveContainer width="100%" height={400}>
                      <BarChart data={frequencyAggChartData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="reach"
                          tickFormatter={kFormatter}
                          label={{ value: 'Reach', position: 'insideBottom', offset: -5 }}
                        />
                        <YAxis
                          tickFormatter={kFormatter}
                          label={{ value: 'Count', angle: -90, position: 'insideLeft', offset: 0 }}
                        />
                        <Tooltip
                          formatter={(value, name) => [kFormatter(value), name]}
                          labelFormatter={(label) => `Reach: ${kFormatter(label)}`}
                        />
                        <Legend />
                        {frequencyAggKeys.map((key, index) => (
                          <Bar
                            key={key}
                            dataKey={key}
                            stackId="a"
                            fill={getColor(index)}
                            name={`Frequency ${index + 1}`}
                          />
                        ))}
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
                
            </div>
            </div>

          {/* Button to trigger PDF generation */}
          <button onClick={generatePdf}>Download as PDF</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatSmartScan;
