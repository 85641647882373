//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : index.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 Oct 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20241001     Jecyline     Add index.s file for Campaign Summary
//
//***************************************************************************/
import React, { Suspense, useState, useEffect } from 'react';
import useAxiosInterceptors from '../../../../hooks/useAxiosInterceptors'; // Import the custom hook
import { useTranslation } from 'react-i18next';
const CampaignPolotnoView = React.lazy(() => import('./campaignPolotnoView'));

const CampaignPolotno = () => {
  useAxiosInterceptors(); 
  const { t } = useTranslation();

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className={`file-wrap file-sidebar-toggle`}>
                <div className="file-cont-wrap">
                  <div className="file-cont-inner">
                    <div className="file-cont-header">
                      <div className="file-options">
                        {/* <Link
                          to="#"
                          id="file_sidebar_toggle"
                          className="file-sidebar-toggle"
                          onClick={toggleSidebar}
                        >
                          <i className="fa-solid fa-bars" />
                        </Link> */}
                      </div>
                      <span>{t('Editor')}</span>
                      <div className="file-options">
                        <span className="btn-file">
                        </span>
                      </div>
                    </div>
                    <div className="file-content">
                      <Suspense fallback={<div>{t('Loading...')}</div>}>
                        <CampaignPolotnoView />
                      </Suspense>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    </>
  );
};

export default CampaignPolotno;
